import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler,HttpResponse, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './services/auth.service';
import * as CryptoJS from 'crypto-js';
import {tap, retry, shareReplay} from 'rxjs/operators';
@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  enableEncryption: any = false;
  orignalHash: string = '';

  constructor(private authenticationService: AuthService) {
  }

  private generateRandom(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  private generateHash() {
    // const time = moment().format();
    const time = new Date().getTime();
    let resData = this.generateRandom(6);
    resData += time;
    this.orignalHash = resData;
    // console.log('signature', resData);
    const secretKeyBytes = '$omemeta$alt';
    const signatureBytes = CryptoJS.HmacSHA256(resData, secretKeyBytes);
    const signatureBase64String = CryptoJS.enc.Base64.stringify(signatureBytes);
    //  console.log('signatureBase64', signatureBase64String);
    return signatureBase64String;

  }
  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   // add authorization header with basic auth credentials if available
  //   const currentUser: any = this.authenticationService.currentUser;
  //   if (currentUser && currentUser.source && currentUser.source._value && currentUser.source._value.token && request.url.indexOf(':8443') === -1) {
  //     let urlTmp = request.url;
  //     let bodyTmp = request.body;
  //     if (request.method.toLocaleUpperCase() === 'POST') {
  //       if (bodyTmp === null) {
  //         bodyTmp = {tnp: this.orignalHash};
  //       } else {
  //         bodyTmp.tnp = this.orignalHash;
  //       }
  //     } else if (request.method.toLocaleUpperCase() === 'GET') {
  //       urlTmp += request.url.indexOf('?') > -1 ? '&tnp=' + this.orignalHash : '?tnp=' + this.orignalHash;
  //     }
  //     if (request.url !== 'https://api.ipify.org?format=json') {
  //       request = request.clone({
  //         setHeaders: {
  //           Authorization: `Bearer ${currentUser.source._value.token}`,
  //           'content-type': `application/json`,
  //           encryption: this.enableEncryption,
  //           hash: this.generateHash(),
  //           platform: 'web',
  //           apiversion: '1.0',
  //         },
  //         url: urlTmp,
  //         body: bodyTmp
  //       });
  //     } else {
  //       request = request.clone({
  //         setHeaders: {
  //           'Access-Control-Allow-Origin': 'https://royalhub.io',
  //           'content-type': `application/json`,
  //         },
  //         url: urlTmp,
  //         body: bodyTmp
  //       });
  //     }
  //
  //   } else {
  //     request = request.clone({
  //       setHeaders: {
  //         'content-type': `application/json`,
  //         'encryption': this.enableEncryption,
  //         'hash': this.generateHash(),
  //         'platform': 'web',
  //         'apiversion': '1.0',
  //         'Access-Control-Allow-Origin': '*',
  //         'Access-Control-Allow-Credentials': 'true'
  //       }
  //     });
  //   }
  //
  //   return next.handle(request);
  // }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    let currentUser = '';
    if (localStorage.getItem('ROYALTOKEN_KEY')) {
      currentUser = JSON.parse(localStorage.getItem('ROYALTOKEN_KEY'));
    }
    const isLoggedIn = currentUser;
    const isApiUrl = request.url.startsWith(request.url);
    //console.log('Inside intercept of isLoggedIn:' + JSON.stringify(currentUser));
    //console.log('Inside intercept of isApiUrl:' + request.url);

    if (isLoggedIn && isApiUrl) {
      let urlsp = request.url.replace('/public', '');

      let urlTmp = request.url.replace('/public', '');;
      //console.log('Inside intercept of isApiUrl:' + urlsp);
      let bodyTmp = request.body;
      if (request.method.toLocaleUpperCase() === 'POST') {
        if (bodyTmp === null) {
          //bodyTmp = {tnp: this.orignalHash};
        } else {
          // bodyTmp.tnp = this.orignalHash;
        }
      }
      // else if (request.method.toLocaleUpperCase() === 'GET') {
      //   urlTmp += request.url.indexOf('?') > -1 ? '&tnp=' + this.orignalHash : '?tnp=' + this.orignalHash;
      // }
      if ( request.method === "POST"  && request.body instanceof FormData && request?.body?.get('file')) {
        request = request.clone({
          setHeaders: {
            Authorization: `${currentUser}`,
            'Access-Control-Allow-Origin': '*',
            'x-app-version': '4.0.19.2',
            'x-client': 'mobile',
            'x-client-id': '476843353.1602776689',
            'x-client-info': 'f582f6103068ba74fe67f8ee1e0ee274',
            'x-log-timing': 'true',
            'x-user-id': 'HZ010101M1H',
            'x-xid': 'bc1cac1e-94d2-6b09-e09f-70f42ea11fd5'
          },
          url: urlTmp,
          body: bodyTmp
        });
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `${currentUser}`,
            'Access-Control-Allow-Origin': '*',
            'content-type': 'application/json',
            'x-app-version': '4.0.19.2',
            'x-client': 'mobile',
            'x-client-id': '476843353.1602776689',
            'x-client-info': 'f582f6103068ba74fe67f8ee1e0ee274',
            'x-log-timing': 'true',
            'x-user-id': 'HZ010101M1H',
            'x-xid': 'bc1cac1e-94d2-6b09-e09f-70f42ea11fd5'
          },
          url: urlTmp,
          body: bodyTmp
        });
      }
    }


    return next.handle(request);

  }
}
