import { Component, OnInit } from '@angular/core';
declare var $:any
import {AuthService} from '../../services/auth.service';
import { LandingService } from 'src/app/services/landing.service';
import {MyprofileService} from "../../services/myprofile.service";

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  istoken:any;
  filteredArray: any;
  sliderImage:any;
  casinoUrl:string;
  Images: any;
  promoInfo: any;
  sliderlConfig = {
    slidesToShow: 3,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,

        }
      }

    ]
  };

  isLoader = false;
  bonusData: any;

  constructor(private profileServices:MyprofileService, private authService: AuthService,private api:LandingService) {
    if (this.authService.currentUserValue==null){
      this.istoken=true;
   }
   else{
     this.istoken=false;
   }
   }

  ngOnInit(): void {
    this.isLoader = true;
    this.getPromo();
    this.getBanners();
  }

  getBanners() {
    this.api.sliderimage().subscribe((res:any)=>{
      res;
      this.sliderImage=res;
      this.Images=res.data;
      let filteredArray = this.Images.filter((item) => item.ID == 11);
      this.filteredArray=filteredArray
    })
  }

  getPromo() {
    this.profileServices.getPromotion().subscribe((res)=>{
      this.bonusData =res.result;
      this.isLoader = false;
      console.log("!!!!!!!!!!!! bonusData", this.bonusData)
    })
  }

  clickMore(item) {
    this.promoInfo = item
    console.log("item",  item)
  }
  closeInfo() {
    this.promoInfo = null
  }
  setBonusDetailes(bonus){
    this.profileServices.setDetailedBonusInfo(bonus)
  }

  protected readonly length = length;
  protected readonly Array = Array;
}
