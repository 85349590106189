import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-expired-bonus-card',
  templateUrl: './expired-bonus-card.component.html',
  styleUrls: ['./expired-bonus-card.component.scss']
})
export class ExpiredBonusCardComponent implements OnInit {

  @Input() bonus: any;

  constructor() { }

  ngOnInit(): void {
  }

}
