<app-accountheader></app-accountheader>
<div class="u-i-p-c-body-bc">
    <div class="back-nav-bc"><i routerLink="/account" class="back-nav-icon-bc bc-i-round-arrow-left"></i><span
            class="back-nav-title-bc ellipsis">{{'Bonuses'|translate}}</span></div>
    <div class="hdr-navigation-scrollable-bc" data-scroll-lock-scrollable="">
        <div class="hdr-navigation-scrollable-content"><a class="hdr-navigation-link-bc active"
                href="/?profile=open&amp;account=bonuses&amp;page=sport">{{'Sport Bonus'|translate}}<i class="  count-blink-even "
                    data-badge=""></i></a><a class="hdr-navigation-link-bc "
                href="/?profile=open&amp;account=bonuses&amp;page=bonus-history">{{'Bonus history'|translate}}<i
                    class="  count-blink-even " data-badge=""></i></a><a class="hdr-navigation-link-bc "
                href="/?profile=open&amp;account=bonuses&amp;page=casino-free-spins">{{'Casino Free Spins'|translate}}<i
                    class="  count-blink-even " data-badge=""></i></a><a class="hdr-navigation-link-bc "
                href="/?profile=open&amp;account=bonuses&amp;page=casino">{{'Casino Bonus'|translate}}<i
                    class=" count-odd-animation count-blink-odd " data-badge="1"></i></a><a
                class="hdr-navigation-link-bc " href="/?profile=open&amp;account=bonuses&amp;page=refer-to-friend">{{'Refer a friend'|translate}}<i class="  count-blink-even " data-badge=""></i></a></div>
    </div>
    <div class="sportBonusWrapper-bc" data-scroll-lock-scrollable="">
        <p class="empty-b-text-v-bc">{{'There are no bonuses for selected type'|translate}}</p>
    </div>
</div>