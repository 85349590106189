<div class="pop-up-promo-info" (click)="closePopup()">
    <div class="pop-up-promo-info__content"
         (click)="$event.stopPropagation()"
         [ngClass]="{'close-anim': closeAnim}"
    >
        <div class="pop-up-promo-info__header">
            <div (click)="closePopup()">
                <span class="close-icon">&times;</span>
            </div>
        </div>
        <div class="pop-up-promo-info__body">
            <div [innerHTML]="sanitizeHtml(popupData)"></div>
        </div>
    </div>
</div>
