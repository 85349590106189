import { Component, OnInit, SecurityContext } from '@angular/core';
import { BonusService } from '../bonus.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MyprofileService } from 'src/app/services/myprofile.service';

@Component({
  selector: 'app-how-to-bonus',
  templateUrl: './how-to-bonus.component.html',
  styleUrls: ['./how-to-bonus.component.scss']
})
export class HowToBonusComponent implements OnInit {

  fullView = false;
  
  sanitizeHead = this.sanitizer.bypassSecurityTrustHtml('');
  sanitizeAmount = this.sanitizer.bypassSecurityTrustHtml('');
  sanitizeInfoRules = this.sanitizer.bypassSecurityTrustHtml('');
  

  constructor(private sanitizer: DomSanitizer, public _bonus : BonusService, private myprofileService:MyprofileService) { }
  
  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }

  ngOnInit(): void {
    // this._bonus.showHowTo.promoDetails['infoHead']
    this.sanitizeHead = this.sanitizeHtml(this._bonus.showHowTo.promoDetails['infoHead']);
    this.sanitizeAmount = this.sanitizeHtml(this._bonus.showHowTo.promoDetails['infoHeadAmount']);
    this.sanitizeInfoRules = this.sanitizeHtml(this._bonus.showHowTo.promoDetails['infoRules']);
    this.myprofileService.isHeaderFixed = true;
  }

  ViewMore(){
    this.fullView = true;
  }

  hide(){
    this._bonus.showHowTo = undefined;
  }

  ngOnDestroy() { 
    this.myprofileService.isHeaderFixed = false;
  }
}
