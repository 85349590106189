import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(value: any): string {
    if (value) {
      const date = new Date(value);
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear();

      const dayWithSuffix = this.getDayWithSuffix(day);

      return `${dayWithSuffix} ${month} ${year}`;
    }
    return '';
  }

  private getDayWithSuffix(day: number): string {
    if (day >= 11 && day <= 13) {
      return day + 'th';
    }

    switch (day % 10) {
      case 1:
        return day + 'st';
      case 2:
        return day + 'nd';
      case 3:
        return day + 'rd';
      default:
        return day + 'th';
    }
  }

}
