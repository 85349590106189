<div class="layout-content-holder-bc">
</div>

<div class="promotions-page">

  <div class="overlay" *ngIf="isLoader">
    <div class="loader-center">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <!-- <img loading="lazy" class="loader1" src="assets/images/loader-gif.gif" alt="Your Image"> -->
      <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>

    </div>
  </div>
  <app-promo-info
          *ngIf="this.promoInfo"
          [popupData]="promoInfo?.info"
          (close)="closeInfo()">
  </app-promo-info>
  <div class="promotions-page__promotions-wrapper">
    <div class="promotions-page__promo" *ngFor="let bonus of bonusData">
      <div class="promotions-page__bonus-image" (click)="clickMore(bonus)">
        <img loading="lazy" [src]="bonus.promoImageUrl" alt="">
      </div>
      <div class="promotions-page__bonus_info">
        <div class="promotions-page__bonus_info_titleBox">
          <div class="promotions-page__bonus_info_titleBox-code">
        {{bonus?.promoCode}}
      </div>
          <div class="promotions-page__bonus_info_titleBox-title">
        {{bonus?.tagLine}}
      </div>

        </div>

        <img loading="lazy" src="../../../assets/images/bonus/bonusShowDetailes.svg"
          class="promotions-page__bonus_info-showDetailes" alt=Detailes [routerLink]="['/bonusDetailes']"
          (click)="setBonusDetailes(bonus)">
      </div>
      <!-- <div class="promotions-page__bonus_info-button" [routerLink]="['/balancemanagement','Deposit']">
        <span>Claim now</span>
      </div> -->
    </div>
  </div>
</div>
