<div class="overlay" *ngIf="isLoader">
  <div class="loader-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <!-- <img loading="lazy" class="loader1" src="../../../assets/images/loader-gif.gif" alt="Your Image"> -->
    <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>

  </div>
</div>
<ng-container *ngIf="!isLoader">
  <ng-container *ngIf="scorecard?.scorecard">
    <div class="matchSection" #scrollContainer>
      <div class="first-card-section" *ngIf="stats?.currentOver|| stats?.lastOver">
        <div class="match-info-section">
          <div class="over-info-card">
            <div class="over-section">
              <span
                *ngIf="stats?.currentOver?.batsNames"><span *ngIf="stats?.currentOver?.bowlerName">{{stats?.currentOver?.bowlerName}} </span>
                {{'to'|translate}} {{stats?.currentOver?.batsNames[0]}} {{'and'|translate}}
                {{stats?.currentOver?.batsNames[1]}}</span>
              <div class="over-detail">
                <ng-container *ngFor="let item of stats?.currentOver?.balls">
                  <div class="over-runs">
                    <span class="run circle"
                      [ngClass]="{'four':item?.value=='4','six':item?.value=='6', 'zero':item?.value=='0', 'wicket':item?.value==='W'}">{{splitNos(item?.value)}}</span>
                    <span class="text-ball">{{splitWords(item?.value)}}</span>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="over-target">
              <div class="over-info">
                <span *ngIf="stats?.currentOver">{{'Ov'|translate}} {{stats?.currentOver?.overNumber}}</span>
                <span *ngIf="!stats?.currentOver">{{'Ov'|translate}} 0</span>
              </div>
              <div class="over-info">
                <span *ngIf="stats?.currentOver">{{stats?.currentOver?.runs}} {{'runs'|translate}}</span>
                <span *ngIf="!stats?.currentOver">0 {{'runs'|translate}}</span>
              </div>
              <div class="target-info">
                <span *ngIf="stats?.currentOver">{{stats?.currentOver?.score}}-{{stats?.currentOver?.wickets}}</span>
                <span *ngIf="!stats?.currentOver">0-0</span>
              </div>
              <div class="target-info">
                <span *ngIf="stats?.currentOver">{{'Current Over'|translate}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="match-info-section" *ngIf="stats?.lastOver">
          <div class="over-info-card">
            <div class="over-section">
              <span *ngIf="stats?.lastOver?.batsNames"><span *ngIf="stats?.lastOver?.bowlerName">{{stats?.lastOver?.bowlerName}} </span>to
                {{stats?.lastOver?.batsNames[0]}} {{"and"|translate}}
                {{stats?.lastOver?.batsNames[1]}}</span>
              <div class="over-detail">
                <ng-container *ngFor="let item of stats?.lastOver?.balls">
                  <div class="over-runs">
                    <span class="run circle"
                      [ngClass]="{'four':item?.value=='4','six':item?.value=='6', 'zero':item?.value=='0', 'wicket':item?.value==='W'}">{{splitNos(item?.value)}}</span>
                    <span class="text-ball">{{splitWords(item?.value)}}</span>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="over-target">
              <div class="over-info">
                <span *ngIf="stats?.lastOver">{{'Ov'|translate}} {{stats?.lastOver?.overNumber}}</span>
                <span *ngIf="!stats?.lastOver">{{'Ov'|translate}} 0</span>
              </div>
              <div class="over-info">
                <span *ngIf="stats?.lastOver">{{stats?.lastOver?.runs}} {{'runs'|translate}}</span>
                <span *ngIf="!stats?.lastOver">0 {{'runs'|translate}}</span>
              </div>
              <div class="target-info">
                <span *ngIf="stats?.lastOver">{{stats?.lastOver?.score}}-{{stats?.lastOver?.wickets}}</span>
                <span *ngIf="!stats?.lastOver">0-0</span>
              </div>
              <div class="target-info">
                <span *ngIf="stats?.lastOver">{{'Last Over'|translate}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Match Info Second section-->
      <div class="match-info-second-section">
        <div class="match-info-second-card">
          <div class="first-card">
            <div class="first-box">
              <small class="ellip">{{'PROJ SCR'|translate}}</small>
              <span *ngIf="stats?.battingTeam?.projScr">{{stats?.battingTeam?.projScr}}</span>
              <span *ngIf="!stats?.battingTeam?.projScr">-</span>
            </div>
            <div class="second-box">
              <small class="ellip">{{"P'SHIP"|translate}}</small>
              <span
                *ngIf="stats?.battingTeam?.partnerShipScore">{{stats?.battingTeam?.partnerShipScore}}({{stats?.battingTeam?.partnerShipBalls}})</span>
              <span *ngIf="!stats?.battingTeam?.partnerShipScore">-</span>
            </div>
            <div class="third-box">
              <small>{{'CRR'|translate}}</small>
              <span>{{stats?.battingTeam?.runRate ||'-'}}</span>
            </div>
            <div class="fourth-box">
              <small class="ellip">{{'LAST WKT'|translate}}</small>
              <span class="ellip" *ngIf="stats?.lastBatsmanOut">{{stats?.lastBatsmanOut?.name}}
                {{stats?.lastBatsmanOut?.batsManRuns}}({{stats?.lastBatsmanOut?.balls}})</span>
              <span class="ellip" *ngIf="!stats?.lastBatsmanOut">-</span>
            </div>
          </div>
          <table class="scoreBoard-card">
            <thead>
              <tr>
                <th class="main-head">{{'BATSMAN'|translate}}</th>
                <th class="runs">{{'R'|translate}}</th>
                <th class="balls">{{'B'|translate}}</th>
                <th class="fours">{{"4s"|translate}}</th>
                <th class="sixes">{{'6s'|translate}}</th>
                <th class="sr">{{'SR'|translate}}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of stats?.batsmen">
                <tr>
                  <td class="main-head">{{item?.name}}<sup *ngIf="item?.isOnStrike"> *</sup></td>
                  <td class="runs">{{item?.batsmanRuns || '-'}}</td>
                  <td class="balls">{{item?.balls|| '-'}}</td>
                  <td class="fours">{{item?.fours|| '-'}}</td>
                  <td class="sixes">{{item?.sixes || '-'}}</td>
                  <td class="sr">{{item?.strikeRate !== undefined ? item?.strikeRate?.toFixed(2) : '-'}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <table class="scoreBoard-card-1">
            <thead>
              <tr>
                <th class="main-head">{{'Bowler'|translate}}</th>
                <th class="runs">{{'O'|translate}}</th>
                <th class="balls">{{'M'|translate}}</th>
                <th class="fours">{{'R'|translate}}</th>
                <th class="sixes">{{'W'|translate}}</th>
                <th class="sr">{{'ECO'|translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="stats?.bowler">
                <td class="main-head">{{ stats?.bowler?.name}}</td>
                <td class="runs">{{ stats?.bowler?.overs}}</td>
                <td class="balls">{{ stats?.bowler?.maidens}}</td>
                <td class="fours">{{ stats?.bowler?.bowlerRuns}}</td>
                <td class="sixes">{{ stats?.bowler?.wickets}}</td>
                <td class="sr">{{ stats?.bowler?.economy?.toFixed(2)}}</td>
              </tr>
              <tr *ngIf="stats?.bowler?.length===0">
                <td class="main-head">-</td>
                <td class="runs">-</td>
                <td class="balls">-</td>
                <td class="fours">-</td>
                <td class="sixes">-</td>
                <td class="sr">-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="change-score-section" *ngIf="stats?.currentOver|| stats?.lastOver">
      <span class="inactive" (click)="scrollLeft()" [ngClass]="{'active':scrollLeftVisible}"></span>
      <span class="inactive" (click)="scrollRight()" [ngClass]="{'active':scrollRightVisible}"></span>
    </div>
  </ng-container>
  <ng-container *ngIf="!scorecard?.scorecard">
    <div class="SRWidgetContainer">
      <div class="sr-widget sr-widget-1"></div>
    </div>
  </ng-container>
</ng-container>
