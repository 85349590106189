<div class="account-header-X max-width-550">
    <div class="logoContainer">
        <a class="logoContianerX" id="close" (click)="navigateToHome()">
            <img loading="lazy" src="../../../../assets/images/11exch.png" alt="" />
        </a>
    </div>
    <div class="closeIconProfile active" (click)="navigateToHome()">
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.2248 14.3251L0.174805 13.2751L5.94981 7.50005L0.174805 1.72505L1.2248 0.675049L6.99981 6.45005L12.7748 0.675049L13.8248 1.72505L8.04981 7.50005L13.8248 13.2751L12.7748 14.3251L6.99981 8.55005L1.2248 14.3251Z"
                fill="#B6BECD" />
        </svg>
    </div>
</div>
<span class="account-header-hr"></span>
