<div class="notify" *ngIf="showNotifiction">
  <marquee behavior="" direction="">
    <div class="notification-msg">
      <span>{{notifyMsg}}</span>
    </div>
  </marquee>
  <svg (click)="closeNotify()" style="border: 1px solid #9CA3AF;background: #9CA3AF;border-radius: 50%;"
    xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 14 15" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M2.91728 10.7587C2.68949 10.9866 2.68952 11.3559 2.91735 11.5837C3.14517 11.8115 3.51452 11.8115 3.7423 11.5836L7.00036 8.32499L10.2587 11.5833C10.4865 11.8111 10.8558 11.8111 11.0836 11.5833C11.3114 11.3555 11.3114 10.9862 11.0836 10.7584L7.82525 7.49998L11.0834 4.24126C11.3111 4.01343 11.3111 3.64409 11.0833 3.41631C10.8555 3.18851 10.4861 3.18855 10.2583 3.41638L7.00024 6.67503L3.74191 3.41666C3.5141 3.18885 3.14475 3.18885 2.91695 3.41666C2.68914 3.64446 2.68914 4.01381 2.91695 4.24162L6.17541 7.50004L2.91728 10.7587Z"
      fill="#111827" />
  </svg>
</div>

<div class="overlay" *ngIf="isLoader">
  <div class="loader-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <!-- <img loading="lazy" class="loader1" src="assets/images/loader-gif.gif" alt="Your Image" /> -->
    <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>

  </div>
</div>
<app-versionPopUp *ngIf="this.versionPopUpOpen" [version]="version" (close)="closeVersionPopUp()"></app-versionPopUp>

<!-- <div class="Modal-Background" *ngIf="howToDownloadAPK">
  <div class="video-player">
    <img class="btn-close" (click)="closeVideoPopup()" src="../../../assets/images/close-circle.svg" alt="">
    <iframe class="video" src="https://player.vimeo.com/video/876696274?h=0c3a5f3af0" width="640" height="1138" frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <iframe class="video" src="https://player.vimeo.com/video/876696274?h=0c3a5f3af0&autoplay=1" width="640"
      height="1138" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <p><a href="https://vimeo.com/876696274">How to download 11exch APP</a> from <a
        href="https://vimeo.com/user208700594">Exch</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
  </div>
</div> -->

<!-- <div class="modal-background lang-popup" *ngIf="langPopup">
  <div class="lang-card">
    <div class="lang-select">
      <span>{{"Select Language"|translate}}</span>
      <svg (click)="closeLangPopup()" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25"
        fill="none">
        <path
          d="M6.22578 19.3251L5.17578 18.2751L10.9508 12.5L5.17578 6.72505L6.22578 5.67505L12.0008 11.45L17.7758 5.67505L18.8258 6.72505L13.0508 12.5L18.8258 18.2751L17.7758 19.3251L12.0008 13.55L6.22578 19.3251Z"
          fill="#B6BECD" />
      </svg>
    </div>
    <div class="lang-card-body">
      <div class="lang-body">
        <ng-container *ngFor="let lang of langs; let index=index">
          <div class="first" (click)="changeLanguage(lang.value)" [ngClass]="{'first_active': activeLanguage[index]}">
            <span>{{lang?.name}}</span>
          </div>
        </ng-container>
      </div>
    </div> -->
<!--    </div>-->
<!--  </div>-->


<div class="Modal-Background max-width-550" *ngIf="howToDownloadVideoPopup">
  <div class="video-player">
    <div class="close-img" (click)="closeVideoPopup()">
      <span>{{'Skip Video'|translate}}</span>
    </div>
    <iframe class="video" src="https://player.vimeo.com/video/876696274?h=0c3a5f3af0" width="640" height="1138"
      frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <iframe class="video" src="https://player.vimeo.com/video/876696274?h=0c3a5f3af0&autoplay=1" width="640"
      height="1138" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    <p><a href="https://vimeo.com/876696274">How to download 11exch APP</a> from <a
        href="https://vimeo.com/user208700594">Exch</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
  </div>
</div>

<div class="andriod-popup" *ngIf="downloadAppPopup && isAndroid && showDownloadAppPopup">
  <div class="rect-1"></div>
  <div class="rect-2"></div>
  <div class="andriod-card">
    <div class="cancel-icon">
      <svg (click)="removePopup()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"
        fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M2.0837 7.32778C1.92099 7.49053 1.92102 7.75432 2.08375 7.91703C2.24648 8.07974 2.5103 8.07973 2.673 7.91699L5.00019 5.5894L7.32756 7.91678C7.49027 8.07948 7.7541 8.07948 7.91681 7.91678C8.07952 7.75407 8.07952 7.49024 7.91681 7.32753L5.5894 5.00011L7.91665 2.67245C8.07931 2.50972 8.07931 2.2459 7.91656 2.0832C7.75385 1.92049 7.49002 1.92051 7.32731 2.08325L5.0001 4.41086L2.67272 2.08345C2.51 1.92073 2.24618 1.92073 2.08347 2.08345C1.92075 2.24617 1.92075 2.50998 2.08347 2.67271L4.41094 5.00015L2.0837 7.32778Z"
          fill="#111827" />
      </svg>
    </div>
    <div class="side-logo">
      <img src="../../../assets/images/app-11exch-logo.png" alt="">
    </div>
    <div class="app-text">
      <h2>{{'Downlod Android Application' | translate}}</h2>
      <h6>{{'Instant Download on your device'| translate}}</h6>
      <div class="star">
        <img src="../../../assets/images/app-star.svg" alt="star">
        <img src="../../../assets/images/app-star.svg" alt="star">
        <img src="../../../assets/images/app-star.svg" alt="star">
        <img src="../../../assets/images/app-star.svg" alt="star">
        <img src="../../../assets/images/app-star.svg" alt="star">
      </div>
    </div>
  </div>
  <a class="install-btn" (click)="goToAPK()">
    <button>{{'Install'| translate}}</button>
  </a>
</div>

<app-ios-instruction *ngIf="downloadAppPopup && showIosInstruction"
  (closeClick)="showIosInstruction = false; downloadAppPopup = false"></app-ios-instruction>

<div class="andriod-popup" *ngIf="downloadAppPopup && isIOS && showDownloadAppPopup">
  <div class="rect-1"></div>
  <div class="rect-2"></div>
  <div class="andriod-card">
    <div class="cancel-icon">
      <svg (click)="removePopup()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"
        fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M2.0837 7.32778C1.92099 7.49053 1.92102 7.75432 2.08375 7.91703C2.24648 8.07974 2.5103 8.07973 2.673 7.91699L5.00019 5.5894L7.32756 7.91678C7.49027 8.07948 7.7541 8.07948 7.91681 7.91678C8.07952 7.75407 8.07952 7.49024 7.91681 7.32753L5.5894 5.00011L7.91665 2.67245C8.07931 2.50972 8.07931 2.2459 7.91656 2.0832C7.75385 1.92049 7.49002 1.92051 7.32731 2.08325L5.0001 4.41086L2.67272 2.08345C2.51 1.92073 2.24618 1.92073 2.08347 2.08345C1.92075 2.24617 1.92075 2.50998 2.08347 2.67271L4.41094 5.00015L2.0837 7.32778Z"
          fill="#111827" />
      </svg>
    </div>
    <div class="side-logo">
      <img src="../../../assets/images/app-11exch-logo.png" alt="">
    </div>
    <div class="app-text">
      <h2>{{'Install On IOS'|translate}}</h2>
      <h6>{{'Instant Download on your device'|translate}}</h6>
      <div class="star">
        <img src="../../../assets/images/app-star.svg" alt="star">
        <img src="../../../assets/images/app-star.svg" alt="star">
        <img src="../../../assets/images/app-star.svg" alt="star">
        <img src="../../../assets/images/app-star.svg" alt="star">
        <img src="../../../assets/images/app-star.svg" alt="star">
      </div>
    </div>
  </div>
  <a class="install-btn" (click)="goToIOS()">
    <button>{{'Instructions'|translate}}</button>
  </a>
</div>

<re-captcha *ngIf="rSiteKey" #captchaRef="reCaptcha" (resolved)="resolved($event)" size="invisible"
  [siteKey]="rSiteKey"></re-captcha>

<div (click)="toggleshowhidebtn()" [ngClass]="{
    'layout-bc': true,
    'theme-default': true,
    'has-exchange-info': true,
    'is-home-page': true,
    'betslip-Hidden': true,
    'smart-panel-is-visible': !showsmartpanel
  }">
  <i *ngIf="favCount > 0" class=" count-odd-animation count-blink-even " [attr.data-badge]="favCount"></i>
  <div class="layout-header-holder-bc" [ngClass]="{ iFrameHeader: isIFrame() }">
    <div class="header max-width-550"
      [ngClass]="{ iFrameHeader: isIFrame(), 'fixedHeader':myprofile.isHeaderFixed && _bonusService.showAllBonuses }">
      <div class="main-header">
        <div (click)="clearIframe()">
          <a *ngIf="!isIFrame()" class="logo" (click)="show(); gotohome()">
            <img loading="lazy" class="hdr-logo-bc" src="../../../assets/images/11exch.png" alt="" />
          </a>
          <svg *ngIf="isIFrame()" xmlns="http://www.w3.org/2000/svg" width="14" height="14"
            style="height: 25px; width: 25px" viewBox="0 0 14 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M9.21407 11.839C9.47031 11.5828 9.47031 11.1672 9.21407 10.911L5.30308 7L9.21407 3.08905C9.47031 2.83277 9.47031 2.41723 9.21407 2.16094C8.95778 1.9047 8.54224 1.9047 8.28595 2.16094L3.91095 6.53594C3.65471 6.79223 3.65471 7.20777 3.91095 7.46406L8.28595 11.839C8.54224 12.0953 8.95778 12.0953 9.21407 11.839Z"
              fill="url(#paint0_linear_618_5690)" />
            <defs>
              <linearGradient id="paint0_linear_618_5690" x1="6.56251" y1="12.0312" x2="6.56251" y2="1.96876"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#CD4295" />
                <stop offset="1" stop-color="#A70D65" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div class="header-content">
          <div class="gift-redeem" (click)="this._bonusService.showAllBonuses = true;">
            <div class="redeem-box" *ngIf="getToken()">
              <img loading="lazy" src="/assets/images/gift-img-2.png" alt="redeem" style="height:30px; width:30px" />
            </div>
          </div>
          <div *ngIf="getToken()" class="balance-box">

            <span *ngIf="showBalance===true" class="balance-amt">₹ {{balanceamounts &&
              balanceamounts?.availBalance &&
              balanceamounts?.availBalance | indianCurrency}}</span>

            <span *ngIf="showBalance===false" class="balance-amt">
              ₹ {{formatAmountWithXs( balanceamounts &&
              balanceamounts?.availBalance &&
              balanceamounts?.availBalance | indianCurrency)}}
            </span>
          </div>
          <div
            *ngIf="getToken() && (loggerUsername?.includes('_') || loggerUsername?.includes('.com') || loggerUsername?.includes('+91'))"
            class="deposit-button" (click)="openSign($event)">
            <a>
              <img loading="lazy" src="../../../assets/images/wallet.svg" alt="wallet" />
              <span class="btn-text"> {{'Deposit'|translate}} </span>
            </a>
          </div>

          <div
            *ngIf="getToken() && (!loggerUsername?.startsWith('b06.91') && !loggerUsername?.includes('.com') && !loggerUsername?.includes('_') && !loggerUsername?.includes('+91'))"
            class="deposit-button" (click)="whatsappAccountRegistration($event)">
            <a>
              <img loading="lazy" src="../../../assets/images/wallet.svg" alt="wallet" />
              <span class="btn-text"> {{'Deposit'|translate}} </span>
            </a>
          </div>

          <div class="tab-nav-item-bc menu" [ngClass]="{ 'footer-active': router.url === '' }" *ngIf="isIFrame()"
            (click)="footermenubarshow()">
            <img class="casino-img" ngSrc="../../../assets/images/footer-menu.svg" alt="MENU" height="17" width="17">
            <p class="tab-nav-title-bc">{{'Menu'|translate}}</p>
          </div>
          <div class="person-logo" *ngIf="getToken() && !isIFrame()" routerLink="/account">
            <img src="../../../assets/images/person-logo.svg" alt="person">
          </div>
          <!-- <i class="hdr-user-avatar-icon-bc bc-i-user user-not-verified" *ngIf="getToken() && !isIFrame()"
            routerLink="/account"></i> -->

          <div id="demo" *ngIf="checkButtonsRender()" class="header-custom-buttons sign-in-button"
            (click)="signinshow()">
            <span> {{'Log in'|translate}} </span>
          </div>

          <button *ngIf="checkButtonsRender()" id="registerButton" class="header-custom-buttons register-button"
            (click)="registershow()">
            <!--                      <a class="btn register">-->
            <span> {{'Register'|translate}} </span>
            <!--                      </a>-->
          </button>
        </div>
      </div>
    </div>
    <div class="navBarmenu">
      <div class="navBar-scrollable" *ngIf="!isIFrame()" #menuContainer>
        <span [id]="item?.id" *ngFor="let item of menuItems; let index=index">
          <span class="name-menu" [ngClass]="{'name-menu-active':(authService.activeMenuItemId | async)== item?.id}"
            (click)="gotoRelatedNavmenu(item.url, index, item?.id)">{{item.name | translate}}</span>
          <span class="hr-menu" *ngIf="(authService.activeMenuItemId | async) == item?.id"></span>
        </span>
      </div>
      <button (click)="openMenu()" class="menu-btn-nav" *ngIf="!isIFrame()">
        <span><svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
            <path
              d="M4.99996 6.09647C4.91222 6.09697 4.82525 6.08016 4.74402 6.04698C4.6628 6.0138 4.58892 5.96492 4.52663 5.90313L0.526628 1.90313C0.401092 1.7776 0.330566 1.60734 0.330566 1.4298C0.330566 1.25227 0.401092 1.082 0.526628 0.956468C0.652163 0.830932 0.822426 0.760406 0.999961 0.760406C1.1775 0.760406 1.34776 0.830932 1.47329 0.956468L4.99996 4.4898L8.52663 0.963134C8.65416 0.853917 8.81821 0.796846 8.986 0.803327C9.15378 0.809807 9.31294 0.879363 9.43167 0.998092C9.5504 1.11682 9.61995 1.27598 9.62643 1.44377C9.63292 1.61155 9.57585 1.7756 9.46663 1.90313L5.46663 5.90313C5.34245 6.0263 5.17485 6.09573 4.99996 6.09647Z"
              fill="white" />
          </svg></span>
      </button>
    </div>
    <!-- <div class="center-bottom-btn nav-floating-btn-hide">
        <button class="btn nav-floating-btn bc-i-double-arrow-top" type="button"></button>
      </div> -->
  </div>






</div>


<div class="layout-betslip-holder-bc">
  <div [ngClass]="{
        'betslip-bc': true,
        Full: true,
        full: true,
        'display-none': betslips
      }" style="transform: translateY(0px); opacity: 1">
    <div id="betslip_container">
      <div class="betslip-full-content-bc">
        <div class="bs-f-header-bc">
          <div class="bs-f-header-top-stage-bc" (click)="betslip()">
            <i class="bs-f-h-t-close-icon-bc bc-i-close-remove"></i>
          </div>
          <div class="second-tabs-bc">
            <div class="tab-bc selected-underline betslip active" title="BetSlip"><span>BetSlip</span>
            </div>
          </div>
          <div class="settings-r-b-row-bc">
            <div class="form-control-bc select s-small has-icon filled">
              <label class="form-control-label-bc inputs"><i class="select-icon-bc bc-i-settings"></i><select
                  class="form-control-select-bc ellipsis">
                  <option value="0">{{'Always ask'|translate}}</option>
                  <option value="1">{{'Accept Higher odds'|translate}}</option>
                  <option value="2">{{'Accept Any odds'|translate}}</option>
                </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i></label>
            </div>
          </div>
        </div>
        <div class="bs-f-header-sub-navigation-bc "></div>
        <div class="bs-f-body-bc">
          <div class="bs-scroll-container-bc" data-scroll-lock-scrollable="">
            <p class="empty-b-text-v-bc">{{'Your betslip is empty'|translate}}</p>
            <div class="bs-actions-bc"></div>
          </div>
        </div>
        <div class="bs-f-footer ">
          <div class="bet-slip-switcher-wrapper-bc"></div>
          <div class="bs-info-massages">
            <div class="quickBetMsgWrapper-bc betslip-warning-message">
              <i class="bs-notification-icon-bc bc-i-attention"></i><span class="quickBetMsgText-bc">
                {{'To place your bet, please'|translate}}
                <span class="bet-slip-info-link" (click)="signinshow()">
                  {{'Log in'|translate}}
                </span>
                {{'or'|translate}}
                <span class="bet-slip-info-link">{{'Register'|translate}}
                </span>
              </span>
            </div>
          </div>
          <div class="bet-button-wrapper-bc content-type-0">
            <button class="btn a-color button-type-0" type="button" title="Bet Now" disabled="">
              <span>{{'Bet Now'|translate}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--login && register-->
<div class="signInPageShowHide max-width-550" [ngClass]="{'popup-holder-bc popup-holder-bc-1': true,windowed: windowshowhide,'full-screen': true,'content-manager': true,
  hidden: signinshowhide,'Modal-Background':showSignInPopup
    }">
  <!-- <div class="popup-holder-bc windowed content-manager hidden" style=""> -->
  <div class="popup-middleware-bc-1" [ngClass]="{'closeWindow':animateWindow}">
    <div class="popup-inner-bc popup-inner-bc-1" style="background: #000">
      <div class="entrance-popup-bc entrance-popup-bc-1  sign-in" style="background-color: #000" *ngIf="!showReg">
        <div class="e-p-content-holder-bc1">
          <div class="e-p-header-bc1">
            <div class="e-p-sections-bc1">
              <img loading="lazy" class="hdr-logo-bc-1" src="../../../assets/images/11exch.png" alt="11Exch"
                (click)="hidesigninRegister()" />
              <!--                <img  loading="lazy" style="height: 1.375rem; width:1.375rem; z-index: 100;" src="../../../assets/images/IndianFlag.svg"-->
              <!--                  alt="" />-->
            </div>
            <div class="e-p-sections-bc1">
              <div style="display: flex; align-items: center; justify-content: center;">
                <button class="e-p-section-item-bc2" (click)="registershow()" title="Register">
                  <span>{{'Register'|translate}}</span>
                </button>
                <div (click)="hidesigninRegister()" style="margin-left: 1rem; margin-right: 0.2rem;">
                  <svg style="border: 1px solid #9CA3AF;background: #9CA3AF;border-radius: 50%;"
                    xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 14 15" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2.91728 10.7587C2.68949 10.9866 2.68952 11.3559 2.91735 11.5837C3.14517 11.8115 3.51452 11.8115 3.7423 11.5836L7.00036 8.32499L10.2587 11.5833C10.4865 11.8111 10.8558 11.8111 11.0836 11.5833C11.3114 11.3555 11.3114 10.9862 11.0836 10.7584L7.82525 7.49998L11.0834 4.24126C11.3111 4.01343 11.3111 3.64409 11.0833 3.41631C10.8555 3.18851 10.4861 3.18855 10.2583 3.41638L7.00024 6.67503L3.74191 3.41666C3.5141 3.18885 3.14475 3.18885 2.91695 3.41666C2.68914 3.64446 2.68914 4.01381 2.91695 4.24162L6.17541 7.50004L2.91728 10.7587Z"
                      fill="#111827" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="e-p-content-bc1">
            <div class="e-p-body-bc" style="overflow-y: auto" *ngIf="showsign">
              <div class="login-page-abc">
                <app-login></app-login>
              </div>
            </div>
          </div>

          <div class="marquee-container">
            <div class="marquee-content">
              <div class="bonus-box">
                <ng-container *ngFor="let item of myCardPromo;let even = even;let odd = odd">
                  <div [ngClass]="{'bonus-card-box1': even,'bonus-card-box': odd}">
                    <img loading="lazy" class="star" src="../../../assets/images/star.svg" *ngIf="odd" alt="" />
                    <img loading="lazy" class="star3" src="../../../assets/images/star.svg" *ngIf="even" alt="" />
                    <div [ngClass]="{'bonus-inner-card1': even,'bonus-inner-card': odd}">
                      <img class="bonus-img" loading="lazy" src="../../../assets/images/bonus-gift-img-1.png"
                        *ngIf="odd" alt="" />
                      <img class="bonus-img" loading="lazy" src="../../../assets/images/bonus-gift-img-2.png"
                        *ngIf="even" alt="" />
                      <span>{{ item["short-info"] }}</span>
                    </div>
                    <img loading="lazy" class="star2" src="../../../assets/images/star.svg" *ngIf="odd" alt="" />
                    <img loading="lazy" class="star4" src="../../../assets/images/star.svg" *ngIf="even" alt="" />
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="entrance-popup-bc entrance-popup-bc-1 register" *ngIf="showReg">
        <div class="e-p-content-holder-bc1">
          <div class="e-p-header-bc1">
            <div style="display: flex; align-items: center">
              <img loading="lazy" class="hdr-logo-bc-1" src="../../../assets/images/11exch.png" alt="11Exch"
                (click)="hidesigninRegister()" />
              <!--                <img  loading="lazy" style="height: 1.375rem; width:1.375rem; z-index: 100;" src="../../../assets/images/IndianFlag.svg"-->
              <!--                  alt="" />-->

            </div>
            <div class="e-p-sections-bc1">
              <div style="display: flex; align-items: center; justify-content: center;">
                <button class="e-p-section-item-bc2" (click)="signinshow()"><span>{{'Log In'|translate}}</span></button>
                <div class="e-p-sections-bc1" (click)="hidesigninRegister()"
                  style="margin-left: 1rem; margin-right: 0.2rem;">
                  <svg style="border: 1px solid #9ca3af;background: #9ca3af;border-radius: 50%;"
                    xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 14 15" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M2.91728 10.7587C2.68949 10.9866 2.68952 11.3559 2.91735 11.5837C3.14517 11.8115 3.51452 11.8115 3.7423 11.5836L7.00036 8.32499L10.2587 11.5833C10.4865 11.8111 10.8558 11.8111 11.0836 11.5833C11.3114 11.3555 11.3114 10.9862 11.0836 10.7584L7.82525 7.49998L11.0834 4.24126C11.3111 4.01343 11.3111 3.64409 11.0833 3.41631C10.8555 3.18851 10.4861 3.18855 10.2583 3.41638L7.00024 6.67503L3.74191 3.41666C3.5141 3.18885 3.14475 3.18885 2.91695 3.41666C2.68914 3.64446 2.68914 4.01381 2.91695 4.24162L6.17541 7.50004L2.91728 10.7587Z"
                      fill="#111827" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="e-p-content-bc1">
            <div class="e-p-body-bc">
              <div class="login-page-abc">
                <app-register></app-register>
              </div>
            </div>
          </div>
          <div class="marquee-container">
            <div class="marquee-content">
              <div class="bonus-box">
                <ng-container *ngFor="let item of myCardPromo;let even = even;let odd = odd">
                  <div [ngClass]="{'bonus-card-box1': even,'bonus-card-box': odd}">
                    <img loading="lazy" class="star" src="../../../assets/images/star.svg" *ngIf="odd" alt="" />
                    <img loading="lazy" class="star3" src="../../../assets/images/star.svg" *ngIf="even" alt="" />
                    <div [ngClass]="{'bonus-inner-card1': even,'bonus-inner-card': odd}">
                      <img class="bonus-img" loading="lazy" src="../../../assets/images/bonus-gift-img-1.png"
                        *ngIf="odd" alt="" />
                      <img class="bonus-img" loading="lazy" src="../../../assets/images/bonus-gift-img-2.png"
                        *ngIf="even" alt="" />
                      <span>{{ item["short-info"] }}</span>
                    </div>
                    <img loading="lazy" class="star2" src="../../../assets/images/star.svg" *ngIf="odd" alt="" />
                    <img loading="lazy" class="star4" src="../../../assets/images/star.svg" *ngIf="even" alt="" />
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <span class="ellipse1"> </span>
  <img loading="lazy" src="../../../assets/images/bgImageSign2.svg" *ngIf="!showReg" class="backImage1" alt="" />
  <span class="ellipse2"></span>
  <img loading="lazy" class="bgImageSignIn" src="../../../assets/images/bgImageSignIn.svg" *ngIf="!showReg" alt="" />
</div>

<div *ngIf="menushow" [ngClass]="{'layout-betslip-holder-bc':true}">
  <div class="hdr-nav-menu-holder-bc ">
    <app-menu (closeClick)="menushow=false;"></app-menu>
  </div>
</div>
