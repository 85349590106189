import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { AccountModule } from './account/account.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SharedRoutingModule } from './shared-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
// import { LayoutComponent } from './layout/layout.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { LiveComponent } from './live/live.component';
import { SportComponent } from './sport/sport.component';
import { SportsbookComponent } from './sportsbook/sportsbook.component';
import { EsportsComponent } from './esports/esports.component';
import { CasinoComponent } from './casino/casino.component';
import { LivecasinoComponent } from './livecasino/livecasino.component';
import { VirtualsportsComponent } from './virtualsports/virtualsports.component';
import { PokerComponent } from './poker/poker.component';
import { SporttournamentComponent } from './sporttournament/sporttournament.component';
import { GamesComponent } from './games/games.component';
import { CasinotournamentsComponent } from './casinotournaments/casinotournaments.component';
import { TvgamesComponent } from './tvgames/tvgames.component';
import { NonstprouletteComponent } from './nonstproulette/nonstproulette.component';
import { DreamwheelComponent } from './dreamwheel/dreamwheel.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { PredictorComponent } from './predictor/predictor.component';
import { BlastOffComponent } from './blastOff/blastOff.component';
import { IntercomComponent } from './intercom/intercom.component';
import { EventviewComponent } from './eventview/eventview.component';

import { LivecalendarComponent } from './livecalendar/livecalendar.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { DetailspageComponent } from './detailspage/detailspage.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RecaptchaModule } from "ng-recaptcha";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { MainComponent } from './main/main.component';
import { BetplaceComponent } from './betplace/betplace.component';
import { EventService } from '../services/broadcast.service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ClickOutsideModule } from 'ng-click-outside';
import { HoreseRacingComponent } from './horese-racing/horese-racing.component';
import { GreyhoundRacingComponent } from './greyhound-racing/greyhound-racing.component';
import { LottieModule } from 'ngx-lottie';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { ClipboardModule } from 'ngx-clipboard';
import { PopUpWithApiData } from './popUpWithApiData/popUpWithApiData';
import { VersionPopUp } from './versionPopUp/versionPopUp';
import { BonusComponent } from './bonus/bonus.component';
import { PromoInfoComponent } from './promo-info/promo-info.component'
import '../../styles.scss';
import { PointPopUPComponent } from './point-pop-up/point-pop-up.component';
import { OutsideClickDirective } from './landingpage/outside-click.directive';
import {IndianCurrencyPipe} from "./pipes/indian-currency.pipe";
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { DeletePopUPComponent} from './delete-pop-up/delete-pop-up.component';
import { BonusesCardsComponent } from './bonuses-cards/bonuses-cards.component';
import { DepositNowCardComponent } from './bonuses-cards/deposit-now-card/deposit-now-card.component';
import { ActiveBonusCardComponent } from './bonuses-cards/active-bonus-card/active-bonus-card.component';
import { PendingBonusCardComponent } from './bonuses-cards/pending-bonus-card/pending-bonus-card.component';
import { ClaimBonusCardComponent } from './bonuses-cards/claim-bonus-card/claim-bonus-card.component';
import { ExpiredBonusCardComponent } from './bonuses-cards/expired-bonus-card/expired-bonus-card.component';
import { NoMoreBonusCardComponent } from './bonuses-cards/no-more-bonus-card/no-more-bonus-card.component';
import { ClaimDonePopupComponent } from './bonuses-cards/claim-done-popup/claim-done-popup.component';
import { HowToBonusComponent } from './bonuses-cards/how-to-bonus/how-to-bonus.component';
import { FrameBoxComponent } from './bonuses-cards/how-to-bonus/frame-box/frame-box.component';
import { FrameBox2Component } from './bonuses-cards/how-to-bonus/frame-box2/frame-box2.component';
import { HorizontalScrollDirective } from './header/horizontal-scroll.directive';
import { DownloadApkComponent } from './download-apk/download-apk.component';
import { WinBonusCardComponent } from './bonuses-cards/win-bonus-card/win-bonus-card.component';
import { PendingBonusCard2Component } from './bonuses-cards/pending-bonus-card2/pending-bonus-card2.component';
import { DepositNowCard2Component } from './bonuses-cards/deposit-now-card2/deposit-now-card2.component';
import { HowToBonus2Component } from './bonuses-cards/how-to-bonus/how-to-bonus2/how-to-bonus2.component';
import { NoWageringClaimedComponent } from './bonuses-cards/no-wagering-claimed/no-wagering-claimed.component';
import { RegisterComponent } from './register/register.component';
import { RegisterPopupComponent } from './register/register-popup/register-popup.component';
import { LoginComponent } from './login/login.component';
import { StickyElementDirective } from './whatsapp/sticky-element.directive';
import { ForgotPopupComponent } from './login/forgot-popup/forgot-popup.component';
import { IphoneBonusCardComponent } from './bonuses-cards/iphone-bonus-card/iphone-bonus-card.component';
import { MenuComponent } from './footer/menu/menu.component';
import { TeamNameSplitPipe } from './pipes/team-name-split.pipe';
import { FootballCardComponent } from './landingpage/football-card/football-card.component';
import { TennisCardComponent } from './landingpage/tennis-card/tennis-card.component';
import { PoliticsCardComponent } from './landingpage/politics-card/politics-card.component';
import { CricketCardComponent } from './landingpage/cricket-card/cricket-card.component';
import { ScoreboardComponent } from './scoreboard/scoreboard.component';
import { BetSlipCardComponent } from './landingpage/bet-slip-card/bet-slip-card.component';
import { CancelSlipPopupComponent } from './landingpage/bet-slip-card/cancel-slip-popup/cancel-slip-popup.component';
import { MatchbetcardComponent } from './detailspage/matchbetcard/matchbetcard.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { BethistoryComponent } from './bet-history/bet-history.component';
import { BetHistoryCardComponent } from './bet-history/bet-history-card/bet-history-card.component';
import { CancelBetPopupComponent } from './bet-history/cancel-bet-popup/cancel-bet-popup.component';
import { LiveVideoComponent } from './live-video/live-video.component';
import { MatchSummeryComponent } from './live/match-summery/match-summery.component';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { GreyhoundCardComponent } from './landingpage/greyhound-card/greyhound-card.component';
import { HorseRacingCardComponent } from './landingpage/horse-racing-card/horse-racing-card.component';
import { IosInstructionComponent } from './ios-instruction/ios-instruction.component';

import { TranslateModule } from '@ngx-translate/core';
import { NoDataAvailableComponent } from './detailspage/no-data-available/no-data-available.component';
import { CalendarComponent } from './calendar/calendar.component';
// import { CancelBonusCardComponent } from './bonuses-cards/cancel-bonus-card/cancel-bonus-card.component';
import { UpdatePopupComponent } from './update-popup/update-popup.component';
import { RedeemGiftCardComponent } from './bonuses-cards/redeem-gift-card/redeem-gift-card.component';

export function playerFactory() {
  return import('lottie-web');
}
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    // LayoutComponent,
    LandingpageComponent,
    LiveComponent,
    SportComponent,
    SportsbookComponent,
    EsportsComponent,
    CasinoComponent,
    LivecasinoComponent,
    VirtualsportsComponent,
    PokerComponent,
    SporttournamentComponent,
    GamesComponent,
    CasinotournamentsComponent,
    TvgamesComponent,
    NonstprouletteComponent,
    DreamwheelComponent,
    PromotionsComponent,
    PredictorComponent,
    LivecalendarComponent,
    StatisticsComponent,
    DetailspageComponent,
    MainComponent,
    BetplaceComponent,
    HoreseRacingComponent,
    GreyhoundRacingComponent,
    PopUpWithApiData,
    VersionPopUp,
    BlastOffComponent,
    IntercomComponent,
    BonusComponent,
    PromoInfoComponent,
    PointPopUPComponent,
    OutsideClickDirective,
    IndianCurrencyPipe,
    WhatsappComponent,
    DeletePopUPComponent,
    BonusesCardsComponent,
    DepositNowCardComponent,
    ActiveBonusCardComponent,
    PendingBonusCardComponent,
    ClaimBonusCardComponent,
    ExpiredBonusCardComponent,
    NoMoreBonusCardComponent,
    ClaimDonePopupComponent,
    HowToBonusComponent,
    FrameBoxComponent,
    FrameBox2Component,
    HorizontalScrollDirective,
    DownloadApkComponent,
    WinBonusCardComponent,
    PendingBonusCard2Component,
    DepositNowCard2Component,
    HowToBonus2Component,
    NoWageringClaimedComponent,
    RegisterComponent,
    RegisterPopupComponent,
    LoginComponent,
    StickyElementDirective,
    ForgotPopupComponent,
    IphoneBonusCardComponent,
    MenuComponent,
    TeamNameSplitPipe,
    FootballCardComponent,
    TennisCardComponent,
    PoliticsCardComponent,
    CricketCardComponent,
    ScoreboardComponent,
    BetSlipCardComponent,
    CancelSlipPopupComponent,
    MatchbetcardComponent,
    SkeletonComponent,
    SkeletonComponent,
    BethistoryComponent,
    BetHistoryCardComponent,
    CancelBetPopupComponent,
    LiveVideoComponent,
    MatchSummeryComponent,
    CustomDatePipe,
    GreyhoundCardComponent,
    HorseRacingCardComponent,
    IosInstructionComponent,
    NoDataAvailableComponent,
    EventviewComponent,
    CalendarComponent,
    // CancelBonusCardComponent,
    UpdatePopupComponent,
    RedeemGiftCardComponent,
  ],
  providers: [EventService],
  imports: [FormsModule, RecaptchaModule, ClipboardModule, ReactiveFormsModule, DragDropModule, ClickOutsideModule, CommonModule, MatProgressSpinnerModule, SharedRoutingModule, SlickCarouselModule, LottieModule.forRoot({ player: playerFactory }), LottieAnimationViewModule.forRoot(), NgOptimizedImage, TranslateModule],
  exports: [LandingpageComponent, UpdatePopupComponent, CalendarComponent, FooterComponent, HeaderComponent, MainComponent, PopUpWithApiData, VersionPopUp, PromoInfoComponent, IndianCurrencyPipe, WhatsappComponent, DeletePopUPComponent, DownloadApkComponent, TeamNameSplitPipe, ScoreboardComponent, IosInstructionComponent, CustomDatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] // Add this line
})
export class SharedModule {}
