<div class="score-card" routerLink="/shared/details/{{data?.event?.id}}/{{data?.eventTypeId}}">
    <div class="card-body">
        <div class="score">
            <div class="match">
            <div class="overs">
                    <div class="first-logo">
                <ng-container *ngIf="data?.scores == null">
                    <img style="width: 1rem; height: 1rem; flex-shrink: 0;" src="../../../../assets/images/clock-match.svg" alt="" srcset="">
                </ng-container>
                <ng-container *ngIf="data?.scores">
                    <div class="over">
                        <div class="numbers">
                                    {{data?.scores?.overs ? data?.scores?.overs : "0"}}
                                </div>
                                <div class="text">
                                    {{'Overs'|translate}}
                                </div>
                                <div class="runs">{{data?.scores?.score ? data?.scores?.score : "0"}}/{{data?.scores ?
                                    data?.scores?.wickets ? data?.scores?.wickets : '0' : '0'}}</div>
                    </div>
                </ng-container>
            </div>
            <div class="divider-line"></div>
                </div>
                <div class="versus">
                    <div class="team-vs-team">
                        <div class="team">
                            <div class="bat-img">
                                <img src="../../../../assets/images/bat-icon.svg" *ngIf="data?.scores?.players[0]?.batting" alt="" srcset="">
                            </div>
                            <div class="team-name">
                                <small>{{data?.players?.[0]?.name}}</small>
                                 <span *ngIf="data?.scores?.currentInning == 2 && data?.scores?.players[0].batting">{{'TARGET'|translate}} {{data?.scores?.target}}</span>
                            </div>
                        </div>
                        <div class="team">
                            <div class="bat-img">
                            <img src="../../../../assets/images/bat-icon.svg" *ngIf="data?.scores?.players[1]?.batting" alt="" srcset="">
                            </div>
                            <div class="team-name">
                                <small>{{data?.players?.[1]?.name}}</small>
                                 <span *ngIf="data?.scores?.currentInning == 2 && data?.scores?.players[1].batting">{{'TARGET'|translate}} {{data?.scores?.target}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="live-tv">
                <ng-lottie *ngIf="data?.scores" [options]="liveDotLottieOptions" width="20px" height="20px"></ng-lottie>

                <img *ngIf="data?.hasVideo" src="../../../../assets/images/tv-icon.svg"/>
            </div>
            <div *ngIf="data?.scores == null && !isMatchInPast" class="date-time" style="width:70px">
                {{date}}
            </div>
        </div>
        <div class="odds" *ngIf="showOdds">
            <ng-container *ngIf="data?.market?.status != 'OPEN'">
                <div class="suspended-btn">{{'SUSPENDED'|translate}}</div>
            </ng-container>
            <ng-container *ngIf="data?.market?.status == 'OPEN'">
                <div class="first" [ngClass]="{'isBettable': !data?.market?.isBettable}">
                    <div class="back" (click)="triggerBetSlip(odds?.back[0]?.price,true,0)"
                        [ngClass]="{'blink_me':applyBackAnimation[0]}">
                        <span>{{odds?.back[0]?.price ? odds?.back[0]?.price : '-' }}</span>
                    </div>
                    <div class="center-badge-white">1</div>
                    <div class="lay" (click)="triggerBetSlip(odds?.lay[0]?.price,false,0)"
                        [ngClass]="{'blink_me':applyLayAnimation[0]}">
                        <span>{{odds?.lay[0]?.price ? odds?.lay[0]?.price : '-' }}</span>
                    </div>
                </div>
                <div class="second" [ngClass]="{'isBettable': !data?.market?.isBettable}">
                    <div class="back" (click)="triggerBetSlip(odds?.back[1]?.price,true,1)"
                        [ngClass]="{'blink_me':applyBackAnimation[1]}">
                        <span>{{odds?.back[1]?.price ? odds?.back[1]?.price : '-' }} </span>
                    </div>
                    <div class="center-badge-white">X</div>
                    <div class="lay" (click)="triggerBetSlip(odds?.lay[1]?.price,false,1)"
                        [ngClass]="{'blink_me':applyLayAnimation[1]}">
                        <span>{{odds?.lay[1]?.price ? odds?.lay[1]?.price : '-' }}</span>
                    </div>
                </div>
                <div class="third" [ngClass]="{'isBettable': !data?.market?.isBettable}">
                    <div class="back" (click)="triggerBetSlip(odds?.back[2]?.price,true,2)"
                        [ngClass]="{'blink_me':applyBackAnimation[2]}">
                        <span>{{odds?.back[2]?.price ? odds?.back[2]?.price : '-' }}</span>
                    </div>
                    <div class="center-badge-white">2</div>
                    <div class="lay" (click)="triggerBetSlip(odds?.lay[2]?.price,false,2)"
                        [ngClass]="{'blink_me':applyLayAnimation[2]}">
                        <span>{{odds?.lay[2]?.price ? odds?.lay[2]?.price : '-' }}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="line" >
</div>