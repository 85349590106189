import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MyprofileService } from 'src/app/services/myprofile.service';


@Component({
  selector: 'app-accountlayout',
  templateUrl: './accountlayout.component.html',
  styleUrls: ['./accountlayout.component.scss'],
  animations: [
    trigger("inOutPaneAnimation", [
      transition(":enter", [
        style({ opacity: 0.8, transform: "translateX(100%)" }), //apply default styles before animation starts
        animate(
          "200ms ease-in-out",
          style({ opacity: 1, transform: "translateX(0)" })
        )
      ])
    ])
  ]
})
export class AccountlayoutComponent implements OnInit {
  exitWindow: boolean = false;
 public getToken:any;
  constructor(private routes: Router, private myprofileServices: MyprofileService) { }
  ngOnInit(): void {

    this.getToken =  localStorage.getItem('ROYALTOKEN_KEY');
    if(this.getToken == null){
      this.routes.navigate(['/']);
    }
  }

  navigateToHome() {
    this.exitWindow = true;
    setTimeout(() => {
      this.myprofileServices.updateActiveMenuItem('/main');
      this.routes.navigate(['/main']);
    }, 50);
  }

}
