import { Injectable } from '@angular/core';
import { LandingService } from 'src/app/services/landing.service';

@Injectable({
  providedIn: 'root'
})
export class BonusService {

  showAllBonuses = false;
  showClaimBonus = false;
  activeBonus: number;

  showHowTo;
  showHowTo2;
  lastClaimBonus = 0;

  isAnyActiveBonus = false;

  bonuses = [];
  promotions = [];


  constructor(private api: LandingService) { }

  getAllBonuses(type){
    this.api.getBonuses(type).subscribe(async (res: any) => {
      // console.log(res);
      this.bonuses = await res.result;
      this.bonuses.forEach(bonus => {
        if(bonus.STS_ID === 20){
          this.isAnyActiveBonus = true;
        }
      });

      if (res.result.length > 0) {
        const currentDate = new Date(); // Get the current date

        const dates = await Promise.all(res.result.map(async (bonus) => {
          const expiryDate = new Date(bonus.EXPIRY_DATE);

          const isExpired = expiryDate < currentDate;
          return isExpired;
        }));

        const isAnyExpired = await dates.filter(date => date === false);
        this.activeBonus = await isAnyExpired.length;
      }

        this.api.getPromotions().subscribe((res: any) => {
          this.promotions = res.result;
        }, (err) => {
          console.log(err);
        });
      // }
    }, err => {
      console.log(err);
      this.api.getPromotions().subscribe((res: any) => {
        this.promotions = res.result;
      }, (err) => {
        console.log(err);
      });
    })
  }

}
