import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CasinoComponent } from './casino/casino.component';
import { CasinotournamentsComponent } from './casinotournaments/casinotournaments.component';
import { DetailspageComponent } from './detailspage/detailspage.component';
import { DreamwheelComponent } from './dreamwheel/dreamwheel.component';
import { EsportsComponent } from './esports/esports.component';
import { EventviewComponent } from './eventview/eventview.component';
import { GamesComponent } from './games/games.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { LiveComponent } from './live/live.component';
import { LivecalendarComponent } from './livecalendar/livecalendar.component';
import { LivecasinoComponent } from './livecasino/livecasino.component';
import { NonstprouletteComponent } from './nonstproulette/nonstproulette.component';
import { PokerComponent } from './poker/poker.component';
import { PredictorComponent } from './predictor/predictor.component';
import { BlastOffComponent } from './blastOff/blastOff.component';
// import { IntercomComponent } from './intercom/intercom.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { SportComponent } from './sport/sport.component';
import { SportsbookComponent } from './sportsbook/sportsbook.component';
import { SporttournamentComponent } from './sporttournament/sporttournament.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { TvgamesComponent } from './tvgames/tvgames.component';
import { VirtualsportsComponent } from './virtualsports/virtualsports.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainComponent } from './main/main.component';
import { HoreseRacingComponent } from './horese-racing/horese-racing.component';
import { GreyhoundRacingComponent } from './greyhound-racing/greyhound-racing.component';
import { BonusComponent } from './bonus/bonus.component';
import { MatchSummeryComponent } from './live/match-summery/match-summery.component';

const routes: Routes = [
  {
    path: 'shared', component: MainComponent, children: [
      { path: 'landing', component: LandingpageComponent },
      { path: 'live/:id', component: LiveComponent },
      { path: 'sport', component: SportComponent },
      { path: 'esports', component: EsportsComponent },
      { path: 'casino', component: CasinoComponent },
      { path: 'predictor', component: PredictorComponent },
      { path: 'livecasino', component: LivecasinoComponent },
      { path: 'livecasino/:gameName/:gameId', component: LivecasinoComponent },
      { path: 'predictor', component: PredictorComponent },
      { path: 'blastOff', component: BlastOffComponent },
      { path: 'virtulsports', component: VirtualsportsComponent },
      { path: 'poker', component: PokerComponent },
      { path: 'sportstournament', component: SporttournamentComponent },
      { path: 'games', component: GamesComponent },
      { path: 'casinotournaments', component: CasinotournamentsComponent },
      { path: 'tvgames', component: TvgamesComponent },
      { path: 'sportsbook', component: SportsbookComponent },
      { path: 'nonstproulette', component: NonstprouletteComponent },
      { path: 'dreamwheel', component: DreamwheelComponent },
      { path: 'promotions', component: PromotionsComponent },
      { path: 'eventview', component: EventviewComponent },
      {path: 'livecalendar', component: LivecalendarComponent},
      {path: 'statistics', component: StatisticsComponent},
      {path: 'details/:id/:sid', component: DetailspageComponent},
      {path: 'horseracing', component: HoreseRacingComponent},
      {path: 'greyhoundRacing', component: GreyhoundRacingComponent},
      { path: 'match-summery/:matchTypeId/:compitionId', component: MatchSummeryComponent },
      {path: 'bonus', component: BonusComponent},
    ]
  }

];

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
