<div *ngIf="show" class="downLoad-apk-pop-up Modal-Background max-width-550">
    <div class="downLoad-apk-pop-up__content">
      <div class="downLoad-apk-pop-up__header">
        <div (click)="closePopup()">
          <span class="close-icon">&times;</span>
        </div>
      </div>
      <div class="downLoad-apk-pop-up__body">
			<ng-container *ngIf="landingBanner">
				<img [src]="landingBanner[0].image" alt="new-update" (click)="redirect(landingBanner[0].redirect)">
			</ng-container>
<!--			<ng-container *ngIf="!landingBanner">-->
<!--				<img src="/assets/images/11exch_update_banner.jpg" alt="new-update">-->
<!--			</ng-container>-->
      </div>
      </div>
</div>
