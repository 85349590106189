<div class="card">
    <div class="card-header">
        <h2>{{title}}</h2>
        <div class="close-btn">
            <svg (click)="closeClicked()" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2.91703 10.2587C2.68924 10.4866 2.68928 10.8559 2.9171 11.0837C3.14493 11.3115 3.51427 11.3115 3.74206 11.0836L7.00012 7.82499L10.2584 11.0833C10.4862 11.3111 10.8556 11.3111 11.0834 11.0833C11.3112 10.8555 11.3112 10.4862 11.0834 10.2584L7.82501 6.99998L11.0832 3.74126C11.3109 3.51343 11.3109 3.14409 11.083 2.91631C10.8552 2.68851 10.4859 2.68855 10.2581 2.91638L7 6.17503L3.74166 2.91666C3.51386 2.68885 3.14451 2.68885 2.91671 2.91666C2.6889 3.14446 2.6889 3.51381 2.91671 3.74162L6.17517 7.00004L2.91703 10.2587Z"
                    fill="#111827" />
            </svg>
        </div>
    </div>
    <div class="card-body">
        <ng-content></ng-content>
    </div>
</div>