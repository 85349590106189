export class BetSlipData {
	itemId: any; //will be runner id
	cardId: any; //will be market id
	selectedCard: any; //will be match card or detail card
	stakeOnBet: any = null;
	profitAndLiability: any;
	selectedTeam: any;
	selectedMatchCard: any;
	selectedButton: any; //for selected button in match card
	price: any;
	stakes: any;
	runners: {
		runnerId: any;
		oldExposure: any;
		newExposure: any;
	}[];
}
