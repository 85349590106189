import { Component, OnInit, ChangeDetectorRef, ViewChild, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { HeaderComponent } from '../header/header.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotifyServiceService } from 'src/app/notify/notify-service.service';
import { MyprofileService } from 'src/app/services/myprofile.service';
import { BonusService } from '../bonuses-cards/bonus.service';

declare var google: any;
declare var window: any;


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private _notify: NotifyServiceService,
    private renderer: Renderer2,
    private myprofile: MyprofileService,
    private _bonus: BonusService,
  ) { }

  registerCongoPopup = false;  //show register congo popup
  registerSaveCongoPopup = false; //show register save congo popup
  showOtpInput = false; //show otp input field
  passwordInput = false; //show password input field
  isMobileNoValid = false; //check mobile no is valid or
  regToken: any; //register token
  userAcceptedTerms = false; //check user accepted terms and condition or not
  otpTimerSeconds = 60; //otp timer in Seconds
  otpTimerInterval: any; //otp timer interval
  showPassword = false; //show password
  showConfirmPassword = false; //show confirm password
  reCaptcha: any; //recaptcha
  private cdr: ChangeDetectorRef; //change detector ref
  @ViewChild('captchaRef') captchaRef: any; //captcha ref
  token: any; //for authorization token
  isLoader = false; //for spinning loader
  siteKey: string; // for site key
  aTag: string; // for a tag
  aff: string;
  clickId: string; // for click id
  rSiteKey: string; // for r site key
  otpInput = [];
  versionPopUpOpen = false;
  version: any;
  googleButtonWrapper: any; // for google button wrapper
  isRegisterMobileNumberValid = false; //for register mobile number validation
  greenCheckColorForPassword: boolean = false;

  otpInputs: string[] = ['', '', '', ''];
  isOtpValid: boolean = false;

  //password validation varibles
  passwordMinLength = false;
  passwordHaveUpperCase = false;
  passwordHaveLowerCase = false;
  passwordHaveNumber = false;
  passwordHaveSpecialChar = false;
  hideOtherOptionsafterGetOtp: boolean = false;
  //password validation varibles

  isLoading: boolean = true;





  ngOnInit(): void {
    // this.getConfigData();
    this.authService.googleConfigObservable$.subscribe((config: any | null) => {
      this.rSiteKey = config?.result?.recaptchaConfig?.recaptchaSiteKey
      // this.executeRecaptchaAsync();
      let interval = setTimeout(() => {
        if (this.captchaRef) {
          this.captchaRef.execute();
          clearInterval(interval);
        }
      }, 100);
    })
    //get element by class name "intercom-lightweight-app"
    const intercom = document.getElementsByClassName('intercom-lightweight-app')[0];
    //if element is present then remove it
    if (intercom) intercom.remove();
    this.reCaptcha = localStorage.getItem('recaptcha');
    this.rSiteKey = localStorage.getItem('rSiteKey');
    // this.getToken();
  }

  //formGroup for register form for mobile number, otp, password and user consent
  registerForm: FormGroup = new FormGroup({
    mobileNo: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]),
    otp1: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{1}$'), Validators.maxLength(1)]),
    otp2: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{1}$'), Validators.maxLength(1)]),
    otp3: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{1}$'), Validators.maxLength(1)]),
    otp4: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{1}$'), Validators.maxLength(1)]),
    password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_]).+/)]),
    confirmPassword: new FormControl(''),
    userConsent: new FormControl(true, Validators.requiredTrue),
  }, { validators: this.passwordMatchValidator });


  /**
   * custom validator for password and confirm password
   */
  passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirmPassword').value;

    return password === confirmPassword ? null : { 'passwordMismatch': true };
  }


  /**
   * user consent for terms and condition
   * @param event
   */
  acceptUserConsent() {
    this.registerForm.patchValue({
      userConsent: this.registerForm.get('userConsent').value ? false : true
    });
  }


  //password validation function on keyup
  passwordValidation(event: any) {
    //if backspace and event.target.value is null then mark false all password validation
    if (event.key == "Backspace" && event.target.value.length <= 0) {
      this.passwordMinLength = false;
      this.passwordHaveUpperCase = false;
      this.passwordHaveLowerCase = false;
      this.passwordHaveNumber = false;
      this.passwordHaveSpecialChar = false;
      return;
    }
    //if registerForm.get('password').value is not null then check password validation
    if (this.registerForm.get('password').value) {
      //check password length
      if (this.registerForm.get('password').value.length >= 8) { //check if password length is greater than 8
        this.passwordMinLength = true;
      } else {
        this.passwordMinLength = false;
      }
      //check password have uppercase
      if (this.registerForm.get('password').value.match(/[A-Z]/)) {
        this.passwordHaveUpperCase = true;
      } else {
        this.passwordHaveUpperCase = false;
      }
      //check password have lowercase
      if (this.registerForm.get('password').value.match(/[a-z]/)) {
        this.passwordHaveLowerCase = true;
      } else {
        this.passwordHaveLowerCase = false;
      }
      //check password have number
      if (this.registerForm.get('password').value.match(/[0-9]/)) {
        this.passwordHaveNumber = true;
      } else {
        this.passwordHaveNumber = false;
      }
      //check password have special character
      if (this.registerForm.get('password').value.match(/[\W_]/)) {
        this.passwordHaveSpecialChar = true;
      } else {
        this.passwordHaveSpecialChar = false;
      }
    }
    if (this.passwordHaveLowerCase === true && this.passwordHaveUpperCase === true && this.passwordHaveNumber === true && this.passwordHaveSpecialChar === true && this.passwordMinLength === true) {
      this.greenCheckColorForPassword = true;
    } else {
      this.greenCheckColorForPassword = false;
    }
  }


  /**
   * this event handler on keyup event of otp input field
   */

  onInputForOtp(event: Event) {
    let mobileNo = (event.target as HTMLInputElement).value.replace(/[^0-9]/g, '');
    (event.target as HTMLInputElement).value = mobileNo;
  }
  otpInputHandler(event: any) {
    // console.log(event);
    //remove errors from otp input field
    this.registerForm.get('otp1').setErrors(null);
    this.registerForm.get('otp2').setErrors(null);
    this.registerForm.get('otp3').setErrors(null);
    this.registerForm.get('otp4').setErrors(null);
    //if event target digit length is equal to 1 then focus on next input field
    if (event.key == "Backspace" && event.target.value.length === 0 && event.target.previousElementSibling) {
      event.target.value = "";
      if (event.target.id == "first") {
        // delete this.otpInput[0];
        event.target.nextElementSibling.focus();
      } else if (event.target.id == "second") {
        // delete this.otpInput[1];
        event.target.nextElementSibling.focus();
      } else if (event.target.id == "third") {
        // delete this.otpInput[2];
        event.target.nextElementSibling.focus();
      } else if (event.target.id == "fourth") {
        // delete this.otpInput[3];
      }
      event.target.previousElementSibling.focus();
    } else if (event.target.value.length === 1 && parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 9) {
      if (event.target.id == "first" && event.target.nextElementSibling) {
        // this.otpInput[0] = event.target.value;
        event.target.nextElementSibling.focus();
      } else if (event.target.id == "second" && event.target.nextElementSibling) {
        // this.otpInput[1] = event.target.value;
        event.target.nextElementSibling.focus();
      } else if (event.target.id == "third" && event.target.nextElementSibling) {
        // this.otpInput[2] = event.target.value;
        event.target.nextElementSibling.focus();
      } else if (event.target.id == "fourth") {
        // this.otpInput[3] = event.target.value;
      }
      // console.log(this.otpInput);
    }
    // this.otpInput = this.otpInput.filter(Boolean);
  }


  otpEnterIncomplete(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  otpEnterIncomplete1(event) {
    // console.log(this.otpInput.length);
    if (event.key === "Enter") {
      event.preventDefault();
    }
    // if (this.otpInput.length >= 4 && event.key === "Enter") {
    //   this.otpSubmit();
    //   event.preventDefault();
    // }
  }


  /**
   * This function is used to show OTP input field
   * after user enter mobile no and click on submit button
   */
  triggerOtp() {
    // this.showOtpInput = true;
    // return;
    //if userConsent if false then return toast message
    if (!this.registerForm.get('userConsent').value) {
      this._notify.error('Please accept terms and condition');
      return;
    }
    if (this.registerForm.get('mobileNo').value.length < 10) {
      this._notify.error('Please enter valid mobile number');
      return;
    }
    const data = {
      countryCode: '+91',
      mobileNumber: this.registerForm.get('mobileNo').value,
      recaptchaResponse: this.reCaptcha,
    };
    //call getOtp api and if response is success then showOtpInput = true
    this.authService.Register(data).subscribe(
      (res: any) => {
        window.srtmCommands.push({ event: "track.user.registration", payload: { action: "start" } })
        this.authService.showSuccess(res.body.result);
        this.startTimer();
        this.showOtpInput = true;
        this.registerForm.controls["mobileNo"].disable();
        this.hideOtherOptionsafterGetOtp = true;
      },
      (error) => {
        this.hideOtherOptionsafterGetOtp = false;
        this._notify.error(error.error.status.statusDesc);
      })
  }

  /**
   * submit otp and if otp is valid then show password input field
   */
  otpSubmit() {
    //call verifyOtp api and if response is success then show password input field
    // this.isLoader = true;
    //if any of the otp is null then return toast message
    if (!this.registerForm.get('otp1').value || !this.registerForm.get('otp2').value || !this.registerForm.get('otp3').value || !this.registerForm.get('otp4').value) {
      this._notify.error('Please enter valid OTP');
      this.isLoader = false;
      return;
    }
    let otp = this.registerForm.get('otp1').value + this.registerForm.get('otp2').value + this.registerForm.get('otp3').value + this.registerForm.get('otp4').value;
    let data = {
      countryCode: '+91',
      mobileNumber: this.registerForm.get('mobileNo').value,
      OTP: otp,
    }
    this.authService.checkOTP(data).subscribe(
      (res: any) => {
        // console.log(res.body);
        this.isLoader = false;
        this._notify.success(res.body.status.statusDesc);
        this.passwordInput = true;
        this.showOtpInput = false;
        this.registerForm.controls["mobileNo"].disable();
        this.regToken = res.body.result;
        // localStorage.setItem('registerToken', token);
      },
      (error) => {
        this.isLoader = false;
        this._notify.error(error.error.error);
        //mark otp as invalid
        this.registerForm.get('otp1').setErrors({ 'incorrect': true });
        this.registerForm.get('otp2').setErrors({ 'incorrect': true });
        this.registerForm.get('otp3').setErrors({ 'incorrect': true });
        this.registerForm.get('otp4').setErrors({ 'incorrect': true });
      }
    )
  }

  /**
   * resend otp function
   */
  resendOtp() {
    this.authService.RsendOTP({ countryCode: '+91', mobileNumber: this.registerForm.get('mobileNo').value }).subscribe(
      (res: any) => {
        this._notify.success(res.body.status.statusDesc);
        this.startTimer();
      },
      (error) => {
        console.log(error);
        this._notify.error(error.error.status.statusDesc);
      }
    )
  }
  whatsappAccountRegistration() {
    const link = 'https://walink.live/11exch';
    window.open(link, '_blank');
  }
  clickOpenIntercom() {
    const intercomContainer = document.getElementById("intercom-container");
    // @ts-ignore
    window.Intercom('show');
    if (intercomContainer) {
      this.renderer.setStyle(intercomContainer, 'display', 'block', RendererStyleFlags2.Important);
    }
    // this.intercomBtn = true;
  }

  /**
   * this function only allow number in mobile number input field and remove all other characters
   */
  onInput(event: Event) {
    let mobileNo = (event.target as HTMLInputElement).value.replace(/[^0-9]/g, '');
    (event.target as HTMLInputElement).value = mobileNo;
    if (mobileNo.length == 10) {
      this.isRegisterMobileNumberValid = true;
    } else {
      this.isRegisterMobileNumberValid = false;
    }
    // console.log(mobileNo.length);
  }

  //start otp timer for 10 seconds
  startTimer() {
    this.otpTimerSeconds = 60;
    this.otpTimerInterval = setInterval(() => {
      if (this.otpTimerSeconds > 0) {
        this.otpTimerSeconds--;
      } else {
        clearInterval(this.otpTimerInterval);
      }
    }, 1000);
  }

  /**
   * register user on submit
   */
  registerUser() {
    //if password and confirm password are empty then return toast message
    if (!this.registerForm.get('password').value || !this.registerForm.get('confirmPassword').value) {
      this._notify.error('Please enter password and confirm password');
      return;
    }
    if (this.registerForm.get('password').value != this.registerForm.get('confirmPassword').value) {
      this._notify.error('Password and confirm password not match');
      this.isLoader = false;
      return;
    }
    this.isLoader = true; //show loader
    if (localStorage.getItem('atag')) {
      this.aTag = localStorage.getItem('atag');
    }
    if (localStorage.getItem('clickId')) {
      this.clickId = localStorage.getItem('clickId');
    }
    const data = {
      countryCode: '+91',
      mobileNumber: this.registerForm.get('mobileNo').value,
      password: this.registerForm.get('password').value,
      confirmPassword: this.registerForm.get('confirmPassword').value,
      atag: this.aTag,
      clickId: this.clickId,
      otpToken: this.regToken
    };
    this.authService.verifyOTP(data).subscribe(
      (res) => {
        //debugger
        this.myprofile.showFooter = true;
        this._bonus.getAllBonuses('alive');
        this.isLoader = false;
        window.srtmCommands.push({ event: "track.user.registration", payload: { action: "complete", userId: res?.body?.result?.memberCode } }); //for srtm
        this.trackRegisterPixelService(res?.body?.result?.memberCode, res?.body?.result?.loginName);
        this.trackShareChatPixel("Complete registration");
        this.signInAfterRegister();
        this.registerCongoPopup = true
      },
      (error) => {
        this.isLoader = false;
        // console.log(error.error.error);
        this._notify.error(error.error.error);
      }
    );

  }

  async signInAfterRegister() {
    this.registerCongoPopup = false
    let data = {
      username: '91_' + this.registerForm.get('mobileNo').value,
      password: this.registerForm.get('password').value,
    }
    //download id and password to user in text file
    const blob = new Blob([JSON.stringify(data)], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    // link.href = url;
    // link.download = 'login.txt';
    // link.click();
    await this.executeRecaptchaAsync();
    try {
      await this.signIn(data);
    } catch (e) {
      await this.executeRecaptchaAsync();
      await this.signIn(data);
    }
  }

  /**
   * for recaptcha execute
   * @returns
   */
  async executeRecaptchaAsync() {
    this.captchaRef.execute();
    return new Promise((resolve) => {
      this.resolved = (captchaResponse: string) => {
        this.reCaptcha = captchaResponse;
        resolve(captchaResponse);
      };
    });
  }

  /**
   * for recaptcha resolved
   * @param captchaResponse
   */
  resolved(captchaResponse: string) {
    console.log('Inside resolved of register component');
    this.reCaptcha = captchaResponse;
    //set to localstorage
    localStorage.setItem('recaptcha', this.reCaptcha);
    // this.cdr.detectChanges();
    // this.cdr.markForCheck();
    //console.log(this.recaptcha);
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }


  signIn(data: any) {
    this.isLoader = true;
    data.recaptchaResponse = this.reCaptcha;
    this.authService.Login(data).subscribe(
      (res: any) => {
        //debugger
        this.isLoader = false;
        this.token = res.headers.get('authorization');
        if (res.body.status.statusCode == '0') {
          this.router.navigate(['/balancemanagement/Deposit']);
          window.srtmCommands.push({
            event: "track.user.login",
            payload: {
              action: "complete",
              userId: res?.body?.result?.memberCode
            }
          });
          this.token = res.headers.get('authorization');
          localStorage.setItem('ROYALTOKEN_KEY', JSON.stringify(this.token));
          localStorage.setItem('Member_code', res.body.result.memberCode);
          localStorage.setItem('User_name', res.body.result.loginName);
          localStorage.setItem('WaLink', res?.body?.result?.wa || 'https://walink.live/11exch');
          localStorage.setItem(
            'betstack',
            res?.body?.result?.settings.presetStakeSettings?.settings
          );
          try {
            var loginNameInter = res.body.result.loginName;
            if (loginNameInter.includes('.')) {
              loginNameInter = loginNameInter.split('.')[1];
            }
            if (loginNameInter.includes('_')) {
              loginNameInter = loginNameInter.split('_')[1];
            }
            (window as any).intercomSettings = {
              api_base: 'https://api-iam.intercom.io',
              branch_id: res?.body?.result?.branchId || 100111,
              branch_name: res?.body?.result?.branchName || "Not updated yet",
              app_id: 'n3wkvmsn',
              name: loginNameInter,
              user_id: res.body.result.memberCode,
            };
            (window as any).Intercom('update');
          } catch (e) {
            this.isLoader = false;
            console.log(e);
          }
          this.cdr.detectChanges();
          this._bonus.getAllBonuses('alive');
          this.router.navigate(['/main']);
        }
      },
      (error) => {
        this.isLoader = false;
        setTimeout(() => {
          this.captchaRef.execute();
        }, 100);
        console.log(error.error.error);
      }
    );
  }

  showGoogleBtn = true;

  getToken() {
    this.showGoogleBtn = false;
    const ROYALTOKEN_KEY = localStorage.getItem('ROYALTOKEN_KEY');
    return ROYALTOKEN_KEY;
  }


  glogin() {
    if (!this.getToken()) {
      google.accounts.id.initialize({
        client_id: environment.gLoginKey,
        callback: (response) => {
          const token = response.credential;
          console.log(token);
          if (localStorage.getItem('atag')) {
            this.aTag = localStorage.getItem('atag');
          }
          if (localStorage.getItem('clickId')) {
            this.clickId = localStorage.getItem('clickId');
          }
          const data = {
            token: token,
            atag: this.aTag,
            clickId: this.clickId
          }
          this.authService.gLogin(data).subscribe(
            (res: any) => {
              console.log(res);
              //debugger
              this.token = res.headers.get('authorization');
              //console.log('Inside signIn of header component value of res:' + JSON.stringify(res));
              //  console.log('Inside signIn of header component value of res:' + JSON.stringify(res?.body?.result?.settings.presetStakeSettings?.settings));
              //        localStorage.setItem(8'ROYALTOKEN_KEY', JSON.stringify(this.token));
              if (res.body.status.statusCode == '0') {
                this.token = res.headers.get('authorization');
                // this.showsign = false;
                // this.errorMsg = 'login successfully';
                document.body.classList.remove('openpopup');
                //this.balancamount();
                //console.log('Inside signIn of header component value of this.token:' + this.token);
                localStorage.setItem(
                  'ROYALTOKEN_KEY',
                  JSON.stringify(this.token)
                );
                localStorage.setItem('Member_code', res.body.result.memberCode);
                localStorage.setItem('loginName', res.body.result.loginName);
                localStorage.setItem(
                  'betstack',
                  res?.body?.result?.settings.presetStakeSettings?.settings
                );
                this.trackRegisterPixelService(res?.body?.result?.memberCode, res?.body?.result?.loginName)
                this.trackShareChatPixel("Complete registration")
                //this.setToken('betstack', this.optionSplit(res?.body?.result?.settings.presetStakeSettings?.settings));
                //console.log('Inside signIn of header component localStorage.setItem(Member_code):' + localStorage.getItem('Member_code'));
                // this.cdr.detectChanges();
                // this.authService.showSuccess(this.errorMsg);
                this.router.navigate(['/main']);
                //subscribe to get all bonuses
                this._bonus.getAllBonuses('alive');
                window.location.reload();
              }
            },
            (error) => {
              //console.log('Inside signIn of error condition header component value of res:');
              setTimeout(() => {
                this.captchaRef.execute();
              }, 100);
              // this.errorMsg = JSON.stringify(error.error.error);
              // this.authService.showError(this.errorMsg);
              //this.authService.showSuccess();
            }
          );
        },
        auto_select: false,
        ux_mode: 'popup'
      });
      this.googleButtonWrapper = createGoogleWrapper();
      this.googleButtonWrapper.click();
      function createGoogleWrapper() {
        const btn = document.createElement('div');
        btn.style.display = 'none';
        btn.classList.add('custom-google-button');
        console.log(btn);
        document.body.appendChild(btn);
        google.accounts.id.renderButton(btn, {
          theme: 'outline',
          size: 'large',
          text: 'continue_with',
          width: '100%',
        });
        const googleLoginWrapper = btn.querySelector("div[role=button]") as HTMLElement;
        return {
          click: () => {
            googleLoginWrapper.click();
          }
        }
      }
      // google.accounts.id.prompt(); // also display the One Tap dialog
    }
  }



  walogin(byClick = true) {
    const url =
      `https://11exch.authlink.me/?redirectUri=` + window.location.origin;
    if (byClick) {
      window.location.href = url;
    }
    // window.open(
    //   url,
    //   'Verify with Whats app',
    //   'width=420,height=730,left=150,top=200,toolbar=1,status=1,'
    // );
    // const interval = setInterval(() => {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   let otp = urlParams.get('waId');
    //   let recaptchaResponse = this.recaptcha;
    //   if (!otp) otp = (<HTMLInputElement>document?.getElementById('otp'))?.value;
    //   if (otp?.length > 4) {
    //     clearInterval(interval);
    //     // alert(otp);
    //     this.authService.waLogin({ otp, recaptchaResponse }).subscribe(
    //       (res: any) => {
    //         console.log(res);
    //         console.log(res);
    //         //debugger
    //         this.token = res.headers.get('authorization');
    //         //console.log('Inside signIn of header component value of res:' + JSON.stringify(res));
    //         //  console.log('Inside signIn of header component value of res:' + JSON.stringify(res?.body?.result?.settings.presetStakeSettings?.settings));
    //         //        localStorage.setItem(8'ROYALTOKEN_KEY', JSON.stringify(this.token));
    //         if (res.body.status.statusCode == '0') {
    //           this.token = res.headers.get('authorization');
    //           document.body.classList.remove('openpopup');
    //           //this.balancamount();
    //           //console.log('Inside signIn of header component value of this.token:' + this.token);
    //           localStorage.setItem(
    //             'ROYALTOKEN_KEY',
    //             JSON.stringify(this.token)
    //           );
    //           localStorage.setItem('Member_code', res.body.result.memberCode);
    //           localStorage.setItem(
    //             'betstack',
    //             res?.body?.result?.settings.presetStakeSettings?.settings
    //           );
    //           //this.setToken('betstack', this.optionSplit(res?.body?.result?.settings.presetStakeSettings?.settings));
    //           //console.log('Inside signIn of header component localStorage.setItem(Member_code):' + localStorage.getItem('Member_code'));
    //           this.router.navigate(['/main']);
    //           window.location.reload();
    //         } else {
    //           //this._notify.error(res.body.status.statusMessage);
    //           this.router.navigate(['/main']);
    //         }
    //       },
    //       (error) => {
    //         console.log(error);
    //       }
    //     );
    //   } else {
    //     console.log('otp not found');
    //   }
    // }, 1000);
  }

  // getConfigData() {
  //   console.log("This is getConfigdata")
  //   this.authService.getConfig().subscribe(
  //     (res) => {
  //       //save res.result to local storage
  //       localStorage.setItem("configData", JSON.stringify(res?.result));
  //       this.rSiteKey = res?.result?.recaptchaConfig?.recaptchaSiteKey;
  //       console.log(this.rSiteKey);
  //       this.version = res?.result?.appConfig["1"]?.model?.["eleven-version"]
  //       if (!localStorage.getItem("versionNow") && this.version) {
  //         localStorage.setItem("versionNow", this.version)
  //       } else if (localStorage.getItem("versionNow") != this.version) {
  //         this.versionPopUpOpen = true
  //         localStorage.setItem("versionNow", this.version)
  //       }
  //       let interval = setTimeout(() => {
  //         if (this.captchaRef) {
  //           this.captchaRef.execute();
  //           clearInterval(interval);
  //         }
  //       }, 100);
  //       console.log(
  //         'Inside getConfigData value of this.rSiteKey:' + this.rSiteKey
  //       );
  //     },
  //     (error) => {
  //       console.log(error)
  //     }
  //   );
  // }

  //redirect to http://localhost:4200/balancemanagement/Deposit
  async goToDeposit() {
    await this.signInAfterRegister();
    this.router.navigate(['/balancemanagement/Deposit']);
  }


  trackRegisterPixelService(memberCode : string, loginName: string) {
    try {
      const pixelIds = ["448953950813931","900978155047808","1026321468616181","974838613755656",
        "586379523499553","2706155189693721","1190972091651303","832160437968868","990285032032635",
        "2706155189693721","900978155047808","678060997780292","453544397402656"];
      var email = loginName.includes("@") ? loginName : '';

      var phoneNumber = loginName.replace('_','');
      for (let i = 0; i < pixelIds.length; i++) {
        if (i == 0) {
          window.fbq('init', pixelIds[i], {
            "ph": phoneNumber || '',
            "external_id": memberCode || '',
            'em': email || ''

          });
        } else {
          window.fbq('init', pixelIds[i]);
        }
      }
      window.fbq('track', 'CompleteRegistration');
    } catch (error) {
      console.error(error);
    }
  }

  trackShareChatPixel(eventName) {
    try {
      if (localStorage.getItem('aff') == 'ShareChat') {
        const script = document.createElement('script');
        script.innerHTML = `scq('${eventName}', 'pre_defined')`;
        document.head.appendChild(script);
        script.remove();
      }
    } catch (error) {
      console.error(error);
    }
  }

}
