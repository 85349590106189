import { Component, OnInit, ChangeDetectorRef, OnDestroy, } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LiveMatchHelperService } from './live-match-helper.service';
import { Observable, catchError, filter, from, map, mergeMap, toArray } from 'rxjs';
import {SamMenu,SmaResult} from './samMenu.interface'
import * as moment from 'moment';
import { EventResult, EventTypes } from './eventType.interface';
import { InPlayEvent, inPlayAndPopularEvents } from './inPlayAndPopularEvents.interface';
import { MyprofileService } from 'src/app/services/myprofile.service';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit,OnDestroy {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }
  public aList: any = []; // get all matches data with eventTypeId
// inPlayData: InPlayResult[]; // get all inPlay matches data with eventTypeId
  isUpcoming: boolean = true; //for active section of upcoming/ leages
  eventType: string ; // if eventTypeId is 4 then cricket, 1 then football, 2 then tennis
upcomingEvents$: Observable<EventResult[][]>; // for get all upcoming matches data with eventTypeId
noUpcomingEvents: boolean = false; // if no upcoming events then true else false
matchSummaryData: SmaResult[]; // get all matches summary with children
public sList: EventResult[];
  public Aname: string;
  public Bname: string;
  istoken:any;
  showbet:any;
  isLoader:boolean=false;
  onshortsports:any = 4;
  isActiveCricket:boolean=true;
isActiveFootball:boolean=false;
isActiveTennis:boolean=false;
public interval300:any;
countsCricket:any=[];
countsfootball:any=[];
countsTennis:any=[];
cricketCount:any;
footballCount:any;
tennisCount:any;
eventName: String;
  marketName: String;
  runner: string;
  price: string;
  currentRoute: string;
eventList$: Observable<any>;

// eventID: any;
isActivePolitics: boolean = false;
countsPolitics: any = [];
politicsCount: any;


constructor(public authService: AuthService,
  private cdr: ChangeDetectorRef,
  private router: Router,
  public _liveMatch: LiveMatchHelperService,
  private _myProfile: MyprofileService,
  private route: ActivatedRoute)
  {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
    if (this.authService.currentUserValue==null){
      this.istoken=true;
   }
   else{
     this.istoken=false;
   }
   }

  ngOnInit(): void {
  this.authService.sportsId = this.route.snapshot.params['id'];
  // console.log(this.authService.sportsId != '2378962',this.isUpcoming)
  this.authService.updateSportsId(this.authService.sportsId);
  // this.getSportsDatabyEventTypeId(this.authService.sportsId); // get all inPlay matches data with eventTypeId
  this.getMatchSummery(); // get all matches summary with children
  this.getMatchData(); // get all matches data with eventTypeId
  this.getEventData(this.authService.sportsId); // get all upcoming matches data with eventTypeId
  this.getEventName(this.authService.sportsId);
  this.eventList$ = this.authService.getEventState().pipe(
    map((res: inPlayAndPopularEvents) => res?.result?.inPlayEvents),
    mergeMap(
      (result: InPlayEvent[]) => from(result).pipe(
          filter(
              event => event.eventTypeId == this.authService.sportsId && event.isInPlay == true),
          toArray())));

    const sportsId = this.authService.updateSportsId$.subscribe(() => {
    this.getEventName(this.authService.sportsId);
    this.shortitembysports(this.authService.sportsId);
  });
  this.shortitembysports(this.authService.sportsId);
    if (localStorage.getItem('sportsId')) {
    this.shortitembysports(localStorage.getItem('sportsId'));
    }
  }

  getEventName(sportsId: any) {
    if (sportsId == 4) {
      this.eventType = 'Cricket';
    }
    else if (sportsId == 1) {
      this.eventType = 'Football';
    }
    else if (sportsId == 2378962) {
      this.eventType = 'Politics';
  }
    else {
      this.eventType = 'Tennis';
    }
  }

// getSportsDatabyEventTypeId(eventTypeId:any) {
//   this.authService.getsportdata(eventTypeId).subscribe(res => {
//     this.inPlayData = res.result;
//   }, error => {
//     console.log(error);
//   });
// }

/**
 * ### Get all matches summary with children
 * @returns void
 * @memberof LiveComponent
 * @description
 * 1. Get all matches summary with children
 * 2. Store the data in matchSummeryData
 * 3. Set isLoader to false
 * 4. Subscribe to getMatchSummeryData() to get the latest data
 */
  getMatchSummery() {
  this.authService.getMatchSummeryData().subscribe((res:SamMenu) => {
    this.matchSummaryData = res?.result?.filter((data:SmaResult) => data?.id == this.authService?.sportsId);
    }, error => {
      console.log('error', error);
    });
  }

  getMatchData(){
  // this.isLoader = true;
  this.authService.geteventdata().subscribe();
    if(this.interval300){
      clearInterval(this.interval300);
          }
         if(this.getToken()){
           this.interval300 =setTimeout(()=>{
             this.getMatchData();
           }, 1000)
          }
          else{
           this.interval300 =setTimeout(()=>{
             this.getMatchData();
           }, 10000)
          }

    this.cdr.detectChanges();
    this.isLoader = false;
  }


/**
 * ### Get all upcoming matches data with eventTypeId
 * @returns void
 * @memberof LiveComponent
 * @description
 * 1. Get all inPlayAndPopularEvents matches data
 * 2. Filter popularEvents by eventTypeId
 * 3. Group the events by same date
 * 4. Store the grouped events in upcomingEvents$ observable
 * 5. Set isLoader to false
 */
getEventData(eventTypeId:string | number) { //endpoint: exchange/odds/inPlayAndPopularEvents
  this.isLoader = true;  //pipe(map((res: any) => res.result.inPlayEvents));
  this.upcomingEvents$ = this.authService.getUpcomingdata(eventTypeId).pipe( // get all upcoming matches data with eventTypeId
    map((res: EventTypes) => res.result), // get all popularEvents
    map((result: EventResult[]) => result.filter(event => event.eventTypeId == this.authService.sportsId)),
    map((result: EventResult[]) => result.filter(event => event.inPlay == false)), // filter popularEvents by eventTypeId
     // filter popularEvents by eventTypeId
    map(event => {
      // console.log('event', event);
      this.noUpcomingEvents = event.length ? false : true;
      this.isUpcoming = event.length ? true : false;
      return event.length ? event : []
    }), //if popularEvents is empty then return empty array
    map(events => this.groupEventsByDate(events)),
    catchError(error => {
        console.error('Error fetching data: ', error);
        // Return an empty array or an observable with a default value
        return ([]); // You can use throwError() for custom error handling
      })
    );
    this.isLoader = false;
  }

/**
 * ### Groups the events by same date
 * @param events: EventResult[]
 * @returns EventResult[]
 */
groupEventsByDate(events: EventResult[]): EventResult[][] {
  const groupedEvents = {};

  events.forEach(event => {
    const openDate = new Date(event.event.openDate);
    const dateKey = openDate.toISOString().split('T')[0]; // Extract the date portion

    if (!groupedEvents[dateKey]) {
      groupedEvents[dateKey] = [];
    }
    groupedEvents[dateKey].push(event);
  });
  // Convert the grouped events object into an array of arrays
  return Object.values(groupedEvents);
}


  shortitembysports(sportsid:any){
    this.isLoader = true;
    this.aList=[];

  this.isActiveCricket = sportsid == 4;
  this.isActiveFootball = sportsid == 1;
  this.isActivePolitics = sportsid == 2378962;
  this.isActiveTennis = !this.isActiveCricket && !this.isActiveFootball && !this.isActivePolitics;

    clearInterval(this.interval300);
    this.getMatchData();

  const tokenInterval = this.getToken() ? 1000 : 10000;

        this.interval300 =setTimeout(()=>{
          this.getMatchData();
  }, tokenInterval);

    this.onshortsports = sportsid;
  this.isLoader = false;
  }
  openbetpop(item,Id){
    this.eventName = item.event.name;
    this.marketName = item.market.name;
       if(Id==0){
       //  console.log('Inside openbetPop up Id;' + Id);
        this.price = item?.market?.consolidatedRunner?.back?.[0]?.price;
       }
       else if(Id==1){
        this.price = item?.market?.consolidatedRunner?.back?.[1]?.price;
       }
       else{
        this.price = item?.market?.consolidatedRunner?.back?.[2]?.price;
       }
      //console.log('Inside openbetpop value of this.price:' + this.price);
    if (!this.getToken() && !this.showbet && this.price != undefined) {
      this.showbet = true;
    }
    else if (!this.getToken() && this.showbet ) {
      this.showbet = true;
      const doc = <HTMLElement>document.getElementsByClassName('betplacedetail')[0];
      doc.style.display = 'block';
    }
    else{
      this.router.navigate(['/shared/details/'+ item?.event.id+'/' +item?.eventTypeId]);
    }
     }


    getToken() {
      return localStorage.getItem('ROYALTOKEN_KEY');
    }
  ngOnDestroy(): void {
    localStorage.removeItem('sportsId');
  }

  toggleUpcoming(bool: boolean) {
    this.isUpcoming = bool
  }

trackByFn(index: any, item: any) {
    return item.apolloBetId;
}

getMatchDate(date: any) {
  // check if date is in past
  // this.isMatchInPast = moment().isAfter(date);
  return moment(date).fromNow();
}

getUpcomeventData(id) {
  this.authService.getUpcomingdata(id).subscribe(
    (res:EventTypes) => {
      this.sList = res.result;
    },
    error => {
      console.log('error', error);
    });
  if (this.interval300) {
    clearInterval(this.interval300);
  }

  if (this.getToken()) {
    this.interval300 = setTimeout(() => {
      this.getUpcomeventData(id);
    }, 1000)
  }
  else {
    this.interval300 = setTimeout(() => {
      this.getUpcomeventData(id);
    }, 10000)
  }
}

  goBack() {
    // localStorage.setItem('sportsId', id);
    // this.authService.sportsId = id;
    // this.authService.updateSportsId(this.authService.sportsId);
    // this.router.navigate(['/shared/live/'+id]);
    this._myProfile.updateActiveMenuItem('/main');
    window.history.back();
  }

}
