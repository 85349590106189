import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-cancel-slip-popup',
  templateUrl: './cancel-slip-popup.component.html',
  styleUrls: ['./cancel-slip-popup.component.scss']
})
export class CancelSlipPopupComponent implements OnInit {

  @Output() closeClick: EventEmitter<any> = new EventEmitter();
  @Input() title: string;

  constructor() { }
  ngOnInit(): void {
    this.closeClicked
  }

  closeClicked() {
    this.closeClick.emit(true);
  }
}
