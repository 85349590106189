import { Component, Input, OnInit } from '@angular/core';
import { BonusService } from '../bonus.service';
import { LandingService } from 'src/app/services/landing.service';
import { MyprofileService } from 'src/app/services/myprofile.service';

@Component({
  selector: 'app-pending-bonus-card',
  templateUrl: './pending-bonus-card.component.html',
  styleUrls: ['./pending-bonus-card.component.scss']
})
export class PendingBonusCardComponent implements OnInit {

  @Input() bonus: any;

  constructor(private api: LandingService, public _bonus: BonusService, private myProfileService:MyprofileService) { }

  ngOnInit(): void {
    this.myProfileService.isHeaderFixed = true;
  }

  activateBonus() {
    if (!this._bonus.isAnyActiveBonus) {
      this.api.activateBonuses(this.bonus.BONUS_ID).subscribe((res: any) => {
        console.log(res);
        this._bonus.getAllBonuses('all');
      }, (err: any) => {
        console.log(err);
      });
    }
  }

  ngOnDestroy() {
    this.myProfileService.isHeaderFixed = false;
  }
  
}
