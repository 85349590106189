<div class="card">
    <div class="flexbox1">
        <div class="txt">
            <p class="bonus-title"> You've won Bonus of ₹{{ this.bonus?.AMOUNT | indianCurrency}}
            </p>
        </div>
        <div class="gift">
            <img loading="lazy" class="giftbox" src="../../../assets/images/checkgreen.svg">
            <p class="claimbtn">{{'Claimed'|translate}}</p>
        </div>
    </div>
</div>
