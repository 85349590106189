import { CdkDropListGroup } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
declare var SIR: any;
declare var $;
@Component({ selector: 'app-scoreboard', templateUrl: './scoreboard.component.html', styleUrls: ['./scoreboard.component.scss'] })
export class ScoreboardComponent implements OnInit, OnDestroy {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @Input() scorecard: any;
  @Input() stats: any;
  @Input() eid: any;
  @Input() sid: any;
  scrollLeftVisible = true;
  scrollRightVisible = false;
  isLoader: boolean = false;
  private container: HTMLElement | null;
  private scrollListener: () => void;

  constructor(private elementRef: ElementRef, private authService: AuthService) {
    this.scrollListener = () => {
      this.updateScrollVisibility();
    };
  }

  ngOnInit() {
    this.getStats();
    this.loadScript();
  }

  ngAfterViewInit() {
    // Call the initial update of scroll visibility
    this.updateScrollVisibility();

    // Add a scroll event listener to the .matchSection class
    this.container = this.elementRef.nativeElement.querySelector('.matchSection');
    // console.log(this.container);
    if (this.container) {
      this.container.addEventListener('scroll', this.scrollListener);
  }
  }

  ngOnDestroy() {
    // Remove the scroll event listener from the .matchSection container
    if (this.container) {
      this.container.removeEventListener('scroll', this.scrollListener);
    }
  }


  scrollLeft() {
    const container = this.scrollContainer.nativeElement;
    container.scrollTo({
      left: 0,
      behavior: 'smooth',
    });
    this.scrollLeftVisible = true;
    this.scrollRightVisible = false;
  }

  scrollRight() {
    const container = this.scrollContainer?.nativeElement;
    const scrollWidth = container?.scrollWidth - container?.clientWidth;
    container.scrollTo({
      left: scrollWidth,
      behavior: 'smooth',
    });
    this.scrollLeftVisible = false;
    this.scrollRightVisible = true;
  }


  splitNos(str: string) {
    let nos;
    if (str !== 'W') {
      if (str === 'wd' || str === 'nb') {
        str === 'wd' ? str = '1wd' : str = '1nb';
        nos = str.match(/\d+/g)[0];
      } else {
        nos = str.match(/\d+/g)[0];
      }
      return nos;
    } else {
      return str;
    }
  }

  splitWords(str: any) {
    if (str === 'W') {
      return null;
    } else {
      const words = str.match(/[a-zA-Z]+/g);
      return words;
    }
  }



  updateScrollVisibility() {
    const container = this.elementRef.nativeElement.querySelector('.matchSection');
    if(container){
    const scrollPosition = container.scrollLeft;
    const scrollWidth = container.scrollWidth - container.clientWidth;
    this.scrollLeftVisible = scrollPosition > scrollWidth / 2 ? false : true;
    this.scrollRightVisible = scrollPosition < scrollWidth / 2 ? false : true;
  }
  }


  node: any;


  getStats() {
    const data = { "sportId": this.sid, "eventId": this.eid };

    this.authService.getStats(data).subscribe((res) => {
      let matchId = res?.result?.url?.match(/\/([\w.]+)\?token/)?.[1];

      this.node = document.createElement('script');
      this.node.src = 'https://ws-cdn001.akamaized.net/f21fffaa0a1dfbb97d1c7be0fa3905ad/widgetloader';
      this.node.type = 'text/javascript';
      this.node.async = true;
      this.node.charset = 'utf-8';
      this.node.id = 'matchscript';
      document.getElementsByTagName('head')[0].appendChild(this.node);

      SIR('addWidget', '.sr-widget-1', 'match.lmtPlus', {
        branding: {
          tabs: { option: "icon", iconPosition: "start", variant: "fullWidth" }
        },
        collapseTo: "momentum",
        goalBannerCustomBgColor: "#027B5C",
        pitchLogo: "../../../assets/images/11exch.png",
        goalBannerImage: "../../../assets/images/11exch.png",
        logo: ["../../../assets/images/11exch.png"],
        layout: 'double',
        tabsPosition: 'top',
        matchId: this.sid == '4' ? this.eid : matchId
      });
      // Combined delay of 2000ms (2 seconds) for both actions
      // this.bindIframe(res?.result?.config?.videoId);
    });
  }


  public loadScript() {
      this.isLoader = true;
      this.getStats();

      if (!this.node && this.eid) {
        let element = document.getElementById("matchscript");
        if (element) {
          document.getElementsByTagName('head')[0].removeChild(element);
        }

        this.node = document.createElement('script');
        this.node.src = 'https://ws-cdn001.akamaized.net/f21fffaa0a1dfbb97d1c7be0fa3905ad/widgetloader';
        this.node.type = 'text/javascript';
        this.node.async = true;
        this.node.charset = 'utf-8';
        this.node.id = 'matchscript';
        document.getElementsByTagName('head')[0].appendChild(this.node);

        SIR('addWidget', '.sr-widget-1', 'match.lmtPlus', {
          branding: {
            tabs: { option: "icon", iconPosition: "start", variant: "fullWidth" }
          },
          collapseTo: "momentum",
          goalBannerCustomBgColor: "#027B5C",
          pitchLogo: "../../../assets/images/11exch.png",
          goalBannerImage: "../../../assets/images/11exch.png",
          logo: ["../../../assets/images/11exch.png"],
          layout: 'double',
          tabsPosition: 'top',
        });

        this.isLoader = false;
        ; // Combined delay of 2000ms (2 seconds) for both actions
      }
    }


}
