<div class="Modal-Background-5 Modal-Background max-width-550">
    <div class="loyalty-popup">
        <img class="coin-1" src="../../../../assets/images/loylty-coin.png" alt="coin">
        <img class="coin-2" src="../../../../assets/images/loylty-coin.png" alt="coin">
        <img class="coin-3" src="../../../../assets/images/loylty-coin.png" alt="coin">
        <img class="plus" src="../../../../assets/images/loylty-plus.svg" alt="plus">
        <img class="plus-1" src="../../../../assets/images/loylty-plus.svg" alt="plus">
        <img class="plus-2" src="../../../../assets/images/loylty-plus.svg" alt="plus">
        <div class="dimond">
            <img class="dim-img" src="../../../../assets/images/loylty-logo-white.svg" alt="loyalty">
            <!-- <img class="dim-img" src="../../../../assets/images/loylty-logo-purple.svg" alt="loyalty"> -->
            <!-- <img class="dim-img" src="../../../../assets/images/loylty-logo-green.svg" alt="loyalty"> -->
            <!-- <img class="dim-img" src="../../../../assets/images/loylty-logo-red.svg" alt="loyalty"> -->
        </div>
        <div class="rect-1"></div>
        <div class="rect-2"></div>
        <div class="loyalty-popup-card">
            <div class="close-image">
                <svg (click)="closeClicked()" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                    viewBox="0 0 20 20" fill="none">
                    <path
                        d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433288 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34879 18.9423 4.80707 17.0676 2.93239C15.1929 1.0577 12.6512 0.0031277 10 0ZM13.8564 12.5718C13.9432 12.6557 14.0124 12.756 14.0601 12.8669C14.1077 12.9778 14.1328 13.0971 14.1339 13.2178C14.1349 13.3385 14.1119 13.4582 14.0662 13.57C14.0205 13.6817 13.953 13.7832 13.8676 13.8685C13.7823 13.9539 13.6808 14.0214 13.5691 14.0671C13.4573 14.1128 13.3376 14.1358 13.2169 14.1348C13.0962 14.1337 12.9769 14.1086 12.866 14.061C12.7551 14.0134 12.6548 13.9441 12.5709 13.8573L10 11.2855L7.42909 13.8573C7.25764 14.0229 7.028 14.1145 6.78964 14.1124C6.55128 14.1104 6.32327 14.0147 6.15471 13.8462C5.98616 13.6776 5.89055 13.4496 5.88848 13.2113C5.88641 12.9729 5.97804 12.7433 6.14364 12.5718L8.71455 10L6.14364 7.42818C6.05681 7.34432 5.98756 7.24401 5.93991 7.13309C5.89227 7.02218 5.86719 6.90289 5.86614 6.78218C5.86509 6.66147 5.88809 6.54176 5.9338 6.43004C5.97951 6.31832 6.04701 6.21681 6.13237 6.13146C6.21773 6.0461 6.31923 5.9786 6.43095 5.93289C6.54268 5.88718 6.66239 5.86418 6.78309 5.86523C6.9038 5.86627 7.02309 5.89135 7.13401 5.939C7.24492 5.98664 7.34523 6.0559 7.42909 6.14273L10 8.71454L12.5709 6.14273C12.6548 6.0559 12.7551 5.98664 12.866 5.939C12.9769 5.89135 13.0962 5.86627 13.2169 5.86523C13.3376 5.86418 13.4573 5.88718 13.5691 5.93289C13.6808 5.9786 13.7823 6.0461 13.8676 6.13146C13.953 6.21681 14.0205 6.31832 14.0662 6.43004C14.1119 6.54176 14.1349 6.66147 14.1339 6.78218C14.1328 6.90289 14.1077 7.02218 14.0601 7.13309C14.0124 7.24401 13.9432 7.34432 13.8564 7.42818L11.2855 10L13.8564 12.5718Z"
                        fill="#929EB5" />
                </svg>
            </div>
            <div class="loyalty-inner-card">
                <div class="inner-first">
                    <div class="first-title">
                        <span>{{'Your Level is Beginner'|translate}}</span>
                        <small>{{'Complete turnover and reach the next level'|translate}}</small>
                    </div>
                </div>
                <div class="second-title">
                    <div class="progressbar-card">
                        <div class="progressbar-title">
                            <span>₹ 0</span>
                            <span>₹ 1,000</span>
                        </div>
                        <div class="progressbar-body">
                            <div class="progressbar">
                                <div class="inner-progressbar">
                                    <div class="outer">
                                        <div class="progress"></div>
                                        <!-- <img src="../../../../assets/images/loylty-logo-purple.svg" alt="loyalty"> -->
                                        <!-- <img src="../../../../assets/images/loylty-logo-green.svg" alt="loyalty"> -->
                                        <!-- <img src="../../../../assets/images/loylty-logo-red.svg" alt="loyalty"> -->
                                        <img src="../../../../assets/images/loylty-logo-winner.svg" alt="loyalty">
                                    </div>
                                </div>
                            </div>
                            <div class="title">
                                <span>{{'Left turnover :'|translate}}</span>
                                <span>₹ 870</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="inner-second">
                    <div class="second-body">
                        <span style="color: #D5D9DE;">{{'Current Turnover'|translate}}</span>
                        <span>₹ 130</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
