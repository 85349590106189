import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ios-instruction',
  templateUrl: './ios-instruction.component.html',
  styleUrls: ['./ios-instruction.component.scss']
})
export class IosInstructionComponent implements OnInit {
  @Output() closeClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.closeClicked
  }

  closeClicked() {
    this.closeClick.emit();
  }
}
