<div class="overlay" *ngIf="isLoader">
    <div class="loader-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <ng-lottie [options]="lottieOptions" width="75px" height="75px"></ng-lottie>
    </div>
</div>

<div class="match-summary">
    <div class="cricket-header ">
        <img (click)="goBack(matchTypeId)" src="../../../assets/images/details-back.svg" alt="back">
        <span>{{competitionName}}</span>
    </div>
    <ng-container *ngIf="authService.sportsId == 4 || authService.sportsId == 2 || authService.sportsId == 1">
        <div class="match-card" *ngFor="let group of matchData$ | async ; let i = index;">
            <div class="cricket-card-header"
                [ngClass]="{'cricket': authService?.sportsId == 4, 'tennis': authService?.sportsId == 2, 'football': authService?.sportsId == 1}">
                <div class="one"
                    [ngClass]="{'one-cricket-bg ': authService?.sportsId == 4, 'one-tennis-bg': authService?.sportsId == 2, 'one-football-bg': authService?.sportsId == 1}">
                </div>
                <div class="cricket-2"
                    [ngClass]="{'cricket-2-bg':authService?.sportsId == 4,'tennis-2-bg':authService?.sportsId == 2,'football-2-bg':authService?.sportsId == 1}">
                </div>
                <div class="header-text">
                    <img *ngIf="authService.sportsId == 4" src="../../../assets/images/menu-cricket.png" alt="cricket">
                    <img *ngIf="authService.sportsId == 1" src="../../../assets/images/menu-sports-option.svg"
                        alt="football">
                    <img *ngIf="authService.sportsId == 2" src="../../../assets/images/menu-tennis.svg" alt="tennis">
                    <span>{{group[0].event.openDate | customDate}}</span>
                </div>
            </div>
            <div class="team-section" *ngFor="let data of group"
                routerLink="/shared/details/{{data?.event?.id}}/{{data?.eventTypeId}}">
                <div class="team-card">
                    <div class="team-body">
                        <div class="score-detail">
                            <div class="over">
                                <img src="../../../../assets/images/clock-match.svg" alt="clock">
                            </div>
                            <div class="divider" [ngClass]="{
                                'cricket-div': authService.sportsId == '4',
                                'tennis-div':authService.sportsId=='2',
                                'football-div':authService.sportsId=='1'}">
                            </div>
                            <div class="team-name">
                                <div *ngIf="!splitVS(data?.event.altName)" class="match-name">
                                    <span>{{data?.event.altName}}</span>
                                </div>
                                <div *ngIf="splitVS(data?.event.altName)" class="match-name">
                                    <div class="first-team">
                                        <span>{{data?.event?.name | teamNameSplit : 'first'}}</span>
                                    </div>
                                    <div class="second-team">
                                        <span>{{data?.event?.name | teamNameSplit : 'second'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="live">
                            <img class="tv" src="../../../../assets/images/tv-icon.svg" alt="tv">
                            <div class="time">
                                <span>{{getMatchDate(data.event.openDate)}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="line-hr"></div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="(matchData$ | async)?.length === 0 && !isLoader">
            <app-no-data-available></app-no-data-available>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="authService.sportsId==7 ||authService.sportsId==4339">
        <div *ngFor="let item of raceLeagues">
            <div class="match-card upcoming-section" (click)="setDetailspageData(item)"
                routerLink="/shared/details/{{item?.id}}/{{authService.sportsId}}">
                <div class="cricket-card-header"
                    [ngClass]="{'horse-header matchCard-horse-1-border':authService.sportsId == 7, 'racing-header matchCard-greyhound-1-border': authService.sportsId == 4339}">
                    <div class="one"
                        [ngClass]="{'one-horse-bg matchCard-horse-2-border':authService.sportsId == 7, 'one-greyhond-bg matchCard-greyhound-2-border':authService.sportsId == 4339}">
                    </div>
                    <div class="cricket-2" [ngClass]="{'horse-2-bg':authService.sportsId == 7, 'greyhound-2-bg':authService.sportsId == 4339}">
                    </div>
                    <div class="header-text">
                        <img *ngIf="authService.sportsId == 7" src="../../../assets/images/menu-horse-racing.svg"
                            alt="horse">
                        <img *ngIf="authService.sportsId == 4339" src="../../../assets/images/menu-greyhord-racing.svg"
                            alt="racehorse">

                        <span>{{item?.startTime | date:"HH:mm"}} {{item?.venue}} ({{item?.countryCode}})</span>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="raceLeagues?.length === 0 && !isLoader">
            <app-no-data-available></app-no-data-available>
        </ng-container>
    </ng-container>
</div>