import {
  Component, ViewChild,
  ElementRef, ChangeDetectorRef, OnInit, AfterViewInit, OnDestroy
} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {OneSignal} from 'onesignal-ngx';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "./services/auth.service";
import jwt_decode from "jwt-decode";
import {BettingHelperService} from './services/betting-helper.service';
import {MyprofileService} from './services/myprofile.service';
import {BetHistory} from './shared/models/bet-history.interface';
import {TranslateService} from '@ngx-translate/core';
import {RecaptchaComponent} from "ng-recaptcha";
// import { environment } from "src/environments/environment";
import { LandingService } from "./services/landing.service";
import { AfterLoginPopup } from "./models/m-banner.interface";

declare global {
  interface Window {
    AdobeDC: any;
    grecaptcha: any;
    srtmCommands: any;
    OneSignalDeferredLoadedCallback: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {

  title = '11Exch';
  token = '';
  atag: string = '';
  aff: string = '';
  clickId: string = '';
  userName = '';
  memberCode = '';
  rSiteKey = "";
	landingBanner: AfterLoginPopup[];
  version = localStorage?.getItem("versionNow");
  @ViewChild("captchaRef") captchaRef: RecaptchaComponent;
  recaptcha: string;

  constructor(
	private cookieService: CookieService,
	public router: Router,
	private translate: TranslateService,
	private route: ActivatedRoute,
	private authService: AuthService,
	private cdr: ChangeDetectorRef,
	private oneSignal: OneSignal,
	public betHelper: BettingHelperService,
	private myProfile: MyprofileService,
  private _landing: LandingService

) {
    // this.oneSignal.init({
    //   appId: "9663e8d6-d33c-4c99-ab68-be0e94b29c41",
    // });
    this.translate.addLangs(['en', 'hi', 'mr']);
    this.translate.setDefaultLang('en');
    const lang = localStorage.getItem('lang');
    this.translate.use(lang ? lang : 'en');

    this.route.queryParams.subscribe(params => {
      console.log(params);
      if (params['token']) {
        this.setToken(params['token']);
      }
    });
    this.authService.googleConfigObservable$.subscribe((config) => {
      if (!config) {
        this.getConfigData();
      }
    });
  }

  ngOnInit() {


    window.addEventListener('popstate', this.handlePopState.bind(this));
    // const onesignalSettingstr = localStorage.getItem("onesignal-notification-prompt");
    // if (onesignalSettingstr !== null) {
    //   const onesignalSetting = JSON.parse(onesignalSettingstr);
    //   const currentTime = new Date().getTime();
    //
    //   // Calculate the timestamp for 5 days ago
    //   const fiveDaysAgoTimestamp = currentTime - (5 * 24 * 60 * 60 * 1000); // 5 days in milliseconds
    //
    //   if (onesignalSetting.timestamp > fiveDaysAgoTimestamp) {
    //     localStorage.removeItem("onesignal-notification-prompt");
    //   }
    // }

    this.authService.currentUser.subscribe((res: any) => {
      // console.log('user data updated')
      this.getBetHistory();
    });
		this.getBannerImage();
  }


  setToken(token: string) {
    localStorage.setItem('ROYALTOKEN_KEY', JSON.stringify(token));
    this.userName = JSON.parse(atob(token.split(".")[1]))['loginName'];
    localStorage.setItem('User_name', this.userName);
    this.memberCode = JSON.parse(atob(token.split(".")[1]))['memberCode'];
    localStorage.setItem('Member_code', this.memberCode);
    localStorage.setItem('betstack', JSON.stringify([100, 500, 1000, 5000, 10000]))
    localStorage.setItem('userData', JSON.stringify(JSON.parse(atob(token.split(".")[1]))));
    this.myProfile.balanceamount().subscribe((res) => {

    });
    // JSON.parse(atob(token.split('.')[1], 'base64').toString('utf8'))['loginName'];
  }

  ngOnDestroy() {
    window.removeEventListener('popstate', this.handlePopState.bind(this));
  }

  ngAfterViewInit() {


    if (window.location.pathname.includes('/walogin')) {
      this.walogin();
    }
    // console.log("!!!!!!!", window.location.href)
    if (window.location.href.includes('waId')) {
      const otp = window.location.href.split('waId=')[1]
      console.log("in if", otp)
      this.loginUserWithOtpLess(otp);
    }

    // if (Notification.permission !== 'granted'){


    try {
      window.OneSignalDeferred = window.OneSignalDeferred || [];

      this.oneSignal.init({appId: '9663e8d6-d33c-4c99-ab68-be0e94b29c41'}).then(() => {
        // do other stuff
      });
    } catch (e) {
      console.log(e);
    }


    try {


      // console.log('ngAfterViewInit of app component.ts:' + window.location.search);
      // set cookies if have locationSearch
      if (window.location.search) {
        // console.log('Inside if  ngAfterViewInit of app component.ts:');
        let s = "=";
        const queryString = window.location.search;
        const parameters = new URLSearchParams(queryString);
        parameters.forEach((value, key, urlParam) => {
          if (key === "atag") {
            this.cookieService.set(`${key}`, `${value}`, {expires: 60});
            localStorage.setItem(`${key}`, `${value}`);
          }
          if (key === "clickId") {
            this.cookieService.set(`${key}`, `${value}`, {expires: 60});
            localStorage.setItem(`${key}`, `${value}`);
          }
          if (key === "aff") {
            this.cookieService.set(`${key}`, `${value}`, {expires: 60});
            localStorage.setItem(`${key}`, `${value}`);
          }
          if (key === "waId") {
            this.loginUserWithOtpLess(value);
          }
        });
      }

      // if(window.location.href.includes('intercom')){
      //   this.router.navigate(['/intercom']);
      // }

      if (window.location.href.includes('intercom')) {
        this.router.navigate(['/intercom']);

      }

      if (this.cookieService.get("atag")) {
        // @ts-ignore
        localStorage.setItem("atag", this.cookieService.get("atag"));
      }
      if (this.cookieService.get("aff")) {
        // @ts-ignore
        localStorage.setItem("aff", this.cookieService.get("aff"));
      }
      if (this.cookieService.get("clickId")) {
        // @ts-ignore
        localStorage.setItem("clickId", this.cookieService.get("clickId"));
      }

    } catch (e) {
      console.log(e);
    }
  }

  navigateToPage(pageName: string) { // for search engine optimization
    history.pushState({page: pageName}, null, `/${pageName}`);
  }

  handlePopState(event: PopStateEvent) {
    const state = event.state;
    if (state && state.page) {
      this.navigateToPage(state.page);
    }
  }

  walogin() {
    // const url =
    //   `https://11exch.authlink.me/?redirectUri=` + window.location.origin;
    // if (byClick) {
    window.location.href = `https://11exch.authlink.me/?redirectUri=` + window.location.origin;
    // }
  }

  createOneSignalUser(data) {
    try {
      const options = {
        method: 'POST',
        headers: {accept: 'application/json', 'content-type': 'application/json'},
        body: JSON.stringify({
          properties: {},
          subscriptions: [
            {
              type: 'SMS',
              token: data?.loginName?.replace('b06.', '+')?.replace('_', ''),
              enabled: true,
            }
          ],
          identity: {external_id: data.memberCode}
        })
      };

      fetch('https://onesignal.com/api/v1/apps/9663e8d6-d33c-4c99-ab68-be0e94b29c41/users', options)
        .then(response => response.json())
        .then(response => console.log(response))
        .catch(err => console.error(err));
    } catch (e) {
      console.log('ONESIGNAL user creating error: ', e)
    }
  }

  loginUserWithOtpLess(otp) {
    if (localStorage.getItem('atag')) {
      this.atag = localStorage.getItem('atag');
    }
    if (localStorage.getItem('clickId')) {
      this.clickId = localStorage.getItem('clickId');
    }
    const data = {
      otp: otp,
      atag: this.atag,
      clickId: this.clickId
    }
    this.authService.waLogin(data).subscribe((res: any) => {
        if (res.body.status.statusCode == '0') {
          this.token = res.headers.get('authorization');
          localStorage.setItem(
            'ROYALTOKEN_KEY',
            JSON.stringify(this.token)
          );
          const decodedToken: any = jwt_decode(this.token)
          this.createOneSignalUser(decodedToken)
          window.srtmCommands.push({
            event: "track.user.login",
            payload: {
              action: "complete",
              userId: res?.body?.result?.memberCode
            }
          });
          this.authService.currentUserSubject.next(res.body.result);
          localStorage.setItem('userData', JSON.stringify(res.body.result));
          localStorage.setItem('Member_code', res.body.result.memberCode);
          localStorage.setItem('User_name', res.body.result.loginName);
          localStorage.setItem(
            'betstack',
            res?.body?.result?.settings.presetStakeSettings?.settings
          );
          this.cdr.detectChanges();
          this.router.navigate(['/main']);
          window.location.replace(window.location.origin);
        } else {
          this.router.navigate(['/main']);
        }
      },
      (error) => {
        console.log(error);
      }
    );

  }


  getBetHistory() {
    if (!localStorage.getItem('ROYALTOKEN_KEY')) {
      return;
    }

    this.myProfile.betHistory(null).subscribe((res: BetHistory) => {
      this.betHelper.betHistorySubject.next(res?.result);
    }, (err: any) => {
      console.log(err)
    })

    this.myProfile.betHistoryCount().subscribe((res: any) => {
        this.myProfile.totalBetCount = res?.result?.count;
      }
    );
  }

	resolved(captchaResponse: string) {
		this.recaptcha = captchaResponse;
		this.cdr.detectChanges();
		this.cdr.markForCheck();
	}

	getConfigData() {
		this.authService.getConfig().subscribe(
			(res) => {
				localStorage.setItem("configData", JSON.stringify(res?.result));
				this.rSiteKey = res?.result?.recaptchaConfig?.recaptchaSiteKey;
				localStorage.setItem("rSiteKey", this.rSiteKey);
				this.version = res?.result?.appConfig["1"]?.model?.["eleven-version"];
				if (!localStorage.getItem("versionNow") && this.version) {
					localStorage.setItem("versionNow", this.version);
				} else if (localStorage.getItem("versionNow") !== this.version) {
					this.authService.versionPopUpOpen = true;
				}
				let redirectUrl = res?.result?.appConfig["3"].model?.[this.getCurrentHost()];
				if ( redirectUrl || redirectUrl != undefined){
					window.location.href = redirectUrl;
				}
				const interval = setTimeout(() => {
					if (this.captchaRef) {
						this.captchaRef?.execute();
						clearInterval(interval);
					}
				}, 100);
			},
			(error) => {
				console.error(error);
			},
		);
	}

	getCurrentHost(): string{
		// window.location.hostname returns the domain without the protocol
		// window.location.host returns the domain with the protocol
		return window.location.hostname;
	}

	getBannerImage(){
		this._landing.sliderimage().subscribe((res => {
		   this.landingBanner = res?.result?.afterLoginPopup
		 })
		   );
	 }
}
