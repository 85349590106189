import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MyprofileService } from 'src/app/services/myprofile.service';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss']
})
export class ProfileHeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private myprofileServices: MyprofileService,
  ) { }

  ngOnInit(): void {
  }

  navigateToHome() {
    this.router.navigate(['/main']);
    this.myprofileServices.updateActiveMenuItem('/main');
  }
}
