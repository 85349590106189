import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {BetSlipData} from "../models/BetSlipData";
import {PnlExposure} from "../models/PnlExposure";

@Injectable({
  providedIn: 'root'
})
export class BettingHelperService {
  price: any;
  stakes: any;

  public currentBetslipSubject: BehaviorSubject<BetSlipData>;
  public currentBetSlip: Observable<BetSlipData>;
  public currentExposureSubject: BehaviorSubject<PnlExposure>;
  public currentExposure: Observable<PnlExposure>;
  public betHistorySubject: BehaviorSubject<any>;
  public betHistoryData: Observable<any>;
  public totalBetCount: number = 0;
  public betCount: number = 0;


  constructor() {
    this.currentBetslipSubject = new BehaviorSubject<BetSlipData>(new BetSlipData());
    this.currentBetSlip = this.currentBetslipSubject.asObservable();
    this.betHistorySubject = new BehaviorSubject<any>(null);
    this.betHistoryData = this.betHistorySubject.asObservable();
    this.currentExposureSubject = new BehaviorSubject<PnlExposure>(new PnlExposure());
    this.currentExposure = this.currentExposureSubject.asObservable();
  }

  public get currentBetslip(): BetSlipData {
    return this.currentBetslipSubject.value;
  }

  public setCurrentBetslip(value) {
    this.currentBetslipSubject.next(value)
  }

  public get betHistory(): any {
    return this.betHistorySubject.value;
  }

  public setBetHistory(value) {
    this.betHistorySubject.next(value)
  }

  public get getCurrentExposure(): any {
    return this.currentExposureSubject.value;
  }

  openBetSlipIndex: any = -1;
  itemId: any; //will be runner id
  cardId: any; //will be market id

  selectedCard: any; //will be match card or detail card
  stakeOnBet: any = null;
  profitAndLiability: any;
  selectedTeam:any = null;
  selectedMatchCard:any = null;
  selectedButton: any = null;

  setCurrentExposure(marketExposure) {
    this.currentExposureSubject.next(marketExposure)
  }
}
