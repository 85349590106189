import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-football-card',
  templateUrl: './football-card.component.html',
  styleUrls: ['./football-card.component.scss']
})
export class FootballCardComponent implements OnInit {

  @Input() data: any;
  @Output() openBetSlip = new EventEmitter<any>(); //emits bet slip data
  @Input() showOdds: boolean = true; //show odds
  isMatchInPast: boolean = false;
  matchDate: any;
  fullTime: any;
  extraTime: any;
  time: any;
  betSlipCard: boolean = false; //show bet slip card
  odds: any; //odds data
  applyBackAnimation: any = [false, false, false];
  applyLayAnimation: any = [false, false, false];

  liveDotLottieOptions: any = {
    path: 'assets/static/media/live.json',
    autoplay: true,
    loop: true
  };


  constructor() { }

  ngOnInit(): void {
    // console.log(this.data);
    this.calculateTimeOfMatch();
    this.matchDate = this.getMatchDate(this.data?.market?.event?.openDate);
    this.odds = this.data?.market?.consolidatedRunner;
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(this.data);
    if (changes['data'] && changes['data'].currentValue && changes['data'].previousValue) {

      const backPrices: number[] = changes['data']?.currentValue?.market?.consolidatedRunner?.back.map(item => item?.price);
      const previousBackPrices: number[] = changes['data']?.previousValue?.market?.consolidatedRunner?.back.map(item => item?.price);

      for (let i = 0; i < backPrices.length; i++) {
        const currentPrice = backPrices[i];
        const previousPrice = previousBackPrices[i];

        if (currentPrice !== previousPrice) {
          this.applyBackAnimation[i] = true;
          setTimeout(() => {
            this.applyBackAnimation[i] = false;
          }, 1000);
        }
      }

      // do the same for lay prices
      const layPrices: number[] = changes['data']?.currentValue?.market?.consolidatedRunner?.lay.map(item => item?.price);
      const previousLayPrices: number[] = changes['data']?.previousValue?.market?.consolidatedRunner?.lay.map(item => item?.price);

      for (let i = 0; i < layPrices.length; i++) {
        const currentPrice = layPrices[i];
        const previousPrice = previousLayPrices[i];

        if (currentPrice !== previousPrice) {
          this.applyLayAnimation[i] = true;
          setTimeout(() => {
            this.applyLayAnimation[i] = false;
          }, 1000);
        }
      }
      this.ngOnInit();
    }
  }

  getMatchDate(date) {
    this.isMatchInPast = moment().isAfter(date);
    return moment(date).fromNow();
  }

  calculateTimeOfMatch() {
    const time = this.data?.scores?.time ? this.data?.scores?.time : 0
    this.fullTime = Math.ceil(time / 60000)
    this.time = this.fullTime / 90 > 1 ? 90 : this.fullTime
    this.extraTime = this.fullTime % 90
  }

  triggerBetSlip(bet: number, isBack: boolean, oddIndex: number) {
    // currently return as this is diabled on landing page
    return;
    //check if market status is open and and market is bettable
    if (this.data?.market?.status == 'OPEN' && this.data?.market?.isBettable) {
      this.openBetSlip.emit(
        {
          eventId: this.data?.event?.id,
          bet: bet,
          sportsId: this.data?.eventTypeId,
          backLay: this.data?.market?.consolidatedRunner,
          isBack,
          oddIndex,
          marketId: this.data?.market?.id,
          betDelay: this.data?.market?.betDelay,
        }
      );
    }
  }
}
