import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CasinoService } from "../../services/casino.service";
import { MyprofileService } from 'src/app/services/myprofile.service';
import { AuthService } from 'src/app/services/auth.service';
import { BettingHelperService } from 'src/app/services/betting-helper.service';
declare var google: any;
type FileNames = 'about-us.html' | 'blogs.html' | 'casino.html' | 'contact-us.html' | 'faq.html' | 'general-terms-conditions.html' | 'poker.html' | 'privacy-policy.html' | 'responsible-gaming.html' | 'self-exclusion.html' | 'sports-betting-rules.html' | 'sportsbook-terms-conditions.html';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }


  menushow: any = false;
  showhidefooterpage: any = true;
  aboutshowhide: any = false;
  aboutshowhide1: any = false;
  helpshowhide: any = false;
  statisticsshowhide: any = false;
  regulationshowhide: any = false;

  showAbout: boolean;
  showRegulation: boolean;
  showHelp: boolean;

  showhideresponsible: any = true;
  showhideaboutshow: any = true;
  showhideblogs: any = true;
  showhidefaq: any = true;
  showhidecontact: any = true;
  showhidesportbetting: any = true;
  showhidecasino: any = true;
  showhidegeneral: any = true;
  showhidesportbook: any = true;
  showhideprivacy: any = true;
  // showHolder: boolean;
  // hideFooter: boolean;
  isMobile = false;

  // variables for showing dynamic html
  // aboutUs: SafeHtml; // about us html received from api
  @ViewChild('aboutUs') aboutUsElement: ElementRef<HTMLElement>;
  @ViewChild('blogs') blogsElement: ElementRef<HTMLElement>; // blogs html received from api
  @ViewChild('casino') casinoElement: ElementRef<HTMLElement>; // casino html received from api
  @ViewChild('poker') pokerElement: ElementRef<HTMLElement>; // poker html received from api
  @ViewChild('contactUs') contactUsElement: ElementRef<HTMLElement>; // contact us html received from api
  @ViewChild('faq') faqElement: ElementRef<HTMLElement>; // faq html received from api
  @ViewChild('generalTnC') generalTermsElement: ElementRef<HTMLElement>; // general terms html received from api
  @ViewChild('privacy') privacyPolicyElement: ElementRef<HTMLElement>; // privacy policy html received from api
  @ViewChild('responsibleGaming') responsibleGamingElement: ElementRef<HTMLElement>; // responsible gaming html received from api
  @ViewChild('sportsBettingRules') sportsBettingRulesElement: ElementRef<HTMLElement>; // sports betting html received from api
  @ViewChild('selfExclusion') selfExclusionElement: ElementRef<HTMLElement>; // self exclusion html received from api
  @ViewChild('sportsbookTnC') sportsbookTermsElement: ElementRef<HTMLElement>; // sportsbook terms html received from api

  // private casinoURLSubscription: Subscription;
  // casinoURL: any;
  //new variables for new menu
  activeMenu: boolean = false;
  activeLiveBets: boolean = false;
  activeSport: boolean = false;
  activeCasino: boolean = false;
  activeHome: boolean = true;
  isLoader: boolean = false;
  showFooterForHome: boolean = false;
  constructor(public router: Router,
    private casinoServices: CasinoService,
    public myProfile: MyprofileService,
    public authService: AuthService,
    public bettingHelper: BettingHelperService) {
  }
  // betcount: any;
  // totaBetcount: any = 0;

  ngOnInit(): void {
    this.myProfile.openMenu.subscribe((res: any) => {
      this.menushow = this.activeMenu = res;
    });

    this.getBetCount();
    this.checkIfMobile();

    if (this.router.url === '/main' || this.router.url === '/') {
      this.showFooterForHome = true;
      this.activeHome = true;
    }

    this.myProfile.activeMenuItem.subscribe((res: any) => {
      this.activeLiveBets = res === '/bethistory';
      this.activeSport = res === '/shared/sport';
      this.activeCasino = res === '/shared/casino';
      this.activeHome = res === '/main';
      this.activeMenu = res === '/menu';
      if ((res === '/main' || res === '/' || res === undefined || res === null) && (this.activeMenu === false) && (res !== '/menu') && (this.router.url === '/main' || this.router.url === '/' || res === '')) {
        this.activeHome = true;
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Do something when a navigation event (route change) occurs
        // You can add your logic here to respond to route changes
        if (event.url === '/main' || event.url === '/') {
          this.activeHome = true;
        } else {
          this.activeHome = false;
          this.myProfile.updateActiveMenuItem(event.url);
        }
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 1024; // Пороговое значение ширины для мобильных устройств
  }

  async showprivacy() {
    this.showhideprivacy = false;
    await this.getElementData('privacy-policy.html').then((data)=> {
      this.privacyPolicyElement.nativeElement.innerHTML = data;
    })
  }

  async showsportbook() {
    this.showhidesportbook = false;
    await this.getElementData('sportsbook-terms-conditions.html').then((data)=> {
      this.sportsbookTermsElement.nativeElement.innerHTML = data;
    })
  }

  async showgeneral() {
    this.showhidegeneral = false;
    await this.getElementData('general-terms-conditions.html').then((data)=> {
      this.generalTermsElement.nativeElement.innerHTML = data;
    })
  }

  async showcasino() {
    this.showhidecasino = false;
    await this.getElementData('casino.html').then((data)=> {
      this.casinoElement.nativeElement.innerHTML = data;
    })
  }

  async showsportbetting() {
    this.showhidesportbetting = false;
    await this.getElementData('sports-betting-rules.html').then((data)=> {
      this.sportsBettingRulesElement.nativeElement.innerHTML = data;
    })
  }

  async showfaq() {
    this.showhidefaq = false;
    await this.getElementData('faq.html').then((data)=> {
      this.faqElement.nativeElement.innerHTML = data;
    })
  }

  async showcontact() {
    this.showhidecontact = false;
    await this.getElementData('contact-us.html').then((data)=> {
      this.contactUsElement.nativeElement.innerHTML = data;
    })
  }

  async showresponsible() {
    this.showhideresponsible = false;
    await this.getElementData('responsible-gaming.html').then((data)=> {
      this.responsibleGamingElement.nativeElement.innerHTML = data;
    })
  }

  async showfooterpage() {
    this.showhidefooterpage = false;
    await this.getElementData('poker.html').then((data)=> {
      this.pokerElement.nativeElement.innerHTML = data;
    })
    // document.body.classList.remove('openpopup');
  }

  async affiliate() {
    this.showhideaboutshow = false;
    await this.getElementData('self-exclusion.html').then((data)=> {
      this.selfExclusionElement.nativeElement.innerHTML = data;
    })
    // document.body.classList.remove('openpopup');
  }

  aboutshow() {
    this.aboutshowhide = !this.aboutshowhide;
    document.body.classList.remove('openpopup');
  }

  async aboutshow1() {
    this.aboutshowhide1 = true;
    await this.getElementData('about-us.html').then((data) => {
        this.aboutUsElement.nativeElement.innerHTML = data;
    });;
  }

  async getElementData(url: FileNames, language = 'en'): Promise<string> { // language for loading html based on selected language
    const response = await fetch(`assets/static/html/${url}`);
    return await response.text();
  }

  async blog() {
    this.showhideblogs = false;
    await this.getElementData('blogs.html').then((data)=> {
      this.blogsElement.nativeElement.innerHTML = data;
    })
    // document.body.classList.remove('openpopup');
  }

  helpshow() {
    this.helpshowhide = !this.helpshowhide;
    document.body.classList.remove('openpopup');
  }
  statisticsshow() {
    this.statisticsshowhide = !this.statisticsshowhide;
    document.body.classList.remove('openpopup');
  }
  regulationshow() {
    this.regulationshowhide = !this.regulationshowhide;
    document.body.classList.remove('openpopup');
  }
  //messegner start

  // messengershow() {
  //   (<any>window).Intercom("show", {
  //     app_id: 'scev8zxr',
  //   });
  // }

  openSign() {
    if (!this.getToken()) {

      document.getElementById("demo").click();
    }
    else {
      this.router.navigate(['/bethistory']);
    }
  }
  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }
  ngAfterViewInit() {
    /*function googleTranslateElementInit() {
      new google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: 'en,hi' }, 'google_translate_element');
    }
    googleTranslateElementInit();*/
  }

  ngDoCheck() {
    //this.translated()
  }
  ngOnChanges() {
    function googleTranslateElementInit() {
      new google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: 'en,hi' }, 'google_translate_element');
    }
    googleTranslateElementInit();
  }

  //new functions for new menu
  footermenubarshow() {
    localStorage.removeItem('sportsId');
    this.isLoader = true;
    this.authService.sportsId = 0;
    // setTimeout(() => {
      this.menushow = !this.menushow;
      this.activeMenu = !this.activeMenu;
      this.myProfile.updateActiveMenuItem('/menu');
      if (this.menushow == false && (this.router.url === '/main' || this.router.url === '/')) {
        this.activeHome = true;
        this.myProfile.updateActiveMenuItem('/main');
      }
      this.activeLiveBets = false;
      this.activeSport = false;
      this.activeCasino = false;
      this.isLoader = false;
    // }, 300)
  }

  footermenubarshowpopclose() {
    this.menushow = false;
    this.myProfile.updateMenuStatus(false);
    this.activeMenu = true;
    this.activeLiveBets = false;
    this.activeSport = false;
    this.activeCasino = false;
    this.activeHome = false;
  }
  gotoLiveBets() {
    if (localStorage.getItem('ROYALTOKEN_KEY') == null) {
      document.getElementById("demo").click();
      return;
    }
    this.navigateTo('/bethistory');
    this.activeLiveBets = true;
    this.myProfile.updateActiveMenuItem('/bethistory');
    //set other to false
    this.activeSport = false;
    this.activeCasino = false;
    this.activeMenu = false;
    this.activeHome = false;
  }

  gotoSprtsSection() {
    this.navigateTo('/shared/sport');
    this.activeSport = true;
    this.myProfile.updateActiveMenuItem('/shared/sport');
    this.authService.updateActiveMenuItem('sports');
    //set other to false
    this.activeLiveBets = false;
    this.activeCasino = false;
    this.activeMenu = false;
    this.activeHome = false;
    this.authService.sportsId = 4;
  }

  gotoCasinoSection() {
    this.navigateTo('/shared/casino');
    this.activeCasino = true;
    this.myProfile.updateActiveMenuItem('/shared/casino');
    //set other to false
    this.activeLiveBets = false;
    this.activeSport = false;
    this.activeMenu = false;
    this.activeHome = false;
  }

  gotoHomeSection() {
    this.myProfile.updateActiveMenuItem('/main');
    this.navigateTo('/main');
    this.activeHome = true;
    this.activeLiveBets = false;
    this.activeSport = false;
    this.activeCasino = false;
    this.activeMenu = false;
  }

  navigateTo(route: string) {
    this.menushow = false;
    this.myProfile.updateMenuStatus(false);
    this.router.navigate([route]);
    this.myProfile.updateActiveMenuItem(route);
  }


  showDropdown(section: string) {
    if (section === 'about') {
      this.showAbout = !this.showAbout;
      if (this.showAbout) {
        this.showHelp = false;
        this.showRegulation = false;
      }
    } else if (section === 'help') {
      this.showHelp = !this.showHelp;
      if (this.showHelp) {
        this.showAbout = false;
        this.showRegulation = false;
      }
    } else if (section === 'regulations') {
      this.showRegulation = !this.showRegulation;
      if (this.showRegulation) {
        this.showAbout = false;
        this.showHelp = false;
      }
    }
  }

  getBetCount() {
    if (localStorage.getItem('ROYALTOKEN_KEY')) {
      this.myProfile.betHistoryCount().subscribe({
        next: (res: any) => {
          this.myProfile.totalBetCount = res?.result?.count;
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    }
  }
}
