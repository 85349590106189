import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-wagering-claimed',
  templateUrl: './no-wagering-claimed.component.html',
  styleUrls: ['./no-wagering-claimed.component.scss']
})
export class NoWageringClaimedComponent implements OnInit {
  @Input() bonus: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
