import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
@Component({
  selector: 'app-livecalendar',
  templateUrl: './livecalendar.component.html',
  styleUrls: ['./livecalendar.component.scss']
})
export class LivecalendarComponent implements OnInit {
  istoken:any;
  constructor(private authService: AuthService) {
    if (this.authService.currentUserValue==null){
      this.istoken=true;
   }
   else{
     this.istoken=false;
   }
   }

  ngOnInit(): void {
  }

}
