<div class="card" [ngClass]="{'close-popup':popupClosed}">
    <div class="card-header">
        <span>{{title}}</span>
        <svg (click)="closeClicked()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6.225 18.8251L5.175 17.7751L10.95 12L5.175 6.22505L6.225 5.17505L12 10.95L17.775 5.17505L18.825 6.22505L13.05 12L18.825 17.7751L17.775 18.8251L12 13.05L6.225 18.8251Z" fill="#E7EAEF"/>
          </svg>
    </div>
    <div class="card-body">
        <ng-content></ng-content>
    </div>
</div>