<ul class="notification-bar">
    <!-- <span *ngFor="let message of this._notify.messages; let i = index" > -->
        <!-- <div *ngIf="i < this._notify.maxLimit" class="notification" [ngClass]="{ -->
    <li *ngFor="let message of this._notify.messages; let i = index" class="notification" [ngClass]="{
            'success': message.type === 'success',
            'error': message.type === 'error',
            'info': message.type === 'info',
            'warning': message.type === 'warning',
            'openAnimation' : !message.isClosing, 
            'closeAnimation' : message.isClosing}"
            [ngStyle]="{'scale' : i * -0.1 + 1 ,  'margin-top': i * -4 + 'px', 'z-index': 1000 - i}">
            <p (click)="pause(message.id)" >{{message.text}}</p> 
            <div *ngIf="!message.hideCloseButton" class="closebtn" (click)="close(message.id)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        d="M5.1875 15.6875L4.3125 14.8125L9.125 10L4.3125 5.1875L5.1875 4.3125L10 9.125L14.8125 4.3125L15.6875 5.1875L10.875 10L15.6875 14.8125L14.8125 15.6875L10 10.875L5.1875 15.6875Z"
                        fill="white" />
                </svg>
            </div>
        </li>
        <!-- </div> -->
</ul>