import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { MyprofileService } from 'src/app/services/myprofile.service';

@Component({
  selector: 'app-account-statement',
  templateUrl: './account-statement.component.html',
  styleUrls: ['./account-statement.component.scss']
})
export class AccountStatementComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  showCalendar = false;
  showAccountHead = false;
  showAccountState = true;
  bettingDetail = false;
  accountStatementData: any = [];
  accountStatementDetailsData: any = [];
  backSubTotal: number = 0;
  laySubTotal: number = 0;
  marketSubTotal: number = 0;
  netMarketTotal: number = 0;
  commissionTotal: number = 0;
  isLoader: boolean = false;

  startDate: any;
  endDate: any;
  activeStartDate: any;
  activeEndDate: any;
  activeApplyBtn: boolean = false;


  constructor(private _myProfileService: MyprofileService) { }

  ngOnInit(): void {
    this.accountStatement();
    this.startDate = this.convertDateToDDMMYYYY(new Date(new Date().setDate(new Date().getDate() - 7)));
    this.endDate = this.convertDateToDDMMYYYY(new Date());
  }
  openCalendar() {
    this.showCalendar = !this.showCalendar;
  }

  convertDateToDDMMYYYY(date: any) {
    return moment(date).format('DD/MM/YYYY');
  }

  showAccountStatement() {
    this.showAccountState = false;
    this.bettingDetail = true;
    this.showAccountHead = true;
  }

  backToAccount() {
    if (!this.showAccountHead) {
      window.history.back();
    }
    this.showAccountState = true;
    this.bettingDetail = false;
    this.showAccountHead = false;
  }

  getBackSubTotal() {
    return this.accountStatementDetailsData.filter((item: any) => item.side == 0).reduce((acc: any, val: any) => acc + val.memberWin, 0).toFixed(2);
  }

  getLaySubTotal() {
    return this.accountStatementDetailsData.filter((item: any) => item.side == 1).reduce((acc: any, val: any) => acc + val.memberWin, 0).toFixed(2);
  }

  getMarketSubTotal() {
    return this.accountStatementDetailsData.reduce((acc: any, val: any) => acc + val.memberWin, 0).toFixed(2);
  }


  getNetMarketTotal() {
    // total for back and lay
    const backTotal = this.accountStatementDetailsData.filter((item: any) => item.side == 0).reduce((acc: any, val: any) => acc + val.memberWin, 0);
    const layTotal = this.accountStatementDetailsData.filter((item: any) => item.side == 1).reduce((acc: any, val: any) => acc + val.memberWin, 0);
    return (backTotal + layTotal).toFixed(2);
  }

  getCommissionTotal() {
    return this.accountStatementDetailsData.reduce((acc: any, val: any) => acc + val.memberWin, 0).toFixed(2);
  }

  parseDate = (dateStr: any) => {
    const [day, month, year] = dateStr?.split("/");
    return new Date(`${month}/${day}/${year}`);
  };

  accountStatement(startDate?: any, endDate?: any) {
    this.isLoader = true;
    if (startDate && endDate) {
      startDate = this.parseDate(startDate);
      endDate = this.parseDate(endDate);
    }
    startDate = startDate ? new Date(startDate) : new Date(new Date().setDate(new Date().getDate() - 7));
    endDate = endDate ? new Date(endDate) : new Date();
    endDate.setHours(23, 59, 59, 999);

    startDate = startDate.getTime();
    endDate = endDate.getTime();

    this._myProfileService.accountstatement(startDate, endDate).subscribe((res) => {
      this.isLoader = false;
      this.activeApplyBtn = false;
      if (res?.status?.statusDesc !== "Success") {
        return;
      }

      const data = res.result?.data || [];
      const accountStatement = data
        .sort((a: any, b: any) => b.date - a.date)
        .map((element: any) => ({
          date: new Date(element.date),
          data: element
        }));

      const uniqueDates = [...new Set(accountStatement.map((element: any) => element.date.toLocaleDateString()))];

      const groupedData = uniqueDates.map((date: any) => ({
        date,
        data: accountStatement.filter((element: any) => element.date.toLocaleDateString() === date)
      }));
      this.accountStatementData = groupedData;
    }, (err) => {
      this.isLoader = false;
      console.error(err);
    });
  }

  dateFormate(date: any) {
    return moment(date).format('MMM DD, YYYY | h:mm a');
  }

  dateFormate1(date: any) {
    const today = new Date().toDateString();
    const incomingDate= date.split('/');
    const date1 = new Date(`${incomingDate[1]}/${incomingDate[0]}/${incomingDate[2]}`).toDateString();
    if (today == date1) {
      return "Today";
    }
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toDateString();
    if (yesterday == date1) {
      return "Yesterday";
    }
    return moment(date1).format('MMM DD, YYYY');
  }

  getPnl(item: any) {
    return item.reduce((acc: any, val: any) => acc + val.pnl, 0);
  }

  getDateAndTime(date: any) {
    return moment(date).format('DD/MM/YYYY, hh:mm:ss');
  }

  betPlacedDate(date: any) {
    return moment(date).format('MMM MM, YYYY | hh:mm');
  }

  showAccountStatementDetailsData(market: any) {
    this.showCalendar = false;
    this.isLoader = true;
    const marketId = market?.description?.market;
    if (market.action === 'TRANSFER_DOWN' || market?.action === null || market?.description?.eventTypeId === '77777' || market?.action === 'OPEN') {
      this.isLoader = false;
      return;
    }
    this._myProfileService.accountstatementsdetails(marketId).subscribe((res) => {
      this.isLoader = false;
      this.showAccountStatement();
      this.accountStatementDetailsData = res.result;
    }, (err) => {
      this.isLoader = false;
      console.error(err);
    });
  }

  getBookmakerAvgOdds(averagePrice: number, matchedSize: number): number {
    let betAvg = averagePrice * matchedSize;
    return Number((betAvg -= matchedSize).toFixed(0));
  }

  handleStartDate(date: any) {
    this.startDate = date;
    this.activeStartDate = true;
    this.activeEndDate = false;
  }

  handleEndDate(date: any) {
    this.endDate = date;
    this.activeStartDate = false;
    this.activeEndDate = true;
    this.activeApplyBtn = true;
  }

  //get account statement for a particular date range
  async getAccountStatement() {
    if (!this.startDate || !this.endDate) {
      return;
    }
    this.showCalendar = false;
    this.accountStatement(this.startDate, this.endDate);
    this.activeEndDate = false;
    this.activeStartDate = false;
  }

  handleActiveStartDate() {
    this.activeStartDate = true;
    this.activeEndDate = false;
    this.showCalendar = !this.showCalendar;
  }

  handleActiveEndDate() {
    this.activeStartDate = false;
    this.activeEndDate = true;
    this.showCalendar = !this.showCalendar;
  }

  applyFilter() {
    this.getAccountStatement();
  }

  closeCalender() {
    if (this.showCalendar) {
      this.showCalendar = false;
    }
  }

}
