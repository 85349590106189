<div class="card bonus-card orange-card">
    <div class="card-body">
        <div class="topbox"></div>
        <div class="bottombox"></div>
        <img loading="lazy" class="giftbox" src="../../../assets/images/fi_6453565.svg">
        <img loading="lazy" class="star1" src="../../../assets/images/Group.svg">
        <img loading="lazy" class="star2" src="../../../assets/images/Group.svg">
        <img loading="lazy" class="star3" src="../../../assets/images/Group.svg">
        <img loading="lazy" class="star4" src="../../../assets/images/Group.svg">
        <!-- <p class="card-title"> <b>Get 500% bonus</b> on 1st deposit</p> -->
        <span class="badge rounded-pill" style="float: right;" >{{'Pending'|translate}}</span>
        <p class="card-title"> {{bonus.promoDetails['short-info']}} </p>
        <small>{{'Complete the current bonus to activate'|translate}}</small>
        <br>
        <span class="badge rounded-pill" style="padding: 8px;" *ngIf="!this._bonus.isAnyActiveBonus" (click)="activateBonus()">{{'Activate Now'|translate}}</span>
        <!-- <button class="btn" (click)="activateNow()" >Activate Now</button> -->
    </div>
</div>
