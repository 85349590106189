<!--<div class="layout-content-holder-bc">
    <div class="e-sports-All">
        <div class="esport-sarch-content">
            <div class="sport-search-bc active"><input type="text" placeholder="Search a competition or a team"
                    class="ss-input-bc" value="">
                <div class="ss-icon-holder-bc"><i class="ss-icon-bc bc-i-search"></i></div>
            </div>
        </div>
        <div class="horizontal-sl-tab-container" data-scroll-lock-scrollable="">
            <div class="horizontal-sl-tab "><a aria-current="page"
                    class="horizontal-sl-tab-bc selected-underline active" data-count="159"
                    href="/en/esports/all"><span>All</span></a><a class="horizontal-sl-tab-bc selected-underline"
                    data-count="136" href="/en/esports/upcoming"><span>Upcoming</span></a><a
                    class="horizontal-sl-tab-bc selected-underline" data-count="23"
                    href="/en/esports/live"><span>Live</span></a><a class="horizontal-sl-tab-bc selected-underline"
                    href="/en/esports/results"><span>Results</span></a></div>
        </div>
        <div>
            <div class="horizontal-sl-list-container" data-scroll-lock-scrollable="">
                <div class="horizontal-sl-list ">
                    <div class="horizontal-sl-item-bc CounterStrike " data-id="CounterStrike"
                        title="Counter-Strike: GO"><i
                            class="horizontal-sl-icon-bc bc-i-default-icon bc-i-CounterStrike"></i>
                        <p class="horizontal-sl-title-bc">Counter-Strike: GO</p><span
                            class="horizontal-sl-count-bc">22</span>
                    </div>
                    <div class="horizontal-sl-item-bc Dota2 " data-id="Dota2" title="Dota 2"><i
                            class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Dota2"></i>
                        <p class="horizontal-sl-title-bc">Dota 2</p><span class="horizontal-sl-count-bc">26</span>
                    </div>
                    <div class="horizontal-sl-item-bc LeagueOfLegends " data-id="LeagueOfLegends"
                        title="League of Legends"><i
                            class="horizontal-sl-icon-bc bc-i-default-icon bc-i-LeagueOfLegends"></i>
                        <p class="horizontal-sl-title-bc">League of Legends</p><span
                            class="horizontal-sl-count-bc">91</span>
                    </div>
                    <div class="horizontal-sl-item-bc Valorant " data-id="Valorant" title="Valorant"><i
                            class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Valorant"></i>
                        <p class="horizontal-sl-title-bc">Valorant</p><span class="horizontal-sl-count-bc">2</span>
                    </div>
                    <div class="horizontal-sl-item-bc KingOfGlory " data-id="KingOfGlory" title="King of Glory"><i
                            class="horizontal-sl-icon-bc bc-i-default-icon bc-i-KingOfGlory"></i>
                        <p class="horizontal-sl-title-bc">King of Glory</p><span class="horizontal-sl-count-bc">6</span>
                    </div>
                    <div class="horizontal-sl-item-bc CyberFootball " data-id="CyberFootball" title="E-Football"><i
                            class="horizontal-sl-icon-bc bc-i-default-icon bc-i-CyberFootball"></i>
                        <p class="horizontal-sl-title-bc">E-Football</p><span class="horizontal-sl-count-bc">9</span>
                    </div>
                    <div class="horizontal-sl-item-bc MobileLegends " data-id="MobileLegends" title="Mobile Legends"><i
                            class="horizontal-sl-icon-bc bc-i-default-icon bc-i-MobileLegends"></i>
                        <p class="horizontal-sl-title-bc">Mobile Legends</p><span
                            class="horizontal-sl-count-bc">3</span>
                    </div>
                </div>
            </div>
            <div class="market-filter-bc">
                <div class="market-filter-s-bc">
                    <div class="form-control-bc select s-small filled"><label
                            class="form-control-label-bc inputs"><select class="form-control-select-bc ellipsis">
                                <option value="WINNER">WINNER</option>
                                <option value="HANDICAP">HANDICAP</option>
                                <option value="TOTALS">TOTALS</option>
                                <option value="ODD/EVEN">ODD/EVEN</option>
                            </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i></label></div>
                </div>
                <div class="market-filter-cell-bc"></div>
            </div>
            <div class="page-view-more">
                <h3 class="view-title-more ellipsis">Live</h3>
            </div>
            <div class="competition-wrapper-bc">
                <div class="competition-header-bc"><i class="competition-icon-league-bc bc-i-CounterStrike"></i><span
                        class="competition-title-bc ellipsis">Counter-Strike: GO</span><span
                        class="competition-group-count">6</span><i
                        class="competition-icon-arrow-bc bc-i-small-arrow-up"></i></div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc world"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">World</span><span
                                    class="c-title-bc ellipsis">ESL Pro League</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="P1P2">Match Winner</option>
                                        <option value="Map2Winner">Map 2 Winner</option>
                                        <option value="Map3Winner">Map 3 Winner</option>
                                    </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="00Nation">00Nation</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Grayhound Gaming">Grayhound Gaming</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">1.25</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">3.57</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">Map
                                                2</span><span class="c-info-score-bc fixed-direction ellipsis">1 : 0,
                                                (19:16), (0:0)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-stream"></i><span
                                            class="c-info-markets-count-bc">+48</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc europe"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">Europe</span><span
                                    class="c-title-bc ellipsis">RES</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="Map2Winner">Map 2 Winner</option>
                                        <option value="Map3Winner">Map 3 Winner</option>
                                    </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Cloud9">Cloud9</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Bad News Eagles">Bad News Eagles</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">1.16</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">4.57</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">Map
                                                1</span><span class="c-info-score-bc fixed-direction ellipsis">0 : 0,
                                                (11:14)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-stream"></i><span
                                            class="c-info-markets-count-bc">+32</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc europe"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">Europe</span><span
                                    class="c-title-bc ellipsis">CCT Central Europe</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="Map3Winner">Map 3 Winner</option>
                                    </select></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="mouz NXT">mouz NXT</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Zeal22">Zeal22</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="market-bc disabled-market-bc"><span class="market-coefficient-bc"><i
                                                    class="market-lock-icon bc-i-lock"></i></span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="market-bc disabled-market-bc"><span class="market-coefficient-bc"><i
                                                    class="market-lock-icon bc-i-lock"></i></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">Map
                                                2</span><span class="c-info-score-bc fixed-direction ellipsis">1 : 0,
                                                (16:3), (9:13)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-stream"></i><i
                                            class="c-info-icon-bc bc-i-lock"></i><span
                                            class="c-info-markets-count-bc">+4</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="P1P2">Match Winner</option>
                                        <option value="Map2Winner">Map 2 Winner</option>
                                        <option value="Map3Winner">Map 3 Winner</option>
                                    </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Astralis Talent">Astralis Talent</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Team Sampi">Team Sampi</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">1.15</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">4.75</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">Map
                                                2</span><span class="c-info-score-bc fixed-direction ellipsis">1 : 0,
                                                (16:9), (0:0)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-stream"></i><span
                                            class="c-info-markets-count-bc">+31</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc europe"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">Europe</span><span
                                    class="c-title-bc ellipsis">CCT West Europe</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="P1P2">Match Winner</option>
                                        <option value="Map1Winner">Map 1 Winner</option>
                                        <option value="Map2Winner">Map 2 Winner</option>
                                        <option value="Map2Result(ExcludingOT)">Map 2 Result (Excluding OT)</option>
                                        <option value="Map3Winner">Map 3 Winner</option>
                                    </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="HAVU Gaming">HAVU Gaming</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Team Spirit Academy">Team Spirit Academy</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="market-bc disabled-market-bc"><span class="market-coefficient-bc"><i
                                                    class="market-lock-icon bc-i-lock"></i></span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="market-bc disabled-market-bc"><span class="market-coefficient-bc"><i
                                                    class="market-lock-icon bc-i-lock"></i></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">Map
                                                1</span><span class="c-info-score-bc fixed-direction ellipsis">0 : 0,
                                                (0:0)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-stream"></i><i
                                            class="c-info-icon-bc bc-i-lock"></i><span
                                            class="c-info-markets-count-bc">+51</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc europe"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">Europe</span><span
                                    class="c-title-bc ellipsis">BB Playlist. Urbanistic</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="Map3Winner">Map 3 Winner</option>
                                    </select></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Sangal Esports">Sangal Esports</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="HONORIS">HONORIS</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">1.80</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">1.90</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">Map
                                                2</span><span class="c-info-score-bc fixed-direction ellipsis">1 : 0,
                                                (16:10), (12:9)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-stream"></i><span
                                            class="c-info-markets-count-bc">+15</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
            </div>
            <div class="competition-wrapper-bc">
                <div class="competition-header-bc"><i class="competition-icon-league-bc bc-i-Dota2"></i><span
                        class="competition-title-bc ellipsis">Dota 2</span><span
                        class="competition-group-count">2</span><i
                        class="competition-icon-arrow-bc bc-i-small-arrow-up"></i></div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc europe"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">Europe</span><span
                                    class="c-title-bc ellipsis">Dota Pro Circuit. Eastern Europe Division I</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="Game3Winner">Game 3 Winner</option>
                                    </select></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Darkside">Darkside</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Natus Vincere">Natus Vincere</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">2.02</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">1.70</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">Game
                                                2</span><span class="c-info-score-bc fixed-direction ellipsis">0 : 1,
                                                (8:30), (9:13)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-stream"></i><span
                                            class="c-info-markets-count-bc">+21</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc europe"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">Europe</span><span
                                    class="c-title-bc ellipsis">European Pro League (EPL)</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="Game3Winner">Game 3 Winner</option>
                                    </select></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="HF">HF</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Ancient Tribe">Ancient Tribe</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">1.08</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">6.50</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">Game
                                                3</span><span class="c-info-score-bc fixed-direction ellipsis">1 : 1,
                                                (31:10), (18:39), (20:4)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-stream"></i><span
                                            class="c-info-markets-count-bc">+1</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
            </div>
            <div class="competition-wrapper-bc">
                <div class="competition-header-bc"><i class="competition-icon-league-bc bc-i-LeagueOfLegends"></i><span
                        class="competition-title-bc ellipsis">League of Legends</span><span
                        class="competition-group-count">4</span><i
                        class="competition-icon-arrow-bc bc-i-small-arrow-up"></i></div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc china"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">China</span><span
                                    class="c-title-bc ellipsis">LoL Development League</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="Game3Winner">Game 3 Winner</option>
                                    </select></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="MAX E-Sports Club">MAX E-Sports Club</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Team Pinnacle">Team Pinnacle</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">1.20</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">4.05</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">Game
                                                2</span><span class="c-info-score-bc fixed-direction ellipsis">1 : 0,
                                                (21:4), (1:0)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><span class="c-info-markets-count-bc">+17</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc china"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">China</span><span
                                    class="c-title-bc ellipsis">Tencent LoL Pro League (LPL)</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="P1P2">Match Winner</option>
                                        <option value="Game2Winner">Game 2 Winner</option>
                                        <option value="Game3Winner">Game 3 Winner</option>
                                    </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="JingDong Gaming">JingDong Gaming</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Lining Gaming">Lining Gaming</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><i
                                                    class="market-arrow-up-down-bc is-up"></i><span
                                                    class="market-odd-bc">3.25</span></div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><i
                                                    class="market-arrow-up-down-bc is-down"></i><span
                                                    class="market-odd-bc">1.29</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">Game
                                                2</span><span class="c-info-score-bc fixed-direction ellipsis">0 : 1,
                                                (8:22), (2:6)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-stream"></i><span
                                            class="c-info-markets-count-bc">+34</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc japan"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">Japan</span><span
                                    class="c-title-bc ellipsis">LoL Japan League Spring (LJL)</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="Game3Winner">Game 3 Winner</option>
                                    </select></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Fukuoka SoftBank Hawks Gaming">Fukuoka SoftBank Hawks
                                                Gaming</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="FENNEL">FENNEL</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">1.55</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">2.30</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">Game
                                                2</span><span class="c-info-score-bc fixed-direction ellipsis">1 : 0,
                                                (22:7), (5:8)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-stream"></i><span
                                            class="c-info-markets-count-bc">+17</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc vietnam"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">Vietnam</span><span
                                    class="c-title-bc ellipsis">Vietnam Championship Series Spring</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="Game2Winner">Game 2 Winner</option>
                                        <option value="Game3Winner">Game 3 Winner</option>
                                    </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Cerberus Esports">Cerberus Esports</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Team Secret">Team Secret</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">1.55</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">2.30</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">1st
                                                Game</span><span class="c-info-score-bc fixed-direction ellipsis">0 : 0,
                                                (0:0)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-stream"></i><span
                                            class="c-info-markets-count-bc">+25</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
            </div>
            <div class="competition-wrapper-bc">
                <div class="competition-header-bc"><i class="competition-icon-league-bc bc-i-Valorant"></i><span
                        class="competition-title-bc ellipsis">Valorant</span><span
                        class="competition-group-count">1</span><i
                        class="competition-icon-arrow-bc bc-i-small-arrow-up"></i></div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc europe"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">Europe</span><span
                                    class="c-title-bc ellipsis">Challengers League Northern Europe Polaris</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="P1P2">Match Winner</option>
                                        <option value="Game1Winner">Map 1 Winner</option>
                                        <option value="Game2Winner">Map 2 Winner</option>
                                        <option value="Game3Winner">Map 3 Winner</option>
                                    </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="HSDIRR">HSDIRR</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="FOKUS">FOKUS</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">5.10</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">1.13</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">Map
                                                1</span><span class="c-info-score-bc fixed-direction ellipsis">0 : 0,
                                                (0:0)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-stream"></i><span
                                            class="c-info-markets-count-bc">+28</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
            </div>
            <div class="competition-wrapper-bc">
                <div class="competition-header-bc"><i class="competition-icon-league-bc bc-i-KingOfGlory"></i><span
                        class="competition-title-bc ellipsis">King of Glory</span><span
                        class="competition-group-count">1</span><i
                        class="competition-icon-arrow-bc bc-i-small-arrow-up"></i></div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc china"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">China</span><span
                                    class="c-title-bc ellipsis">King Pro League</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="P1P2">Match Winner</option>
                                        <option value="Game1Winner">Game 1 Winner</option>
                                        <option value="Game2Winner">Game 2 Winner</option>
                                        <option value="Game3Winner">Game 3 Winner</option>
                                        <option value="Game4Winner">Game 4 Winner</option>
                                        <option value="Game5Winner">Game 5 Winner</option>
                                    </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Talent Gaming">Talent Gaming</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="LGD Goose">LGD Goose</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">1.80</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">1.90</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">1st
                                                Game</span><span class="c-info-score-bc fixed-direction ellipsis">0 : 0,
                                                (0:0)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><span class="c-info-markets-count-bc">+61</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
            </div>
            <div class="competition-wrapper-bc">
                <div class="competition-header-bc"><i class="competition-icon-league-bc bc-i-CyberFootball"></i><span
                        class="competition-title-bc ellipsis">E-Football</span><span
                        class="competition-group-count">8</span><i
                        class="competition-icon-arrow-bc bc-i-small-arrow-up"></i></div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc europe"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">Europe</span><span
                                    class="c-title-bc ellipsis">FIFA Champions League C</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc"></div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="SSC Napoli (Piki)">SSC Napoli (Piki)</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Borussia Dortmund (Markec)">Borussia Dortmund (Markec)</span>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="market-bc disabled-market-bc"><span class="market-coefficient-bc"><i
                                                    class="market-lock-icon bc-i-lock"></i></span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="market-bc disabled-market-bc"><span class="market-coefficient-bc"><i
                                                    class="market-lock-icon bc-i-lock"></i></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">2nd
                                                Half</span><span class="c-info-score-bc fixed-direction ellipsis">1 : 2,
                                                (0:2), (1:0) 90`</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-lock"></i></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc europe"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">Europe</span><span
                                    class="c-title-bc ellipsis">FIFA Europa League B</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc"></div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Real Sociedad (djamik_afc)">Real Sociedad (djamik_afc)</span>
                                        </div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="AS Monaco (Luntik)">AS Monaco (Luntik)</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc empty-market-bc">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">-</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc empty-market-bc">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">-</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">2nd
                                                Half</span><span class="c-info-score-bc fixed-direction ellipsis">1 : 0,
                                                (1:0), (0:0) 68`</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><span class="c-info-markets-count-bc">+5</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc world"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">World</span><span
                                    class="c-title-bc ellipsis">Esoccer Live Arena</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc"></div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Atletico Madrid (STDM)">Atletico Madrid (STDM)</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Real Madrid (BiZzoN_98)">Real Madrid (BiZzoN_98)</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="market-bc disabled-market-bc"><span class="market-coefficient-bc"><i
                                                    class="market-lock-icon bc-i-lock"></i></span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="market-bc disabled-market-bc"><span class="market-coefficient-bc"><i
                                                    class="market-lock-icon bc-i-lock"></i></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">1st
                                                Half</span><span class="c-info-score-bc fixed-direction ellipsis">1 : 1,
                                                (1:1) 10`</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-lock"></i></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc world"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">World</span><span
                                    class="c-title-bc ellipsis">Esoccer GT Leagues</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="P1XP2">Match Result</option>
                                    </select></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">X</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Benfica Lisbon (Carnage)">Benfica Lisbon (Carnage)</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Eintracht Frankfurt (Spex)">Eintracht Frankfurt (Spex)</span>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">3.70</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">4.90</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">1.55</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">1st
                                                Half</span><span class="c-info-score-bc fixed-direction ellipsis">0 : 0,
                                                (0:0) 1`</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><span class="c-info-markets-count-bc">+15</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc world"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">World</span><span
                                    class="c-title-bc ellipsis">FIFA International 1</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="P1XP2">Match Result</option>
                                        <option value="HalfTimeResult">1st Half Result</option>
                                    </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">X</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Portugal (djamik_afc)">Portugal (djamik_afc)</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="England (Kybuch)">England (Kybuch)</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">2.60</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><i
                                                    class="market-arrow-up-down-bc is-down"></i><span
                                                    class="market-odd-bc">3.50</span></div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">2.20</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">1st
                                                Half</span><span class="c-info-score-bc fixed-direction ellipsis">0 : 0,
                                                (0:0) 1`</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><span class="c-info-markets-count-bc">+21</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc"></div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Argentina (Aleksis)">Argentina (Aleksis)</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Brazil (Makson)">Brazil (Makson)</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc empty-market-bc">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">-</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc empty-market-bc">
                                            <div class="market-coefficient-bc"><span class="market-odd-bc">-</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i
                                            class="info-live-prematch-icon-bc bc-i-live"></i><time
                                            class="c-info-time-bc">12.03.23, 08:00</time>
                                        <div class="c-info-score-wrapper"><span
                                                class="c-info-score-bc fixed-direction ellipsis">Not
                                                Started</span></div>
                                    </div>
                                    <div class="c-info-more-bc"></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc italy"></i>
                            <p class="c-country-bc-text ellipsis"><span class="c-title-bc ellipsis">Italy</span><span
                                    class="c-title-bc ellipsis">FIFA Serie A</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc"></div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Juventus (ECF_Rebels)">Juventus (ECF_Rebels)</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="AC Milan (Rus_1995_LAN)">AC Milan (Rus_1995_LAN)</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="market-bc disabled-market-bc"><span class="market-coefficient-bc"><i
                                                    class="market-lock-icon bc-i-lock"></i></span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="market-bc disabled-market-bc"><span class="market-coefficient-bc"><i
                                                    class="market-lock-icon bc-i-lock"></i></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">2nd
                                                Half</span><span class="c-info-score-bc fixed-direction ellipsis">3 : 1,
                                                (1:1), (2:0) 90`</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-lock"></i></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc"></div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="AS Roma (ECF_dm1trena)">AS Roma (ECF_dm1trena)</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Inter Milan (ECF_Foggy)">Inter Milan (ECF_Foggy)</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="market-bc disabled-market-bc"><span class="market-coefficient-bc"><i
                                                    class="market-lock-icon bc-i-lock"></i></span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="market-bc disabled-market-bc"><span class="market-coefficient-bc"><i
                                                    class="market-lock-icon bc-i-lock"></i></span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">2nd
                                                Half</span><span class="c-info-score-bc fixed-direction ellipsis">1 : 1,
                                                (1:1), (0:0) 79`</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><i class="c-info-icon-bc bc-i-lock"></i></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
            </div>
            <div class="competition-wrapper-bc">
                <div class="competition-header-bc"><i class="competition-icon-league-bc bc-i-MobileLegends"></i><span
                        class="competition-title-bc ellipsis">Mobile Legends</span><span
                        class="competition-group-count">1</span><i
                        class="competition-icon-arrow-bc bc-i-small-arrow-up"></i></div>
                <div class="competition-bc">
                    <div class="c-segment-holder-bc head">
                        <div class="c-head-bc"><i class="c-country-bc flag-bc indonesia"></i>
                            <p class="c-country-bc-text ellipsis"><span
                                    class="c-title-bc ellipsis">Indonesia</span><span class="c-title-bc ellipsis">Mobile
                                    Legends Professional League</span></p><i
                                class="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"></i>
                        </div>
                    </div>
                    <div class="market-filter-bc">
                        <div class="market-filter-s-bc">
                            <div class="form-control-bc select s-small filled"><label
                                    class="form-control-label-bc inputs"><select
                                        class="form-control-select-bc ellipsis">
                                        <option value="P1P2">Match Winner</option>
                                    </select></label></div>
                        </div>
                        <div class="market-filter-cell-bc">
                            <div class="market-filter-info-bc">W1</div>
                            <div class="market-filter-info-bc">W2</div>
                        </div>
                    </div>
                    <div class="c-segment-holder-bc single-g-info-bc">
                        <div class="sw-container-bc " style="transform: translateX(0px);">
                            <div class="c-segment-holder-bc markets">
                                <div class="c-row-bc bordered">
                                    <div class="c-teams-bc">
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Bigetron Alpha">Bigetron Alpha</span></div>
                                        <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                title="Rex Regum Qeon">Rex Regum Qeon</span></div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><i
                                                    class="market-arrow-up-down-bc is-down"></i><span
                                                    class="market-odd-bc">2.37</span></div>
                                        </div>
                                    </div>
                                    <div class="c-odd-bc">
                                        <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                            <div class="market-coefficient-bc"><i
                                                    class="market-arrow-up-down-bc is-up"></i><span
                                                    class="market-odd-bc">1.52</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="c-segment-holder-bc info">
                                <div class="c-info-bc">
                                    <div class="c-info-content-bc"><i class="info-live-prematch-icon-bc bc-i-live"></i>
                                        <div class="c-info-score-wrapper"><span class="c-info-score-bc">Game
                                                3</span><span class="c-info-score-bc fixed-direction ellipsis">1 : 1,
                                                (8:16), (12:6), (0:0)</span></div>
                                    </div>
                                    <div class="c-info-more-bc"><span class="c-info-markets-count-bc">+2</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                class="c-info-icon-bc bc-i-favorite"></i></div>
                    </div>
                </div>
            </div>
            <div class="page-view-more">
                <h3 class="view-title-more ellipsis">Upcoming</h3>
            </div>
            <div class="competition-wrapper-bc">
                <div class="competition-header-bc"><i class="competition-icon-league-bc bc-i-CounterStrike"></i><span
                        class="competition-title-bc ellipsis">Counter-Strike: GO</span><span
                        class="competition-group-count">16</span><i
                        class="competition-icon-arrow-bc bc-i-small-arrow-down"></i></div>
            </div>
            <div class="competition-wrapper-bc">
                <div class="competition-header-bc"><i class="competition-icon-league-bc bc-i-Dota2"></i><span
                        class="competition-title-bc ellipsis">Dota 2</span><span
                        class="competition-group-count">24</span><i
                        class="competition-icon-arrow-bc bc-i-small-arrow-down"></i></div>
            </div>
            <div class="competition-wrapper-bc">
                <div class="competition-header-bc"><i class="competition-icon-league-bc bc-i-LeagueOfLegends"></i><span
                        class="competition-title-bc ellipsis">League of Legends</span><span
                        class="competition-group-count">87</span><i
                        class="competition-icon-arrow-bc bc-i-small-arrow-down"></i></div>
            </div>
            <div class="competition-wrapper-bc">
                <div class="competition-header-bc"><i class="competition-icon-league-bc bc-i-Valorant"></i><span
                        class="competition-title-bc ellipsis">Valorant</span><span
                        class="competition-group-count">1</span><i
                        class="competition-icon-arrow-bc bc-i-small-arrow-down"></i></div>
            </div>
            <div class="competition-wrapper-bc">
                <div class="competition-header-bc"><i class="competition-icon-league-bc bc-i-KingOfGlory"></i><span
                        class="competition-title-bc ellipsis">King of Glory</span><span
                        class="competition-group-count">5</span><i
                        class="competition-icon-arrow-bc bc-i-small-arrow-down"></i></div>
            </div>
            <div class="competition-wrapper-bc">
                <div class="competition-header-bc"><i class="competition-icon-league-bc bc-i-CyberFootball"></i><span
                        class="competition-title-bc ellipsis">E-Football</span><span
                        class="competition-group-count">1</span><i
                        class="competition-icon-arrow-bc bc-i-small-arrow-down"></i></div>
            </div>
            <div class="competition-wrapper-bc">
                <div class="competition-header-bc"><i class="competition-icon-league-bc bc-i-MobileLegends"></i><span
                        class="competition-title-bc ellipsis">Mobile Legends</span><span
                        class="competition-group-count">2</span><i
                        class="competition-icon-arrow-bc bc-i-small-arrow-down"></i></div>
            </div>
        </div>
    </div>
</div>-->

<div class="layout-content-holder-bc">
    <div>
        <div id="betby-na"></div>
    </div>

</div>