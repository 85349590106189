import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-upi-popup',
  templateUrl: './upi-popup.component.html',
  styleUrls: ['./upi-popup.component.scss']
})
export class UpiPopupComponent implements OnInit {


  @Output() closeClick: EventEmitter<any> = new EventEmitter();
  @Input() title: string;
  constructor() { }

  ngOnInit(): void {
    this.closeClicked
  }

  closeClicked() {
    this.closeClick.emit(true);
  }
}
