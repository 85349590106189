import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-more-bonus-card',
  templateUrl: './no-more-bonus-card.component.html',
  styleUrls: ['./no-more-bonus-card.component.scss']
})
export class NoMoreBonusCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
