<div class="menu-page max-width-550" [ngClass]="{'menu-page-close':closeMenuPage}">
    <div class="header-menu">
        <div class="left">
            <div class="user">
                <img src="../../../../assets/images/login-person1.svg" alt="user">
            </div>
            <div class="welcome">
                <span class="hii-msg">{{'Hi, There'|translate}}</span>
                <span class="wlcm-msg" *ngIf="!user">{{'Welcome to 11Exch'|translate}}</span>
                <span class="wlcm-msg" *ngIf="user">{{userName?.replace('b06.', '')}}</span>
            </div>
        </div>
        <div class="right">
            <button class="reg-btn" (click)="navigateToregister()" *ngIf="!deposit && !user">
                <span>{{ 'Register Now' | translate}}</span>
            </button>
            <button class="dep-btn" *ngIf="deposit" (click)="navigateToDeposit()">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <path
                        d="M6.83331 8.00004C6.83507 8.79515 7.15171 9.55719 7.71394 10.1194C8.27617 10.6816 9.0382 10.9983 9.83331 11H14.3333V13C14.3316 13.3089 14.2081 13.6047 13.9897 13.8231C13.7713 14.0415 13.4755 14.165 13.1666 14.1667H2.49998C2.1911 14.165 1.89537 14.0415 1.67696 13.8231C1.45854 13.6047 1.33506 13.3089 1.33331 13V3.00004C1.33506 2.69116 1.45854 2.39543 1.67696 2.17702C1.89537 1.9586 2.1911 1.83512 2.49998 1.83337H13.1666C13.4755 1.83512 13.7713 1.9586 13.9897 2.17702C14.2081 2.39543 14.3316 2.69116 14.3333 3.00004V5.00004H9.83331C9.0382 5.0018 8.27617 5.31844 7.71394 5.88067C7.15171 6.44289 6.83507 7.20493 6.83331 8.00004Z"
                        fill="white" />
                    <path
                        d="M15.1666 6H9.83331C9.30288 6 8.79417 6.21071 8.4191 6.58579C8.04403 6.96086 7.83331 7.46957 7.83331 8C7.83331 8.53043 8.04403 9.03914 8.4191 9.41421C8.79417 9.78929 9.30288 10 9.83331 10H15.1666C15.3435 10 15.513 9.92976 15.6381 9.80474C15.7631 9.67971 15.8333 9.51014 15.8333 9.33333V6.66667C15.8333 6.48986 15.7631 6.32029 15.6381 6.19526C15.513 6.07024 15.3435 6 15.1666 6ZM10.1666 8.66667C10.0348 8.66667 9.9059 8.62757 9.79627 8.55431C9.68663 8.48106 9.60119 8.37694 9.55073 8.25512C9.50027 8.13331 9.48707 7.99926 9.51279 7.86994C9.53851 7.74062 9.60201 7.62183 9.69524 7.5286C9.78848 7.43536 9.90727 7.37187 10.0366 7.34614C10.1659 7.32042 10.3 7.33362 10.4218 7.38408C10.5436 7.43454 10.6477 7.51999 10.721 7.62962C10.7942 7.73925 10.8333 7.86815 10.8333 8C10.8333 8.17681 10.7631 8.34638 10.6381 8.4714C10.513 8.59643 10.3435 8.66667 10.1666 8.66667Z"
                        fill="white" />
                </svg>
                <span>{{'Deposit'|translate}}</span>
            </button>
            <svg (click)="closeClicked()" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"
                fill="none">
                <path
                    d="M5.70627 17.2562L4.74377 16.2937L10.0375 11L4.74377 5.70624L5.70627 4.74374L11 10.0375L16.2938 4.74374L17.2563 5.70624L11.9625 11L17.2563 16.2937L16.2938 17.2562L11 11.9625L5.70627 17.2562Z"
                    fill="#B6BECD" />
            </svg>
        </div>
    </div>

    <div class="top-options">
        <div class="sports">
            <span class="title">{{'SPORTS'|translate}}</span>
            <ng-container *ngFor="let item of sportOptiosArray">
                <div class="option" (click)="naavigateToRelatedPage(item.navigate)">
                    <span class="rect" [style.background]="item.leftStripColor"></span>
                    <span class="logo">
                        <img src={{item.img}} alt={{item.name}}>
                    </span>
                    <span class="main-title">{{item.name|translate}}</span>
                </div>
            </ng-container>

        </div>
        <div class="sports">
            <span class="title">{{'CASINO'|translate}}</span>
            <ng-container *ngFor="let item of casinoOptiosArray">
                <div class="option" (click)="naavigateToRelatedPage(item.navigate)">
                    <span class="rect" [style.background]="item.leftStripColor"></span>
                    <span class="logo">
                        <img src={{item.img}} alt={{item.name}}>
                    </span>
                    <span class="main-title">{{item.name|translate}}</span>
                </div>
            </ng-container>

        </div>
    </div>
    <div class="other-options">
        <span class="title">{{"OTHER GAMES"|translate}}</span>
        <div class="options-other-games">
            <ng-container *ngFor="let item of otherOptionsArray">
                <div class="game-opt" (click)="naavigateToRelatedPage(item.navigate)">
                    <img src={{item.img}} alt={{item.name}}>
                    <span class="title-game-opt">
                        {{item.name|translate}}
                    </span>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="download-apk-section">
        <div class="download-apk-card">
            <img src="../../../../assets/images/menu-dice.png" class="middle-dice" alt="dice-bg">
            <img src="../../../../assets/images/menu-dice-bg.png" class="middle-dice-left" alt="dice-bg">
            <img src="../../../../assets/images/menu-dice-bg2.png" class="middle-dice-right" alt="dice-bg">
            <div class="left">
                <img src="../../../../assets/images/menu-apk-option.svg" alt="Android App">
                <div class="left-text">
                    <span class="upper">
                        {{'Android Application'|translate}}
                    </span>
                    <span class="lower">
                        {{'Instant download on your device'|translate}}
                    </span>
                </div>
            </div>
            <div class="right">
                <button class="install-btn" (click)="goToAPK()"><span>{{'Install Now'|translate}}</span></button>
            </div>
        </div>
    </div>
    <div class="all-sports">
        <span class="title-all-sports">
            {{'ALL SPORTS'|translate}}
        </span>
        <div class="options">
            <ng-container *ngFor="let item of allSportOptArray">
                <div class="all-sport-opt" (click)="navigateToSportBook(item.navigate)">
                    <img src={{item.img}} *ngIf="(item.name!=='FIFA' && item.name!=='FIFA24')" alt={{item.name}}>
                    <div class="Fifa-image" *ngIf="item.name==='FIFA'">
                        <img class="footBall" src="../../../../assets/images/menu-sports-option.svg"
                            *ngIf="item.name==='FIFA'" alt={{item.name}}>
                        <img class="virtual-img" src="../../../../assets/images/menu-virtual-games-option.svg"
                            *ngIf="item.name==='FIFA'" alt={{item.name}}>
                    </div>
                    <div class="Fifa-image" *ngIf="item.name==='FIFA24'">
                        <img class="footBall" src="../../../../assets/images/menu-sports-option.svg"
                            *ngIf="item.name==='FIFA24'" alt={{item.name}}>
                        <img class="virtual-img" src="../../../../assets/images/menu-virtual-games-option.svg"
                            *ngIf="item.name==='FIFA24'" alt={{item.name}}>
                    </div>
                    <span class="title-all-sport">{{item.name|translate}}</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>
