<div class="main-no-data">
    <div class="no-data">
        <div class="no-data-img">
            <img src="../../../../assets/images/no-data-available.png" alt="" />
        </div>
        <div class="no-data-text">
            <span>{{'No data available.'|translate}}</span>
        </div>
        <button class="button-go-back" (click)="goBack()" ><span>Go Back</span></button>
    </div>
</div>