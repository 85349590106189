import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LiveMatchHelperService } from '../live-match-helper.service';
import { OddsGroup, OddsGroupResult } from './oddsGroup.interface';
import { Observable, catchError, map } from 'rxjs';
import * as moment from 'moment';
import { Races, RacesResult } from 'src/app/models/races.interface';
import { MyprofileService } from 'src/app/services/myprofile.service';

@Component({
  selector: 'app-match-summery',
  templateUrl: './match-summery.component.html',
  styleUrls: ['./match-summery.component.scss']
})
export class MatchSummeryComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  };

  // matchSumarryData: OddsGroupResult[];
  matchTypeId = 0;
  compitionId: any = 0;
  competitionName: string; //for showing competition name in match summery
  matchData$: Observable<OddsGroupResult[][]>
  raceLeagues: RacesResult[];        // for horse and greyhound racing only
  upcomingRaces$: Observable<RacesResult[]>;
  isLoader: boolean = true;



  splitRegex = / v | vs. /;

  constructor(private _route: ActivatedRoute,
    public authService: AuthService,
    // private router: Router,
    private _myProfile: MyprofileService
  ) {
    this._route.params.subscribe(params => {
      this.matchTypeId = params['matchTypeId'];
      this.compitionId = params['compitionId'];
    });
    this._route.queryParams.subscribe(params => {
      this.competitionName = params['competitionName']; // Replace 'paramName' with your parameter name
      // console.log(this.competitionName);
    });
  }

  ngOnInit(): void {
    this._myProfile.isHeaderFixed = true;
    this.authService.sportsId = this.matchTypeId;
    this.getMatchSummery();
  }

  splitVS(str: string) {
    const splitValue = str.split(this.splitRegex);
    return splitValue.length > 1;
  }

  getMatchSummery() {
    if (this.matchTypeId == 1 || this.matchTypeId == 2 || this.matchTypeId == 4) {
      this.isLoader = true;
      this.matchData$ = this.authService.getCricktMatchData(this.matchTypeId, this.compitionId).pipe(
        map((res: OddsGroup) => res.result),
        map((res: OddsGroupResult[]) => {
          if (!this.competitionName) {
            this.competitionName = res[0]?.competition?.name;
          }
          this.isLoader = false;
          return res;
        }),
        map((events: OddsGroupResult[]) => this.groupEventsByDate(events)),
      );
      this.isLoader = false;
    } else {
      this.isLoader = true;
      this.upcomingRaces$ =
        this.authService.getHorseandGreyRacing(this.matchTypeId)
          .pipe(  // get all upcoming matches data with eventTypeId
            map((res: Races) => res.result),  // get all popularEvents
            catchError(error => {
              console.error('Error fetching data: ', error);
              this.isLoader = false;
              return ([]);
            }));
      this.upcomingRaces$?.subscribe(res => {
        this.raceLeagues = this.getRaceLeaguesWithCountryCode(res, this.compitionId);
        if (!this.competitionName) {
          this.competitionName = this.raceLeagues[0]?.countryCode;
        }
        this.isLoader = false;
      },
        error => {
          console.error('Error fetching data: ', error);
          this.isLoader = false;
        }
      );
    }
  }

  getRaceLeaguesWithCountryCode<T extends RacesResult>(leagues: T[], countryCode: string): RacesResult[] {
    //return array of leagues with country code
    return leagues.filter(league => league?.countryCode === countryCode);
  }

  /**
 * ### Groups the events by same date
 * @param events: EventResult[]
 * @returns EventResult[]
 */
  groupEventsByDate(events: OddsGroupResult[]): OddsGroupResult[][] {
    const groupedEvents = {};

    events.forEach(event => {
      const openDate = new Date(event.event.openDate);
      const dateKey = openDate.toISOString().split('T')[0]; // Extract the date portion

      if (!groupedEvents[dateKey]) {
        groupedEvents[dateKey] = [];
      }
      groupedEvents[dateKey].push(event);
    });
    // Convert the grouped events object into an array of arrays and sort by "openDate"
  const groupedEventsArray = Object.values(groupedEvents).map((eventsArray:OddsGroupResult[]) => {
    return eventsArray.sort((a, b) => {
      const dateA = new Date(a.event.openDate);
      const dateB = new Date(b.event.openDate);
      return dateA.getTime() - dateB.getTime();
    });
  });

  console.log(groupedEventsArray);

  return groupedEventsArray;
  }



  getMatchDate(date: any) {
    // check if date is in past
    // this.isMatchInPast = moment().isAfter(date);
    return moment(date).fromNow();
  }

  goBack(id) {
    localStorage.setItem('sportsId', id);
    this.authService.sportsId = id;
    this.authService.updateSportsId(this.authService.sportsId);
    // this.router.navigate(['/shared/live/'+id]);
    this._myProfile.updateActiveMenuItem('/main');
    window.history.back();
  }

  setDetailspageData(item: any) {
    this.authService.updateDetailsPageData(item);
  }

  ngOnDestroy() {
    this._myProfile.isHeaderFixed = false;
  }

}
