<!-- spinning loader -->
<div class="overlay" *ngIf="isLoader">
    <div class="loader-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <ng-lottie [options]="lottieOptions" width="75px" height="75px"></ng-lottie>
    </div>
</div>
<!-- spinning loader -->

<app-accountheader></app-accountheader>

<div (click)="closeCalender()" class="back-header">
    <svg (click)="backToAccount()" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"
        fill="none">
        <path
            d="M13.8147 17.646L6.94336 10.7747L13.8147 3.90332L14.7767 4.88249L8.88451 10.7747L14.7767 16.6668L13.8147 17.646Z"
            fill="#D71082" />
    </svg>
    <span *ngIf="!showAccountHead" class="white-large-text">{{'Account Statement'|translate}}</span>
    <span *ngIf="showAccountHead" class="white-large-text">{{'Account Statement Detail'|translate}}</span>
</div>
<div class="profit-lose-section">
    <div (clickOutside)="closeCalender()" class="bettingDetail" *ngIf="showAccountState">
        <div class="date-box">
            <div class="rect"></div>
            <div class="rect-3"></div>
            <div class="date-card">
                <div class="date-rang">
                    <div (click)="handleActiveStartDate()" class="date-input"
                        [ngClass]="{'active':activeStartDate, 'border':startDate}">
                        <span *ngIf="!startDate" class="gray-large-text">{{'Start Date'|translate}}</span>
                        <span *ngIf="startDate" class="gray-large-text white">{{startDate}}</span>
                        <img src="../../../../../assets/images/calender-icon.svg" alt="calender">
                    </div>
                    <div (click)="handleActiveEndDate()" class="date-input"
                        [ngClass]="{'active':activeEndDate, 'border':endDate}">
                        <span *ngIf="!endDate" class="gray-large-text">{{'End Date'|translate}}</span>
                        <span *ngIf="endDate" class="gray-large-text white">{{endDate}}</span>
                        <img src="../../../../../assets/images/calender-icon.svg" alt="calender">
                    </div>
                </div>
                <div class="right-arrow" (click)="applyFilter()" [ngClass]="{'right-arrow-op': activeApplyBtn}">
                    <img src="../../../../../assets/images/right-arrow.svg" alt="right">
                </div>
            </div>
        </div>
        <app-calendar class="calender-section" *ngIf="showCalendar" (selectedStartDateChange)="handleStartDate($event)"
            (selectedEndDateChange)="handleEndDate($event)" [startDateInput]="startDate"
            [endDateInput]="endDate"></app-calendar>
        <span (click)="closeCalender()">
            <ng-container *ngIf="accountStatementData?.length>0 && !isLoader">
                <ng-container *ngFor="let group of accountStatementData">
                    <div class="pl-card">
                        <span class="gray-md-text">{{dateFormate1(group?.date)}}</span>
                        <ng-container *ngFor="let item of group?.data">
                            <div class="profit-loss-card" (click)="showAccountStatementDetailsData(item?.data)">
                                <div class="rect-1"></div>
                                <div class="rect-2"></div>
                                <div class="profit-loss-header header-border">
                                    <div class="logo">
                                        <img *ngIf="item?.data?.description?.eventTypeId == '4'"
                                            src="/assets/images/menu-cricket.png" alt="cricket">
                                        <img *ngIf="item?.data?.action === 'TRANSFER_DOWN' && item?.data?.pnl < 0"
                                            src="/assets/images/bank-logo.svg" alt="Withdraw">
                                        <img *ngIf="item?.data?.action === 'TRANSFER_DOWN' && item?.data?.pnl >= 0"
                                            src="/assets/images/imps.svg" alt="Deposit">
                                        <img *ngIf="item?.data?.description?.eventName == 'Blastoff'"
                                            src="/assets/images/menu-aviator.svg" alt="bank">
                                        <img *ngIf="item?.data?.description?.eventTypeId === '2378962'"
                                            src="/assets/images/menu-politics.svg" alt="">
                                        <!-- <img src="../../../../../../assets/images/menu-badminton.svg" alt="bank"> -->
                                        <!-- <img src="../../../../../../assets/images/menu-baseBall.png" alt="bank"> -->
                                        <!-- <img src="../../../../../../assets/images/menu-basketBall.svg" alt="bank"> -->
                                        <img *ngIf="item?.data?.description?.eventName == 'Spingame'"
                                            src="/assets/images/menu-roulette-option.png" alt="">
                                        <img *ngIf="item?.data?.description?.eventTypeId == '7'"
                                            src="/assets/images/menu-horse-racing.svg" alt="Horse">
                                        <img *ngIf="item?.data?.description?.eventTypeId == '1'"
                                            src="/assets/images/menu-tennis.svg" alt="Tennis">
                                        <img *ngIf="item?.data?.description?.eventTypeId == '4339'"
                                            src="/assets/images/menu-greyhord-racing.svg" alt="greyhord">
                                        <img *ngIf="item?.data?.description?.eventName == 'Live Teenpatti'"
                                            src="/assets/images/menu-teenPatti.png" alt="Teenpatti">
                                        <img *ngIf="item?.data?.description?.eventTypeId == '2'"
                                            src="/assets/images/menu-sports-option.svg" alt="Football">
                                    </div>
                                    <span class="white-lg-text"
                                        *ngIf="item?.data?.actionDisplayName !== 'Transfer' && item?.data?.actionDisplayName !== 'Bonus Lapsed' && item?.data?.actionDisplayName !== 'Bonus Added' && item?.data?.actionDisplayName !== 'Account Open'">{{item?.data?.description?.eventName}}
                                        - {{item?.data?.description?.marketName}}</span>
                                    <span class="white-lg-text"
                                        *ngIf="item?.data?.description == null && item?.data?.action !== 'TRANSFER_DOWN'">{{item?.data?.actionDisplayName}}</span>
                                    <span class="white-lg-text"
                                        *ngIf="item?.data?.action === 'TRANSFER_DOWN' && item?.data?.pnl >= 0">Dep.with</span>
                                    <span class="white-lg-text"
                                        *ngIf="item?.data?.action === 'TRANSFER_DOWN' && item?.data?.pnl < 0">Wit.with</span>
                                </div>
                                <div class="profit-loss-body">
                                    <div class="comission-net-box">
                                        <div class="comission-card">
                                            <span
                                                *ngIf="item?.data?.action === 'SETTLE' || item?.data?.action === 'VOID' || item?.data?.description?.eventName === 'Evolution'"
                                                class="gray-sm-text">{{'Betting P&L'|translate}}</span>
                                            <span *ngIf="item?.data?.action === 'TRANSFER_DOWN' && item?.data?.pnl >= 0"
                                                class="gray-sm-text">{{'Money Added'|translate}}</span>
                                            <span *ngIf="item?.data?.action === 'TRANSFER_DOWN' && item?.data?.pnl < 0"
                                                class="gray-sm-text">{{'Money Transfer'|translate}}</span>
                                        </div>
                                        <div class="win-card">
                                            <span class="white-tw-text"
                                                *ngIf="item?.data?.actionDisplayName === 'Betting P&L' || item?.data?.action === 'SETTLE' || item?.data?.action === 'VOID'">P&L
                                                :</span>
                                            <span class="lg-text red"
                                                *ngIf="item?.data?.actionDisplayName !== 'Betting P&L' && item?.data?.action !== 'SETTLE' && item?.data?.action !== 'VOID' && item?.data?.action !== 'OPEN' && item?.data?.actionDisplayName !== 'Bonus Added' && item?.data?.actionDisplayName !== 'Bonus Lapsed'"
                                                [ngClass]="{'green':item?.data?.pnl >= 0}">{{item?.data?.pnl}}</span>
                                            <small class="lg-text red" *ngIf="item?.data?.action !== 'TRANSFER_DOWN'"
                                                [ngClass]="{'green':item?.data?.pnl >= 0}">{{item?.data?.pnl}}</small>
                                            <img *ngIf="item?.data?.pnl >= 0 && item?.data?.action !== 'TRANSFER_DOWN' && item?.data?.action !== 'OPEN' && item?.data?.actionDisplayName !== 'Bonus Added' && item?.data?.actionDisplayName !== 'Bonus Lapsed'"
                                                src="/assets/images/green-up-arrow.svg" alt="green">
                                            <img *ngIf="item?.data?.pnl < 0 && item?.data?.action !== 'TRANSFER_DOWN' && item?.data?.action !== 'OPEN' && item?.data?.actionDisplayName !== 'Bonus Added' && item?.data?.actionDisplayName !== 'Bonus Lapsed'"
                                                src="/assets/images/red-down-arrow.svg" alt="red">
                                        </div>
                                    </div>
                                </div>
                                <div class="date-body">
                                    <div class="date">
                                        <span class="gray-md-text">{{getDateAndTime(item?.data?.date)}}</span>
                                        <span class="gray-md-text">{{'Balance'|translate}} : <small
                                                class="white-md-text">{{item?.data?.balance}}</small></span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </span>

        <ng-container *ngIf="accountStatementData?.length==0 && !isLoader">
            <span (click)="closeCalender()" class="white-md-text text-center mt-2 h3">No Data Available For Selected
                Date Range.</span>
        </ng-container>
    </div>

    <ng-container *ngIf="bettingDetail">
        <div class="betting-detail">
            <div class="pl-card">
                <div class="profit-loss-card">
                    <div class="rect-1"></div>
                    <div class="rect-2"></div>
                    <div class="profit-loss-header header-border">
                        <div class="logo">
                            <img src="../../../../../assets/images/menu-cricket.png" alt="cricket">
                        </div>
                        <span class="white-lg-text">{{accountStatementDetailsData[0]?.eventName}} -
                            {{accountStatementDetailsData[0]?.marketName}}</span>
                    </div>

                    <ng-container *ngFor="let data of accountStatementDetailsData; let i=index">
                        <div class="betting-second-card"
                            [ngClass]="{'header-border':accountStatementDetailsData?.length-1 !==i}">
                            <div class="first-card">
                                <span class="gray-tw-text">{{'Selection'|translate}}</span>
                                <span class="white-tw-text">{{data?.selectionName}}</span>
                            </div>
                            <div class="second-card">
                                <div class="profit-loss-input">
                                    <span class="gray-tw-text">P&L</span>
                                    <small class="lg-text red" [ngClass]="{'green':data?.profitLoss >= 0}">₹
                                        {{data?.profitLoss}}</small>
                                    <!-- <small class="red md-text">₹500</small> -->
                                </div>
                                <div class="won-input win"
                                    [ngClass]="{'win':data?.outcome == 'WON', 'lost':data?.outcome == 'LOST'}">
                                    <span class="gray-tw-text">{{'Status'|translate}}</span>
                                    <small *ngIf="data?.outcome == 'WON'"
                                        class="green sm-text">{{'WON'|translate}}</small>
                                    <!-- <small class="orange sm-text">Void</small> -->
                                    <small *ngIf="data?.outcome == 'LOST'"
                                        class="red sm-text">{{'LOST'|translate}}</small>
                                </div>
                            </div>
                            <div class="third-card">
                                <div class="first">
                                    <span class="gray-tw-text">{{'Bet ID'|translate}}</span>
                                    <span class="white-tw-text">{{data?.apolloBetId}}</span>
                                </div>
                                <div class="first">
                                    <span class="gray-tw-text">{{'Placed'|translate}} </span>
                                    <span class="white-tw-text">{{betPlacedDate(data?.betPlacedDate)}}</span>
                                </div>
                                <div class="third">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th class="first">{{'Type'|translate}}</th>
                                                <th class="second">{{'Odds'|translate}}</th>
                                                <th class="third">{{'Stake'|translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td *ngIf="data?.side == 0" class="fourth">Back</td>
                                                <td *ngIf="data?.side == 1" class="fourth-1">Lay</td>
                                                <td class="fifth">{{data?.marketName == "BOOKMAKER" ?
                                                    getBookmakerAvgOdds(data?.averagePrice,data?.sizeMatched):
                                                    data?.averagePrice}}</td>
                                                <td class="sixth">₹ {{data?.sizeMatched}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <!-- <div class="profit-loss-card">
                <div class="rect-1"></div>
                <div class="rect-2"></div>
                <div class="account-detail">
                    <div class="account-header">
                        <span class="gray-tw-text">Request IDs</span>
                        <span class="white-tw-text">123456789123236589</span>
                    </div>
                </div>
                <div class="date-time-card">
                    <div class="date-time-body">
                        <div class="first-box">
                            <div class="type">
                                <img src="../../../../../../assets/images/wallet-icon.svg" alt="wallet">
                            </div>
                            <div class="type-text">
                                <span class="gray-md-text">Type</span>
                                <span class="white-md-text">Deposit</span>
                            </div>
                        </div>
                        <div class="first-box">
                            <div class="type">
                                <img src="../../../../../../assets/images/calendar.svg" alt="calendar">
                            </div>
                            <div class="type-text">
                                <span class="gray-md-text">Date</span>
                                <span class="white-md-text">Aug 24, 2022</span>
                            </div>
                        </div>
                        <div class="first-box">
                            <div class="type">
                                <div class="clock">
                                    <img src="../../../../../../assets/images/clock.svg" alt="wallet">
                                </div>
                            </div>
                            <div class="type-text">
                                <span class="gray-md-text">Time</span>
                                <span class="white-md-text">11:19 PM</span>
                            </div>
                        </div>

                    </div>
                    <div class="amount-box">
                        <div class="amount-card">
                            <span class="gray-tw-text">Amount</span>
                            <span class="md-text green">₹ 300</span>
                        </div>
                    </div>
                </div>
                <div class="betting-second-card-1">
                    <div class="first-card">
                        <span class="large-text">Back subtotal</span>
                        <span class="large-text">UTR Number</span>
                        <span class="white-large-text">₹ 100</span>
                    </div>
                    <div class="first-card">
                        <span class="large-text">Lay subtotal</span>
                        <span class="large-text">Payment Type</span>
                        <span class="white-large-text">₹ 0</span>
                    </div>
                    <div class="first-card">
                        <span class="large-text">Market subtotal</span>
                        <span class="large-text">Account no/UPI ID</span>
                        <span class="white-large-text">₹ 100</span>
                    </div>
                    <div class="first-card">
                        <span class="large-text">Commission</span>
                        <span class="large-text">Account Name</span>
                        <span class="white-large-text">₹ 2.38</span>
                    </div>
                </div>
            </div> -->

                <div class="profit-loss-card">
                    <div class="rect-1"></div>
                    <div class="rect-2"></div>
                    <div class="betting-second-card-1">
                        <div class="first-card">
                            <span class="large-text-color">{{'Back subtotal'|translate}}</span>
                            <span class="white large-text">₹
                                {{getBackSubTotal()}}</span>
                        </div>
                        <div class="first-card">
                            <span class="large-text-color">{{'Lay subtotal'|translate}}</span>
                            <span class="white large-text">₹
                                {{getLaySubTotal()}}</span>
                        </div>
                        <div class="first-card">
                            <span class="large-text-color">{{'Market subtotal'|translate}}</span>
                            <span class="white large-text">₹
                                {{getMarketSubTotal()}}</span>
                        </div>
                        <div class="first-card">
                            <span class="large-text-color">{{'Commission'|translate}}</span>

                            <span class="white large-text">₹
                                {{0}}</span>
                        </div>
                        <div class="hr-line"></div>
                        <div class="first-card">
                            <span class="large-text-color">{{'Net Market Total'|translate}}</span>
                            <span class="red lg-text" [ngClass]="{'green':getMarketSubTotal()>=0}">₹
                                {{getNetMarketTotal()}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>