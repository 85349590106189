import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifyServiceService } from 'src/app/notify/notify-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { MyprofileService } from 'src/app/services/myprofile.service';
import { environment } from 'src/environments/environment';
import { OneSignal } from 'onesignal-ngx';
import jwt_decode from "jwt-decode";
import { BonusService } from '../bonuses-cards/bonus.service';


declare var google: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  imgLoaded: boolean = false;
  passImageLoaded: boolean = false;
  userImageLoaded: boolean = false;
  showSigninSectionWithPhoneNumber: boolean = true;
  showSigninSectionWithUserId: boolean = false;
  showPassword: boolean = false;
  mobileNoInput: string = '';
  validMobileNo: string = '';
  rightClick: boolean = false;
  focusedDiv: boolean = false;
  focusesPassword: boolean = false;
  shpowPasswordforUserId: boolean = false;
  focusedPasswordforUserId: boolean = false;
  focusedDivForUserId: boolean = false;
  errorMessage: string = '';
  recaptcha: any;
  errorMsg: any;
  showsign: boolean = false;
  token: any;
  rSiteKey: string;
  @ViewChild('captchaRef') captchaRef: any;

  isMobileNoValid = false;
  showForgot = false;
  showSmsPopup = true;
  showWhtasAppPopUp = false;
  mobileNoForOtpVerification: string = '';
  OtpInputSection: boolean = false;
  otpInput = [];//otp input array
  otpTimerSeconds = 60; //otp timer in Seconds
  otpTimerInterval: any; //otp timer interval
  PasswordSectionforSms: boolean = false;
  showPasswordForForgotBySms: boolean = false;
  rightClickforForgotPasswordBySms: boolean = false;
  showConfirmPasswordForForgotBySms: boolean = false;
  version: string = localStorage?.getItem("versionNow");
  googleButtonWrapper: any;
  versionPopUpOpen: boolean = false;
  isLoader: boolean = false;
  showSuccessPopUp: boolean = false;
  selectedOptionforForgoTpassword: string = 'sms';
  loginWith: Boolean = true;
  atag: string = '';
  clickId: string = '';

  //password validation varibles
  passwordMinLength = false;
  passwordHaveUpperCase = false;
  passwordHaveLowerCase = false;
  passwordHaveNumber = false;
  passwordHaveSpecialChar = false;
  //password validation varibles
  //otpSectionPage
  otpSectionPage: boolean = false;
  conditionsforForPassword: boolean = false;//for condition of forgot password
  userId = new FormControl('');
  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  @ViewChild('taebSwitch') taebSwitch: ElementRef;

  otpToken = '';


  constructor(
    private authService: AuthService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private _notify: NotifyServiceService,
    private oneSignal: OneSignal,
  private myprofile: MyprofileService,
  private _bonus: BonusService,

  ) { }

  signInForm: FormGroup = new FormGroup({
    mobileNo: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]\d{9}$/)]),
    password: new FormControl(''),
  });

  signInFormByUserId: FormGroup = new FormGroup({
    userId: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  forgotPasswordFormBySms: FormGroup = new FormGroup({
    mobileNo: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]\d{9}$/)]),
    password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]),
    otp1: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{1}$'), Validators.maxLength(1)]),
    otp2: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{1}$'), Validators.maxLength(1)]),
    otp3: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{1}$'), Validators.maxLength(1)]),
    otp4: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]{1}$'), Validators.maxLength(1)])
  });

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.authService.googleConfigObservable$.subscribe((config: any | null) => {
      this.rSiteKey = config?.result?.recaptchaConfig?.recaptchaSiteKey
      this.executeRecaptchaAsync();
    })
    this.signInFormByUserId.get('userId').valueChanges.subscribe((value) => {
      if (value) {
        this.signInFormByUserId.get('userId').setValue(value.trim(), { emitEvent: false });
      }
    });
    this.signInForm.get('password').valueChanges.subscribe((value) => {
      if (value) {
        this.signInForm.get('password').setValue(value.trim(), { emitEvent: false });
      }
    });
    // this.executeRecaptchaAsync();
    // console.log(this.recaptcha, "recaptcha");
  }

  handleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  onInput(event: Event) {
    let mobileNo = (event.target as HTMLInputElement).value.replace(/[^0-9]/g, '');
    (event.target as HTMLInputElement).value = mobileNo;
    if (mobileNo.length >= 10) {
      this.rightClick = true;
    } else {
      this.rightClick = false;
    }
  }

  checkForSpace(event: KeyboardEvent) {
    //check for ennter key
    if (event.key == " ") {
      event.preventDefault();
    }
  }

  checkForSpace1(event: Event) {
    //check for space
    if ((event.target as HTMLInputElement).value.includes(" ")) {
      (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.replace(/ /g, '');
    }
  }
  handleSigninSectionWithPhoneNumber() {
    this.showSigninSectionWithPhoneNumber = true;
    this.showSigninSectionWithUserId = false;
    //reset userid form
    this.signInFormByUserId.reset();
  }

  handleSigninSectionWithuserId() {
    this.showSigninSectionWithUserId = true;
    this.showSigninSectionWithPhoneNumber = false;
    //reset mobile no form
    this.signInForm.reset();
  }

  onFocus() {
    this.focusedDiv = true;
  }

  onFocurRemove() {
    this.focusedDiv = false;
  }

  onFocusPassword() {
    this.focusesPassword = true;
  }
  onFocurRemovePassword() {
    this.focusesPassword = false;
  }

  onFocusPasswordForUserId() {
    this.focusedPasswordforUserId = true;
  }

  focusForUserId() {
    this.focusedDivForUserId = true;
  }

  onFocurRemoveForUserId() {
    this.focusedDivForUserId = false;
  }

  onFocurRemovePasswordForUserId() {
    this.focusedPasswordforUserId = false;
  }

  handleShowPasswordForUserId() {
    this.shpowPasswordforUserId = !this.shpowPasswordforUserId;
  }

  signIn() {
    const mobileNoControl = this.signInForm.get('mobileNo');
    const passwordControl = this.signInForm.get('password');
    if (!mobileNoControl?.value) {
      this._notify.error("Please Enter Mobile No");
    } else if (!mobileNoControl.value.match(/^[0-9]\d{9}$/)) {
      this._notify.error("Please Enter Valid Mobile No");
    } else if (!passwordControl?.value) {
      this._notify.error("Please Enter Password");
    } else if (this.signInForm.valid && !this.signInForm.pristine) {
      // console.log(this.signInForm.value);
      this.handleSignIn();
    } else if (this.signInForm.pristine) {
      this._notify.error("Please Fill the Required Fields");
    } else {
      this._notify.error("Wrong Password");
    }
  }

  signInByUserId() {
    const userIdContorol = this.signInFormByUserId.get('userId');
    const passwordControl = this.signInFormByUserId.get('password');
    if (!userIdContorol?.value) {
      this._notify.error("Please Enter User Id");
    } else if (!passwordControl?.value) {
      this._notify.error("Please Enter Password");
    } else if (this.signInFormByUserId.valid) {
      // console.log(this.signInFormByUserId.value);
      this.handleSignIn();
    } else {
      this._notify.error("Wrong Password");
    }
  }



  async handleSignIn() {
    this.loginWith = false;
    this.isLoader = true;
    let username;
    let password;
    if (this.signInForm.value.mobileNo) {
      username = `91_` + this.signInForm.value.mobileNo;
      password = this.signInForm.value.password;
    }
    if (this.signInFormByUserId.value.userId) {
      username = this.signInFormByUserId.value.userId;
      password = this.signInFormByUserId.value.password;
    }
    if (localStorage.getItem('atag')) {
      this.atag = localStorage.getItem('atag');
    }
    if (localStorage.getItem('clickId')) {
      this.clickId = localStorage.getItem('clickId');
    }
    await this.executeRecaptchaAsync();
    let data = {
      username,
      password,
      recaptchaResponse: this.recaptcha,
      atag: this.atag,
      clickId: this.clickId
    }
    this.authService.Login(data).subscribe(
      (res: any) => {
        this.myprofile.showFooter = true;
        this.loginWith = true;
        this.token = res.headers.get('authorization');
        this.authService.currentUserSubject.next(res.body.result);
        localStorage.setItem('userData', JSON.stringify(res.body.result));
        localStorage.setItem('ROYALTOKEN_KEY', JSON.stringify(this.token));
        if (res.body.status.statusCode == '0') {
          const decodedToken: any = jwt_decode(this.token)
          console.log("🚀 ~ file: login.component.ts:570 ~ LoginComponent ~ glogin ~ decodedToken:", decodedToken)
          this.createOneSignalUser(this.signInForm?.value?.mobileNo ? decodedToken : '')

          try{
            this?.oneSignal?.User?.addSms(res.body.result.loginName?.replace('b06.', '+')?.replace('_', ''))
            console.log('SMS OneSignal', this?.oneSignal?.User)
          } catch(e) {
            console.log('SMS OneSignal error: ', e)
          }
          this.router.navigate(['/shared/promotions']);
          window.srtmCommands.push({
            event: "track.user.login",
            payload: {
              action: "complete",
              userId: res?.body?.result?.memberCode
            }
          });
          this.token = res.headers.get('authorization');
          this.showsign = false;
          // this.errorMsg = 'Login successfully';
          // this._notify.success(this.errorMsg);
          document.body.classList.remove('openpopup');
          localStorage.setItem('ROYALTOKEN_KEY', JSON.stringify(this.token));
          localStorage.setItem('userData', JSON.stringify(res.body.result));
          localStorage.setItem('Member_code', res.body.result.memberCode);
          localStorage.setItem('User_name', res.body.result.loginName);
          localStorage.setItem('WaLink', res?.body?.result?.wa || 'https://walink.live/11exch');
          localStorage.setItem(
            'betstack',
            res?.body?.result?.settings.presetStakeSettings?.settings
          );
          try {
            var loginNameInter = res.body.result.loginName;
            if (loginNameInter.includes('.')) {
              loginNameInter = loginNameInter.split('.')[1];
            }
            if (loginNameInter.includes('_')) {
              loginNameInter = loginNameInter.split('_')[1];
            }
            (window as any).intercomSettings = {
              api_base: 'https://api-iam.intercom.io',
              branch_id: res?.body?.result?.branchId || 100111,
              branch_name: res?.body?.result?.branchName || "Not updated yet",
              app_id: 'n3wkvmsn',
              name: loginNameInter,
              user_id: res.body.result.memberCode,
            };
            (window as any).Intercom('update');
          } catch (e) {
          this.isLoader = false;
            console.log(e);
          }
          this.cdr.detectChanges();
          const myElement = document.querySelector('.signInPageShowHide') as HTMLElement;
          myElement.style.display = 'none';
          this.router.navigate(['/main']);
          this.isLoader = false;
          this._bonus.getAllBonuses('alive');
        }
      },
      (error) => {
        this.loginWith = true;
        setTimeout(() => {
          this.captchaRef.execute();
        }, 100);
        console.log(error);
        this.errorMsg = JSON.stringify(error.error.error);
        this.authService.showError(this.errorMsg);
        this.isLoader = false;
      }
    );
  }




  handleForgot() {
    this.showForgot = !this.showForgot;
  }

  handleSmsPopup() {
    this.selectedOptionforForgoTpassword = "sms"
    this.showSmsPopup = true;
    this.showWhtasAppPopUp = false;
    this.PasswordSectionforSms = false;
    this.OtpInputSection = false;
  }

  handleWhatsAppPopUp() {
    this.selectedOptionforForgoTpassword = "whats-app"
    this.showSmsPopup = false;
    this.showWhtasAppPopUp = true;
    this.PasswordSectionforSms = false;
    this.OtpInputSection = false;
    this.conditionsforForPassword = false;
    this.forgotPasswordFormBySms.reset();
    this.rightClickforForgotPasswordBySms = false;
  }

  onInputForForgotPassword(event: Event) {
    let mobileNo = (event.target as HTMLInputElement).value.replace(/[^0-9]/g, '');
    (event.target as HTMLInputElement).value = mobileNo;
    this.mobileNoForOtpVerification = mobileNo;
    if (mobileNo?.length == 10) {
      this.rightClickforForgotPasswordBySms = true;
    } else {
      this.rightClickforForgotPasswordBySms = false;
    }
  }

  onInputForOtp(event: Event) {
    let mobileNo = (event.target as HTMLInputElement).value.replace(/[^0-9]/g, '');
    (event.target as HTMLInputElement).value = mobileNo;
    this.mobileNoForOtpVerification = mobileNo;
  }

  async getOtpBtn() {
    await this.executeRecaptchaAsync();
    if (this.forgotPasswordFormBySms.get('mobileNo').value?.length === 10) {
      const data = {
        countryCode: '+91',
        mobileNumber: this.forgotPasswordFormBySms.get('mobileNo').value,
        recaptchaResponse: this.recaptcha,
      };
      this.isLoader = true;
      this.authService.forgotPass(data).subscribe(
        (res: any) => {
          this.isLoader = false;
          if (res.status === 200) {
            this._notify.success(res?.body?.result);
            this.OtpInputSection = true;
            this.otpSectionPage = true;
            this.startTimer();
            this.conditionsforForPassword = false;
          } else {
            this._notify.error('Error in Sending Verification Code');
          }
        },
        (error: any) => {
          this.isLoader = false;
          this._notify.error(error?.error?.error);
        }
      )
    } else {
      this._notify.error("Please Enter Valid Mobile No");
      this.OtpInputSection = false;
      this.isLoader = false;
    }
  }
  //otp input handler
  selectAll(event) {
    // select all text on focus
    event.target.select();
  }

  SubmitOtpBtn() {
    this.isLoader = true;
    let data = {
      countryCode: '91',
      mobileNumber: this.forgotPasswordFormBySms.get('mobileNo').value,
      OTP: this.otpInput.join(''),
    }
    this.authService.verifyForgotPasswordOtp(data).subscribe(
      (res: any) => {
        this.isLoader = false;
        if (res.status === 200) {
          this.otpToken = res?.body?.result;
          this._notify.success(res?.body?.status?.statusDesc);
          this.PasswordSectionforSms = true;
          this.OtpInputSection = false;
          this.conditionsforForPassword = true;
        } else {
          this._notify.error('Error in Verifying OTP');
        }
      },
      (error: any) => {
        this.isLoader = false;
        this._notify.error(error?.error?.error);
        this.forgotPasswordFormBySms.get('otp1').setErrors({ 'incorrect': true });
        this.forgotPasswordFormBySms.get('otp2').setErrors({ 'incorrect': true });
        this.forgotPasswordFormBySms.get('otp3').setErrors({ 'incorrect': true });
        this.forgotPasswordFormBySms.get('otp4').setErrors({ 'incorrect': true });
      }
    )
  }

  //otp input handler
  otpInputHandler(event: any) {
    // console.log(event);
    //if event target digit length is equal to 1 then focus on next input field
    if (event.key == "Backspace" && event.target.value?.length === 0 && event.target.previousElementSibling) {
      event.target.value = "";
      if (event.target.id == "first") {
        delete this.otpInput[0];
        event.target.nextElementSibling.focus();
      } else if (event.target.id == "second") {
        delete this.otpInput[1];
        event.target.nextElementSibling.focus();
      } else if (event.target.id == "third") {
        delete this.otpInput[2];
        event.target.nextElementSibling.focus();
      } else if (event.target.id == "fourth") {
        delete this.otpInput[3];
      }
      event.target.previousElementSibling.focus();
    } else if (event.target.value?.length === 1 && parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 9) {
      if (event.target.id == "first" && event.target.nextElementSibling) {
        this.otpInput[0] = event.target.value;
        event.target.nextElementSibling.focus();
      } else if (event.target.id == "second" && event.target.nextElementSibling) {
        this.otpInput[1] = event.target.value;
        event.target.nextElementSibling.focus();
      } else if (event.target.id == "third" && event.target.nextElementSibling) {
        this.otpInput[2] = event.target.value;
        event.target.nextElementSibling.focus();
      } else if (event.target.id == "fourth") {
        this.otpInput[3] = event.target.value;
      }
      // console.log(this.otpInput);
    }

    this.otpInput = this.otpInput.filter(Boolean);
  }

  //start otp timer
  startTimer() {
    this.otpTimerSeconds = 60;
    this.otpTimerInterval = setInterval(() => {
      if (this.otpTimerSeconds > 0) {
        this.otpTimerSeconds--;
      } else {
        clearInterval(this.otpTimerInterval);
      }
    }, 1000);
  }

  //resend otp
  resendOtp() {
    let data = {
      countryCode: '91',
      mobileNumber: this.forgotPasswordFormBySms.get('mobileNo').value,
    }
    this.authService.RsendOTP(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status === 200) {
          this._notify.success(res?.body?.result);
          this.otpInput = [];
          this.startTimer();
        } else {
          this._notify.error('Error in Resending OTP');
        }
      },
      (error: any) => {
        this._notify.error(error?.error?.error);
      }
    )
  }

  showGoogleBtn = true;

  resolved(captchaResponse: string) {
    // console.log('Inside resolved of header component');
    this.recaptcha = captchaResponse;
    //set to localstorage
    // localStorage.setItem('recaptcha', this.recaptcha);
    this.cdr.detectChanges();
    this.cdr.markForCheck();
    // console.log(this.recaptcha, "recaptcha");
    // console.log(`Resolved captcha in login: ${captchaResponse}`);
  }

  async executeRecaptchaAsync() {
    // console.log('captacha function', this.captchaRef);
    this.captchaRef?.execute();
    return new Promise((resolve) => {
      this.resolved = (captchaResponse: string) => {
        this.recaptcha = captchaResponse;
        resolve(captchaResponse);
      };
    });
  }

  getToken() {
    this.showGoogleBtn = false;
    const ROYALTOKEN_KEY = localStorage.getItem('ROYALTOKEN_KEY');
    return ROYALTOKEN_KEY;
  }

  handleShowPasswordForSms() {
    this.showPasswordForForgotBySms = !this.showPasswordForForgotBySms;
  }

  handleShowConfirmPasswordForSms() {
    this.showConfirmPasswordForForgotBySms = !this.showConfirmPasswordForForgotBySms;
    this.forgotPasswordFormBySms.invalid;
  }

  createOneSignalUser (data, sms = true) {
    if (data) {

      try {
        const options = {
        method: 'POST',
        headers: {accept: 'application/json', 'content-type': 'application/json'},
        body: JSON.stringify({
          properties: {
          },
          subscriptions: [
            {
              type: sms ? 'SMS' : 'Email',
              token: sms ? data?.loginName?.replace('b06.', '+')?.replace('_', '') : data?.loginName?.replace('b06.', ''),
              enabled: true,
            }
          ],
          identity: {external_id: data.memberCode}
        })
      };

      fetch('https://onesignal.com/api/v1/apps/9663e8d6-d33c-4c99-ab68-be0e94b29c41/users', options)
        .then(response => response.json())
        .then(response => console.log(response))
        .catch(err => console.error(err));
    } catch (e) {
      console.log('ONESIGNAL user creating error: ', e)
    }
    }
  }

  glogin() {
    if (!this.getToken()) {
      google.accounts.id.initialize({
        client_id: environment.gLoginKey,
        callback: (response) => {
          const token = response.credential;
          // console.log(token);
          if (localStorage.getItem('atag')) {
            this.atag = localStorage.getItem('atag');
          }
          if (localStorage.getItem('clickId')) {
            this.clickId = localStorage.getItem('clickId');
          }
          const data = {
            token: token,
            atag: this.atag,
            clickId: this.clickId
          }
          this.authService.gLogin(data).subscribe(
            (res: any) => {
              //debugger
              this.token = res.headers.get('authorization');
              const decodedToken: any = jwt_decode(this.token)
              this.createOneSignalUser(decodedToken, false)
              if (res.body.status.statusCode == '0') {

                window.srtmCommands.push({
                  event: "track.user.login",
                  payload: {
                    action: "complete",
                    userId: res.body.result.memberCode
                  }
                });
                this.authService.currentUserSubject.next(res.body.result);
                this.token = res.headers.get('authorization');
                // this.showsign = false;
                // this.errorMsg = 'login successfully';
                document.body.classList.remove('openpopup');
                //this.balancamount();
                //console.log('Inside signIn of header component value of this.token:' + this.token);
                localStorage.setItem(
                  'ROYALTOKEN_KEY',
                  JSON.stringify(this.token)
                );
                localStorage.setItem('userData', JSON.stringify(res.body.result));
                localStorage.setItem('loginName', res.body.result.loginName);
                localStorage.setItem('Member_code', res.body.result.memberCode);
                localStorage.setItem(
                  'betstack',
                  res?.body?.result?.settings.presetStakeSettings?.settings
                );
                //this.setToken('betstack', this.optionSplit(res?.body?.result?.settings.presetStakeSettings?.settings));
                //console.log('Inside signIn of header component localStorage.setItem(Member_code):' + localStorage.getItem('Member_code'));
                // this.cdr.detectChanges();
                // this.authService.showSuccess(this.errorMsg);
                this.router.navigate(['/main']);
                //subscribe to getAllBonuses
                this._bonus.getAllBonuses('alive');
                window.location.reload();
              }
            },
            (error) => {
              //console.log('Inside signIn of error condition header component value of res:');
              setTimeout(() => {
                this.captchaRef.execute();
              }, 100);
              // this.errorMsg = JSON.stringify(error.error.error);
              // this.authService.showError(this.errorMsg);
              //this.authService.showSuccess();
            }
          );
        },
        auto_select: false,
        ux_mode: 'popup'
      });
      this.googleButtonWrapper = createGoogleWrapper();
      this.googleButtonWrapper.click();
      function createGoogleWrapper() {
        const btn = document.createElement('div');
        btn.style.display = 'none';
        btn.classList.add('custom-google-button');
        console.log(btn);
        document.body.appendChild(btn);
        google.accounts.id.renderButton(btn, {
          theme: 'outline',
          size: 'large',
          text: 'continue_with',
          width: '100%',
        });
        const googleLoginWrapper = btn.querySelector("div[role=button]") as HTMLElement;
        return {
          click: () => {
            googleLoginWrapper.click();
          }
        }
      }
    }
  }

  walogin(byClick = true) {
    const url =
      `https://11exch.authlink.me/?redirectUri=` + window.location.origin;
    if (byClick) {
      window.location.href = url;
    }
    // window.open(
    //   url,
    //   'Verify with Whats app',
    //   'width=420,height=730,left=150,top=200,toolbar=1,status=1,'
    // );
    // const interval = setInterval(() => {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   let otp = urlParams.get('waId');
    //   let recaptchaResponse = this.recaptcha;
    //   if (!otp) otp = (<HTMLInputElement>document?.getElementById('otp'))?.value;
    //   if (otp?.length > 4) {
    //     clearInterval(interval);
    //     // alert(otp);
    //     this.authService.waLogin({ otp, recaptchaResponse }).subscribe(
    //       (res: any) => {
    //         console.log(res);
    //         console.log(res);
    //         //debugger
    //         this.token = res.headers.get('authorization');
    //         //console.log('Inside signIn of header component value of res:' + JSON.stringify(res));
    //         //  console.log('Inside signIn of header component value of res:' + JSON.stringify(res?.body?.result?.settings.presetStakeSettings?.settings));
    //         //        localStorage.setItem(8'ROYALTOKEN_KEY', JSON.stringify(this.token));
    //         if (res.body.status.statusCode == '0') {
    //           this.token = res.headers.get('authorization');
    //           document.body.classList.remove('openpopup');
    //           //this.balancamount();
    //           //console.log('Inside signIn of header component value of this.token:' + this.token);
    //           localStorage.setItem(
    //             'ROYALTOKEN_KEY',
    //             JSON.stringify(this.token)
    //           );
    //           localStorage.setItem('Member_code', res.body.result.memberCode);
    //           localStorage.setItem(
    //             'betstack',
    //             res?.body?.result?.settings.presetStakeSettings?.settings
    //           );
    //           //this.setToken('betstack', this.optionSplit(res?.body?.result?.settings.presetStakeSettings?.settings));
    //           //console.log('Inside signIn of header component localStorage.setItem(Member_code):' + localStorage.getItem('Member_code'));
    //           this.router.navigate(['/main']);
    //           window.location.reload();
    //         } else {
    //           //this.toastr.error(res.body.status.statusMessage);
    //           this.router.navigate(['/main']);
    //         }
    //       },
    //       (error) => {
    //         console.log(error);
    //       }
    //     );
    //   } else {
    //     console.log('otp not found');
    //   }
    // }, 1000);
  }

  getConfigData() {
    // console.log("This is getConfigdata login")
    // this.authService.getConfig().subscribe(
    //   (res) => {
    //     //save res.result to local storage
    //     localStorage.setItem("configData", JSON.stringify(res?.result));
    //     this.rSiteKey = res?.result?.recaptchaConfig?.recaptchaSiteKey;
    //     // console.log(this.rSiteKey);
    //     this.version = res?.result?.appConfig["1"]?.model?.["eleven-version"]
    //     if (!localStorage.getItem("versionNow") && this.version) {
    //       localStorage.setItem("versionNow", this.version)
    //     } else if (localStorage.getItem("versionNow") != this.version) {
    //       this.versionPopUpOpen = true
    //       localStorage.setItem("versionNow", this.version)
    //     }
    //     let interval = setTimeout(() => {
    //       if (this.captchaRef) {
    //         this.captchaRef.execute();
    //         clearInterval(interval);
    //       }
    //     }, 100);
    //     // console.log(
    //     //   'Inside getConfigData value of this.rSiteKey:' + this.rSiteKey
    //     // );
    //   },
    //   (error) => {
    //     console.log(error)
    //   }
    // );
  }

  //forgot password
  forgoPasswordFormBySms() {
    this.isLoader = true;
    let data = {
      countryCode: '91',
      mobileNumber: this.forgotPasswordFormBySms.get('mobileNo').value,
      password: this.forgotPasswordFormBySms.get('password').value,
      confirmPassword: this.forgotPasswordFormBySms.get('confirmPassword').value,
      OTP: this.otpInput.join(''),
      otpToken: this.otpToken
    }
    this.authService.verifyforgotPass(data).subscribe(
      (res: any) => {
        this.isLoader = false;
        if (res.status === 200) {
          this._notify.success("Password Changed Successfully");
          this.showSuccessPopUp = true;
          this.conditionsforForPassword = false;
        } else {
          this._notify.error('Error in Resetting Password');
        }
      },
      (error: any) => {
        this.isLoader = false;
        this._notify.error(error?.error?.error);
      }
    )
  }

  passwordValidation(event: any) {
    //if key is backspace and password length is 0 then set all password validation to false
    if (event.key == "Backspace" && this.forgotPasswordFormBySms.get('password').value?.length === 0) {
      this.passwordMinLength = false;
      this.passwordHaveUpperCase = false;
      this.passwordHaveLowerCase = false;
      this.passwordHaveNumber = false;
      this.passwordHaveSpecialChar = false;
    }
    //if forgotPasswordFormBySms.get('password').value is not null then check password validation
    if (this.forgotPasswordFormBySms.get('password').value) {
      //check password length
      if (this.forgotPasswordFormBySms.get('password').value?.length >= 8) { //check if password length is greater than 8
        this.passwordMinLength = true;
      } else {
        this.passwordMinLength = false;
      }
      //check password have uppercase
      if (this.forgotPasswordFormBySms.get('password').value.match(/[A-Z]/)) {
        this.passwordHaveUpperCase = true;
      } else {
        this.passwordHaveUpperCase = false;
      }
      //check password have lowercase
      if (this.forgotPasswordFormBySms.get('password').value.match(/[a-z]/)) {
        this.passwordHaveLowerCase = true;
      } else {
        this.passwordHaveLowerCase = false;
      }
      //check password have number
      if (this.forgotPasswordFormBySms.get('password').value.match(/[0-9]/)) {
        this.passwordHaveNumber = true;
      } else {
        this.passwordHaveNumber = false;
      }
      //check password have special character
      if (this.forgotPasswordFormBySms.get('password').value.match(/[\W_]/)) {
        this.passwordHaveSpecialChar = true;
      } else {
        this.passwordHaveSpecialChar = false;
      }
    }
  }
  //back button for forgot password
  backButtonForForgotPassword() {
    this.otpSectionPage
      ? ((this.showForgot = true), (this.OtpInputSection = false), clearInterval(this.otpTimerInterval), (this.otpTimerSeconds = 60), (this.otpInput = []))
      : ((this.showForgot = false), this.forgotPasswordFormBySms.get('mobileNo').setValue(''), this.rightClickforForgotPasswordBySms = false);
    if (this.otpSectionPage === true) {
      this.otpSectionPage = false;
      this.showForgot = true;
      this.OtpInputSection = false;
      clearInterval(this.otpTimerInterval);
      this.otpTimerSeconds = 60;
      this.otpInput = [];
    } else {
      this.showForgot = false;
      this.forgotPasswordFormBySms.get('mobileNo').setValue('');
      this.rightClickforForgotPasswordBySms = false;
    }
  }

  mobileEnter(event) {
    if (event.key === "Enter") {
      this.getOtpBtn();
      event.preventDefault();
    }
  }

  otpEnterIncomplete(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  otpEnterIncomplete1(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
    if (this.otpInput?.length >= 4 && event.key === "Enter") {
      this.SubmitOtpBtn();
      event.preventDefault();
    }
  }

  navigateToSignInPage() {
    this.showForgot = false;
    this.showSuccessPopUp = false;
    this.OtpInputSection = false;
    this.PasswordSectionforSms = false;
    this.showSmsPopup = true;
    this.forgotPasswordFormBySms.reset();
    //pristine the forgot password by sms form
    this.forgotPasswordFormBySms.markAsPristine();
    this.conditionsforForPassword = false;
    this.rightClickforForgotPasswordBySms = false;
  }
}
