<div class="box">
    <svg class="leftwall" width="151" height="425" viewBox="0 0 151 425" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M133.813 0.998825C135.285 0.998825 136.479 2.19273 136.479 3.66549C136.479 5.13825 135.285 6.33216 133.813 6.33216C133.686 6.33216 133.562 6.32336 133.44 6.30635C131.537 6.04034 129.802 4.16549 127.88 4.16549L3.50906 4.1655C3.23292 4.1655 3.00906 3.94164 3.00906 3.6655C3.00906 3.38936 3.23292 3.1655 3.50906 3.1655L127.88 3.16549C129.802 3.16549 131.537 1.29064 133.44 1.02464C133.562 1.00762 133.686 0.998825 133.813 0.998825ZM150.609 420.842C150.804 421.038 150.804 421.354 150.609 421.549L147.427 424.731C147.231 424.927 146.915 424.927 146.72 424.731C146.524 424.536 146.524 424.22 146.72 424.024C147.579 423.165 146.97 421.696 145.755 421.696H1.25342C0.977276 421.696 0.753418 421.472 0.753418 421.196C0.753418 420.92 0.977276 420.696 1.25342 420.696H1.38071C1.72716 420.696 2.00801 420.415 2.00801 420.069L2.00805 3.56422C2.00805 3.28808 2.2319 3.06422 2.50805 3.06422C2.78419 3.06422 3.00805 3.28808 3.00805 3.56422L3.00801 416.696C3.00801 418.905 4.79887 420.696 7.00801 420.696H145.755C146.97 420.696 147.579 419.227 146.72 418.368C146.524 418.172 146.524 417.856 146.72 417.66C146.915 417.465 147.231 417.465 147.427 417.66L150.609 420.842Z"
            fill="url(#paint0_linear_369_2544)" fill-opacity="0.2" />
        <defs>
            <linearGradient id="paint0_linear_369_2544" x1="0.753416" y1="35.6613" x2="153.109" y2="42.2906"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#36E2FA" />
                <stop offset="1" stop-color="#EBFFBF" />
            </linearGradient>
        </defs>
    </svg>


    <svg class="rightwall" width="151" height="425" viewBox="0 0 151 425" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M17.1787 0.998219C15.7059 0.998219 14.512 2.19213 14.512 3.66489C14.512 5.13764 15.7059 6.33155 17.1787 6.33155C17.3051 6.33155 17.4295 6.32276 17.5512 6.30574C19.4544 6.03974 21.1895 4.16489 23.1111 4.16489L147.491 4.16489C147.767 4.16489 147.991 3.94103 147.991 3.66489C147.991 3.38875 147.767 3.16489 147.491 3.16489L23.1111 3.16489C21.1895 3.16489 19.4544 1.29004 17.5512 1.02403C17.4295 1.00701 17.3051 0.998219 17.1787 0.998219ZM0.381668 420.842C0.186401 421.037 0.186401 421.354 0.381668 421.549L3.56364 424.731C3.75891 424.926 4.07549 424.926 4.27075 424.731C4.466 424.536 4.466 424.219 4.27075 424.024C3.4115 423.164 4.02005 421.695 5.23521 421.695H149.747C150.023 421.695 150.247 421.471 150.247 421.195C150.247 420.919 150.023 420.695 149.747 420.695H149.619C149.273 420.695 148.992 420.414 148.992 420.068L148.992 3.56358C148.992 3.28744 148.768 3.06359 148.492 3.06359C148.216 3.06359 147.992 3.28744 147.992 3.56359L147.992 416.695C147.992 418.904 146.201 420.695 143.992 420.695H5.23521C4.02007 420.695 3.4115 419.226 4.27075 418.367C4.466 418.172 4.466 417.855 4.27075 417.66C4.07549 417.464 3.75891 417.464 3.56364 417.66L0.381668 420.842Z"
            fill="url(#paint0_linear_369_2548)" fill-opacity="0.2" />
        <defs>
            <linearGradient id="paint0_linear_369_2548" x1="150.247" y1="35.6606" x2="-2.11909" y2="42.2909"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#36E2FA" />
                <stop offset="1" stop-color="#EBFFBF" />
            </linearGradient>
        </defs>
    </svg>

    <div class="matter">
        <ng-content></ng-content>
    </div>
</div>