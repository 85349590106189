<div class="layout-content-holder-bc">
    <div class="live-casino-block">
        <!-- <div class="slick-slidertv carousel slide hm-row-bc has-slider">
            <div class="sdr-item-holder-bc"><img
                    src="https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/jeetoindia1/wstatic/sliderimages/tvgames/t1.webp"
                    class="img">
            </div>
            <div class="sdr-item-holder-bc"><img
                    src="https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/jeetoindia1/wstatic/sliderimages/tvgames/t2.webp"
                    class="img">
            </div>
            <div class="sdr-item-holder-bc"><img
                    src="https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/jeetoindia1/wstatic/sliderimages/tvgames/t3.webp"
                    class="img">
            </div>
            <div class="sdr-item-holder-bc"><img
                    src="https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/jeetoindia1/wstatic/sliderimages/tvgames/t4.webp"
                    class="img">
            </div>
            <div class="sdr-item-holder-bc"><img
                    src="https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/jeetoindia1/wstatic/sliderimages/tvgames/t5.webp"
                    class="img">
            </div>

        </div> -->
        <ngx-slick-carousel class="slick-sliderp carousel slide hm-row-bc has-slider" #slickModal="slick-carousel"
            [config]="sliderlConfig">
            <div class="sdr-item-holder-bc" *ngFor="let SliderImg of filteredArray" ngxSlickItem>
                <img loading="lazy" src="https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/jeetoindia1{{SliderImg.ImageURL}}"
                    class="img" alt="...">
            </div>
        </ngx-slick-carousel>
        <!-- <div class="slider-bc  ">
            <div class="carousel">
                <div class="carousel-count">2 / 2</div>
                <div class="carousel-viewport">
                    <div class="carousel-container" style="transform: translate3d(-100%, 0px, 0px);">
                        <div class="carousel-slide " style="width: 100%; left: 200%;">
                            <div class="sdr-item-holder-bc"><a class="sdr-item-bc"><img
                                        src="https://cmsbetconstruct.com/storage/medias/vbetindia/content_1378_aa5f0abe5ffc15336898f1a2a3126e41.webp"
                                        loading="lazy" class="sdr-image-bc" alt="" title="WORLD OF GAMES"></a></div>
                        </div>
                        <div class="carousel-slide active-slide" style="width: 100%;">
                            <div class="sdr-item-holder-bc"><a class="sdr-item-bc"><img
                                        src="https://cmsbetconstruct.com/storage/medias/vbetindia/content_1378_2c2f9896ec37f76e12069162bcb3878d.webp"
                                        loading="lazy" class="sdr-image-bc" alt="" title="LIVE TV GAMES"></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="casino-game-tabs live-casino-no-categories">
            <div class="horizontal-sl-tab-container" data-scroll-lock-scrollable="">
                <div class="horizontal-sl-tab ">
                    <div class="horizontal-sl-tab-bc selected-underline active " data-count="" data-badge=""
                        data-id="All">{{'All'|translate}}</div>
                    <div class="horizontal-sl-tab-bc selected-underline  " data-count="" data-badge=""
                        data-id="BETGAMES">{{'BETGAMES'|translate}}</div>
                    <div class="horizontal-sl-tab-bc selected-underline  " data-count="" data-badge="" data-id="TV Bet">
                        {{'TV Bet'|translate}}</div>
                    <div class="horizontal-sl-tab-bc selected-underline  " data-count="" data-badge=""
                        data-id="Lotto Instant Win">{{'Lotto Instant Win'|translate}}</div>
                    <div class="horizontal-sl-tab-bc selected-underline  badge-new" data-count="" data-badge="New"
                        data-id="SportGamesTV">{{'SportGamesTV'|translate}}</div>
                </div>
            </div>
        </div>
        <div class="live-casino-search casino-title-search">
            <div class="sport-search-bc ">
                <div class="ss-icon-holder-bc"><i class="ss-icon-bc bc-i-search"></i></div>
            </div>
        </div>
        <div class="casino-category-types casino-category-types-full" id="casino_games_container">
            <div class="infinite-scroll-component__outerdiv">
                <div class="infinite-scroll-component casino-category-games" style="height: auto; overflow: auto;">
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/1b83aed8a0ad82efb215fe3fe0d7017d_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Bet On Poker" title="Bet On Poker"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/76c9b1da89d8b45dff2c04325ffc04b8_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Classic Wheel" title="Classic Wheel"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/2480148744808a1049bdb44f20d33063_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Football Grid" title="Football Grid"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/3a488a130cafe70191f8c37c42ce453b_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Bet On Baccarat" title="Bet On Baccarat"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/c3beb9e2de86c831281d674fcf29ac95_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Lucky 7" title="Lucky 7"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/442be21affbfc2f27885a2eb3cd7f68b_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="War Of Bets" title="War Of Bets"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/5498d51b4f4568d24f2bb177f9a137de_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="WHEEL" title="WHEEL"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/31c3a02befc61064a6caac17d15f1f44_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Andar Bahar" title="Andar Bahar"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/4cfadd0b9b00c6b37c80a5c10ba28fe1_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Teen Patti" title="Teen Patti"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/b4478e90a8fccd98efcb1330be4ae317_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="WAR OF ELEMENTS" title="WAR OF ELEMENTS"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/4f226f732b7b5c73d50161125622e002_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Win 7/48" title="Win 7/48"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/d9096487015a4ab77f79733427c4ee1a_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Win 50/80" title="Win 50/80"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/4c81c3946ffa489cbcae67356e4f138d_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Win 5/80" title="Win 5/80"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/6b19ee870abe0e7157d69b20939293b1_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Win 5/48" title="Win 5/48"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/629cee76ce4d52717085fc118eba3c5a_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Win 5/20" title="Win 5/20"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/0d9d4d8b1cd2f05fefd962519dfa26c1_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Wheel of Fortune" title="Wheel of Fortune"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/46fc108f7dfda0e4a53e0917f8e9fe55_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Speedy 7" title="Speedy 7"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/7b0ca709b4dd7b0f1fbc970e344409ff_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Lucky 5" title="Lucky 5"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/db8015654efe09b12498b07609182505_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Dice Duel" title="Dice Duel"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/ba6c3f9c25b2cb8113a6dcc60311605b_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Lucky 6" title="Lucky 6"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/1702f5c111fee44fb9fd3c0f34bebf93_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="POKER" title="POKER"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/5201eb178abaaae3695f65100c57fd53_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Keno" title="Keno"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/cfcc408ded938214f68a65c3865aa203_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="7BET" title="7BET"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/95eaa5ce9f075eda98ea37387df727ba_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="5BET" title="5BET"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/b9c3348f2179fcc07068aa903c4c88ce_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="1Bet" title="1Bet"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/4a92a73d244e28328235b655b988c6e0_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Lucky 6" title="Lucky 6"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/ae7ff83b6c802b9042e22b7ac4c701b6_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Win 40/80" title="Win 40/80"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/2e9e727f46590399ec07e9c6566bd994_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Win 30/80" title="Win 30/80"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/072dffc1bba8d1559058e6b5b1d87408_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Win 20/80" title="Win 20/80"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                    <div class="casino-game-item-content">
                        <div class="casino-game-item  " data-badge=""><img loading="lazy"
                                src="https://cmsbetconstruct.com/content/images/casino/icon2/72eb3c46071a0b4d8ac8258caa61f6c3_casinoGameIcon2.webp"
                                class="casino-game-item-image-bc" alt="Win 10/80" title="Win 10/80"><i
                                class="casino-game-item-fav-bc bc-i-favorite null"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
