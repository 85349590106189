import {Component, EventEmitter, Input, OnInit, Output, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import { MyprofileService } from 'src/app/services/myprofile.service';

@Component({
  selector: 'app-popUpWithApiData',
  templateUrl: './popUpWithApiData.html',
  styleUrls: ['./popUpWithApiData.scss']
})

export class PopUpWithApiData implements OnInit {
  @Input() popupData: any;
  @Input() popuppromoData: any;
  @Input() Exposure: any;
  @Input() infoWalletType: any;
  @Input() activeWallet: any;
  @Output() close = new EventEmitter();
  showhidepopupdata: any = false;
  showhideterms: any = true;
  wagerDetail:any=[];
  walletLoading= false
  closePopup() {
    this.close.emit();
  }
  constructor(private sanitizer: DomSanitizer,private myprofileServices:MyprofileService,) { }
  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }

  ngOnInit(): void {
     console.log("infoWalletType", this.infoWalletType);
     console.log("activeWallet", this.activeWallet)
    //this.showWagerDetail();
  }

  showhidetermsc(){
    this.showhideterms=!this.showhideterms;
    this.showhidepopupdata=!this.showhidepopupdata;
  }

  async changeWallet() {
    this.walletLoading = true
    const disableBonus = (this.infoWalletType === "M" || this.activeWallet === this.infoWalletType)  ? false : true
    // const wallet = type === "M" ? this.typeWallet : type
    this.myprofileServices.bonusWallet(disableBonus ,this.infoWalletType).subscribe(res => {
        this.myprofileServices.balanceamount()
        setTimeout(() => {
          this.walletLoading = false
        }, 500)
      },error=>{
      }
    );
    localStorage.setItem("bonus",this.infoWalletType)
  }

  bonusamount(tabId) {
    /*this.isActive=tabId;
     let Id;
      if(tabId=='bonus'){
           Id='B';
           this.isBonus=!this.isBonus;
           this.isActiveTab=this.isBonus;
           this.isCasino=false;
         this.isSport=false;
      }
       else if(tabId=='casino'){
         Id='C';
         this.isCasino=!this.isCasino;
         this.isActiveTab=this.isCasino;
         this.isBonus=false;
         this.isSport=false;
       }
       else if(tabId=='sport'){
         Id='S';
         this.isSport=!this.isSport;
         this.isActiveTab=this.isSport;
         this.isBonus=false;
         this.isCasino=false;
       }
       else{

       }
   this.myprofileServices.bonusWallet(this.isActiveTab,Id).subscribe(res => {

   },error=>{
   }
   );
   var bonusValue:any = this.isBonus
localStorage.setItem("bonus",bonusValue)*/
 }

  /*showWagerDetail(){
  this.myprofileServices.wageringDetails().subscribe((res)=>{
   if(res){
    this.wagerDetail=res?.result;

   }



  },(err)=>{

  })
  }*/

}
