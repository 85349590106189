import {Component, EventEmitter, Input, OnInit, Output, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-promo-info',
  templateUrl: './promo-info.component.html',
  styleUrls: ['./promo-info.component.scss']
})
export class PromoInfoComponent implements OnInit {
  @Input() popupData: any;
  @Output() close = new EventEmitter();

  closeAnim = false
  closePopup() {
    this.closeAnim = true

    setTimeout(() => {
      this.closeAnim = false
      this.close.emit();
    }, 1000)
  }
  constructor(private sanitizer: DomSanitizer) { }
  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }

  ngOnInit(): void {
    console.log("popupData", this.popupData)
  }
}
