import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MyprofileService } from 'src/app/services/myprofile.service';
@Component({
  selector: 'app-accountheader',
  templateUrl: './accountheader.component.html',
  styleUrls: ['./accountheader.component.scss']
})
export class AccountheaderComponent implements OnInit {

  constructor(
    private router: Router,
    private myprofileServices: MyprofileService,
  ) { }

  ngOnInit(): void {
  }

  gotoHome(){
    this.myprofileServices.updateActiveMenuItem('/main');
    this.router.navigate(['/main']);
  }
}
