<div class="overlay" *ngIf="isLoader">
    <div class="loader-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <ng-lottie [options]="lottieOptions" width="75px" height="75px"></ng-lottie>
    </div>
</div>

<div class="layout-content-holder-bc" title="Cricket-football-tennis"
    *ngIf="authService.sportsId == 4 || authService.sportsId == 2 || authService.sportsId == 1 || authService.sportsId == 2378962">
    <div class="detail-page" *ngIf="!isLoaderf">
        <div class="details-header-1">
            <div class="details-header" *ngIf="AllMarket?.[0]?.event?.name!=null">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="header-card">
                    <div class="first-section" [ngClass]="{'first-section-tennis':sid!=='4' && !stats?.status}">
                        <div class="first-head" *ngIf="eid!='28569726'">
                            <button class="backbtn" (click)="backClicked()">
                                <img style="width: 10px;" src="../../../assets/images/details-back.svg" alt="back">
                            </button>
                            <div class="in-play">
                                <ng-lottie *ngIf="stats?.status==='InPlay' || videoStats?.isBRScore"
                                    [options]="liveDotLottieOptions" width="20px" height="20px"></ng-lottie>
                                <ng-lottie *ngIf="!stats?.status && !videoStats?.isBRScore"
                                    [options]="notliveDotLottieOptions" width="20px" height="20px"></ng-lottie>
                                <span>{{getInPlayStatus()}}</span>
                            </div>
                        </div>
                        <div class="head-title" *ngIf="eid!=='28569726'">
                            <div class="second-head">
                                {{AllMarket?.[0]?.event?.name}}</div>
                            <div class="third-head">{{AllMarket?.[0]?.competition?.name}}</div>
                        </div>

                        <div class="main-head-title" *ngIf="eid==='28569726'">
                            <button class="backbtn" (click)="backClicked()">
                                <img *ngIf="eid=='28569726'" (click)="backClicked()"
                                    src="../../../assets/images/details-back.svg" alt="">
                            </button>
                            <div class="head-title">
                                <div class="second-head">
                                    {{AllMarket?.[0]?.event?.name}}</div>
                                <div class="third-head">{{AllMarket?.[0]?.competition?.name}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="second-section"
                        [ngClass]="{'second-section-1':(stats?.battingTeam==null && sid=='4')|| !stats,
                       'second-section-2':(stats?.teams == undefined && sid=='1')|| !stats, 'second-section-3':(stats?.selections?.length==0 && sid=='2')|| !stats, 'second-section-tennis':sid!=='4'}">
                        <div class="second-head">
                            <img (click)="openRulePopup()" *ngIf="generalRulesData?.length>0"
                                src="../../../assets/images/detail-line-header.svg" alt="line">
                            <!-- cricket -->
                            <ng-container *ngIf="sid=='4'">
                                <div class="score-detail" *ngIf="stats?.battingTeam">
                                    {{formatBattingTeamStats(stats)}}
                                </div>
                                <div class="opt-bat" *ngIf="stats?.battingTeam?.name">
                                    {{tossWinnerData(stats)}}
                                </div>
                            </ng-container>
                            <!-- football -->
                            <ng-container *ngIf="(sid=='1' && stats)">
                                <table class="scoreCard-tennis">
                                    <tr class="footballTeam-score" *ngIf="(sid=='1' && stats)">
                                        <td style="width: 80%;"><span>{{stats?.teams[0]?.name}}</span></td>
                                        <td style="text-align: right;"> <span>{{stats?.teams[0]?.score}}<span
                                                    *ngIf="stats?.teams[0]?.penaltyScore">
                                                    ({{stats?.teams[0]?.penaltyScore}})</span></span></td>

                                    </tr>
                                    <tr class="footballTeam-score" *ngIf="(sid=='1' && stats)">
                                        <td style="width: 80%;"><span>{{stats?.teams[1]?.name}}</span></td>
                                        <td style="text-align: right;"> <span>{{stats?.teams[1]?.score}}<span
                                                    *ngIf="stats?.teams[1]?.penaltyScore">
                                                    ({{stats?.teams[1]?.penaltyScore}})</span></span></td>
                                    </tr>
                                    <span class="footBallTime" *ngIf="(sid=='1' && stats)">(<span class="teamName" >Time-</span><span
                                            class="teamName">{{((stats?.time/1000)/60).toFixed(2)}}'</span>)
                                    </span>
                                </table>
                            </ng-container>
                            <!-- tennis -->
                            <ng-container *ngIf="sid=='2' && stats">
                                <table class="scoreCard-tennis">
                                    <tr>
                                        <td style="width: 40%;"><span>{{stats?.selections[0]?.name}}</span></td>
                                        <td style="width: 5%;"><span *ngIf="stats?.selections[0]?.serving"><svg
                                                    _ngcontent-qhb-c98="" xmlns="http://www.w3.org/2000/svg" width="10"
                                                    height="10" viewBox="0 0 5 5">
                                                    <circle _ngcontent-qhb-c98="" cx="2.5" cy="2.5" r="1.25"
                                                        fill="white"></circle>
                                                </svg></span>
                                        </td>
                                        <td style="text-align: right;">
                                            <span>{{stats?.selections[0]?.score?.current}}</span>
                                        </td>
                                        <ng-container *ngFor="let item of stats?.selections[0]?.score?.sets">
                                            <td style="text-align: right;"><span>{{item}}</span></td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <td style="width: 40%;"><span>{{stats?.selections[1]?.name}}</span></td>
                                        <td style="width: 5%"><span *ngIf="stats?.selections[1]?.serving"><svg
                                                    _ngcontent-qhb-c98="" xmlns="http://www.w3.org/2000/svg" width="10"
                                                    height="10" viewBox="0 0 5 5">
                                                    <circle _ngcontent-qhb-c98="" cx="2.5" cy="2.5" r="1.25"
                                                        fill="white"></circle>
                                                </svg>
                                            </span>
                                        </td>
                                        <td style="text-align: right;">
                                            <span>{{stats?.selections[1]?.score?.current}}</span>
                                        </td>
                                        <ng-container *ngFor="let item of stats?.selections[1]?.score?.sets">
                                            <td style="text-align: right;"><span>{{item}}</span></td>
                                        </ng-container>
                                    </tr>
                                </table>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header-card-1" *ngIf="stats?.commentary">
                <span>Commentary : {{stats?.commentary}}</span>
            </div>
            <div class="details-navbar" [ngClass]="{'details-flex-start':!stats}">
                <div class="match-info" (click)="showActiveNav('market')">
                    <span [ngClass]="{'market-2':activeNav==='market'}">{{'Market'|translate}}</span>
                    <div class="market" [ngClass]="{'market-1':activeNav==='market'}"></div>
                </div>
                <div class="match-info" *ngIf="videoStats?.isBRScore || stats" (click)="showActiveNav('match-info')">
                    <span [ngClass]="{'market-2':activeNav==='match-info'}">{{'Match Info'|translate}}</span>
                    <div class="market" [ngClass]="{'market-1':activeNav==='match-info'}"></div>
                </div>
                <div class="match-info" *ngIf="videoStats?.hasVideo" (click)="showActiveNav('live-video')">
                    <span [ngClass]="{'market-2':activeNav==='live-video'}">{{'Live Video'|translate}}</span>
                    <div class="market" [ngClass]="{'market-1':activeNav==='live-video'}"></div>
                </div>
                <div class="match-info" (click)="showActiveNav('live-bets')">
                    <span [ngClass]="{'market-2':activeNav==='live-bets'}">{{'Open Bets'|translate}}<span
                            [ngClass]="{'market-2':activeNav==='live-bets'}"
                            *ngIf="betCount>0">({{betCount}})</span></span>
                    <div class="market" [ngClass]="{'market-1':activeNav==='live-bets'}"></div>
                </div>
            </div>
        </div>
        <div class="scorcard-section" *ngIf="(activeNav==='match-info' && eid!='28569726')">
            <!-- this eid is for icc cricket world cup -->
            <app-scoreboard *ngIf="sid !== '2378962'" [stats]="stats" [scorecard]="scoreCard"
                [eid]="eid"></app-scoreboard>
        </div>
        <div class="scorcard-section-2" *ngIf="activeNav==='live-bets'">
            <app-bet-history [eid]="eid" (setActiveMarket)="handleToBetInstant($event)"></app-bet-history>
        </div>
        <div class="scorcard-section-1" *ngIf="activeNav==='live-video'">
            <app-live-video [videoStats]="videoStats"></app-live-video>
        </div>
        <div class="detail-page-section"
            *ngIf="activeNav==='market' || activeNav==='live-video' || activeNav==='match-info' || activeNav==='live-bets'">
            <div class="matchSection" *ngIf="AllMarket">
                <!-- Match odds card -->
                <ng-container *ngFor="let item of matchOdds;let i = index; trackBy: trackByFn">
                    <app-matchbetcard [matchData]="item" [exposure]="marketExposure">
                        <div class="header-match-card">
                            <div class="match-header-match-card">
                                <span class="heading">{{item.name}}</span>
                                <div class="clock-section">
                                    <div (click)="cashOut(item.id)" class="cash-out">
                                        <span>{{'cash out'|translate}}</span>
                                    </div>
                                    <div class="win-clock">
                                        <img src="../../../assets/images/detail-watch.svg" alt="clock">
                                        <span>{{item?.betDelay}}s</span>
                                    </div>
                                </div>
                            </div>
                            <div class="back-lay">
                                <span>Back</span>
                                <span>Lay</span>
                            </div>

                        </div>
                    </app-matchbetcard>
                </ng-container>
                <!-- Match odds card -->

                <!-- Bookmaker card -->
                <ng-container *ngFor="let item of otherBms;let i = index; trackBy: trackByFn">
                    <app-matchbetcard [matchData]="item" [exposure]="marketExposure">
                        <div class="bookmaker-match-header">
                            <div class="header-match-card">
                                <div class="clock-section">
                                    <span class="header-text">{{item.name | titlecase}}</span>
                                    <div class="win-clock">
                                        <img src="../../../assets/images/detail-watch.svg" alt="clock">
                                        <span>{{item?.betDelay}}s</span>
                                    </div>
                                </div>
                                <div class="back-lay">
                                    <span>Back</span>
                                    <span>Lay</span>
                                </div>
                            </div>
                        </div>
                    </app-matchbetcard>
                </ng-container>

                <app-matchbetcard *ngIf="winner.length>0" [matchData]="winner[0]">
                    <div class="header-match-card">
                        <div class="match-header-match-card">
                            <span>{{winner[0].name}}</span>
                        </div>
                        <div class="back-lay">
                            <span>Back</span>
                            <span>Lay</span>
                        </div>
                    </div>
                </app-matchbetcard>
                <!-- who will win the card -->
                <app-matchbetcard *ngIf="whoWillWintheMatch.length>0" [matchData]="whoWillWintheMatch[0]"
                    [exposure]="marketExposure">
                    <div class="win-input">
                        <span>{{'Who will win the match?'|translate}}</span>
                        <div class="win-clock">
                            <img src="../../../assets/images/detail-watch.svg" alt="clock">
                            <span>{{whoWillWintheMatch[0]?.betDelay}}s</span>
                        </div>
                    </div>
                </app-matchbetcard>

                <app-matchbetcard *ngIf="toWintheToss.length>0" [matchData]="toWintheToss[0]"
                    [exposure]="marketExposure">
                    <div class="win-input">
                        <span>{{toWintheToss[0]?.name}}</span>
                        <div class="win-clock">
                            <img src="../../../assets/images/detail-watch.svg" alt="clock">
                            <span>{{toWintheToss[0]?.betDelay}}s</span>
                        </div>
                    </div>
                </app-matchbetcard>

                <app-matchbetcard *ngIf="tiedMatch.length>0" [matchData]="tiedMatch[0]" [exposure]="marketExposure">
                    <div class="header-match-card">
                        <div class="match-header-match-card">
                            <span class="heading">{{tiedMatch[0].name}}</span>
                            <div class="clock-section">
                                <div (click)="cashOut(tiedMatch[0].id)" class="cash-out">
                                    <span>{{'cash out'|translate}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="back-lay">
                            <span>Back</span>
                            <span>Lay</span>
                        </div>
                    </div>
                </app-matchbetcard>
            </div>
            <!--Market Section -->
            <div class="market-page" *ngIf="AllMarket">
                <div class="market-section">
                    <div class="market-header">
                        <div class="premium-cricket" *ngIf="fancyMarket.length>0 && sid=='4'"
                            [ngClass]="{'fancy':activetabGroup==='Fancy'}" (click)="switchTabGroup('Fancy')">
                            <span>{{'Fancy'|translate}}</span>
                        </div>
                        <div class="premium-cricket" *ngIf="premiumMarket.length>0 && sid=='4'"
                            [ngClass]="{'fancy':activetabGroup==='Premium Cricket'}"
                            (click)="switchTabGroup('Premium Cricket')">
                            <span>{{'Premium Cricket'|translate}}</span>
                        </div>
                        <div class="premium-cricket-1"
                            *ngIf="fancyMarket.length>0 && premiumMarket.length>0 && sid=='4' && activetabGroup==='Premium Cricket'"
                            (click)="switchTabGroup('Premium Cricket')">
                        </div>
                        <div class="premium-cricket-2"
                            *ngIf="premiumMarket.length>0 && fancyMarket.length>0 && sid=='4' && activetabGroup==='Fancy'"
                            (click)="switchTabGroup('Premium Cricket')">
                        </div>
                    </div>
                </div>
                <!--Fancy cricket section -->

                <!--Betting card -->
                <ng-container *ngIf="activetabGroup==='Fancy'  && fancyMarket.length>0">
                    <ng-container *ngFor="let item of fancyMarket;let i = index;trackBy:trackByMethod">
                        <app-matchbetcard [matchData]="item" [exposure]="marketExposure"
                            (showLadder)="showmarket($event)"></app-matchbetcard>
                    </ng-container>
                </ng-container>

                <!--Premium Cricket section -->
                <ng-container *ngIf="activetabGroup==='Premium Cricket' && premiumMarket.length>0">
                    <!--Betting card -->
                    <ng-contianer *ngFor="let item of premiumMarket;let i = index;trackBy:trackByMethod">
                        <app-matchbetcard [matchData]="item" [exposure]="marketExposure">
                            <div class="header-match-card">
                                <div class="match-header-match-card">
                                    <span class="heading">{{item.name}}</span>
                                    <div class="clock-section">
                                        <div (click)="cashOut(item.id)" class="cash-out">
                                            <span>{{'cash out'|translate}}</span>
                                        </div>
                                        <div class="win-clock">
                                            <img src="../../../assets/images/detail-watch.svg" alt="clock">
                                            <span>{{item?.betDelay}}s</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="back-lay">
                                    <span>Back</span>
                                    <span>Lay</span>
                                </div>
                            </div>
                        </app-matchbetcard>
                    </ng-contianer>
                </ng-container>

                <div class="modal-background Modal-Background rules-popup max-width-550" *ngIf="rulesPopup"
                    [ngClass]="{'rulesPopupClose':rulesPopupclose}">
                    <div class="rules-page">
                        <!-- <div class="rect-1"></div>
                        <div class="rect-2"></div>
                        <div class="rect-3"></div> -->
                        <div class="rules-popup-header">
                            <span>{{'Rules'|translate}}</span>
                            <svg (click)="closePopup()" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C22.9966 9.08367 21.8365 6.28778 19.7744 4.22563C17.7122 2.16347 14.9163 1.00344 12 1ZM16.242 14.829C16.3375 14.9212 16.4137 15.0316 16.4661 15.1536C16.5185 15.2756 16.5461 15.4068 16.5473 15.5396C16.5484 15.6724 16.5231 15.8041 16.4728 15.927C16.4225 16.0499 16.3483 16.1615 16.2544 16.2554C16.1605 16.3493 16.0489 16.4235 15.926 16.4738C15.8031 16.5241 15.6714 16.5494 15.5386 16.5483C15.4058 16.5471 15.2746 16.5195 15.1526 16.4671C15.0306 16.4147 14.9203 16.3385 14.828 16.243L12 13.414L9.172 16.243C8.9834 16.4252 8.7308 16.526 8.4686 16.5237C8.20641 16.5214 7.95559 16.4162 7.77019 16.2308C7.58478 16.0454 7.47961 15.7946 7.47733 15.5324C7.47505 15.2702 7.57585 15.0176 7.758 14.829L10.586 12L7.758 9.171C7.66249 9.07875 7.58631 8.96841 7.5339 8.8464C7.48149 8.7244 7.45391 8.59318 7.45275 8.4604C7.4516 8.32762 7.4769 8.19594 7.52718 8.07305C7.57746 7.95015 7.65172 7.8385 7.74561 7.74461C7.8395 7.65071 7.95115 7.57646 8.07405 7.52618C8.19695 7.4759 8.32863 7.4506 8.46141 7.45175C8.59418 7.4529 8.72541 7.48049 8.84741 7.5329C8.96941 7.58531 9.07976 7.66149 9.172 7.757L12 10.586L14.828 7.757C14.9203 7.66149 15.0306 7.58531 15.1526 7.5329C15.2746 7.48049 15.4058 7.4529 15.5386 7.45175C15.6714 7.4506 15.8031 7.4759 15.926 7.52618C16.0489 7.57646 16.1605 7.65071 16.2544 7.74461C16.3483 7.8385 16.4225 7.95015 16.4728 8.07305C16.5231 8.19594 16.5484 8.32762 16.5473 8.4604C16.5461 8.59318 16.5185 8.7244 16.4661 8.8464C16.4137 8.96841 16.3375 9.07875 16.242 9.171L13.414 12L16.242 14.829Z"
                                    fill="#E7EAEF" />
                            </svg>
                        </div>
                        <ng-container *ngFor="let item of generalRulesData; let i=index">
                            <div class="rules-page-section">
                                <div class="rules-section-header" (click)="openRulePage(i)">
                                    <span class="text">{{item?.marketName | titlecase}}</span>
                                    <svg class="showText" [ngClass]="{'showText-1':showRulesPage[i]}"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <path
                                            d="M8.25439 3.50877L16.5 11.7544L8.25439 20L7.1 18.825L14.1706 11.7544L7.1 4.68377L8.25439 3.50877Z"
                                            fill="white" />
                                    </svg>
                                </div>
                                <div class="animationApplytoOpen" *ngIf="showRulesPage[i]" [innerHTML]="item?.rules">
                                </div>

                            </div>
                        </ng-container>
                    </div>
                </div>

            </div>

        </div>
        <div [ngClass]="{'display-none':showhidemarket}">
            <div class="fancy-market-data">
                <div class="betslip-bc Full  full" style="transform: translateY(0px); opacity: 1;">
                    <div id="betslip_container">
                        <div class="betslip-full-content-bc">
                            <div class="hdr-main-content-bc">
                                <div class="logo-container"><a class="logo" (click)="closeMarket(null)"><img
                                            loading="lazy" class="hdr-logo-bc"
                                            src="../../../assets/images/img/11exch-logo-new.png" alt=""></a>
                                </div><i class="hdr-user-close bc-i-close-remove" (click)="closeMarket(null)"></i>
                            </div>
                            <div class="bs-f-header-sub-navigation-bc "></div>
                            <div class="bs-f-body-bc">
                                <div class="bs-scroll-container-bc" data-scroll-lock-scrollable=""
                                    *ngIf="fancyprofit?.length!=0">

                                    <h4 class="text-white text-center">{{markeName}} </h4>
                                    <table class="table">
                                        <thead>
                                            <tr>

                                                <th scope="col">{{'Runs'|translate}}</th>
                                                <th scope="col">{{'P&L'|translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let item of fancyprofit">
                                            <tr>

                                                <td *ngIf="item?.end==undefined">{{item?.start}}+</td>
                                                <td *ngIf="item?.end!=undefined">{{item?.start}}-{{item?.end}}</td>
                                                <td *ngIf="item?.exposure < 0" style="color:red">{{item?.exposure |
                                                    indianCurrency}}</td>
                                                <td *ngIf="item?.exposure >= 0" class="win">{{item?.exposure |
                                                    indianCurrency}}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <div class="bs-f-footer ">
                                        <div class="bet-slip-switcher-wrapper-bc"></div>
                                        <div class="bs-info-massages"></div>
                                        <div class="bet-button-wrapper-bc content-type-0">
                                            <button class="btn a-color deposit " (click)="closeMarket(null)"
                                                type="submit" title="Deposit"><span>{{'ok'|translate}}</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-data" *ngIf="!AllMarket">
                <app-no-data-available></app-no-data-available>
            </div>
        </div>
    </div>
</div>

<div class="layout-content-holder-bc" title="Races" *ngIf="authService.sportsId == 7 || authService.sportsId == 4339">
    <div class="race-page">
        <div class="details-header-1">
            <div class="details-header">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="racer-card">
                    <div class="first-section">
                        <div class="first-head">
                            <button class="backbtn" (click)="backClicked()">
                                <img style="width: 10px;" src="../../../assets/images/details-back.svg" alt="back">
                            </button>
                            <div class="second-head" *ngIf="matchDetails">{{matchDetails?.startTime | date:"HH:mm"}}
                                {{matchDetails?.venue}} ({{matchDetails?.countryCode}})</div>
                        </div>
                    </div>
                    <div class="second-section">
                        <div class="second-head">
                            <img (click)="openRulePopup()" *ngIf="generalRulesData?.length>0"
                                src="../../../assets/images/detail-line-header.svg" alt="line">
                        </div>
                    </div>
                </div>
            </div>
            <div class="details-navbar" [ngClass]="{'details-flex-start':!stats}">
                <div class="match-info" (click)="showActiveNav('market')">
                    <span [ngClass]="{'market-2':activeNav==='market'}">{{'Market'|translate}}</span>
                    <div class="market" [ngClass]="{'market-1':activeNav==='market'}"></div>
                </div>
                <div class="match-info" (click)="showActiveNav('live-bets')">
                    <span [ngClass]="{'market-2':activeNav==='live-bets'}">{{'Open Bets'|translate}}<span
                            [ngClass]="{'market-2':activeNav==='live-bets'}"
                            *ngIf="betCount>0">({{betCount}})</span></span>
                    <div class="market" [ngClass]="{'market-1':activeNav==='live-bets'}"></div>
                </div>
            </div>
        </div>
        <div class="scorcard-section-2" *ngIf="activeNav==='live-bets'">
            <app-bet-history [eid]="eid"></app-bet-history>
        </div>
        <div class="detail-page-section" *ngIf="raceData">
            <app-matchbetcard [isRace]="true" [matchData]="raceData"> <!--[exposure]="marketExposure" -->
                <div class="header-match-card">
                    <div class="match-header-match-card">
                        <span class="heading">{{"Race Odds"|translate}}</span>
                        <div class="clock-section">
                            <div class="win-clock">
                                <img src="../../../assets/images/detail-watch.svg" alt="clock">
                                <span>{{raceData?.betDelay}}s</span>
                            </div>
                        </div>
                    </div>
                    <div class="back-lay">
                        <span>Back</span>
                        <span>Lay</span>
                    </div>
                </div>
            </app-matchbetcard>
        </div>
        <div class="no-data" *ngIf="!isRaceDataAvailable && !raceData">
            <app-no-data-available></app-no-data-available>
        </div>
    </div>
</div>
