import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit  {
  @Input() className?: string;
  @Input() styles: { [key: string]: string };

  constructor() {
    this.styles = {};
  }
  ngOnInit(): void {
  }

}
