<!-- <div class="card bonus-card orange-card">
    <div class="card-body">

        <div class="flexbox1">
            <div class="txt">
                <p class="bonus-title"> You've won Bonus of ₹{{ this.bonus.wagered['claimAmount'] | indianCurrency}}
                </p>
                <div class="boxssd" style="display: flex;justify-content: space-between;">
                    <p class="pull-right">Wagering completed</p>
                    <p class="pull-left">₹{{ this.bonus.wagered['totalWagered'] | indianCurrency}}</p>
                </div>
                <div class="progressbar" *ngIf="this.bonus.wagered['claimAmount'] > 0">
                    <div class="progressbg">
                        <div class="progressdot"></div>
                    </div>
                </div>
            </div>
            <div class="gift">
                <img loading="lazy" class="giftbox" src="../../../assets/images/checkgreen.svg">
                <p class="claimbtn">Claimed</p>
            </div>
        </div>
    </div>
</div> -->

<div class="card">
    <div class="flexbox1">
        <div class="txt">
            <p class="bonus-title"> {{"You've won Bonus of ₹"|translate}}{{ this.bonus?.wagered?.claimAmount | indianCurrency}}
            </p>
            <div class="boxssd">
                <p class="pull-right">{{'Wagering completed'|translate}}</p>
                <p class="pull-left">₹{{ this.bonus?.wagered?.totalWagered | indianCurrency}}</p>
            </div>
            <div class="progressbar">
                <div class="progressbar">
                    <div class="progressbg">
                        <div class="progressdot"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="gift">
            <img loading="lazy" class="giftbox" src="../../../assets/images/checkgreen.svg">
            <p class="claimbtn">{{'Claimed'|translate}}</p>
        </div>
    </div>
</div>
