import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MBannerResponse } from '../models/m-banner.interface';

type PromoCodeResponse = Pick<MBannerResponse, 'status' | 'success'> & { result: string, error?: string };

@Injectable({
  providedIn: 'root'
})
export class LandingService {
  API_URL = environment.API_Event_URL;

  favouriteimage = new Subject();
  constructor(private http:HttpClient) { }

  sliderimage(){
    return this.http.get<MBannerResponse>(`${this.API_URL}mbanners`);
  }

  getBonuses(type: any) {
    const url = this.API_URL + 'account/bonuses?bonusType=' + type;
    // const url = this.API_URL + 'account/bonuses?bonusType=all';
    const token = localStorage.getItem('ROYALTOKEN_KEY');
    if(token == null){
      return this.http.get(url);
    }
    const headers = { 'Authorization': token };
    return this.http.get(url, { headers });
  }

  claimBonuses(bonus_id: any) {
    // const url = this.API_URL + 'account/bonuses?bonusType=alive';
    const url = this.API_URL + 'account/bonus/claim?bonus_id=' + bonus_id;
    const token = localStorage.getItem('ROYALTOKEN_KEY');
    const headers = { 'Authorization': token };
    return this.http.get(url, { headers });
  }

  activateBonuses(bonus_id: any) {
    // const url = this.API_URL + 'account/bonuses?bonusType=alive';
    const url = this.API_URL + 'account/bonus/activate?bonus_id=' + bonus_id;
    const token = localStorage.getItem('ROYALTOKEN_KEY');
    const headers = { 'Authorization': token };
    return this.http.get(url, { headers });
  }

  getPromotions() {
    const token = localStorage.getItem('ROYALTOKEN_KEY');
    // console.log(token);
    let url = this.API_URL + 'promotions?type=DEPOSIT';
    if (token != null) {
      url = this.API_URL + 'account/promotions?type=DEPOSIT';
      const headers = { 'Authorization': token };
      return this.http.get(url, { headers });
    }
    return this.http.get(url);
  }

  redeemPromoCode(promoCode: string) {
    const URL = 'payments/userId/redeem_promo';
    return this.http.post<PromoCodeResponse>(`${this.API_URL}${URL}`, { coupon_code: promoCode });
  }

}
