import { Component, OnInit, SecurityContext } from '@angular/core';
import {MyprofileService} from "../../../../services/myprofile.service";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-bonusDetailes',
  templateUrl: './bonusDetailes.component.html',
  styleUrls: ['./bonusDetailes.component.scss']
})
export class BonusDetailesComponent implements OnInit {

  bonusInfo = ''
  bonusImage = ''

  constructor(private profileServices:MyprofileService, private sanitizer: DomSanitizer){}
 sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }
  ngOnInit(): void {
    this.profileServices.getDetailedBonusInfo().subscribe((data) => {
      console.log("🚀 ~ file: bonusDetailes.component.ts:17 ~ BonusDetailesComponent ~ this.profileServices.getBalanceData ~ data:", data)
      this.bonusInfo = data?.info
      this.bonusImage = data?.promoImageUrl
    }
    )
  }

}
