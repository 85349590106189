<div class="layout-header-holder-bc">
    <div class="header-bc max-width-550">
        <div class="hdr-main-content-bc">
      <div class="logo-container">
        <a class="logo" (click)="gotoHome()">
          <img loading="lazy" class="hdr-logo-bc" style="margin-right: 6px;" src="../../../../assets/images/11exch.png" alt="">
        </a>
        <!-- <a class=" header-icon" routerLink="/shared/promotions">
                    <img
                        src="https://cmsbetconstruct.com/storage/medias/vbetindia/media_1378_f9a252b0c2cd0be8ee18bbaffe98ae60.gif"
                        alt="">
                </a> -->
      </div>
      <div class="" (click)="gotoHome()">
        <svg style="border:1px solid #9CA3AF; background:#9CA3AF; border-radius:50%;" xmlns="http://www.w3.org/2000/svg"
          width="1.2rem" height="1.2rem" viewBox="0 0 14 15" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.91728 10.7587C2.68949 10.9866 2.68952 11.3559 2.91735 11.5837C3.14517 11.8115 3.51452 11.8115 3.7423 11.5836L7.00036 8.32499L10.2587 11.5833C10.4865 11.8111 10.8558 11.8111 11.0836 11.5833C11.3114 11.3555 11.3114 10.9862 11.0836 10.7584L7.82525 7.49998L11.0834 4.24126C11.3111 4.01343 11.3111 3.64409 11.0833 3.41631C10.8555 3.18851 10.4861 3.18855 10.2583 3.41638L7.00024 6.67503L3.74191 3.41666C3.5141 3.18885 3.14475 3.18885 2.91695 3.41666C2.68914 3.64446 2.68914 4.01381 2.91695 4.24162L6.17541 7.50004L2.91728 10.7587Z"
            fill="#111827" />
        </svg>
      </div>
        </div>

    </div>
</div>
