import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SportsbookService {
  signinshowhide = new Subject();  
  constructor() { }
}
