<div class="match-odds" title="cricket-football-tennis">
    <div class="match-odd-header" *ngIf="!matchData?.id.endsWith('FY')">
        <div class="one"></div>
        <div class="cricket-2"></div>
        <div class="match-header">
            <ng-content></ng-content>
        </div>
    </div>
    <ng-container *ngIf="!matchData?.id.endsWith('FY') && !isRace">
        <ng-container *ngFor="let item of matchData?.runners;trackBy:trackByMethod; let i = index">
            <div class="first-team">
                <div class="team-name-bet">
                    <span>{{item.name}}</span>
                    <div class="new-value">
                        <ng-container *ngIf="getRunnerPl(item.id)">
                            <span
                                [ngClass]="{'color-even':getRunnerPl(item.id).oldExposure>0,'color-odd':getRunnerPl(item.id).oldExposure<0}"
                                *ngIf="getRunnerPl(item.id).oldExposure != null && getRunnerPl(item.id).oldExposure != 0 && getRunnerPl(item.id).oldExposure != undefined && !isNaN(getRunnerPl(item.id).oldExposure)">{{getRunnerPl(item.id).oldExposure}}</span>
                            <!--                <span [ngClass]="{'color-even':betHelper.profitAndLiability>0,'color-odd':betHelper.profitAndLiability<0}"-->
                            <!--                      *ngIf="betHelper.selectedTeam == matchData?.id + '_' + item.id">{{getRunnerPl(item.id).oldExposure}}</span>-->
                            <span
                                [ngClass]="{'color-even':getRunnerPl(item.id).newExposure>0,'color-odd':getRunnerPl(item.id).newExposure<0}"
                                *ngIf="getRunnerPl(item.id).newExposure != null && getRunnerPl(item.id).newExposure != 0 && getRunnerPl(item.id).newExposure != undefined && !isNaN(getRunnerPl(item.id).newExposure)">»
                                {{getRunnerPl(item.id).newExposure}}</span>
                        </ng-container>
                    </div>

                </div>
                <div class="back-lay-detail"
                    *ngIf="matchData?.status==='OPEN' && !matchData?.id.endsWith('.340._BR') && matchData?.name!=='To Win The Toss'">
                    <div class="back" *ngIf="!matchData?.id.endsWith('_SB')"
                        (click)="openBetSlip(item?.back[0]?.price,i,true)" [ngClass]="{
                        'color-change': applyBackAnimation[i],'isBettable': !matchData?.isBettable,
                        'back-disable':matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN',
                        'bet-selected-button':betHelper.selectedCard =='match' && betHelper.selectedButton == matchData?.id + '_' + item.id + '_' + 'back'
                    }">
                        <span>{{item?.back[0]?.price || '-'}}</span>
                        <small>{{item?.back[0]?.size}}</small>
                    </div>
                    <div class="back" *ngIf="matchData?.id.endsWith('_SB')"
                         (click)="openBetSlip(item?.back[0]?.price,i,true)" [ngClass]="{
                        'color-change': applyBackAnimation[i],'isBettable': !matchData?.isBettable,
                        'back-disable':matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN',
                        'bet-selected-button':betHelper.selectedCard =='match' && betHelper.selectedButton == matchData?.id + '_' + item.id + '_' + 'back'
                }">
                        <span>{{calculatePrice(item?.back[0]?.price)}}</span>
                    </div>
                    <div class="lay" *ngIf="!matchData?.id.endsWith('_SB')"
                         (click)="openBetSlip(item?.lay[0]?.price,i,false)" [ngClass]="{
                        'color-change': applyLayAnimation[i],'isBettable': !matchData?.isBettable,
                        'lay-disable':matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN',
                        'bet-selected-button': betHelper.selectedCard =='match' && betHelper.selectedButton == matchData?.id + '_' + item.id + '_' + 'lay'
                }">
                        <span>{{item?.lay[0]?.price || '-'}}</span>
                        <small>{{item?.lay[0]?.size}}</small>
                    </div>
                    <div class="lay" *ngIf="matchData?.id.endsWith('_SB')"
                         (click)="openBetSlip(item?.lay[0]?.price,i,false)" [ngClass]="{
                        'color-change': applyLayAnimation[i],'isBettable': !matchData?.isBettable,
                        'lay-disable':matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN',
                        'bet-selected-button':betHelper.selectedCard =='match' && betHelper.selectedButton == matchData?.id + '_' + item.id + '_' + 'lay'
                }">
                        <span>{{calculatePrice(item?.lay[0]?.price)}}</span>

                    </div>
                </div>
                <div class="back-lay-detail"
                    *ngIf="matchData?.status==='OPEN' && matchData?.id.endsWith('.340._BR') && matchData?.name!=='To Win The Toss'">
                    <div class="back" *ngIf="!matchData?.id.endsWith('_SB')"
                         (click)="openBetSlip(item?.back[0]?.price,i,true)" [ngClass]="{
                        'color-change': applyBackAnimation[i],'isBettable': !matchData?.isBettable,
                        'back-disable':matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN',
                        'bet-selected-button':betHelper.selectedCard =='match' && betHelper.selectedButton == matchData?.id + '_' + item.id + '_' + 'back'
                }">
                        <span>{{item?.back[0]?.price || '-'}}</span>
                    </div>
                    <div class="back" *ngIf="matchData?.id.endsWith('_SB')"
                         (click)="openBetSlip(item?.back[0]?.price,i,true)" [ngClass]="{
                        'color-change': applyBackAnimation[i],'isBettable': !matchData?.isBettable,
                        'back-disable':matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN',
                        'bet-selected-button':betHelper.selectedCard =='match' && betHelper.selectedButton == matchData?.id + '_' + item.id + '_' + 'back'
                }">
                        <span>{{calculatePrice(item?.back[0]?.price)}}</span>
                    </div>
                </div>
                <div class="back-lay-detail"
                    *ngIf="matchData?.status==='OPEN'&& matchData?.id.endsWith('_SB') && matchData?.name==='To Win The Toss'">
                    <div class="back" *ngIf="matchData?.id.endsWith('_SB')"
                         (click)="openBetSlip(item?.back[0]?.price,i,true)" [ngClass]="{
                        'color-change': applyBackAnimation[i],'isBettable': !matchData?.isBettable,
                        'back-disable':matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN',
                        'bet-selected-button':betHelper.selectedCard =='match' && betHelper.selectedButton == matchData?.id + '_' + item.id + '_' + 'back'
                }">
                        <span>{{calculatePrice(item?.back[0]?.price)}}</span>
                    </div>
                </div>
                <div class="suspended" *ngIf="matchData?.status==='SUSPENDED'|| matchData?.status!=='OPEN'">
                    <span style="color: rgba(255, 255, 255, 0.40);">{{'SUSPENDED'|translate}}</span>
                </div>
            </div>
            <app-bet-slip-card style="width: 100%;" (closeBetSlip)="closeBetSlip()" [eventId]="matchData?.event?.id"
                [index]="i" [matchData]="matchData" [betData]="betData"
                *ngIf="betHelper.cardId+ '_' + betHelper.itemId == matchData?.id + '_' + item.id "></app-bet-slip-card>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="matchData?.id.endsWith('FY') && !isRace">
        <div class="over-betting-card">
            <div class="card-section">
                <div class="betting-text">
                    <div class="betting-value">
                    <span>{{matchData?.name}}</span>
                        <div class="bet-value">
                            <ng-container *ngIf="getRunnerPl(matchData?.runners[0].id)">
                        <span
                                    [ngClass]="{'color-even':getRunnerPl(matchData?.runners[0].id).oldExposure>0,'color-odd':getRunnerPl(matchData?.runners[0].id).oldExposure<0}"
                                    *ngIf="getRunnerPl(matchData?.runners[0].id).oldExposure != null && getRunnerPl(matchData?.runners[0].id).oldExposure != 0 && getRunnerPl(matchData?.runners[0].id).oldExposure != undefined && !isNaN(getRunnerPl(matchData?.runners[0].id).oldExposure)">{{getRunnerPl(matchData?.runners[0].id).oldExposure}}</span>
                        <span
                                    [ngClass]="{'color-even':getRunnerPl(matchData?.runners[0].id).newExposure>0,'color-odd':getRunnerPl(matchData?.runners[0].id).newExposure<0}"
                                    *ngIf="getRunnerPl(matchData?.runners[0].id).newExposure != null && getRunnerPl(matchData?.runners[0].id).newExposure != 0 && getRunnerPl(matchData?.runners[0].id).newExposure != undefined && !isNaN(getRunnerPl(matchData?.runners[0].id).newExposure)">»
                                    {{getRunnerPl(matchData?.runners[0].id).newExposure}}</span>
                    </ng-container>
                        </div>
                    </div>
                    <div class="delay-section" (click)="showmarket(matchData)">
                        <img style="width: 1rem; height: 1rem;" src="../../../assets/images/detail-watch-1.svg"
                            alt="watch">
                        <small>{{matchData?.betDelay}}s </small>
                        <img style="width: 1.85rem;"
                            [ngClass]="{'filter-ladder': getRunnerPl(matchData?.runners[0].id).oldExposure != 0}"
                            style="width: 1.2rem;" src="../../../assets/images/detail-bet-icon.svg" alt="bet">
                    </div>
                </div>
                <div class="back-lay-detail"
                    *ngIf="matchData?.status==='OPEN' && matchData?.statusLabel!=='Ball Running'">
                    <div class="lay"  [ngClass]="{ 'color-change': applyLayAnimationFancy}"  (click)="openFancyBetSlip(matchData?.runners[0].lay[0]?.price,0,false,matchData)">
                        <span>{{matchData?.runners[0]?.lay[0]?.line}}</span>
                        <small>{{matchData?.runners[0]?.lay[0]?.price}}</small>
                    </div>
                    <div class="back" [ngClass]="{ 'color-change': applyBackAnimationFancy}" (click)="openFancyBetSlip(matchData?.runners[0].back[0]?.price,0,true,matchData)">
                        <span>{{matchData?.runners[0]?.back[0]?.line}}</span>
                        <small>{{matchData?.runners[0]?.back[0]?.price}}</small>
                    </div>
                </div>
                <div class="suspended" *ngIf="matchData?.status!=='OPEN'&& matchData?.statusLabel!=='Ball Running'">
                    <span style="color: rgba(255, 255, 255, 0.40);">{{'SUSPENDED'|translate}}</span>
                </div>
                <div class="back-lay-1-detail"
                    *ngIf="matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN'">
                    <span class="lay"></span>
                    <span class="text-back">{{'Ball Running'|translate}}</span>
                    <span class="back"></span>
                </div>
            </div>
        </div>
        <app-bet-slip-card (closeBetSlip)="closeBetSlip()" [eventId]="matchData?.event?.id" [matchData]="matchData"
            [betData]="betData"
            *ngIf="betHelper.cardId+ '_' + betHelper.itemId == matchData?.id +'_'+ matchData?.runners[0].id"></app-bet-slip-card>
    </ng-container>
    <ng-container *ngIf="isRace">
        <ng-container *ngFor="let item of matchData?.runners;trackBy:trackByMethod; let i = index">
            <div class="first-team">
                <div class="team-name-bet">
                    <div class="race-data">
                        <div class="racer-meta" *ngIf="matchData.eventTypeId == 7">
                            <span *ngIf="item?.metadata?.['CLOTH_NUMBER']">{{item?.metadata?.['CLOTH_NUMBER']}}</span>
                            <span *ngIf="item?.metadata?.['STALL_DRAW']">({{item?.metadata?.['STALL_DRAW']}})</span>
                        </div>
                        <div class="racer-name">
                            <span>{{item.name}}</span>
                            <small *ngIf="matchData.eventTypeId == 7" class="jockey-name">{{item?.metadata?.['JOCKEY_NAME']}}</small>
                        </div>
                    </div>
                    <div class="new-value">
                        <ng-container *ngIf="getRunnerPl(item.id)">
                            <span
                                [ngClass]="{'color-even':getRunnerPl(item.id).oldExposure>0,'color-odd':getRunnerPl(item.id).oldExposure<0}"
                                *ngIf="getRunnerPl(item.id).oldExposure != null && getRunnerPl(item.id).oldExposure != 0 && getRunnerPl(item.id).oldExposure != undefined && !isNaN(getRunnerPl(item.id).oldExposure)">{{getRunnerPl(item.id).oldExposure}}</span>
                            <span
                                [ngClass]="{'color-even':getRunnerPl(item.id).newExposure>0,'color-odd':getRunnerPl(item.id).newExposure<0}"
                                *ngIf="getRunnerPl(item.id).newExposure != null && getRunnerPl(item.id).newExposure != 0 && getRunnerPl(item.id).newExposure != undefined && !isNaN(getRunnerPl(item.id).newExposure)">»
                                {{getRunnerPl(item.id).newExposure}}</span>
                        </ng-container>
                    </div>
                </div>
                <div class="back-lay-detail"
                    *ngIf="matchData?.status==='OPEN' && !matchData?.id.endsWith('.340._BR') && matchData?.name!=='To Win The Toss'">
                    <div class="back" *ngIf="!matchData?.id.endsWith('_SB')"
                        (click)="openBetSlip(item?.back[0]?.price,i,true)" [ngClass]="{
                        'color-change': applyBackAnimation[i],'isBettable': !matchData?.isBettable,
                        'back-disable':matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN',
                        'bet-selected-button':betHelper.selectedCard =='match' && betHelper.selectedButton == matchData?.id + '_' + item.id + '_' + 'back'
                    }">
                        <span>{{item?.back[0]?.price || '-'}}</span>
                        <small>{{item?.back[0]?.size}}</small>
                    </div>
                    <div class="back" *ngIf="matchData?.id.endsWith('_SB')"
                        (click)="openBetSlip(item?.back[0]?.price,i,true)" [ngClass]="{
                        'color-change': applyBackAnimation[i],'isBettable': !matchData?.isBettable,
                        'back-disable':matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN',
                        'bet-selected-button':betHelper.selectedCard =='match' && betHelper.selectedButton == matchData?.id + '_' + item.id + '_' + 'back'
                }">
                        <span>{{calculatePrice(item?.back[0]?.price)}}</span>
                    </div>
                    <div class="lay" *ngIf="!matchData?.id.endsWith('_SB')"
                        (click)="openBetSlip(item?.lay[0]?.price,i,false)" [ngClass]="{
                        'color-change': applyLayAnimation[i],'isBettable': !matchData?.isBettable,
                        'lay-disable':matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN',
                        'bet-selected-button': betHelper.selectedCard =='match' && betHelper.selectedButton == matchData?.id + '_' + item.id + '_' + 'lay'
                }">
                        <span>{{item?.lay[0]?.price || '-'}}</span>
                        <small>{{item?.lay[0]?.size}}</small>
                    </div>
                    <div class="lay" *ngIf="matchData?.id.endsWith('_SB')"
                        (click)="openBetSlip(item?.lay[0]?.price,i,false)" [ngClass]="{
                        'color-change': applyLayAnimation[i],'isBettable': !matchData?.isBettable,
                        'lay-disable':matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN',
                        'bet-selected-button':betHelper.selectedCard =='match' && betHelper.selectedButton == matchData?.id + '_' + item.id + '_' + 'lay'
                }">
                        <span>{{calculatePrice(item?.lay[0]?.price)}}</span>

                    </div>
                </div>
                <div class="back-lay-detail"
                    *ngIf="matchData?.status==='OPEN' && matchData?.id.endsWith('.340._BR') && matchData?.name!=='To Win The Toss'">
                    <div class="back" *ngIf="!matchData?.id.endsWith('_SB')"
                        (click)="openBetSlip(item?.back[0]?.price,i,true)" [ngClass]="{
                        'color-change': applyBackAnimation[i],'isBettable': !matchData?.isBettable,
                        'back-disable':matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN',
                        'bet-selected-button':betHelper.selectedCard =='match' && betHelper.selectedButton == matchData?.id + '_' + item.id + '_' + 'back'
                }">
                        <span>{{item?.back[0]?.price || '-'}}</span>
                    </div>
                    <div class="back" *ngIf="matchData?.id.endsWith('_SB')"
                        (click)="openBetSlip(item?.back[0]?.price,i,true)" [ngClass]="{
                        'color-change': applyBackAnimation[i],'isBettable': !matchData?.isBettable,
                        'back-disable':matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN',
                        'bet-selected-button':betHelper.selectedCard =='match' && betHelper.selectedButton == matchData?.id + '_' + item.id + '_' + 'back'
                }">
                        <span>{{calculatePrice(item?.back[0]?.price)}}</span>
                    </div>
                </div>
                <div class="back-lay-detail"
                    *ngIf="matchData?.status==='OPEN'&& matchData?.id.endsWith('_SB') && matchData?.name==='To Win The Toss'">
                    <div class="back" *ngIf="matchData?.id.endsWith('_SB')"
                        (click)="openBetSlip(item?.back[0]?.price,i,true)" [ngClass]="{
                        'color-change': applyBackAnimation[i],'isBettable': !matchData?.isBettable,
                        'back-disable':matchData?.statusLabel==='Ball Running' && matchData?.status ==='OPEN',
                        'bet-selected-button':betHelper.selectedCard =='match' && betHelper.selectedButton == matchData?.id + '_' + item.id + '_' + 'back'
                }">
                        <span>{{calculatePrice(item?.back[0]?.price)}}</span>
                    </div>
                </div>
                <div class="suspended" *ngIf="matchData?.status==='SUSPENDED'|| matchData?.status!=='OPEN'">
                    <span style="color: rgba(255, 255, 255, 0.40);">{{'SUSPENDED'|translate}}</span>
                </div>
            </div>
            <app-bet-slip-card style="width: 100%;" (closeBetSlip)="closeBetSlip()" [eventId]="matchData?.event?.id"
                [index]="i" [matchData]="matchData" [betData]="betData"
                *ngIf="betHelper.cardId+ '_' + betHelper.itemId == matchData?.id + '_' + item.id "></app-bet-slip-card>
        </ng-container>
    </ng-container>

</div>
