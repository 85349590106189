import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class LiveMatchHelperService {

  constructor(private _http: HttpClient) { }

  // matchSummeryData: any;
  // footballMatchSummaryData: any;
  // tennisMatchSummaryData: any;

  // getCricktMatchData(matchTypeId: number, compitionId: number) {
  //   return this._http.get(`https://9xzfo.com/api/public/exchange/odds/group/${matchTypeId}/${compitionId}?includeOutrights=true&includeToQualify=true`);
  // }

  // getFootballData(matchTypeId: number, compitionId: number) {
  //   return this._http.get(`https://9xzfo.com/api/public/exchange/odds/sma-event/${matchTypeId}/${compitionId}`);
  // }

}
