import { Component, OnInit } from '@angular/core';
import { SportsbookService } from 'src/app/services/sportsbook.service';
import {AuthService} from '../../services/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
declare const BTRenderer:any;
declare var $:any;
@Component({
  selector: 'app-sportsbook',
  templateUrl: './sportsbook.component.html',
  styleUrls: ['./sportsbook.component.scss']
})
export class SportsbookComponent implements OnInit {
  BTrendererVar:any;
  istoken:any;
  signinshowhide: any = '';
  url: any;

  constructor(private loginshow: SportsbookService,public router: Router,private authService: AuthService) {
    if (this.authService.currentUserValue==null){
      this.istoken=true;
   }
   else{
     this.istoken=false;
   }
  }

  ngOnInit(): void {
       this.url= this.router.url;
       const gameId=localStorage.getItem('gameId');
       if(gameId){
         this.url=`/${gameId}`;
       }else{
        this.url='/';
       }
  }

ngAfterViewInit(){
  if(this.getToken()){
    this.sportBookOpen();
       }
   else{
    this.BTrendererVar = new BTRenderer().initialize({
      brand_id: '1928062748156104704',
      key: null,
      //token: `${this.getToken()}`,
      themeName: 'default',
      lang: 'en',
      target: document.getElementById('betby-na'),
        url: this.url,
      // minFrameHeight: 700,
      // betSlipOffsetTop: 0,
      // betslipZIndex: 999,
      cssUrls: [
        'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
      ],
      fontFamilies: ['Montserrat, sans-serif', 'Roboto, sans-serif'],

      onRouteChange: function() {
        //console.log('Route changed')
      },
      // callback to recharge
      onRecharge: () => {
        // this.BTrendererVar.kill()
        //console.log('Recharge changed')
      },
      // callback to sign in
      onLogin: () => {
        // dispatch(setLoginNew(true));

        if(localStorage.getItem('ROYALTOKEN_KEY')){
          return

        }else{

          this.loginshow.signinshowhide.next(false);
        }

        //console.log('Login changed')
        this.BTrendererVar.kill()
      },
      onOutcomeClick:()=>{
        //console.log("onoutcome");

      },
      // callback to sign up
      onRegister: () => {
        // dispatch(setLoginNew(true))
        // dispatch(setTab(2))
        //console.log('Signup changed')
      },
      onSessionRefresh: () => {
        this.BTrendererVar.kill()
        // history.push('/default-page')
        // initSportsbook();
      },
      // callback to change url when user clicks on event link
      goToClick: args => {
        //console.log('Click changed')
      },

      widgetParams: {
        // placeholder name from backoffice
        // placeholder: "placeholder_name",
        // authentication is needed for this widget
        // withAuthentication: false


      },
    })


  $('#betby-na button').click(function(){

  })
   }
}
getToken(){
  let currentUser = '';
        if(localStorage.getItem('ROYALTOKEN_KEY')){
          currentUser=JSON.parse(localStorage.getItem('ROYALTOKEN_KEY'));
        }
        //console.log('Inside getToken of currentUser:' + JSON.stringify(currentUser));
  return  currentUser;
}
sportBookOpen(){
  this.authService.openSportbook().subscribe(
    res => {
      this.BTrendererVar = new BTRenderer().initialize({
        brand_id: '1928062748156104704',
        key: res?.result?.frame_key,
        //token: `${this.getToken()}`,
        themeName: 'default',
        lang: 'en',
        target: document.getElementById('betby-na'),
          url: this.url,
        // minFrameHeight: 700,
        // betSlipOffsetTop: 0,
        // betslipZIndex: 999,
        cssUrls: [
          'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
        ],
        fontFamilies: ['Montserrat, sans-serif', 'Roboto, sans-serif'],

        onRouteChange: function() {
          //console.log('Route changed')
        },
        // callback to recharge
        onRecharge: () => {
          // this.BTrendererVar.kill()
          this.router.navigate(['/balancemanagement/Deposit']);
          //console.log('Recharge changed')
        },
        // callback to sign in
        onLogin: () => {
          // dispatch(setLoginNew(true));

          if(localStorage.getItem('ROYALTOKEN_KEY')){
            this.BTrendererVar.kill()
            return

          }else{

            this.loginshow.signinshowhide.next(false);
          }

          //console.log('Login changed')
          this.BTrendererVar.kill()
        },
        onOutcomeClick:()=>{
          //console.log("onoutcome");

        },
        // callback to sign up
        onRegister: () => {
          // dispatch(setLoginNew(true))
          // dispatch(setTab(2))
          //console.log('Signup changed')
        },
        onSessionRefresh: () => {
          this.BTrendererVar.kill()
          // history.push('/default-page')
          // initSportsbook();
        },
        // callback to change url when user clicks on event link
        goToClick: args => {
          //console.log('Click changed')
        },

        widgetParams: {
          // placeholder name from backoffice
          // placeholder: "placeholder_name",
          // authentication is needed for this widget
          // withAuthentication: false


          }
      })


    $('#betby-na button').click(function(){
      alert("hello");
    })
    },
    error => {
    }
  );
}

ngOnDestroy(): void {
   if(this.BTrendererVar!=undefined){
this.BTrendererVar.kill();
   }
    localStorage.removeItem('gameId');
}

}
