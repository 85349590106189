import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { CasinoService } from 'src/app/services/casino.service';
import { LandingService } from 'src/app/services/landing.service';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MyprofileService } from "../../services/myprofile.service";
import { BonusService } from '../bonuses-cards/bonus.service';
import { Subscription } from 'rxjs';
import FingerprintJS from "@fingerprintjs/fingerprintjs"
import { InPlayEvent, inPlayAndPopularEvents } from '../live/inPlayAndPopularEvents.interface';
declare var $: any

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss'],
})
export class LandingpageComponent implements OnInit, AfterViewInit, OnDestroy {
  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @Input() isLogin: boolean = false;
  isLoginCheck = false;
  isLoader = false;
  showbet: any = false;
  istoken: any;
  public interval300: any;
  itr: number = 0;
  Exposure: any;
  value: any; //cricket data
  public aList: any = [];
  public previousAList: any = [];
  allgames: any = [];
  tennapatti: any = [];
  livecasino: any = [];
  gameId: any;
  sliderlConfig = {
    slidesToShow: 1,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          // dots: true
        }
      }

    ]
  };
  gamepath = environment.gamespath;
  gameCategory: any = [];
  teenaPattiData: any = [];
  teenaPattiGameData: any = [];
  trendingGameData: any = [];
  trendingGameData1: any = [];
  livecasinogamesshow: any = [];
  isActivefav: any = true;
  favouriteGamelistcasino: any = [];
  eventName: String;
  casinoUrl: string;
  marketName: String;
  runner: string;
  price: string
  sliderImage: any;
  Images: any;
  filteredArray: any;
  urlIframe: string;
  public yt: any;
  @ViewChild('iframeLoad', { static: false }) iframeLoad: ElementRef;
  isIphoneChrome = (navigator.userAgent.includes("iPhone") && navigator.userAgent.includes('CriOS'));
  isIphoneSafari = (navigator.userAgent.includes("iPhone") && !navigator.userAgent.includes('CriOS'));
  // @ts-ignore
  isSafariPWA = navigator?.standalone
  title: string
  isBonus: boolean;
  promoItemInfo: any;
  balnceamout: any;
  bonusamout: any;
  sportamout: any;
  casinoamount: any;
  typeWallet: any;
  walletLoading = false
  infoWalletType = '';
  showVideo1 = false;
  showVideo2 = false;
  showVideo3 = false;
  showVideo4 = false;
  isMobile: boolean = false;
  private urlIframeSubscription: Subscription;
  matchesWithBr = [];  //sorted live matches whose market id has .BR at the end.
  matchesWithoutBr = []; //sorted live matches whose does not have market id .BR at the end.
  prevMatchesWithBr = [];
  prevMatchesWithoutBr = [];
  openBetSlipId: any;
  betData: any;
  matchData: any;
  politicsData: InPlayEvent[];
  cricketData: InPlayEvent[];
  footballData: InPlayEvent[];
  tennisData: InPlayEvent[];

  //new home variables
  allSportOptArray = [
    { name: 'Cricket', img: '../../../../assets/images/menu-cricket.png', navigate: 'c' },
    { name: 'Tennis', img: '../../../../assets/images/menu-tennis.svg', navigate: 't' },
    { name: 'Football', img: '../../../../assets/images/menu-sports-option.svg', navigate: 'f' },
    { name: 'Horse Racing', img: '../../../../assets/images/menu-horse-racing.svg', navigate: 'horseRacing' },
    { name: 'Greyhound Racing', img: '../../../../assets/images/menu-greyhord-racing.svg', navigate: 'greyhoundRacing' },
    { name: 'Basket Ball', img: '../../../../assets/images/menu-basketBall.svg', navigate: 'basketball-2' },
    { name: 'Table Tennis', img: '../../../../assets/images/menu-tableTennis.png', navigate: 'tennis-5' },
    { name: 'Kabaddi', img: '../../../../assets/images/menu-kabaddi.png', navigate: 'kabaddi-138' },
    { name: 'Rugby', img: '../../../../assets/images/menu-rugby.svg', navigate: 'rugby-12' },
    { name: 'Boxing', img: '../../../../assets/images/menu-boxing.svg', navigate: 'boxing-10' },
  ];

  fireLottieOptions: any = {
    path: 'assets/static/media/fire.json',
    autoplay: true,
    loop: true
  };

  constructor(private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private casinoServices: CasinoService,
    private faverate: LandingService,
    private router: Router,
    private _landingService: LandingService,
    public myprofileServices: MyprofileService, public _bonusService: BonusService
  ) {
    if (this.authService.currentUserValue == null) {
      this.istoken = true;
    }
    else {
      this.istoken = false;
    }
  }

  toggleVideo1() {
    this.showVideo1 = !this.showVideo1;
  }
  toggleVideo2() {
    this.showVideo2 = !this.showVideo2;
  }
  toggleVideo3() {
    this.showVideo3 = !this.showVideo3;
  }
  toggleVideo4() {
    this.showVideo4 = !this.showVideo4;
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    // const startTime = performance.now();
    if (this.getToken()) {
      this._bonusService.getAllBonuses('alive');
    }
    this.urlIframeSubscription = this.casinoServices.casinoURLObservable$.subscribe((url: string | null) => {
      if (!url && this.urlIframe) {
        this.clearIframe()
      }
      if (url) {
        this.openGame(this.gameId ? url + '?opentable=' + this.gameId : url)
      }
      this.urlIframe = url;
    })
    this.checkIfMobile();

    // this.getUpcomingData(4);
    this._landingService.sliderimage().subscribe((res: any) => {
      this.sliderImage = res;
      this.Images = res.data || [];
      this.filteredArray = res?.result?.inPlaySlidingBanners;
      this.trendingGameData = res?.result?.trendingGames?.sort((a: any, b: any) => a?.index - b?.index);
    })

    this.authService.getEventState().subscribe(async (res: inPlayAndPopularEvents) => {
      const aList = res?.result?.inPlayEvents || [];
      const filteredEvents = res?.result?.inPlayEvents?.filter(data => ["1", "2", "4", "2378962"].includes(data?.eventTypeId));
      this.cricketData = filteredEvents?.filter(data => data?.eventTypeId === "4");
      this.footballData = filteredEvents?.filter(data => data?.eventTypeId === "1");
      this.tennisData = filteredEvents?.filter(data => data?.eventTypeId === "2");
      this.politicsData = filteredEvents?.filter(data => data?.eventTypeId === "2378962");

      const isBR = (event: InPlayEvent) => event?.market?.id?.endsWith('._BR');

      this.aList = aList
      this.matchesWithBr = this.cricketData?.filter(isBR);
      this.matchesWithoutBr = this.cricketData?.filter((event) => !isBR(event));



      if (!this.previousAList || !this.previousAList.length) {
        this.previousAList = this.cricketData;
      }

      if (!this.prevMatchesWithBr || !this.prevMatchesWithBr.length) {
        this.prevMatchesWithBr = this.cricketData?.filter(isBR);
      }

      if (!this.prevMatchesWithoutBr || !this.prevMatchesWithoutBr.length) {
        this.prevMatchesWithoutBr = this.cricketData?.filter((event) => !isBR(event));
      }

      setTimeout(() => {
        this.prevMatchesWithBr = this.cricketData?.filter(isBR);
        this.prevMatchesWithoutBr = this.cricketData?.filter((event) => !isBR(event));
        this.previousAList = this.cricketData;
      }, 100);

      const casinoData = await res?.result?.inPlayEvents?.filter(data => ['77777', '1444001'].includes(data?.eventTypeId));
      this.gameCategory = casinoData?.filter(data => data?.eventTypeId === '77777');
      this.teenaPattiData = casinoData?.filter(data => data?.eventTypeId === '1444001');
    });

    this.getvkbetmarketdata();
    // this.casinogame();
    this.livecasinogame();
    // this.teenLiveGame();
    //  this.favouriteGame(null,null);
    document.body.classList.remove('openpopup');
    this.teenPattiGame();
    // this.trendingGame();
    this.myprofileServices.getBalanceData().subscribe((data) => {
      this.balnceamout = data?.result;
      this.sportamout = data?.result?.sWallet;
      this.bonusamout = data?.result?.bWallet;
      this.casinoamount = data?.result?.cWallet;
      if (data?.result?.useBWallet == true) {
        this.typeWallet = 'B';
        this.isBonus = true
      } else if (data?.result?.useSWallet == true) {
        this.typeWallet = 'S';
        this.isBonus = true
      } else if (data?.result?.useCWallet == true) {
        this.typeWallet = 'C';
        this.isBonus = true
      } else {
        this.typeWallet = 'M';
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 1024;
  }

  trackByFn(index: number, item: any): any {
    return item?.market?.id;
  }

  ngAfterViewInit() {


    const slider: HTMLElement = document.querySelector('.flexbox');
    let mouseDown = false;
    let startX, scrollLeft;

    let startDragging = (e) => {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    let stopDragging = function (event) {
      mouseDown = false;
    };

    slider?.addEventListener('mousemove', (e) => {
      e.preventDefault();
      if (!mouseDown) { return; }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    // Add the event listeners
    slider?.addEventListener('mousedown', startDragging, false);
    slider?.addEventListener('mouseup', stopDragging, false);
    slider?.addEventListener('mouseleave', stopDragging, false);

  }

  ngOnChanges(changes) {
    if (changes.isLogin !== this.isLoginCheck) {
      this.isLoginCheck = changes.isLogin;
    }
  }

  showBonuses() {
    this._bonusService.showAllBonuses = true;
  }





  getvkbetmarketdata() {

    this.authService.geteventdata().subscribe(
      res => {
        // debugger;
        // this.aList = res.result.inPlayEvents;
        // if(!this.previousAList || !this.previousAList.length){
        //   this.previousAList = res.result.inPlayEvents;
        //   }
        // setTimeout(() => {
        //   this.previousAList = res.result.inPlayEvents;
        // }, 100)
      },
      error => {
      }
    );
    if (this.interval300) {
      clearInterval(this.interval300);
    }
    if (this.getToken()) {
      this.interval300 = setTimeout(() => {
        this.getvkbetmarketdata(); this.itr++;
      }, 1000)
    }
    else {
      this.interval300 = setTimeout(() => {
        this.getvkbetmarketdata(); this.itr++;
      }, 10000)
    }
  }

  // casinogame() {

  //   const casinocat = {
  //     "casino_cat_ids": ["51", "52", "53", "54", "55", "56", "57"]
  //   }
  //   this.casinoServices.casinogames(casinocat).subscribe((res: any) => {
  //     // debugger;
  //     this.allgames = res.data;
  //     for (const games of this.allgames) {
  //       this.gameCategory.push(games)
  //       this.gameCategory = this.gameCategory.slice(0, 10);
  //     }
  //   })
  // }

  teenPattiGame() {
    this.casinoServices.teenpattiBanner().subscribe((res: any) => {
      // debugger;
      if (res?.result?.popularBanners) {
        this.allgames = res?.result?.popularBanners;

        for (const games of this.allgames) {
          this.teenaPattiGameData.push(games)
          this.gameCategory = this.gameCategory?.slice(0, 10);
        }
      }
    })
  }

  // trendingGame() {
  //   this.casinoServices.getTrendingGame().subscribe((res: any) => {
  //     this.allgames = res?.data;
  //     let i = 0;
  //     console.log(res, 171);
  //     for (const games of this.allgames) {
  //       if (i <= 3) {
  //         this.trendingGameData.push(games);
  //       }
  //       else {
  //         this.trendingGameData1.push(games);
  //       }
  //       i++;
  //       //this.gameCategory = this.gameCategory.slice(0, 10);

  //     }
  //   })
  // }
  livecasinogame() {

    const casinocat = {
      "casino_cat_ids": ["11", "12", "13", "14", "15", "16", "17", "18", "19", "20"]

    }
    this.casinoServices.casinogames(casinocat).subscribe((res: any) => {

      this.livecasino = res.result;
      for (const games of this.livecasino) {
        this.livecasinogamesshow.push(games)
        this.livecasinogamesshow = this.livecasinogamesshow.slice(0, 10);

      }

    })


  }


  // teenLiveGame() {

  //   const casinocat = {
  //     "casino_cat_ids": ["51", "52", "53", "54", "55", "56", "57"]

  //   }
  //   this.casinoServices.liveGame().subscribe((res: any) => {

  //     this.tennapatti = res.data;

  //     for (const games of this.tennapatti) {
  //       this.teenaPattiData.push(games)
  //       this.teenaPattiData = this.teenaPattiData.slice(0, 10);

  //     }

  //   })

  // }

  favouriteGame(selectedgames: any, imageurl: any) {
    var gamesl = localStorage.getItem("gamelist");
    if (gamesl == null) {
      this.isActivefav = selectedgames;
      this.casinoServices.favouritegame(imageurl);
      this.favouriteGamelistcasino = this.casinoServices.equipment;
      this.faverate.favouriteimage.next(this.favouriteGamelistcasino);
    } else if (gamesl.includes(imageurl)) {
      const list = this.casinoServices.equipment;
      const index = list.indexOf(imageurl);
      this.casinoServices.equipment.splice(index, 1);
      // this.casinoServices.favouritegame(list);
      localStorage.setItem("gamelist", JSON.stringify(this.casinoServices.equipment));
      this.favouriteGamelistcasino = this.casinoServices.equipment;
      this.faverate.favouriteimage.next(this.favouriteGamelistcasino);

      // }




    } else {


      this.isActivefav = selectedgames;

      // this.favouriteGamelistcasino.push(imageurl);

      this.casinoServices.favouritegame(imageurl);
      this.favouriteGamelistcasino = this.casinoServices.equipment;
      this.faverate.favouriteimage.next(this.favouriteGamelistcasino);



    }

  }
  firsttimeData: any;



  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }

  goToGame(game: any) {
    console.log('game', game);
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = ''
    }
    if (game) {
      this.title = game?.name || game?.Trans?.en
    }

    if (document.getElementById("close_bet")) {
      document.getElementById("close_bet").click();
    }

    if (this.getToken()) {
      const data = {
        "id": game?.event?.id,
        "type": game?.eventType
      };
      this.casinoServices.getliveGameUrl(data).subscribe((res: any) => {
        console.log('res', res);
      })
    }

  }

  async getLiveGame(Id, game) {
    this.gameId = Id
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = ''
    }
    if (game) {
      this.title = game?.name
    }
    console.log('Inside getLiveGame of predictor value of Id is' + Id);
    if (document.getElementById("close_bet")) {
      document.getElementById("close_bet").click();
    }
    if (this.getToken()) {
      const data = {
        "id": Id,
        "type": "FAWK"
      };
      await this.casinoServices.getliveGameUrl(data).subscribe((res: any) => {
        let url;
        url = res.success + '?opentable=' + Id;
        this.urlIframe = res.success + '?opentable=' + Id;
        this.openGame(url)
      })
    }
    else {
      if (Id === '67722-2') {
        FingerprintJS.load().then((fp) => {
          fp.get().then(async (result) => {
            const generatedToken = result.visitorId; // Assuming visitorId is the generated token from FingerprintJS
            this.urlIframe = await this.casinoServices.getliveGameUrl(`https://games.fawk.app/${generatedToken}/6666`, true)
            // this.urlIframe = `https://games.fawk.app/${generatedToken}/6666`;
            this.openGame(this.urlIframe);
          });
        });
      } else if (Id === '677222') {
        FingerprintJS.load().then((fp) => {
          fp.get().then(async (result) => {
            const generatedToken = result.visitorId; // Assuming visitorId is the generated token from FingerprintJS
            // this.urlIframe = `https://games.fawk.app/${generatedToken}/6666/67722-2`;
            this.urlIframe = await this.casinoServices.getliveGameUrl(`https://games.fawk.app/${generatedToken}/6666/677222`, true)

            this.openGame(this.urlIframe);
          });
        });
      } else {
        document.getElementById("demo").click();
      }

    }
  }

  getLiveCasino(Id, game) {
    this.gameId = Id
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = ''
    }
    if (game) {
      this.title = game?.name
    }
    console.log('Inside getLiveGame of predictor value of Id is' + Id);
    if (document.getElementById("close_bet")) {
      document.getElementById("close_bet").click();
    }
    if (this.getToken()) {
      const data = {
        "id": Id,
        "type": "FAWK"
      };
      this.casinoServices.getliveGameUrl(data).subscribe((res: any) => {
        // let url;
        // url = res.success + '?opentable=' + Id;
        // this.urlIframe = res.success + '?opentable=' + Id;
        // this.openGame(url)
      })
    }
    else {
      if (Id === '67722-1') {
        FingerprintJS.load().then((fp) => {
          fp.get().then(async (result) => {
            const generatedToken = result.visitorId; // Assuming visitorId is the generated token from FingerprintJS
            this.urlIframe = await this.casinoServices.getliveGameUrl(`https://games.fawk.app/${generatedToken}/6666`, true)
            // this.urlIframe = `https://games.fawk.app/${generatedToken}/6666`;
            this.openGame(this.urlIframe);
          });
        });
      } else if (Id === '67722-2') {
        FingerprintJS.load().then((fp) => {
          fp.get().then(async (result) => {
            const generatedToken = result.visitorId; // Assuming visitorId is the generated token from FingerprintJS
            // this.urlIframe = `https://games.fawk.app/${generatedToken}/6666/67722-2`;
            this.urlIframe = await this.casinoServices.getliveGameUrl(`https://games.fawk.app/${generatedToken}/6666/67722-2`, true)

            this.openGame(this.urlIframe);
          });
        });
      } else {
        document.getElementById("demo").click();
      }

    }
  }

  getGameShow(Id, game = null) {
    this.gameId = null
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = ''
    }
    if (game) {
      this.title = game?.name
    }
    //console.log('Inside getLiveGame of predictor value of Id is' + Id);
    if (document.getElementById("close_bet")) {
      document.getElementById("close_bet").click();
    }
    if (this.getToken()) {
      const data = {
        "id": Id,
        "type": game?.provider
      };
      this.casinoServices.getCasinoUrl(Id).subscribe((res: any) => {
        // this.casinoUrl = res.result.url;
        //  console.log("insidde getPNl value of res:" +- JSON.stringify(this.casinoUrl));
        // this.urlIframe = res.result.url;
        // this.openGame(res.result.url)
      })
    }
    else {
      document.getElementById("demo").click();
    }

  }
  openGame(iurl) { // INIT IFRAME
    // this.loaderShow = false;
    console.log('Inside openCasino of casino component');
    this.yt = '<iframe  scrolling="auto"  style="width: 100%; height: 100%" class="exchange-iframe" src="' + iurl + '" frameborder="0" id="casino-fram" allow="autoplay; encrypted-media"  allowfullscreen></iframe>';
    this.iframeLoad.nativeElement.innerHTML = this.yt;
  }
  clearIframe() {
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = ''
    }
    this.title = ""
    this.urlIframe = ""
    this.casinoServices.clearCasinoURL()
  }

  ngOnDestroy(): void {
    clearInterval(this.interval300);
    this.urlIframeSubscription.unsubscribe();
  }

  async changeWallet(type: any) {
    this.walletLoading = true
    const disableBonus = (type === "M" || this.typeWallet === type) ? false : true
    // const wallet = type === "M" ? this.typeWallet : type
    this.myprofileServices.bonusWallet(disableBonus, type).subscribe(res => {
      this.myprofileServices.balanceamount()
      setTimeout(() => {
        this.walletLoading = false
      }, 500)
    }, error => {
    }
    );
    localStorage.setItem("bonus", type)
  }
  closeInfo() {
    this.promoItemInfo = null
  }
  //new home functions
  navigateToSportBook(item: string) {
    const containsNumber = /\d/.test(item);
    if (item == 'f') {
      localStorage.removeItem('sportsId');
      this.authService.sportsId = '1';
      this.authService.updateSportsId(this.authService.sportsId);
      localStorage.setItem('sportsId', this.authService.sportsId);
      this.router.navigate(['/shared/sport/']);

    } else if (item === 't') {
      localStorage.removeItem('sportsId');
      this.authService.sportsId = '2';
      localStorage.setItem('sportsId', this.authService.sportsId);
      this.authService.updateSportsId(this.authService.sportsId);
      this.router.navigate(['/shared/sport/']);

    } else if (item === 'c') {
      localStorage.removeItem('sportsId');
      this.authService.sportsId = '4';
      localStorage.setItem('sportsId', this.authService.sportsId);
      this.authService.updateSportsId(this.authService.sportsId);
      this.router.navigate(['/shared/sport/']);

    } else if (item == 'horseRacing') {
      this.authService.sportsId = '7';
      this.authService.updateSportsId(this.authService.sportsId);
      this.router.navigate(['/shared/sport']);
    } else if (item == 'greyhoundRacing') {
      this.authService.sportsId = '4339';
      this.authService.updateSportsId(this.authService.sportsId);
      this.router.navigate(['/shared/sport']);
    } else if (!containsNumber) {
      this.router.navigate([item]);
      localStorage.setItem('gameId', item);

    } else {
      this.router.navigate(['/shared/sportsbook']);
      localStorage.setItem('gameId', item);

    }
  }
  openBetSlip(event: any, item: any) {
    this.betData = event;
    this.openBetSlipId = event?.eventId;
    this.matchData = item;
    this.cdr.detectChanges();
  }
  scrollLeft() {
    const container = this.scrollContainer.nativeElement;
    container.scrollBy({
      left: -100,
      behavior: 'smooth',
    });
  }
  scrollRight() {
    const container = this.scrollContainer.nativeElement;
    container.scrollBy({
      left: 100,
      behavior: 'smooth',
    });
  }
}
