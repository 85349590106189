import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-popup',
  templateUrl: './forgot-popup.component.html',
  styleUrls: ['./forgot-popup.component.scss']
})
export class ForgotPopupComponent implements OnInit {


  @Output() closeClick: EventEmitter<any> = new EventEmitter();
  @Input() title = "";

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.closeClick
  }


  closeClicked() {
    this.closeClick.emit(true);
    //navigate to home page
    window.location.reload();
  }

}
