<div class="overlay" *ngIf="isLoader===true">
    <div class="loader-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <!-- <img loading="lazy" class="loader1" src="../../../assets/images/loader-gif.gif" alt="Your Image"> -->
        <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>

    </div>
</div>
<div class="layout-content-holder-bc">
    <div class="sport-section">
        <div class="horizontal-sport-section">
            <ng-container *ngFor="let item of allSportOptArray; let index=index">
                <div class="sport-card" (click)="navigateToSportBook(item?.navigate)" [ngClass]="{
                    'cricket':isActiveCricket && index == 0,
                    'football':isActiveFootball&& index == 2,
                    'tennis':isActiveTennis && index == 1,
                    'horse':isActivehorse && index == 3,
                    'racing':isActivegreyhound && index == 4}">
                    <div class="cricket-icon">
                        <img src={{item.img}} alt={{item.name}}>
                    </div>
                    <span class="sport-text">{{item.name|translate}}</span>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="sports-section"
        [ngClass]="{'matchCard-greyhound-border':isActivegreyhound, 'matchCard-horse-border':isActivehorse}">
        <ng-container
            *ngIf="(isActiveCricket || isActiveTennis || isActiveFootball) && (inPlayEvents$ | async)?.length > 0">
            <div class="cricket-card-head"
                [ngClass]="{'cricket-card-bg':isActiveCricket,'tennis-card-bg':isActiveTennis,'football-card-bg':isActiveFootball}">
                <div class="one"
                    [ngClass]="{'one-cricket-bg':isActiveCricket,'one-tennis-bg':isActiveTennis,'one-football-bg':isActiveFootball}">
                </div>
                <div class="cricket-2"
                    [ngClass]="{'cricket-2-bg':isActiveCricket,'tennis-2-bg':isActiveTennis,'football-2-bg':isActiveFootball}">
                </div>
                <div class="card-content-cricket">
                    <img *ngIf="isActiveCricket" class="icon-cricket" src="../../../assets/images/menu-cricket.png"
                        alt="cricket">
                    <img *ngIf="isActiveFootball" class="icon-cricket"
                        src="../../../assets/images/menu-sports-option.svg" alt="football">
                    <img *ngIf="isActiveTennis" class="icon-cricket" src="../../../assets/images/menu-tennis.svg"
                        alt="tennis">
                    <span class="inplay">{{'In Play'|translate}}</span>
                    <div class="count-bet">
                        <span>{{(inPlayEvents$ | async)?.length}}</span>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="(isActivehorse || isActivegreyhound) && this.inPlayRaces?.length > 0">
            <div class="cricket-card-head"
                [ngClass]="{'horse-racing-bg':isActivehorse, 'greyhond-card-bg':isActivegreyhound}">
                <div class="one" [ngClass]="{'one-horse-bg':isActivehorse, 'one-greyhond-bg ':isActivegreyhound}">
                </div>
                <div class="cricket-2" [ngClass]="{'horse-2-bg':isActivehorse, 'greyhound-2-bg':isActivegreyhound}">
                </div>
                <div class="card-content-cricket">
                    <img *ngIf="isActivehorse" class="icon-cricket" src="../../../assets/images/menu-horse-racing.svg"
                        alt="horse">
                    <img *ngIf="isActivegreyhound" class="icon-cricket"
                        src="../../../assets/images/menu-greyhord-racing.svg" alt="racehorse">
                    <span class="inplay">{{'In Play'|translate}}</span>
                    <div class=" count-bet">
                        <span>{{this.inPlayRaces?.length}}</span>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="isActiveCricket">
            <ng-container *ngFor="let item of inPlayEvents$ | async; trackBy:trackByFn;let i=index">
                <app-cricket-card class="cricketComp" [isLastItem]="i === (inPlayEvents$ | async).length - 1"
                    [showOdds]="true" [data]="item"></app-cricket-card>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="isActiveTennis">
            <ng-container *ngFor="let item of inPlayEvents$ | async; let i = index">
                <app-tennis-card style="width: 100%;" [showOdds]="true" [data]="item"></app-tennis-card>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="isActiveFootball">
            <ng-container *ngFor="let item of inPlayEvents$ | async; let i = index">
                <app-football-card style="width: 100%;" [showOdds]="true" [data]="item"></app-football-card>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="isActivehorse || isActivegreyhound">
            <ng-container *ngFor="let item of upcomingRaces$ | async; let i = index">
                <ng-container *ngIf="item.isBettable">
                    <div (click)="setDetailspageData(item)" class="football-card-body"
                        routerLink="/shared/details/{{item?.id}}/{{authService.sportsId}}">
                        <span><small>{{item?.startTime | date:"HH:mm"}}</small> {{item?.venue}}
                            ({{item?.countryCode}})</span>
                        <div class="line-hr"></div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

    </div>
    <div class="upcoming-match-section">
        <button (click)="toggleUpcoming(true)" [ngClass]="{'active':isUpcoming}">
            <span>{{'Upcoming'|translate}}</span>
        </button>
        <button (click)="toggleUpcoming(false)" [ngClass]="{'active':!isUpcoming}">
            <span>{{'Leagues'|translate}}</span>
        </button>
    </div>
    <ng-container *ngIf="isUpcoming">
        <ng-container *ngIf="authService.sportsId==4 ||authService.sportsId==2 ||authService.sportsId==1">
            <ng-container *ngFor="let group of upcomingEvents$ | async;trackBy:trackByFn">
                <div class="match-card upcoming-section" *ngIf="group[0].runners.length < 3">
                    <div class="cricket-card-head"
                        [ngClass]="{'cricket-card-bg':isActiveCricket,'tennis-card-bg':isActiveTennis,'football-card-bg':isActiveFootball}">
                        <div class="one"
                            [ngClass]="{'one-cricket-bg':isActiveCricket,'one-tennis-bg':isActiveTennis,'one-football-bg':isActiveFootball}">
                        </div>
                        <div class="cricket-2"
                            [ngClass]="{'cricket-2-bg':isActiveCricket,'tennis-2-bg':isActiveTennis,'football-2-bg':isActiveFootball}">
                        </div>
                        <div class="card-content-cricket">
                            <img *ngIf="isActiveCricket" class="icon-cricket"
                                src="../../../assets/images/menu-cricket.png" alt="cricket">
                            <img *ngIf="isActiveFootball" class="icon-cricket"
                                src="../../../assets/images/menu-sports-option.svg" alt="football">
                            <img *ngIf="isActiveTennis" class="icon-cricket"
                                src="../../../assets/images/menu-tennis.svg" alt="tennis">
                            <span class="detailsofmatch">{{group[0].event.openDate | customDate}}</span>
                        </div>
                    </div>
                    <div class="team-section" *ngFor="let data of group"
                        routerLink="/shared/details/{{data?.event?.id}}/{{data?.eventTypeId}}">
                        <div class="team-card">
                            <div class="team-body">
                                <div class="score-detail">
                                    <div class="over">
                                        <img src="../../../../assets/images/clock-match.svg" alt="clock">
                                    </div>
                                    <div class="divider"
                                        [ngClass]="{'cricket-div': authService.sportsId == '4', 'tennis-div':authService.sportsId=='2','football-div':authService.sportsId=='1'}">
                                    </div>
                                    <div class="team-name">
                                        <div class="match-name">
                                            <div class="first-team">
                                                <span>{{data?.event?.name | teamNameSplit : 'first'}}</span>
                                            </div>
                                            <div class="second-team">
                                                <span>{{data?.event?.name | teamNameSplit : 'second'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="live">
                                    <img class="tv" src="../../../../assets/images/tv-icon.svg" alt="tv">
                                    <div class="time">
                                        <span>{{getMatchDate(data.event.openDate)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="line-hr">
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="authService.sportsId==7 ||authService.sportsId==4339">
            <ng-container *ngFor="let group of upcomingRaces$ | async;trackBy:trackByFn">
                <ng-container *ngIf="(upcomingRaces$ |async).length>=0">
                    <div class="match-card upcoming-section" (click)="setDetailspageData(group)"
                        routerLink="/shared/details/{{group?.id}}/{{authService.sportsId}}">
                        <div class="cricket-card-head"
                            [ngClass]="{'horse-racing-bg matchCard-horse-1-border':isActivehorse, 'greyhond-card-bg matchCard-greyhound-1-border':isActivegreyhound}">
                            <div class="one"
                                [ngClass]="{'one-horse-bg matchCard-horse-2-border':isActivehorse, 'one-greyhond-bg matchCard-greyhound-2-border':isActivegreyhound}">
                            </div>
                            <div class="cricket-2"
                                [ngClass]="{'horse-2-bg':isActivehorse, 'greyhound-2-bg':isActivegreyhound}">
                            </div>
                            <div class="card-content-cricket">
                                <!-- <img *ngIf="isActiveCricket" src="../../../assets/images/menu-cricket.png" alt="cricket"> -->
                                <img *ngIf="isActivehorse" class="icon-cricket"
                                    src="../../../assets/images/menu-horse-racing.svg" alt="horse">
                                <img *ngIf="isActivegreyhound" class="icon-cricket"
                                    src="../../../assets/images/menu-greyhord-racing.svg" alt="racehorse">
                                <!-- <img *ngIf="isActiveFootball" src="../../../assets/images/menu-sports-option.svg"
                            alt="football"> -->
                                <!-- <img *ngIf="isActiveTennis" src="../../../assets/images/menu-tennis.svg" alt="tennis"> -->
                                <span class="detailsofmatch">{{group?.startTime | date:"HH:mm"}} {{group?.venue}}
                                    ({{group?.countryCode}})</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!isUpcoming" class="leagues-section">
        <div class="subtitle-header leagues-section">
            <img class="trophy-icon" src="../../../assets/images/trophy-icon.svg" alt="trophy">
            <span>{{'Competitions'|translate}}</span>
        </div>
        <div class="cricket-second-card leagues-section">
            <ng-container *ngIf="authService.sportsId==4 ||authService.sportsId==2 ||authService.sportsId==1">
                <ng-container *ngFor="let item of matchSummaryData$ | async ">
                    <ng-container *ngFor="let comp of item?.children; let i = index">
                        <div class="compitation-card"
                            [routerLink]="['/shared','match-summery',authService.sportsId, comp.id]"
                            [queryParams]="{ competitionName: comp?.altName }">
                            <div class="compitation-body">
                                <span>{{comp?.name}}</span>
                                <img src="../../../assets/images/live-right-arrow.svg" alt="right">
                            </div>

                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="authService.sportsId==7 ||authService.sportsId==4339">
                <ng-container *ngFor="let race of raceLeagues">
                    <!-- <ng-container *ngFor="let comp of item; let i = index"> -->
                    <div class="compitation-card"
                        [routerLink]="['/shared','match-summery',authService.sportsId, race[0].countryCode]"
                        [queryParams]="{competitionName: race?.[0]?.countryCode}">
                        <div class="compitation-body">
                            <span>{{race[0]?.countryCode}}</span>
                            <img src="../../../assets/images/live-right-arrow.svg" alt="right">
                        </div>

                    </div>
                    <!-- </ng-container> -->
                </ng-container>
            </ng-container>
        </div>
    </ng-container>

    <div class="special-games-container"></div>
</div>
<app-betplace *ngIf="showbet" [eventName]="eventName" [marketName]="marketName" [price]="price"> </app-betplace>
