import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-loyalty-popup',
  templateUrl: './loyalty-popup.component.html',
  styleUrls: ['./loyalty-popup.component.scss']
})
export class LoyaltyPopupComponent implements OnInit {
  @Output() closeClick: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    this.closeClicked
  }

  closeClicked() {
    this.closeClick.emit();
  }

}
