import { ChangeDetectorRef, Component, HostListener, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, filter, map, switchMap } from 'rxjs';
import { Balance } from 'src/app/services/balance.interface';
// import {AuthService} from 'src/app/services/auth.service';
import { BettingHelperService } from 'src/app/services/betting-helper.service';
import { MyprofileService } from 'src/app/services/myprofile.service';
import { BetHistoryResult, N0 } from 'src/app/shared/models/bet-history.interface';

@Component({
  selector: 'app-bethistory',
  templateUrl: './bethistory.component.html',
  styleUrls: ['./bethistory.component.scss']
})
export class BethistoryComponent implements OnInit {

    lottieOptions: any = {
        path: 'assets/static/media/loader-lottie.json',
        autoplay: true,
        loop: true,
        controls: true,
    }

  showhideaccountfilter: any = false;

  bethistory:any=[]
  betdetails:any={};
  side:any;
  winvalue:any
  wintext:any;
  s:any=[];
  bethistoryunmatched:any=[]
  betdetailsunmatched:any={};
  sideunmatched:any;
  winvalueunmatched:any
  wintextunmatched:any;

  // new variable for bet history popup
  unmatchedShowPopup = false;
  cancelPopup = false;
  unmatchedSection = false;
  matchedSection = true;
  mathedBetHistoryData: N0[] = [];
  unmathedBetHistoryData: N0[] = [];
  isLoader: boolean = false;
  source: string;  // to remember the source of the page e.g. from where it is
  // navigated
  matchedLength: number = 0;
  unMatchedLength: number = 0;

  testslec: any = {};
    matchedBetsList: any[] = [];
    unMatchedBetsList: any[] = [];
  matchedBetsList$: Observable<Selection[]>;
  unMatchedBetsList$: Observable<Selection[]>;
  betHistoryData$: any;
  itemHeight: number = 100;
  visibleMatcheBets: any = [];
  visibleUnMatcheBets: any = [];
  itemsPerLoad: number = 7;
  itemsPerLoadUnMatched: number = 7;
  lastVisibleIndex: number = 0;
  lastVisibleIndexUnMatched: number = 0;
  scrollYPosition: number = 0;

  constructor(
    private myprofileServices: MyprofileService, public router: Router,
    private route: ActivatedRoute,
    public bettingHelper: BettingHelperService, private cdr: ChangeDetectorRef) { }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event): void {
    // This method will be called whenever the user scrolls the window
    this.scrollYPosition = window.scrollY;
    // Get the vertical scroll position
    //get the total height of the page
    const scrollHeight = document.documentElement.scrollHeight;
    //get the inner height of the window
    const innerHeight = window.innerHeight;
    //if the user has scrolled to the bottom of the page then console log the scroll position and total height

    if ((scrollHeight - (this.scrollYPosition + innerHeight)) <= 50) {
      if (this.matchedSection) {
        this.loadMoreData();
      }
      if (this.unmatchedSection) {
        this.loadMoreDataUnMatched();
      }
    }

  }

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.setIntervalOfIntercom();
    this.checkLastActivityDate();
    this.source = this.route.snapshot.queryParamMap.get('source');
        if (localStorage.getItem('ROYALTOKEN_KEY') != null) {
      this.isLoader = true;
            this.myprofileServices.betHistory(null).subscribe((res: any) => {
                this.bettingHelper.betHistorySubject.next(res?.result);
        this.getBetHistoryData();
        this.isLoader = false;
      }, (err) => {
        this.isLoader = false;
        console.error(err);
            });
        }
    //set scroll positions of window to 0
    if (window.scrollY > 0) {
      window.scrollTo(0, 0);
    }

  }

  getBetHistoryData(): void {
            this.isLoader = true;
    this.matchedBetsList$ = this.bettingHelper.betHistoryData?.pipe(
      filter(res => localStorage.getItem('ROYALTOKEN_KEY') !== null),
      switchMap((res: BetHistoryResult) => {
                this.mathedBetHistoryData = res?.matched ? Object.values(res?.matched) : [];
                this.matchedBetsList = this.getBetList(this.mathedBetHistoryData);
        return this.bettingHelper.betHistoryData;
      }),
      map(() => this.matchedBetsList)
    );

    this.unMatchedBetsList$ = this.bettingHelper.betHistoryData.pipe(
      filter((res) => localStorage.getItem('ROYALTOKEN_KEY') !== null),
      switchMap((res: BetHistoryResult) => {
        this.unmathedBetHistoryData = res?.unmatched ? Object.values(res.unmatched) : [];
                this.unMatchedBetsList = this.getBetList(this.unmathedBetHistoryData);
        return this.bettingHelper.betHistoryData;
      }),
      map(() => this.unMatchedBetsList)
    );
    this.matchedBetsList$.subscribe((res) => {
      this.matchedBetsList = res;
      if (this.matchedBetsList.length > 0) {
        this.visibleMatcheBets = this.matchedBetsList.slice(0, this.itemsPerLoad);
        this.lastVisibleIndex = this.itemsPerLoad;
        const element = document.getElementsByClassName('betting-box');
        if (element) {
          this.itemHeight = element[0]?.clientHeight || 98;
          this.itemsPerLoad = Math.round(window.innerHeight / this.itemHeight);
        }
          }
    })

    this.unMatchedBetsList$.subscribe((res) => {
      this.unMatchedBetsList = res;
      if (this.unMatchedBetsList.length > 0) {
        this.visibleUnMatcheBets = this.unMatchedBetsList.slice(0, this.itemsPerLoadUnMatched);
        this.lastVisibleIndexUnMatched = this.itemsPerLoadUnMatched;
        const element = document.getElementsByClassName('betting-box');
        if (element) {
          this.itemHeight = element[0]?.clientHeight || 98;
          this.itemsPerLoadUnMatched = Math.round(window.innerHeight / this.itemHeight);
        }
      }
    })
            this.isLoader = false;
  }


  loadMoreData() {
    //get new items based on the last visible index from the matcheBetsList$
    const newItems = this.matchedBetsList.slice(this.lastVisibleIndex, this.lastVisibleIndex + this.itemsPerLoad);
    //concat new items with the visibleMatcheBets
    this.visibleMatcheBets = this.visibleMatcheBets.concat(newItems);
    this.lastVisibleIndex = this.lastVisibleIndex + this.itemsPerLoad;
  }

  loadMoreDataUnMatched() {
    const newItems = this.unMatchedBetsList.slice(this.lastVisibleIndexUnMatched, this.lastVisibleIndexUnMatched + this.itemsPerLoadUnMatched);
    this.visibleUnMatcheBets = this.visibleUnMatcheBets.concat(newItems);
    this.lastVisibleIndexUnMatched = this.lastVisibleIndexUnMatched + this.itemsPerLoadUnMatched;
  }


  showaccountfilter() {
    this.showhideaccountfilter = !this.showhideaccountfilter;

  }

  //function for continuous check if intercom is present or not
  checkIntercom() {
    const intercom = document.getElementsByClassName('intercom-lightweight-app')[0];
    //if element is present then remove it
    if (intercom) {
      intercom.remove();
    }
  }

  getBetList(betHistoryData: N0[]) {
    this.isLoader = true;
    let fullList: Selection[] = [];
    for (const historyData of betHistoryData) {
      for (const market of historyData.markets || []) {
        for (const selection of market.selections || []) {
          Array.prototype.push.apply(fullList, selection);
        }
      }
    }
    this.isLoader = false;
    return fullList;
  }


  setIntervalOfIntercom() {
    setInterval(() => {
      this.checkIntercom();
    }, 1000);
  }

  createLink(item) {
    if (item.eventTypeId === '4339') {
      this.router.navigate(['/shared/greyhoundRacing', {id : item?.eventId}]);
      return
    }
    if (item.eventTypeId === '7') {
      this.router.navigate(['/shared/horseracing', {id : item?.eventId}]);
      return
    }
    this.router.navigate([`/shared/details/${item?.eventId}/${item?.eventTypeId}`])
  }

  removeUnmatchedItem([i1, i2, i3]) {

    this.unmathedBetHistoryData[i1].markets[0]?.selections[i2].splice(i3, 1);
  }

  bethist:any;
  matcheddata:any;

  // new function for bet history popup

    trackByFn(index: any, item: any) {
        return item.apolloBetId;
    }


  goToMatchedSection() {
    this.unmatchedSection = false;
    this.matchedSection = true;
  }

  goToUnmatchedSection() {
    this.unmatchedSection = true;
    this.matchedSection = false;
}

  goToBetHistoryPage() {
    // navigate to home page
    if (localStorage.getItem('ROYALTOKEN_KEY') != null) {
      this.router.navigate(['/main']);
      this.myprofileServices.updateActiveMenuItem('/main');
    }
  }

  cancelBetPopup() {
    this.unmatchedShowPopup = true;
    this.cancelPopup = true;
  }

  cancelBet() {
    this.unmatchedShowPopup = false;
    this.cancelPopup = false;
  }

  betNotCancel() {
    this.unmatchedShowPopup = true;
    this.cancelPopup = false;
  }

  goBack() {
    if (this.source === 'profile') {
      // If the source is the profile page, go back to the profile page
      this.router.navigate(['/account']);
    } else {
      // Otherwise, go back to the landing page (default behavior)
      this.router.navigate(['/main']);
      this.myprofileServices.updateActiveMenuItem('/main');
    }
  }

  //call getBalanceData() function from myprofile service
  //and check for myprofile.lastActivityDate value matches or not with data
  checkLastActivityDate() {
    this.myprofileServices.getBalanceData().subscribe((data: Balance) => {
      if (this.myprofileServices?.lastActivityDate != data?.result?.lastActivityDate) {
        this.myprofileServices.lastActivityDate = data?.result?.lastActivityDate;
        this.getBetHistoryData();
      }
    })
  }
}
