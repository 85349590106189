import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientdetailsComponent } from './clientdetails/clientdetails.component';
import { BethistoryComponent } from './clientdetails/bethistory/bethistory.component';
import { BetbuilderComponent } from './clientdetails/betbuilder/betbuilder.component';
import { BonusesComponent } from './clientdetails/bonuses/bonuses.component';
import { BonusDetailesComponent } from './clientdetails/bonusDetailes/bonusDetailes.component';
import { BalancemanagementComponent } from './clientdetails/balancemanagement/balancemanagement.component';
import { MyprofileComponent } from './clientdetails/myprofile/myprofile.component';
import { MessagesComponent } from './clientdetails/messages/messages.component';
import { DepositWithdrawReportComponent } from './clientdetails/deposit-withdraw-report/deposit-withdraw-report.component';
import { LoyaltyProgramComponent } from './clientdetails/loyalty-program/loyalty-program.component';
import { AccountStatementComponent } from './clientdetails/balancemanagement/account-statement/account-statement.component';

const routes: Routes = [
  { path: '', component: ClientdetailsComponent },
  { path: 'bethistory', component: BethistoryComponent },
  { path: 'builderhistory', component: BetbuilderComponent },
  { path: 'bonuses', component: BonusesComponent },
  { path: 'bonusDetailes', component: BonusDetailesComponent },
  { path: 'balancemanagement', component: BalancemanagementComponent },
  { path: 'balancemanagement/:id', component: BalancemanagementComponent },
  { path: 'myprofile', component: MyprofileComponent },
  { path: 'myprofile/:id', component: MyprofileComponent },
  { path: 'messages', component: MessagesComponent },
  { path: 'depositwithdrawreport', component: DepositWithdrawReportComponent},
  { path: 'loyalty', component: LoyaltyProgramComponent },
  { path: 'account-statement', component: AccountStatementComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
