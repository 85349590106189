import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CdkDragStart } from '@angular/cdk/drag-drop';

declare var SIR: any;
declare var $;

@Component({
  selector: 'app-live-video',
  templateUrl: './live-video.component.html',
  styleUrls: ['./live-video.component.scss']
})
export class LiveVideoComponent implements OnInit {
  @Input() videoStats: any;
  isTvActive = false;
  sid: any;
  eid: any;
  public dragging: boolean;


  @ViewChild('tvIframe') tvIframe: ElementRef;
  public bindSCOREURL: string;



  constructor(private authService: AuthService, private changedRef: ChangeDetectorRef, private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.eid = this.route.snapshot.params['id'];
    this.sid = this.route.snapshot.params['sid'];
    this.getIframe();
    if (this.videoStats?.hasVideo) {
      this.isTvActive = true;
    }
  }

  bindIframe(data: string) {
    this.tvIframe.nativeElement.innerHTML = '';
    this.bindSCOREURL = data;
    data = '<div class="tvol" cdkDrag ><div (cdkDragStarted)="handleDragStart($event)" (click)="handleClick($event)"cdkDrag><div class="closeIcon"><i class=" bc-i-close-remove close" aria-hidden="true"></i></div><iframe class="htscoreboard" src="' + this.bindSCOREURL + '" frameborder="0" scrolling="no" allow="autoplay; encrypted-media" allowfullscreen"></iframe></div></div>';
    this.tvIframe.nativeElement.innerHTML = data;
    this.changedRef.detectChanges();
    $('.close').on('click', function (event) {
      $('.tvol').hide();
      this.isTvActive = false
    });
    this.setDesktopTV();
  }

  getIframe() {
    this.isTvActive = true;
    var closes = $('.tvol');
    $('.tvol').show();
    const data = { "sportId": this.sid, "eventId": this.eid };
    console.log(data);
    this.authService.getTvURl(data).subscribe((res) => {
      if (true) {
        this.bindIframe(res?.result?.config?.videoId);
      } else {
        this.bindIframe(null);
      }
    });
  }


  setDesktopTV() {
    const $window = $(window);
    const $videoWrap = $('.tvol');

    const $video = $('.card-body');
    const videoHeight = $video.outerHeight();

    $window.on('scroll', () => {
      const windowScrollTop = $window.scrollTop();
      const videoBottom = videoHeight + $videoWrap.offset().top;

      if (windowScrollTop > videoBottom) {

        $videoWrap.height(videoHeight);
        //$video.removeClass('stuck1');
        $video.addClass('stuck ');
        $video.removeClass('rada1');
      } else {
        $videoWrap.height('auto');
        $video.removeAttr("style");
        $video.removeClass('stuck');
        $video.addClass('rada1 ');
        //$video.addClass('stuck1');
      }
    });
  }

  public handleDragStart(event: CdkDragStart): void {
    this.dragging = true;
  }

  public handleClick(event: MouseEvent): void {
    if (this.dragging) {
      this.dragging = false;
      return
    }
  }

  
}
