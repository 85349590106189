import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { CasinoService } from 'src/app/services/casino.service';
import { LandingService } from 'src/app/services/landing.service';
import { MyprofileService } from '../../services/myprofile.service';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { Subscription } from 'rxjs';
import { casinoGamesArray, LiveCasinoGamesArray } from '../models/gamesArray';
declare var $: any
@Component({
  selector: 'app-casino',
  templateUrl: './casino.component.html',
  styleUrls: ['./casino.component.scss']
})
export class CasinoComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  @ViewChild('iframeLoad', { static: false }) iframeLoad: ElementRef;
  isIphoneChrome = (navigator.userAgent.includes("iPhone") && navigator.userAgent.includes('CriOS'));
  isIphoneSafari = (navigator.userAgent.includes("iPhone") && !navigator.userAgent.includes('CriOS'));
  // @ts-ignore
  isSafariPWA = navigator?.standalone
  urlIframe: string;
  title: string
  constructor(private casinoServices: CasinoService, private myprofile: MyprofileService, private faverate: LandingService, private api: LandingService, private formBuilder: FormBuilder) { }
  allgames: any = [];
  showhideprovider: any = true;
  showhidecasinopopup: any = true;
  isScroll: boolean = false;
  isLoading = false;
  isLoader = false;
  gamepath = environment.gamespath;
  gameCategory: any = [];
  gameCategoryAndProvideid: any = [];
  selcetedgamesactive: any = 'all';
  gamebyproviderid: any = [];
  public yt: any;
  isActivefav: any;
  public form: FormGroup;
  isActivefavlive: any;
  balanceamounts: any = {};
  casinoUrl: string;
  jiliId: string;
  jiliGID: string;
  favouriteGamelistcasino: any = [];
  demourl: any;
  sliderImage: any;
  Images: any;
  pointPopUpOpen = true;
  filteredArray: any;
  isLoginCheck = false;
  isMobile: boolean = false;
  openIframe: boolean = false;
  private urlIframeSubscription: Subscription;
  public activeCategory: any;
  sliderlConfig = {
    slidesToShow: 3,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,

        }
      }

    ]
  };

  searchValue: string = '';
  expandSearch: boolean = false;
  activeTab: string = 'Live Casino';
  expandSearchBoxAnimate: boolean = false;
  contractInput: boolean = false;
  specialGamesArray: any = [];


  activeCasinoGamesArray = new Array(casinoGamesArray.length).fill(false);



  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.urlIframeSubscription = this.casinoServices.casinoURLObservable$.subscribe((url: string | null) => {
      if (!url && this.urlIframe) {
        this.clearIframe()
      }
      if (url && this.activeTab == 'Live Casino') {
        this.openGame(url)
      }
      this.urlIframe = url;
    })
    this.checkIfMobile();
    this.isLoader = true;
    this.api.sliderimage().subscribe((res: any) => {
      res;
      this.sliderImage = res;
      this.Images = res?.data;
      this.filteredArray = res?.result?.inPlaySlidingBanners;
      this.isLoader = false;
    });

    this.casinogame();

    this.createForm();
    this.myprofile.getBalanceData().subscribe((data) => {
      this.balanceamounts = data?.result;
      if (data?.result?.useBWallet == true) {
        this.balanceamounts = data?.result.bWallet;
      }
      else if (data?.result?.useSWallet == true) {
        this.balanceamounts = data?.result.sWallet;
      }
      else if (data?.result?.useCWallet == true) {
        this.balanceamounts = data?.result.cWallet;
      }
      else {
        this.balanceamounts = data?.result;
      }
    })
    // this.casinoServices.fa
    this.favouriteGame(null, null)

    this.activeCasinoGamesArray[0] = true;
  }

  getCasinogamesArray() {
    if (this.activeTab == 'Casino') {
      return casinoGamesArray;
    }
    else {
      return LiveCasinoGamesArray;
    }
  }

  createForm() {
    this.form = this.formBuilder.group({
      amount: ['', [Validators.required]],
    });

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 1024;
  }

  getMember() {
    return localStorage.getItem('Member_code');
  }

  showcasino() {
    this.showhidecasinopopup = !this.showhidecasinopopup;
  }

  casinogame() {
    this.isLoader = true;
    const casinocat = this.activeTab == 'Casino' ? {
      "casino_cat_ids": ["51", "52", "53", "54", "55", "56", "57"]
    } : {
      "casino_cat_ids": ["11", "12", "13", "14", "15", "17", "18", "19", "20"]
    }
    if (this.activeTab == 'Live Casino') {
      this.casinoServices.casinogames(casinocat).subscribe((res: any) => {
        this.gameCategoryAndProvideid = [];
        res?.result?.forEach((game: any) => {
          this.gameCategoryAndProvideid.push({ id: game?.id, name: game?.name });
        });
        this.getCategoryGames(11);
      }, error => {
        this.isLoader = false;
        console.error(error);
      })
    } else {
      this.casinoServices.slotGames().subscribe((res: any) => {
        this.gameCategoryAndProvideid = [];
        res?.result?.forEach((game: any) => {
          this.gameCategoryAndProvideid.push({ id: game?.id, name: game?.name });
        });
        this.getCategoryGames(51);
      }, error => {
        this.isLoader = false;
        console.error(error);
      })
    }
  }

  goToGame(games: any) {
    console.log("goToGame", games);
    this.isLoader = true;
    this.iframeLoad.nativeElement.innerHTML = ''
    if (games) {
      this.title = games?.name || games?.Trans.en
    }
    let id = games.id;
    let cId = id?.slice(0, 4);

    if (cId == 'jili' && this.getToken()) {
      this.form.reset();
      this.jiliId = games.Trans.en;
      this.jiliGID = games.ID;
      this.showhidecasinopopup = !this.showhidecasinopopup;
    } else if (this.getToken()) {
      this.casinoServices.getCasinoUrl(id).subscribe(
        res => {
          this.casinoUrl = res.result.url;
          this.urlIframe = res?.result?.url;
          this.openIframe = true;
          this.openCasino(this.casinoUrl);
        },
        error => {
        }
      );
    }
    else {
      document.getElementById("demo").click();
    }
    this.isLoader = false;
  }

  goToGame1(id, game = null) {
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = ''
    }
    if (game) {
      this.title = game?.name || game?.Trans.en
    }
    let cId = id.slice(0, 4);

    /*if(cId=='jili' && this.getToken()){
      this.jiliId=id;
      this.showhidecasinopopup=!this.showhidecasinopopup;
    }else*/
    if (this.getToken()) {
      this.casinoServices.getCasinoUrl(id).subscribe(
        res => {
          //   this.casinoUrl=res?.result?.url;
          // //  console.log("insidde getPNl value of res:" +- JSON.stringify(this.casinoUrl));
          // //   window.open(this.casinoUrl);
          //   this.urlIframe = res?.result?.url;
          //   this.openGame(this.casinoUrl)
        },
        error => {
        }
      );
    }
    else {
      document.getElementById("demo").click();
    }

  }

  clearIframe() {
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = ''
    }
    this.title = ""
    this.urlIframe = ""
    this.openIframe = false;
    this.casinoServices.clearCasinoURL()
  }
  openCasino(iurl) { // INIT IFRAME
    // this.loaderShow = false;
    this.yt = '<iframe style="width: 100%; height: 100%" class="casino-iframe" src="' + iurl + '" frameborder="0" id="casino-fram" allow="autoplay; encrypted-media"  allowfullscreen></iframe>';
    this.iframeLoad.nativeElement.innerHTML = this.yt;
  }

  depositJili() {
    let amt = this.form.value;
    const data = [
      {
        "line": null,
        "marketId": "7.7",
        "oddsType": null,
        "price": 1,
        "selectionId": 1,
        "side": 1,
        "totalSize": amt.amount
      }
    ];
    this.casinoServices.jiliDeposit(data).subscribe(
      res => {
      },
      error => {
        console.error(error);
      }
    );
    this.casinoServices.jiliGame(this.jiliGID).subscribe(
      res => {
        this.showhidecasinopopup = !this.showhidecasinopopup;
        this.casinoUrl = res.result.url;
        // window.open(this.casinoUrl);
        this.urlIframe = res?.result?.url;
        this.openCasino(this.casinoUrl)
      },
      error => {
        console.error(error);
      }
    );

  }

  slotgames(category: any, index: number, category_name: any) {
    setTimeout(() => {
      this.scrollToElementById(category_name);
    }, 100);
    this.selcetedgamesactive = category;
    this.gameCategory = [];
    this.activeCasinoGamesArray[index] = true;
    this.getCategoryGames(category);
    this.activeCategory = category;
    for (let i = 0; i < this.activeCasinoGamesArray.length; i++) {
      if (i != index) {
        this.activeCasinoGamesArray[i] = false;
      }
    }
    if (category == 'all') {
      this.gameCategory = this.allgames;
    } else {
      this.gameCategory = this.allgames.filter(games => {
        if (this.activeTab == 'Casino') {
          return games.Categories[0] == category || games.Categories[1] == category;
        } else {
          return games.Categories.includes(category);
        }
      });
    }
  }

  getCategoryGames(id: any) {
    this.isLoader = true;
    this.gameCategory = [];
    if (this.activeTab == 'Live Casino') {
      this.casinoServices.getCategoryWiseCasinoGames(id).subscribe((res: any) => {
        this.allgames = res?.result;
        this.gameCategory = res?.result;
      });
      this.isLoader = false;
    } else {
      this.casinoServices.getCategoryWiseSlotGames(id).subscribe((res: any) => {
        this.allgames = res?.result;
        this.gameCategory = res?.result;
        this.isLoader = false;
      });
    }
  }

  favouriteGame(selectedgames: any, imageurl: any) {

    var gamesl = localStorage.getItem("gamelist");


    if ((gamesl == null) && (imageurl != null)) {
      this.casinoServices.equipment = []

      this.isActivefav = selectedgames;
      this.casinoServices.favouritegame(imageurl);
      this.favouriteGamelistcasino = this.casinoServices.equipment;
      this.faverate.favouriteimage.next(this.favouriteGamelistcasino);
    } else if (gamesl?.includes(imageurl)) {


      const list = this.casinoServices.equipment;

      const index = list.indexOf(imageurl);

      this.casinoServices.equipment.splice(index, 1);
      localStorage.setItem("gamelist", JSON.stringify(this.casinoServices.equipment));
      this.favouriteGamelistcasino = this.casinoServices.equipment;
      this.faverate.favouriteimage.next(this.favouriteGamelistcasino);
    } else if ((imageurl != null) && (gamesl != null)) {



      this.isActivefav = selectedgames;

      this.casinoServices.favouritegame(imageurl);
      this.favouriteGamelistcasino = this.casinoServices.equipment;
      this.faverate.favouriteimage.next(this.favouriteGamelistcasino);
    } else {
      return;
    }

  }





  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }

  scrollToTop() {
    $("#button").on("click", function () {
      $(window).scrollTop(0);
    });
  }
  setScrollbutton() {
    const $window = $(window);
    $window.on('scroll', () => {
      const windowScrollTop = $window.scrollTop();
      const windowheight = $window.outerHeight();;

      if (windowScrollTop > windowheight) {
        this.scrollToTop();
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
    });
  }

  // activechecked(val: any) {
  //   //console.log(val );

  // }

  ngAfterViewInit() {
    this.scrollToTop();
    this.setScrollbutton();
  }
  ngOnDestroy() {
    this.scrollToTop();
    this.setScrollbutton();
  }
  closePointPopUp() {
    this.pointPopUpOpen = false
  }

  async scrollToElementById(elementId: string) {
    const element = await document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }

  handleSearch(event: any) {
    const value = event.target.value;
    if (value?.length > 0) {
      this.gameCategory = this.gameCategory.filter((game: any) => {
        return game?.name?.toLowerCase().includes(value.toLowerCase());
      })
    } else {
      this.gameCategory = this.allgames;
    }
  }

  expandSearchBox() {
    this.expandSearchBoxAnimate = true;
    setTimeout(() => {
      this.expandSearch = true;
      this.expandSearchBoxAnimate = false;
    }, 400);
  }

  contractSearchBox() {
    this.contractInput = true;
    setTimeout(() => {
      this.expandSearch = false;
      this.contractInput = false;
      this.searchValue = null;
    }, 400);
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
    this.casinogame();
  }


  openGame(iurl) {
    FingerprintJS.load().then((fp) => {
      fp.get().then((result) => {
        console.log("finger", result)
      });
    });
    this.yt = '<iframe style="width: 100%; height: 100%" class="live-game-iframe" src="' + iurl + '" frameborder="0" id="casino-fram" allow="autoplay; encrypted-media"  allowfullscreen></iframe>';
    this.iframeLoad.nativeElement.innerHTML = this.yt;
  }

}
