import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { NotifyServiceService } from 'src/app/notify/notify-service.service';
import { MyprofileService } from 'src/app/services/myprofile.service';
@Component({
  selector: 'app-deposit-details',
  templateUrl: './deposit-details.component.html',
  styleUrls: ['./deposit-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DepositDetailsComponent implements OnInit {
  cancelDeposit: boolean = false;
  editDeposit: boolean = false;
  @Input() detailsWithdrawDeposit: any;
  @Output() closeClick: EventEmitter<any> = new EventEmitter();


  editDepositForm = new FormGroup({
    UTR: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(22), Validators.pattern(/^[a-zA-Z0-9]*$/)
    ]),
    amount: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/),
    Validators.min(100), Validators.max(10000000), Validators.maxLength(8), Validators.minLength(3)
    ]),
  });


  cancelWithdrawlForm = new FormGroup({
    reason: new FormControl('', [Validators.required, Validators.minLength(1)]),
  });

  cancelDepositForm = new FormGroup({
    reason: new FormControl('', [Validators.required, Validators.minLength(1)]),
  });

  constructor(private myprofileServices: MyprofileService, private toastr: NotifyServiceService) {
  }

  ngOnInit(): void {
    const amountControl = this.editDepositForm.get('amount');
    amountControl.setErrors({ 'required': 'Amount is required.' });
    amountControl.setErrors({ 'pattern': 'Only numeric characters are allowed in Amount.' });
    amountControl.setErrors({ 'min': 'Minimum value is 1.' });
    amountControl.setErrors({ 'max': 'Maximum value is 10,000,000.' });
    amountControl.setErrors({ 'maxlength': 'Maximum length is 8.' });
    amountControl.setErrors({ 'minlength': 'Minimum length is 3.' });
    if (localStorage.getItem('paymentGateWayTrxnId')) {
      if (this.detailsWithdrawDeposit?.status !== 'Pending' || this.detailsWithdrawDeposit?.status !== 'pending' || this.detailsWithdrawDeposit?.status !== 'PENDING') {
        localStorage.removeItem('paymentGateWayTrxnId');
      }
    }
  }

  cancelDepositFun() {
    this.cancelDeposit = true;
  }

  editDepositFun() {
    this.editDeposit = true;
    //set editdeposit form values
    //first reset the form
    this.editDepositForm.reset();
    this.editDepositForm.setValue({
      UTR: this.detailsWithdrawDeposit?.utr,
      amount: this.detailsWithdrawDeposit?.amount,
    });

  }

  dateFormate(date: any) {
    return moment(date).format('MMM DD, YYYY');
  }

  timeFormate(date: any) {
    return moment(date).format('h:mm a');
  }



  submitEditedForm(id: any) {
    let data = {
      "requestId": this.detailsWithdrawDeposit.requestId,
      "amount": this.editDepositForm.value.amount,
      "UTR": this.editDepositForm.value.UTR,
    }
    console.log(data);
    this.myprofileServices.deposittransactionreportedit(id, data).subscribe((res) => {
      if (res) {
        this.toastr.success("Your request has been sent successfully");
        console.log(res);
        this.editDeposit = false;
        this.editDepositForm.reset();
        this.transactionReport();
        this.closeClicked();
      }
    }, (err) => {
      this.toastr.error(err?.error?.error || err.error.result);
    })
  }

  submitReasonForCancel(id: any) {
    let data = {
      "requestId": this.detailsWithdrawDeposit.requestId,
      "reason": this.cancelDepositForm.value.reason,
    }
    this.myprofileServices.deposittransactionreportcancel(id, data).subscribe((res) => {
      if (res) {
        this.toastr.success("Your request has been sent successfully");
        console.log(res);
        this.cancelDeposit = false;
        this.cancelDepositForm.reset();
        this.transactionReport();
        this.closeClicked();
      }
    }, (err) => {
      this.toastr.error(err?.error?.error || err.error.result);
    })
  }

  transactionReport() {
    this.myprofileServices.transactionreport().subscribe(async (res: any) => {
      if (res) {
        this.myprofileServices.updateTransactionReports(res);
      }
    }, (err) => {
      if (err) {
        console.log(err);
      }
    });
  }

  submitReasonForCancelWithdraw(id: any) {
    let data = {
      "requestId": this.detailsWithdrawDeposit.requestId,
      "reason": this.cancelWithdrawlForm.value.reason,
    }
    this.myprofileServices.deposittransactionreportcancel(id, data).subscribe((res) => {
      if (res) {
        this.toastr.success("Your request has been sent successfully");
        console.log(res);
        this.cancelDeposit = false;
        this.cancelWithdrawlForm.reset();
        this.transactionReport();
        this.closeClicked();
      }
    }, (err) => {
      this.toastr.error(err?.error?.error || err.error.result);
    })
  }


  closeClicked() {
    this.closeClick.emit(true);
  }

  preventDecimalInput(event: any) {
    //avoid other inputs except numbers
    if (event.key === "." || event.key === "e" || event.key === "-" || event.key === "+" || event.key === "E") {
      event.preventDefault();
    }

    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
      //update the formcontrol
      this.editDepositForm.controls['amount'].setValue(event.target.value);
    }
  }

}
