<div class="pop-up-with-api-data">
  <div class="pop-up-with-api-data__content">
    <div class="pop-up-with-api-data__header">
      <div (click)="closePopup()">
        <span class="close-icon">&times;</span>
      </div>
    </div>
      <div class="pop-up-with-api-data__body">
      <div class="Bonus-information" [ngClass]="{'d-none':showhidepopupdata}">
        <h1>{{'Bonus Information'|translate}}</h1>
        <div class="back-info info-strip">
          <span class="info-name">
            {{'balance'|translate}}
          </span>
          <span class="info-data">
            {{popupData?.balance | indianCurrency}}
          </span>

        </div>

        <div class="back-info">
          <span class="info-name">
            {{"exposure"|translate}}
          </span>
          <span class="info-data">
            {{Exposure | indianCurrency}}
          </span>

        </div>

        <div class="back-info info-strip">
          <span class="info-name">
            {{'balance'|translate}}
          </span>
          <span class="info-data">
<!--            <label class="switch3 switch3-checked" *ngIf="infoWalletType === activeWallet">-->

<!--              <input type="checkbox" (click)="changeWallet()" />-->
<!--              <input type="checkbox" *ngIf="infoWalletType === activeWallet" class="switch3-checked"-->
<!--                  (click)="changeWallet()" checked />-->

<!--              <div></div>-->
<!--          </label>-->
<!--          <label class="switch3 offse" *ngIf="infoWalletType !== activeWallet">-->

<!--              <input type="checkbox" (click)="changeWallet()" />-->
<!--              <input type="checkbox" *ngIf="infoWalletType !== activeWallet" class="switch3-checked"-->
<!--                  (click)="changeWallet()" />-->
<!--              <div></div>-->
<!--          </label>-->
             <label class="toggle-switch" (click)="changeWallet()"
                               [ngClass]="{'selectedBonus': infoWalletType === activeWallet}">
                <input type="checkbox" disabled [checked]="infoWalletType === activeWallet">
                <div class="toggle-switch-background secondSwitcher">
                  <div class="toggle-switch-handle">
                  <img loading="lazy" *ngIf="walletLoading" src="../../../assets/images/loadingSvg.svg"
                    alt="loadingSvg">
                  </div>
                </div>
            </label>
          </span>

        </div>

        <h2 *ngIf="infoWalletType === 'S'">{{'Sports Wallet'|translate}}</h2>
        <h2 *ngIf="infoWalletType === 'B'">{{'Bonus Wallet'|translate}}</h2>
        <h2 *ngIf="infoWalletType === 'C'">{{'Casino Wallet'|translate}}</h2>

        <div class="back-info info-strip">
          <span class="info-name">
            {{'Wagering Remaining'|translate}}
          </span>
          <span class="info-data">
            {{popupData?.wagerRemaining}}
          </span>

        </div>

        <div class="back-info">
          <span class="info-name">
            {{'Bonus Amount'|translate}}
          </span>
          <span class="info-data">
            {{popupData?.bonusAmount | indianCurrency}}
          </span>

        </div>

        <div class="back-info info-strip">
          <span class="info-name">
            {{'Bonus Issued'|translate}}
          </span>
          <span class="info-data highlighter">
            {{popupData?.issued  | indianCurrency}}
          </span>

        </div>

        <div class="back-info">
          <span class="info-name">
            {{'Bonus Expiry'|translate}}
          </span>
          <span class="info-data highlighter">
            {{popupData?.expiry}}
          </span>

        </div>
        <a (click)="showhidetermsc()" class="bonus-link highlighter"> {{'Terms and Condition'|translate}}</a>

      </div>

      <div [ngClass]="{'d-none':showhideterms}">
        <!-- <button class="back-button" (click)="showhidetermsc()">back</button> -->
        <div [innerHTML]="sanitizeHtml(popuppromoData)">

        </div>
        <button class="back-button" [ngClass]="{'d-none':showhideterms}" (click)="showhidetermsc()">{{'back'|translate}}</button>
      </div>


      </div>
  </div>
</div>
