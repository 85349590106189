import { Component, Input, OnInit } from '@angular/core';
import { LandingService } from 'src/app/services/landing.service';
import { BonusService } from '../bonus.service';
import { MyprofileService } from 'src/app/services/myprofile.service';

@Component({
  selector: 'app-claim-bonus-card',
  templateUrl: './claim-bonus-card.component.html',
  styleUrls: ['./claim-bonus-card.component.scss']
})
export class ClaimBonusCardComponent implements OnInit {

  @Input() bonus: any;

  constructor(private api: LandingService, private _bonus : BonusService, private myProfileService: MyprofileService) { }

  ngOnInit(): void {
    this.myProfileService.isHeaderFixed = true;
  }

  claim() {
    this.api.claimBonuses(this.bonus.BONUS_ID).subscribe((res: any) => {
      console.log(res);
      this._bonus.lastClaimBonus = this.bonus.AMOUNT;
      this._bonus.showClaimBonus = true;
    }, (err: any) => {
      console.log(err);
    });
  }

  ngOnDestroy() {
    this.myProfileService.isHeaderFixed = false;
  }
}
