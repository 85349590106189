import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as lottie from 'lottie-web';
@Component({
  selector: 'app-greyhound-racing',
  templateUrl: './greyhound-racing.component.html',
  styleUrls: ['./greyhound-racing.component.scss']
})
export class GreyhoundRacingComponent implements OnInit {
  public interval300: any;
  public sList: any = [];
  public isActive: boolean=false;
  public ename: string;
  public errorMsg: any;
  public previousAList: any = [];
  public detailList: any = [];
  public profitAL: any;
  public secId: string;
  public marketType: any;
  public isBack: boolean;
  public betdata: any = [];
  public matchOdd2profit: any = 0;
  public matchOddprofit: any = 0;
  public stackPriceSort: any =[];
  public stackPriceArr: any ={};
  public matchOdd3price: any;
  public placeBetM: any = {};
  isActiveBet:boolean=false;
  public betText: string;
  public marketId: string;
  public eventId: string;
  public isLoader: boolean = false;
  public matchOdd2price: any;
  public index=0;
  public activeItem: any;
  public eventData: any;
  lottieOptions: any = {
    path: 'assets/static/media/greyhound-running.json',
    autoplay: true,
    loop: true
  };

  lottieOption: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }
  constructor(private authService: AuthService,private router: Router) { }

  ngOnInit(): void {
    this.getUpcomeventData(4339);
    const fromBets = this.router?.routerState?.snapshot?.url?.split("id=")[1]
    if (fromBets) {
      this.getdetail({id : fromBets})
    }
  }
  getUpcomeventData(id) {
    //console.log('Inside getUpcomeventData value of id:' + id);
    this.authService.getHorseandGreyRacing(id).subscribe(
      res => {
        this.isActive = true;
        this.sList = res.result;
        this.getdetail(this.sList[this.index]);
      },
      error => {
      }
    );
  }

  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }
  openbet(side,Sitem,price) {
    this.isActiveBet = true;
    if (side == 0) {
      this.betText = 'Possible win:';
      this.isBack = true;
    }
    else {
      this.betText = 'Possible liabilites:';
      this.isBack = false;
    }
    this.betdata = { side: side, price: price, selectionId: Sitem.id, marketId: this.marketId};
    this.secId = Sitem.id;
    this.matchOdd3price=this.betdata.price;
    this.ename = Sitem?.name;
    this.stackPriceSort=[];
    this.stackPriceArr=localStorage.getItem('betstack').split(",");
      for(let i=0;i<=3;i++){
           this.stackPriceSort.push(this.stackPriceArr[i]);
      }
   //console.log("insidde getvkbet0marketdata value of this.betdata:" + JSON.stringify(this.betdata));
   //console.log("insidde getvkbet0marketdata value of this.matchOdd2profit:" + JSON.stringify(this.matchOdd2price));
 }
  placeBet() {
  this.errorMsg = ''
    this.isLoader = true;
    this.isActiveBet = false;
    const data = [{
      "side": this.betdata?.side,
      "price": this.betdata?.price,
      "selectionId": this.betdata?.selectionId,
      "btype": "ODDS",
      "oddsType": null,
      "eventTypeId": "4339",
      "marketId": this.marketId,
      "totalSize": this.matchOdd2price ,
      "betSlipRef": 0,
      "fromOneClick": 0,
      "betDelay": 0,
      "line": null
    }];
    //console.log("insidde getvkbet0marketdata value of data:" + JSON.stringify(data));
    this.authService.betplace(data).subscribe(
      res => {
        this.errorMsg = 'Bet Placed Successfully'
        this.matchOdd2profit = '';
        this.matchOddprofit='';
        this.matchOdd2price = ''
        this.marketId = '';
        this.authService.showSuccess(this.errorMsg);
        this.isLoader = false;
      //  console.log("insidde getvkbet0marketdata value of this.matchData:" + JSON.stringify(res));
      },
      error => {
        this.authService.showError(error.error.error[0][0]?.description);
        this.isActiveBet = false;
        this.isLoader = false;
        this.matchOdd2profit = '';
        this.matchOddprofit='';
        this.matchOdd2price = ''
        this.marketId = '';
      }
    );
  }
  getdetail(sitem){
    this.betdata.betDelay=sitem?.betDelay;
    this.eventId=sitem?.id;
    this.activeItem = sitem
 //   console.log("insidde getdetail value of Sitem:" + sitem?.id);
    this.authService.getHorseandGreyRacingdet(4339,this.eventId).subscribe(
      res => {
        this.isActive = true;
        this.detailList = res?.result[0]?.runners;
        this.marketId=res?.result[0]?.id;
        this.eventData = res?.result[0];
        if(res?.result.length==0){
        //  console.log('Inside if of getdetail of horse racing component:');
          this.getdetail(this.sList[++this.index]);
        }
      if(!this.previousAList || !this.previousAList.length){
          this.previousAList = res.result;

        }
        setTimeout(() => {
          this.previousAList = res.result;
          // console.log('Inside getMatchData value of this.previousAList:' + JSON.stringify(this.previousAList));
        }, 100)
      },
      error => {
      }
    );
    if(this.interval300){
      clearInterval(this.interval300);
          }

    if(this.getToken()){
      this.interval300=setTimeout(()=>{
        this.getdetail(sitem);
      }, 1000)
     }
     else{
    //  console.log("inside else getdetail value of Sitem:" + this.eventId);
      this.interval300=setTimeout(()=>{
        this.getdetail(sitem);
      }, 10000)
     }
  }
  closebet() {
    /*const doc=<HTMLElement> document.getElementsByClassName('betplacedetail1')[0];
    doc.style.display='none';*/
    this.isActiveBet = false;
    this.matchOdd2profit = '';
    this.matchOddprofit='';
    this.matchOdd2price = ''
    this.marketId = '';
  }
  openSignIn(){
    document.getElementById("demo").click();
  }
  openSign() {
    document.getElementById("demo").click();
    //console.log('Inside openSign');
  }
  plus(){
    let profit= Number(this.matchOdd3price) + 0.01;
    this.matchOdd3price = profit.toFixed(2);
  }
  minus(){
    let profit=Number(this.matchOdd3price) - 0.01;
    this.matchOdd3price=  profit.toFixed(2);
  }
  keyboard(action: string, type: string) {
    let saction = this.placeBetM.m_type;

    if ((this.matchOdd2price == undefined || this.matchOdd2price == '') && action != 'back') {
      this.matchOdd2price = action;
    }
    else if (this.matchOdd2price.length >= 1 && action != 'back' && type!='bigOh') {
      this.matchOdd2price = this.matchOdd2price + action;
    }
    else if (action!='back' && type=='bigOh') {
      this.matchOdd2price = Number(this.matchOdd2price) + Number(action);
      //console.log('Inside else if of bigOh' + this.matchOdd2price);
      }
      else if (action!='back' && type!='bigOh') {
        this.matchOdd2price = this.matchOdd2price + action;
        //console.log('Inside else if of bigOh' + this.matchOdd2price);
        }
    else {
    }
    let tmp = 0;
    let profit = 0;
    let Mid=this.marketId;
    if (this.profitAL != undefined) {
      //console.log('Inside keyboard of detailpage component:' + JSON.stringify(this.profitAL[this.marketId + '-' + this.secId]));
    }
    //this.matchOdd2profit=(this.betdata.price-1)*this.matchOdd2price;
    switch (this.marketType) {
      case 'MATCH_ODDS':
        if (action == 'back') {
          this.matchOdd2price = String(this.matchOdd2price).slice(0, -1);
          let profit = (this.betdata.price - 1) * this.matchOdd2price;
          this.matchOdd2profit = profit.toFixed(2);
          this.matchOddprofit=profit.toFixed(2);
        }
        else if (action != '.' && action != 'back' && this.profitAL?.[this.marketId + '-' + this.secId] == undefined) {
          let profit = (this.betdata.price - 1) * this.matchOdd2price;
          this.matchOdd2profit = profit.toFixed(2);
          this.matchOddprofit=profit.toFixed(2);
          //console.log('Inside keyboard of switch condition this.matchOdd2profit:' + this.matchOdd2profit);
        }
        else if (action != '.' && action != 'back' && this.profitAL[this.marketId + '-' + this.secId] != undefined) {
          let profit = (this.betdata.price - 1) * this.matchOdd2price;
          this.matchOdd2profit = Number(profit.toFixed(2)) + Number(this.profitAL[this.marketId + '-' + this.secId].pnl);
          //this.matchOdd2profit += this.profitAL[this.marketId + '-' + this.secId];
          this.matchOddprofit=profit.toFixed(2);
          //console.log('Inside keyboard of switch condition this.profitAL' + JSON.stringify(this.profitAL[this.marketId + '-' + this.secId]));
        }
        else {

        }
        break;
      case 'INNINGS_RUNS':
        if (action == 'back') {
          this.matchOdd2price = String(this.matchOdd2price).slice(0, -1);
          let profit = (this.betdata.price / 100) * this.matchOdd2price;
          this.matchOdd2profit = profit.toFixed(2);
          this.matchOddprofit=profit.toFixed(2);
        }
        else if (action != '.' && this.profitAL?.[this.marketId + '-' + this.secId] == undefined) {
          let profit = (this.betdata.price / 100) * this.matchOdd2price;
          this.matchOdd2profit = profit.toFixed(2);
          this.matchOddprofit=profit.toFixed(2);
        }
        else if (action != '.' && this.profitAL?.[this.marketId + '-' + this.secId] != undefined) {
          let profit = (this.betdata.price / 100) * this.matchOdd2price;
          this.matchOdd2profit = Number(profit.toFixed(2)) + Number(this.profitAL[this.marketId + '-' + this.secId].pnl);
          this.matchOddprofit=profit.toFixed(2);
        }
        else {

        }
        break;
      case 'MATCH_ODDS_SB':
        if (action == 'back') {
          this.matchOdd2price = String(this.matchOdd2price).slice(0, -1);
          let profit = (this.betdata.price - 1) * this.matchOdd2price;
          this.matchOdd2profit = profit.toFixed(2);
          this.matchOddprofit=profit.toFixed(2);
        }
        else if (action != '.' && this.profitAL?.[this.marketId + '-' + this.secId] == undefined) {
          let profit = (this.betdata.price - 1) * this.matchOdd2price;
          this.matchOdd2profit = profit.toFixed(2);
          this.matchOddprofit=profit.toFixed(2);
        }
        else if (action != '.' && this.profitAL?.[this.marketId + '-' + this.secId] != undefined) {
          let profit = (this.betdata.price - 1) * this.matchOdd2price;
          this.matchOdd2profit = Number(profit.toFixed(2)) + Number(this.profitAL[this.marketId + '-' + this.secId].pnl);
          //this.matchOdd2profit += this.profitAL[this.marketId +'-' + this.secId].pnl;
          this.matchOddprofit=profit.toFixed(2);
        }
        else {

        }
        break;
      default:
        if (action == 'back') {
          this.matchOdd2price = String(this.matchOdd2price).slice(0, -1);
          let profit = (this.betdata.price - 1) * this.matchOdd2price;
          this.matchOdd2profit = profit.toFixed(2);
          this.matchOddprofit=profit.toFixed(2);
        }
        else if (action != '.' && action != 'back' && this.profitAL?.[this.marketId + '-' + this.secId] == undefined) {
          let profit = (this.betdata.price - 1) * this.matchOdd2price;
          this.matchOdd2profit = profit.toFixed(2);
          this.matchOddprofit=profit.toFixed(2);
          //console.log('Inside keyboard of switch condition this.matchOdd2profit:' + this.matchOdd2profit);
        }
        else if (action != '.' && action != 'back' && this.profitAL[this.marketId + '-' + this.secId] != undefined) {
          let profit = (this.betdata.price - 1) * this.matchOdd2price;
          this.matchOdd2profit = Number(profit.toFixed(2)) + Number(this.profitAL[this.marketId + '-' + this.secId].pnl);
          this.matchOddprofit=profit.toFixed(2);
          //this.matchOdd2profit += this.profitAL[this.marketId + '-' + this.secId];
          //console.log('Inside keyboard of switch condition this.profitAL' + JSON.stringify(this.profitAL[this.marketId + '-' + this.secId]));
        }
        else {

        }
    }
  }

}
