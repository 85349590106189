import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { CasinoService } from 'src/app/services/casino.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  urlIframe: string = '';
  isMobile: boolean = false;
  private urlIframeSubscription: Subscription;

    constructor(public router: Router, private route: ActivatedRoute, private casinoServices: CasinoService) { }

  ngOnInit(): void {
    this.checkIfMobile();

    this.urlIframeSubscription = this.casinoServices.casinoURLObservable$.subscribe((url: string | null) => {
      this.urlIframe = url;
    });
    this.route.queryParams.subscribe((queryParams) => {
      const gameId = queryParams['game_id'];
      // console.log('Game ID from query parameter:', gameId);
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 1024; // Пороговое значение ширины для мобильных устройств
  }

  isIFrame() {
    if ((this.urlIframe && this.isMobile) || (this.router.url === '/shared/sportsbook' || this.router.url === '/shared/esports')){
      return false;
    } else {
      return true;
    }
  }

}
