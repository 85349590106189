<!-- <div class="card bonus-card orange-card">
    <div class="card-body">
        <div class="flexbox1">
            <div class="txt">
                <p class="bonus-title"> You've won Bonus of {{bonus.wagered ? bonus.wagered['claimAmount'] : '' | currency : 'INR'}} </p>
                <div class="boxssd" style="display: flex;justify-content: space-between;">
                    <p class="pull-right">Wagering completed</p>
                    <p class="pull-left">{{bonus.wagered['wageredCap'] | currency : 'INR'}}</p>
                </div>
                <div class="progressbar">
                    <div class="progressbg">
                        <div class="progressdot"></div>
                    </div>
                </div>
            </div>
            <div class="gift">
                <img loading="lazy" class="giftbox" src="../../../assets/images/check.png">
                <p class="claimbtn" (click)="claim()" >Claim Bonus</p>
            </div>
        </div>
    </div>
</div> -->


<div class="card">
    <div class="flexbox1">
        <div class="txt">
            <p class="bonus-title"> {{"You've won Bonus of ₹"|translate}}{{bonus.wagered ? bonus?.wagered?.claimAmount : 0 |
                indianCurrency}} </p>
            <div class="boxssd" style="display: flex;justify-content: space-between;">
                <p class="pull-right">{{'Wagering completed'|translate}}</p>
                <p class="pull-left">₹{{bonus.wagered['wageredCap'] | indianCurrency}}</p>
            </div>
            <div class="progressbar">
                <div class="progressbg">
                    <div class="progressdot"></div>
                </div>
            </div>
        </div>
        <div class="gift">
            <img loading="lazy" class="giftbox" src="../../../assets/images/check.png">
            <!-- <p class="claimbtn" >How to claim?</p> -->
            <p class="claimbtn" (click)="claim()">{{'Claim Bonus'|translate}}</p>
        </div>
    </div>
</div>
