import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { CasinoService } from 'src/app/services/casino.service';
import { LandingService } from 'src/app/services/landing.service';
declare var $: any
import { AuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-livecasino',
  templateUrl: './livecasino.component.html',
  styleUrls: ['./livecasino.component.scss'],
})
export class LivecasinoComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }
  showplaybutton: any = true;
  allgames: any = [];
  isScroll: boolean = false
  showhideprovider: any = true;
  gamepath = environment.gamespath;
  gameCategory: any = [];
  ezukifeaturegames: any = [];
  activeGameId: number = 11;
  isActivefavlive: any;
  istoken: any;
  favouriteGamelistcasino: any = [];
  sliderImage: any;
  casinoUrl: string;
  isLoader: boolean = false;
  Images: any;
  filteredArray: any;
  Teenpatti: boolean = false;
  casinoCategories: any = [];
  sliderlConfig = {
    slidesToShow: 3,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,

        }
      }

    ]
  };
  sliderlConfig1 = {
    slidesToShow: 6,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,

        }
      }

    ]
  };
  urlIframe: string;
  isMobile: boolean = false;
  private urlIframeSubscription: Subscription;
  isIphoneChrome = (navigator.userAgent.includes("iPhone") && navigator.userAgent.includes('CriOS'));
  isIphoneSafari = (navigator.userAgent.includes("iPhone") && !navigator.userAgent.includes('CriOS'));
  // @ts-ignore
  isSafariPWA = navigator?.standalone
  public yt: any;
  @ViewChild('iframeLoad', { static: false }) iframeLoad: ElementRef;
  title: string
  pointPopUpOpen = true



  constructor(private casinoServices: CasinoService, private faverate: LandingService, private authService: AuthService, private api: LandingService,
    private route: ActivatedRoute
  ) {
    if (this.authService.currentUserValue == null) {
      this.istoken = true;
    }
    else {
      this.istoken = false;
    }
    this.route.params.subscribe(params => {
      if (params['gameName'] === 'teenPatti' && params['gameId'] === '98789') {
        this.isLoader = true;
        this.gotoTeenPatti(params['gameId']);
        this.Teenpatti = true;
      } else {
        this.Teenpatti = false;
      }
    });
  }

  ngOnInit(): void {
    this.urlIframeSubscription = this.casinoServices.casinoURLObservable$.subscribe((url: string | null) => {
      if (!url && this.urlIframe) {
        this.clearIframe()
      }
      if (url) {
        this.openGame(url)
      }
      this.urlIframe = url;
    })
    this.checkIfMobile();
    $(window).scrollTop(0);
    this.isLoader = true;
    this.api.sliderimage().subscribe((res: any) => {
      res;
      this.sliderImage = res;
      this.Images = res?.data;
      this.filteredArray = res?.result?.inPlaySlidingBanners;
      this.isLoader = false;

    })

    // $(".slick-sliderlc").slick({
    //   slidesToShow: 1,
    //   infinite:true,
    //   slidesToScroll: 1,
    //   autoplay: true,
    //   autoplaySpeed: 2500
    //  });
    this.casinogame();

    this.favouriteGame(null, null)
  }

  gotoTeenPatti(gameId: any) {
    const data = {
      "id": '98789',
      "type": "FAWK"
    };
    this.casinoServices.getTeenPattiGameUrl(data).subscribe(
      res => {
        const url = res?.result?.urL;
        this.isLoader = false;
      },
      error => {
        console.error('TeenPatti game url error', error);
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 1024;
  }

  showbutton() {
    this.showplaybutton = !this.showplaybutton;
  }
  casinogame() {
    const casinocat = {
      "casino_cat_ids": ["11", "12", "13", "14", "15", "17", "18", "19", "20"]
    }

    this.casinoServices.casinogames(casinocat).subscribe((res: any) => {
      res?.result?.forEach((element: any) => {
        this.casinoCategories.push({ id: element?.id, name: element?.name });
      });
      this.getGamesCategoryWise(this.activeGameId);
    }

    )
    this.ezuki(44);
  }

  getGamesCategoryWise(id: any) {
    this.activeGameId = id;
    this.casinoServices.getCategoryWiseCasinoGames(id).subscribe(async (res: any) => {
      this.gameCategory = await res?.result;
    });
  }

  ezuki(provideId: any) {
    for (const games of this.ezukifeaturegames) {
      if (games.provider_id == provideId) {
        this.ezukifeaturegames.push(games);
      }
    }
  }


  goToGame(id: any, game = null) {
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = ''
    }
    if (game) {
      this.title = game?.name || game?.Trans.en
    }
    if (this.getToken()) {
      this.casinoServices.getCasinoUrl(id).subscribe(
        res => {
        },
        error => {
        }
      );
    }
    else {
      const button = document.getElementById('demo');
      button?.click();
    }

  }

  clearIframe() {
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = ''
    }
    this.title = ""
    this.urlIframe = ""
    this.casinoServices.clearCasinoURL()
  }

  openGame(iurl) { // INIT IFRAME
    FingerprintJS.load().then((fp) => {
      fp.get().then((result) => {
        console.log("finger", result)
      });
    });
    this.yt = '<iframe style="width: 100%; height: 100%" class="live-game-iframe" src="' + iurl + '" frameborder="0" id="casino-fram" allow="autoplay; encrypted-media"  allowfullscreen></iframe>';
    this.iframeLoad.nativeElement.innerHTML = this.yt;
  }
  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }

  //   favouriteGamelive(selectedgames:any,imageurl:any){

  //     this.isActivefavlive =selectedgames;
  //     this.favouriteGamelistcasino.push(imageurl);
  //     this.faverate.favouriteimage.next(this.favouriteGamelistcasino);
  //     this.casinoServices.favouritegame(imageurl);
  //   // alert("favourite games added");
  // }
  favouriteGame(selectedgames: any, imageurl: any) {

    var gamesl = localStorage.getItem("gamelist");

    if ((gamesl == null) && (imageurl != null)) {
      this.casinoServices.equipment = []
      // this.isActivefav = selectedgames;
      this.casinoServices.favouritegame(imageurl);
      this.favouriteGamelistcasino = this.casinoServices.equipment;
      this.faverate.favouriteimage.next(this.favouriteGamelistcasino);
    } else if (gamesl?.includes(imageurl)) {


      const list = this.casinoServices.equipment;

      const index = list.indexOf(imageurl);

      this.casinoServices.equipment.splice(index, 1);
      // this.casinoServices.favouritegame(list);
      localStorage.setItem("gamelist", JSON.stringify(this.casinoServices.equipment));
      this.favouriteGamelistcasino = this.casinoServices.equipment;


      // }




    } else if ((imageurl != null) && (gamesl != null)) {

      this.casinoServices.favouritegame(imageurl);
      this.favouriteGamelistcasino = this.casinoServices.equipment;
      this.faverate.favouriteimage.next(this.favouriteGamelistcasino);
    }

    else {

      return


    }

  }



  pushDataTOMyArray(val: any) {

    // this.casinoServices.favouritegame(val);

  }
  scrollToTop() {
    //console.log('Inside scrollToTop of casino component');
    $("#button").on("click", function () {
      //console.log('Inside button scrollToTop of casino component');
      $(window).scrollTop(0);
    });
  }
  setScrollbutton() {
    const $window = $(window);
    $window.on('scroll', () => {
      const windowScrollTop = $window.scrollTop();
      const windowheight = $window.outerHeight();;
      //console.log('Inside setDesktopTV of detailpage component windowScrollTop' + windowScrollTop);
      //console.log('Inside setDesktopTV of detailpage component windowScrollTop' + windowheight);

      if (windowScrollTop > windowheight) {
        this.scrollToTop();
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
    });
  }
  closePointPopUp() {
    this.pointPopUpOpen = false
  }

  ngAfterViewInit() {
    this.scrollToTop();
    this.setScrollbutton();
  }
  ngOnDestroy() {
    this.scrollToTop();
    this.setScrollbutton();
  }

}
