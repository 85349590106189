export const bankLogoLinks: Record<string, string> = {
	APMC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	ABHY: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	AMCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	AHDC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	AMDN: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	AIRP: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/AIRTEL_PAYMENT_BANK.svg",
	AJAR: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	ADCC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	AKJB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	AUCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	AJHC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	APGV: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	APBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	APGB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	TAUB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	ASBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	ARBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	AUBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/AU_SMALL_FINANCE_BANK.svg",
	ANZB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/ANZ.svg",
	TBMC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	BDBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BANDHAN_BANK.svg",
	BOFA: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BANK_OF_AMERICA.svg",
	BBKM: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	BARB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BANK_OF_BARODA.svg",
	BCEY: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BOC.svg",
	BKID: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BOI.svg",
	MAHB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BANK+OF+MAHARASTHRA.svg",
	NOSC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	BARA: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	BARC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BARCLAYS.svg",
	BACB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	BNSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	BCBM: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	BNPA: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/BPN_PARIBAS.svg",
	BMCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	CNRB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CANARA_BANK.svg",
	CLBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	CSBK: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CSB_BANK.svg",
	CBIN: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CENTAL_BANK_OF_INDIA.svg",
	CRGB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	CTCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	CITI: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	CCBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	CIUB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CITY_UNION_BANK_LTD.svg",
	COAS: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	COSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	CRLY: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CREDIT_AGRICOLE.svg",
	CRES: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/CREDIT_SUISSE.svg",
	DCUB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	DMKJ: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	DCBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/DCB_BANK.svg",
	DLSC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	DEOB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	DICG: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	DEUT: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/DEUTSCHE_BANK.svg",
	DBSS: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/DBS.svg",
	DLXB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/DHANLAXMI_BANK.svg",
	DNSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	DURG: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	EBIL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	ESFB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	ESMF: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	EIBI: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	FDRL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	FSFB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	FINO: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/FINO_PAYMENTS_BANK.svg",
	FIRN: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	GDCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	PJSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	GBCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	GSCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	HARC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	HCBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	HDFC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/HDFC_BANK.svg",
	HPSC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	HSBC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/HSBC.svg",
	DOHB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/HSBC.svg",
	HUSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	ICIC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/ICICI_BANK.svg",
	IBKL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/IDBI_BANK.svg",
	IDFB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/IDFC_FIRST_BANK.svg",
	IPOS: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/INDIA_POST_PAYMENTS_BANK.svg",
	IDIB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/INDIAN_BANK.svg",
	IOBA: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/INDIAN_OVERSEAS_BANK.svg",
	INDB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/INDUSLND_BANK.svg",
	ICBK: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	IBKO: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/INDUSTRIAL_BANK_OF_KOREA.svg",
	ITBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	JJSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	JPCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	JAKA: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/J%26K_BANK.svg",
	JSFB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	JSBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	JASB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	JANA: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	JSBP: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	JTSC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	JIOP: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/JIO_PAYMENT_BANK.svg",
	CHAS: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KAIJ: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KCCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KJSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KACE: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KANG: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KUCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KARB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/KARNATAKA_BANK_LTD.svg",
	PKGB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KSCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KVGB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KVBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KOEX: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KLGB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KSBK: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KOLH: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KBKB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	KKBK: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/KOTAK.svg",
	KNSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	MCBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	MAHG: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	MSCI: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	MSBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	MSHQ: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	MCAB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	MSNU: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	MHCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	MDBK: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	BOTM: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	MDCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	MUBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	MSLM: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	NGSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	NTBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	NMCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	NBRD: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	NBAD: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	NJBK: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	NVNM: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	TNCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	NICB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	NCUB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	NKGS: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	NESF: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	NSPB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	NNSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	ORCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	PUCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	PSBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	PYTM: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/PAYTM_PAYMENTS_BANK.svg",
	PMEC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	IBBK: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	PSIB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/PUNJAB_%26_SIND_BANK.svg",
	PUNB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/PUNJAB_NATIONAL_BANK.svg",
	TPSC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	PUSD: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	QNBA: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	RABO: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	RRBP: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	RSSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	RMGB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	RSCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	RSBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	RNSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	RDCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	RATN: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/RBL_BANK.svg",
	RBIN: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	RBIS: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SBLS: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SANT: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SNBK: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SRCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SDCE: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	TSSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SGBA: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SABR: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	STCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SKSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SHBK: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SMCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SKNB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SVSH: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	CRUB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SIDC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	MVCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SMNB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SOGE: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SJSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SIBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/SOUTH_INDIAN_BANK.svg",
	SECB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SCBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/STANDARD_CHARTERED_BANK.svg",
	SBIN: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/SBI.svg",
	SUSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SMBC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SDCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SUNB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SPCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SURY: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SUTB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	SVCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	TMBL: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	TNSC: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/TAMILNAD_MERCANTILE_BANK.svg",
	TSAB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	TTCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	TBSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	TDCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	TMSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	TJSB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	TGMB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
	UCBA: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/UCO_BANK.svg",
	UBIN: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/UNION_BANK.svg",
	HVBK: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/WOORI_BANK.svg",
	ADCB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/ADCB_BANK.svg",
	UTIB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/AXIX_BANK.svg",
	YESB: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/YES_BANK.svg",
	UJVN: "https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/UJJIVAN_SMALL_FINANCE_BANK.svg",
	DEFAULT:
		"https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/gstatic/bankIcons/Bydefualtbanklogo.svg",
};
