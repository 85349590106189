import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { CasinoService } from 'src/app/services/casino.service';
import { LandingService } from 'src/app/services/landing.service';
import FingerprintJS from "@fingerprintjs/fingerprintjs"
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-blastOff',
  templateUrl: './blastOff.component.html',
  styleUrls: ['./blastOff.component.scss']
})
export class BlastOffComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  istoken: any;
  public userToken: string;
  public operaterId = '9414';
  casinoUrl: string;
  isLoader = false;
  isScroll = false;
  filteredArray: any;
  urlIframe: string;
  public yt: any;
  @ViewChild('iframeLoad', { static: false }) iframeLoad: ElementRef;
  isIphoneChrome = (navigator.userAgent.includes("iPhone") && navigator.userAgent.includes('CriOS'));
  isIphoneSafari = (navigator.userAgent.includes("iPhone") && !navigator.userAgent.includes('CriOS'));
    // @ts-ignore
   isSafariPWA = navigator?.standalone
  title: string;
  isMobile: boolean = false;
  private urlIframeSubscription: Subscription;


  constructor(
    private authService: AuthService,
    private casinoServices: CasinoService,
  ) {
    this.istoken = this.authService.currentUserValue == null;
  }

  ngOnInit(): void {
    this.isLoader = true;
    this.getLiveGame();
    this.checkIfMobile();
    this.urlIframeSubscription = this.casinoServices.casinoURLObservable$.subscribe((url: string | null) => { //this function is being called again and again Need to FIx
      if(url){
        this.urlIframe = url;
      }else{
        console.log("!!!!!!!!!!!!!!! url", url);
        return;
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 1024;
  }

  getLiveGame() {
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = '';
    }
    this.title = 'Aviator';

    if (this.getToken()) {
      const data = {
        id: '677222',
        type: 'FAWK'
      };
      this.casinoServices.getliveGameUrl(data).subscribe((res: any) => {
        const url = res.success;
        this.urlIframe = url;
        this.openGame(url);
      });
    } else {
      // Generate token via FingerprintJS
      FingerprintJS.load().then((fp) => {
        fp.get().then(async (result) => {
          const generatedToken = result.visitorId; // Assuming visitorId is the generated token from FingerprintJS
          // this.urlIframe = `https://games.fawk.app/${generatedToken}/6666/67722-2`;
          this.urlIframe = await this.casinoServices.getliveGameUrl(`https://games.fawk.app/${generatedToken}/6666/677222`, true)
          this.openGame(this.urlIframe);
        });
      });
    }
  }


  clearIframe() {
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = '';
    }
    this.title = '';
    this.urlIframe = '';
    this.casinoServices.clearCasinoURL("blastoff");
  }

  openGame(iurl) {
    console.log('Inside openCasino of casino component');
    this.yt = '<iframe scrolling="auto" style="width: 100%; height: 100%" class="exchange-iframe" src="' + iurl + '" frameborder="0" id="casino-fram" allow="autoplay; encrypted-media"  allowfullscreen></iframe>';
    this.iframeLoad.nativeElement.innerHTML = this.yt;
    this.isLoader = false;
  }

  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0;

    if (windowScrollTop > windowHeight) {
      this.isScroll = true;
    } else {
      this.isScroll = false;
    }
  }

  ngOnDestroy() {
    console.log('Blostoff destroyed')
    this.urlIframeSubscription.unsubscribe();
  }
}
