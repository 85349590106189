<div class="card bonus-card orange-card">
    <div class="card-body">
        <div class="topbox"></div>
        <div class="bottombox"></div>
        <img loading="lazy" class="star1" src="../../../assets/images/Group.svg" >
        <img loading="lazy" class="star2" src="../../../assets/images/Group.svg" >
        <img loading="lazy" class="star3" src="../../../assets/images/Group.svg" >
        <img loading="lazy" class="star4" src="../../../assets/images/Group.svg" >

        <div class="flexbox1">
            <div class="txt">
                <p class="bonus-title"> {{'Your 500% Deposit bonus has expired'|translate}} </p>
                <!-- <div class="boxssd" style="display: flex;justify-content: space-between;">
                    <p class="pull-right">Wagering completed</p>
                    <p class="pull-left">₹1,50,000</p>
                </div> -->
                <!-- <div class="progressbar">
                    <div class="progressbg">
                        <div class="progressdot"></div>
                    </div>
                </div> -->
            </div>
            <div class="gift">
                <img loading="lazy" class="giftbox" src="../../../assets/images/goldgiftbox.png">
                <img loading="lazy" class="ban" src="../../../assets/images/ban.png">
                <p class="claimbtn" >{{'Activate Next'|translate}}</p>
            </div>
        </div>
    </div>
</div>
