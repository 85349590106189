import { Component, OnInit } from '@angular/core';
declare var $:any
import {AuthService} from '../../services/auth.service';
import { LandingService } from 'src/app/services/landing.service';
@Component({
  selector: 'app-tvgames',
  templateUrl: './tvgames.component.html',
  styleUrls: ['./tvgames.component.scss']
})
export class TvgamesComponent implements OnInit {
  istoken:any;
  filteredArray: any;
  sliderImage:any;
  Images: any;
  sliderlConfig = {
    slidesToShow: 1,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500
  };
  constructor(private authService: AuthService,private api:LandingService) { 
    if (this.authService.currentUserValue==null){
      this.istoken=true;
   }
   else{
     this.istoken=false;
   }
  }

  ngOnInit(): void {
    this.api.sliderimage().subscribe((res:any)=>{
      res;
      this.sliderImage=res;
      this.Images=res.data;
      let filteredArray = this.Images.filter((item) => item.ID == 6);
      this.filteredArray=filteredArray
   
      
    })
    // $(".slick-slidertv").slick({
    //   slidesToShow: 1,
    //   infinite:true,
    //   slidesToScroll: 1,
    //   autoplay: true,
    //   autoplaySpeed: 2500
    //  });
    // this.favouriteGame(null,null)
  }

}
