import { Component, OnInit } from '@angular/core';
import { NotifyServiceService } from './notify-service.service';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyComponent implements OnInit {


  constructor(public _notify: NotifyServiceService) { }

  ngOnInit(): void {
    
  }

  close(id): void {
    this._notify.close(id);
  }

  pause(id){
    this._notify.pause(id);
  }

}
