<div class="overlay" *ngIf="isLoader">
  <div class="loader-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <!-- <img loading="lazy" class="loader1" src="../../../../../assets/images/loader-gif.gif" alt="Your Image"> -->
    <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>

  </div>
</div>
<div class="live-bet">
  <div class="live-bet-section max-width-550">
    <div class="bet-btn-section">
      <div [ngClass]="{ 'unmatch-btn': matchedSection }" (click)="goToMatchedSection()" class="matched-btn">
        <span>{{'Matched bets'|translate}}</span>
        <div class="bet-count">
          <span>{{matchedLength}}</span>
        </div>
      </div>
      <div [ngClass]="{ 'unmatch-btn': unmatchedSection }" (click)="goToUnmatchedSection()" class="matched-btn">
        <span>{{'Unmatched bets'|translate}}</span>
        <div class="bet-count">
          <span>{{unMatchedLength}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="live-bet-page">
    <!--Unmatched Betting detail-->

    <div class="unmatched-bet-section" *ngIf="unmatchedSection">
      <div class="bet-now-box" *ngIf="unmathedBetHistoryData.length <= 0">
        <div class="watch-box">
          <img src="../../../../../assets/images/watch-img.svg" alt="" />
          <span>{{"You don't have any unmatched bets"|translate}}</span>
        </div>
        <button (click)="goToBetHistoryPage()"><span>{{'Bet now'|translate}}</span></button>
      </div>
      <ng-container *ngIf="unmathedBetHistoryData.length >= 0">
      <ng-container *ngFor="let item1 of visibleUnMatcheBets; trackBy: trackByFn">
        <app-bet-history-card [item]="item1"></app-bet-history-card>
        </ng-container>
      </ng-container>
    </div>

    <!--Matched Betting detail-->

    <div class="matched-bet-section" *ngIf="matchedSection">
      <div class="bet-now-box" *ngIf="mathedBetHistoryData.length <= 0">
        <div class="watch-box">
          <img src="../../../../../assets/images/watch-img.svg" alt="" />
          <span>{{"You don't have any matched bets"|translate}}</span>
        </div>
        <button (click)="goToBetHistoryPage()"><span>{{'Bet now'|translate}}</span></button>
      </div>
      <ng-container *ngIf="mathedBetHistoryData.length >= 0">
      <ng-container *ngFor="let item1 of visibleMatcheBets; trackBy: trackByFn">
        <app-bet-history-card [data]="item1" *ngIf="mathedBetHistoryData.length > 0"></app-bet-history-card>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
