<!-- spinning loader -->
<div class="overlay" *ngIf="isLoader">
  <div class="loader-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <!-- <img loading="lazy" class="loader1" src="../../../../../../assets/images/loader-gif.gif" alt="Your Image"> -->
    <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>


  </div>
</div>
<!-- spinning loader -->



<!--unmatched bet data-->
<div class="betting-box" *ngIf="item">
  <div class="rectangle-1"></div>
  <!-- <div class="rectangle-2"></div> -->
  <div class="betting-card">
    <div class="betting-match-card-1">
      <div class="card-heading-box">
        <div class="img-text">
          <div class="bat-logo" *ngIf="item?.eventTypeId == 4">
            <img src="../../../../../../assets/images/menu-cricket.png" alt="cricket">
          </div>
          <div class="tennis-logo" *ngIf="item?.eventTypeId == 2">
            <img class="tennis-bat-1" src="../../../../.././../assets/images/tennis-bat-logo.svg" alt="bat" />
            <img class="tennis-bat-2" src="../../../../../../assets/images/tennis-bat-logo-2.svg" alt="bat" />
          </div>

          <img *ngIf="item?.eventTypeId == 7" src="../../../../../../assets/images/menu-horse-racing.svg" alt="horse">

          <img *ngIf="item?.eventTypeId == 4339" src="../../../../../../assets/images/menu-greyhord-racing.svg"
            alt="greyhoundRacing">

          <img style="height: 1.5rem; width: 1.5rem;" *ngIf="item?.eventTypeId == 1"
            src="../../../../../../assets/images/football-logo.png" alt="">

          <span class="bet-header">{{ item?.side }} <div class="ellip">{{ item?.selectionName
              }}</div>{{'for'|translate}}
            {{ item?.size }}
          </span>
        </div>
        <img class="vector" src="../../../../../assets/images/vector.svg" alt="">
        <div [ngClass]="{'odds-btn':item?.side == 'Lay','odds-btn-1':item?.side == 'Back'}">
          <span>
            {{ item?.averagePrice }}
            {{ item?.marketBettingType }}
          </span>
        </div>
      </div>
      <div class="card-desc-box">

        <!-- <div class="betting-match-card">
          <div class="match-detail-card">
            <div class="text">
              <span class="ellip">{{cleanhhmm(item?.eventName)}}</span>
            </div>
          </div>
        </div> -->

        <ng-container *ngIf="isVs(item?.eventName)">
          <div [ngClass]="{'desc-card-1':(item?.side == 'Lay'),'card-3':(item?.side == 'Back')}">
            <span class="ellip">{{ item?.eventName | teamNameSplit : "first" }}</span>
          </div>
          <div *ngIf="item?.eventTypeId != '4339'"
            [ngClass]="{'vs-logo':(item?.side == 'Lay'),'vs-logo-1':(item?.side == 'Back')}">
            <small>{{'VS'|translate}}</small>
          </div>
          <div *ngIf="item?.eventTypeId != '4339'"
            [ngClass]="{'desc-card-2':(item?.side == 'Lay'),'card-4':(item?.side == 'Back')}">
            <span class="ellip">{{ item?.eventName | teamNameSplit : "second" }}</span>
          </div>
        </ng-container>

      </div>
    </div>
    <div class="betting-match-card-2">
      <span>{{'Bet ID :'|translate}} {{ item?.apolloBetId }}</span>
    </div>
    <div class="match">
      <div class="betting-match-card-3" style="margin-bottom: 0.44rem">
        <span class="ellip">{{ item?.marketName }}</span>
        <small>{{'Placed :'|translate}} {{item?.betPlacedDate | date : 'MMM/dd/yyyy | hh:mm:ss a'}}</small>
      </div>
    </div>
    <div (click)="cancelBet()" class="betting-cancel-btn">
      <span>{{'Cancel'|translate}}</span>
    </div>
  </div>
</div>

<!--matched bet data-->
<div class="betting-box" *ngIf="data">
  <div class="rectangle-1"></div>
  <!-- <div class="rectangle-2"></div> -->
  <div class="betting-card">
    <div class="betting-match-card-1">
      <div class="card-heading-box">
        <div class="img-text">
          <div class="bat-logo" *ngIf="data?.eventTypeId == 4">
            <img src="../../../../../../assets/images/menu-cricket.png" alt="cricket">
          </div>
          <div class="tennis-logo" *ngIf="data?.eventTypeId == 2">
            <img class="tennis-bat-1" src="../../../../.././../assets/images/tennis-bat-logo.svg" alt="bat" />
            <img class="tennis-bat-2" src="../../../../../../assets/images/tennis-bat-logo-2.svg" alt="bat" />
          </div>

          <img *ngIf="data?.eventTypeId == 7" src="../../../../../../assets/images/horse-logo.svg" alt="horse">

          <img *ngIf="data?.eventTypeId == 4339" src="../../../../../../assets/images/menu-greyhord-racing.svg"
            alt="greyhoundRacing">

          <img style="height: 1.5rem; width: 1.5rem;" *ngIf="data?.eventTypeId == 1"
            src="../../../../../../assets/images/football-logo.png" alt="">

          <span class="bet-header">{{ data?.side }} <div class="ellip">{{ data?.selectionName
              }}</div>{{'for'|translate}}
            {{ data?.size }}
          </span>
        </div>
        <img class="vector" src="../../../../../assets/images/vector.svg" alt="" />
        <div [ngClass]="{'odds-btn':data?.side == 'Lay','odds-btn-1':data?.side == 'Back'}">
          <span>
            {{ data?.averagePrice }}
            {{ data?.marketBettingType }}
          </span>
        </div>
      </div>
      <div class="card-desc-box">

        <!-- <div class="betting-match-card">
          <div class="match-detail-card">
            <div class="text">
              <span class="ellip">{{cleanhhmm(data?.eventName)}}</span>
            </div>
          </div>
        </div> -->

        <ng-container *ngIf="isVs(data?.eventName)">
          <div [ngClass]="{'desc-card-1':(data?.side == 'Lay'),'card-3':(data?.side == 'Back')}">
            <span class="ellip">{{ data?.eventName | teamNameSplit : "first" }}</span>
          </div>
          <div [ngClass]="{'vs-logo':(data?.side == 'Lay'),'vs-logo-1':(data?.side == 'Back')}">
            <small>{{'VS'|translate}}</small>
          </div>
          <div [ngClass]="{'desc-card-2':(data?.side == 'Lay'),'card-4':(data?.side == 'Back')}">
            <span class="ellip">{{ data?.eventName | teamNameSplit : "second" }}</span>
          </div>
        </ng-container>

      </div>
    </div>
    <div class="betting-match-card-2">
      <span>{{'Bet ID :'|translate}} {{ data?.apolloBetId }}</span>
    </div>
    <div class="match">
      <div class="betting-match-card-3" style="margin-bottom: 0.44rem">
        <span class="ellip">{{ data?.marketName }}</span>
        <small>{{'Placed :'|translate}} {{data?.betPlacedDate | date : 'MMM dd yyyy | hh:mm:ss a'}}</small>
      </div>
    </div>
  </div>
</div>

<!--cancel bet popup-->
<app-cancel-bet-popup class="Modal-Background max-width-550" [title]="'Cancel Bet'" (closeClick)="cancelPopup = false"
  *ngIf="cancelPopup">
  <div class="hr-line"></div>
  <div class="remove-text">
    <span>{{'Are you sure you want to cancel this bet?'|translate}}</span>
  </div>
  <div class="remove-btn">
    <button (click)="removeBet()" class="cancel-btn">
      <span>{{'Yes, Cancel'|translate}}</span>
    </button>
    <button (click)="betNotCancel()" class="no-btn"><span>{{'No'|translate}}</span></button>
  </div>
</app-cancel-bet-popup>
