import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { AccountlayoutComponent } from './accountlayout/accountlayout.component';
import { ClientdetailsComponent } from './clientdetails/clientdetails.component';
import { AccountheaderComponent } from './accountheader/accountheader.component';
import { BethistoryComponent } from './clientdetails/bethistory/bethistory.component';
import { BetbuilderComponent } from './clientdetails/betbuilder/betbuilder.component';
import { BalancemanagementComponent } from './clientdetails/balancemanagement/balancemanagement.component';
import { BonusesComponent } from './clientdetails/bonuses/bonuses.component';
import { BonusDetailesComponent } from './clientdetails/bonusDetailes/bonusDetailes.component';

import { MyprofileComponent } from './clientdetails/myprofile/myprofile.component';
import { MessagesComponent } from './clientdetails/messages/messages.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ClipboardModule } from 'ngx-clipboard';
import { SharedModule, playerFactory } from "../shared.module";
import { DepositStepperComponent } from './clientdetails/balancemanagement/deposit-stepper/deposit-stepper.component';
import { BalancePopupComponent } from './clientdetails/balancemanagement/balance-popup/balance-popup.component';
import { CenterPopupComponent } from './clientdetails/balancemanagement/center-popup/center-popup.component';
import { WithdrawStepperComponent } from './clientdetails/balancemanagement/withdraw-stepper/withdraw-stepper.component';
// import { CelebPromoComponent } from '../../shared/celeb-promo/celeb-promo.component';
import { UpiPopupComponent } from './clientdetails/balancemanagement/upi-popup/upi-popup.component';
import { HowToBonusComponent } from './clientdetails/how-to-bonus/how-to-bonus.component';
import { HowToBonus2Component } from './clientdetails/how-to-bonus/how-to-bonus2/how-to-bonus2.component';
import { FrameBoxComponent } from './clientdetails/how-to-bonus/frame-box/frame-box.component';
import { FrameBox2Component } from './clientdetails/how-to-bonus/frame-box2/frame-box2.component';
import { SwitchComponent } from './clientdetails/myprofile/switch/switch.component';
import { CancelBetPopupComponent } from './clientdetails/bethistory/cancel-bet-popup/cancel-bet-popup.component';
import { BetHistoryCardComponent } from './clientdetails/bethistory/bet-history-card/bet-history-card.component';
import { DepositWithdrawReportComponent } from './clientdetails/deposit-withdraw-report/deposit-withdraw-report.component';
import { DepositWithdrawReportPopupComponent } from './clientdetails/deposit-withdraw-report/deposit-withdraw-report-popup/deposit-withdraw-report-popup.component';
import { ProfileHeaderComponent } from './clientdetails/profile-header/profile-header.component';
import { DepositDetailsComponent } from './clientdetails/deposit-withdraw-report/deposit-details/deposit-details.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { LottieModule } from 'ngx-lottie';
import { LoyaltyProgramComponent } from './clientdetails/loyalty-program/loyalty-program.component';
import { LoyaltyPopupComponent } from './clientdetails/loyalty-program/loyalty-popup/loyalty-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { AccountStatementComponent } from './clientdetails/balancemanagement/account-statement/account-statement.component';
import { CalendarComponent } from '../calendar/calendar.component';



// import { HowToBonusComponent } from '../bonuses-cards/how-to-bonus/how-to-bonus.component';
// import { HowToBonus2Component } from '../bonuses-cards/how-to-bonus/how-to-bonus2/how-to-bonus2.component';

@NgModule({
  declarations: [
    AccountlayoutComponent,
    ClientdetailsComponent,
    AccountheaderComponent,
    BethistoryComponent,
    BetbuilderComponent,
    BalancemanagementComponent,
    BonusesComponent,
    MyprofileComponent,
    MessagesComponent,
    BonusDetailesComponent,
    DepositStepperComponent,
    BalancePopupComponent,
    CenterPopupComponent,
    WithdrawStepperComponent,
    // CelebPromoComponent,
    UpiPopupComponent,
    HowToBonusComponent,
    HowToBonus2Component,
    FrameBoxComponent,
    FrameBox2Component,
    SwitchComponent,
    CancelBetPopupComponent,
    BetHistoryCardComponent,
    DepositWithdrawReportComponent,
    DepositWithdrawReportPopupComponent,
    ProfileHeaderComponent,
    DepositDetailsComponent,
    LoyaltyProgramComponent,
    LoyaltyPopupComponent,
    AccountStatementComponent,
  ],
  imports: [MatProgressSpinnerModule, ClipboardModule, FormsModule, CommonModule, QRCodeModule, AccountRoutingModule, ReactiveFormsModule, MatDatepickerModule, MatFormFieldModule, MatNativeDateModule, MatInputModule, SharedModule, LottieModule.forRoot({ player: playerFactory }), LottieAnimationViewModule.forRoot(), TranslateModule],
  exports: [AccountlayoutComponent, BonusDetailesComponent, BethistoryComponent],
})
export class AccountModule {}
