import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotifyServiceService } from 'src/app/notify/notify-service.service';
import { LandingService } from 'src/app/services/landing.service';

@Component({
  selector: 'app-redeem-gift-card',
  templateUrl: './redeem-gift-card.component.html',
  styleUrls: ['./redeem-gift-card.component.scss']
})
export class RedeemGiftCardComponent implements OnInit {

  redeemValue: string = '';

  constructor(private _landing: LandingService, private _notify: NotifyServiceService) { }

  ngOnInit(): void {
  }

  redeemPromo(promoCode: string) {
    if (promoCode === '') {
      this._notify.error('Please enter a promo code');
      return;
    }
    this._landing?.redeemPromoCode(promoCode).subscribe(
      (res) => {
        this.redeemValue = '';
        if (res.success === true) {
          this._notify.success('Promo code redeemed successfully');
        } else {
          this._notify.error(res.error);
        }
      },
      (err) => {
        this.redeemValue = '';
        console.log(err);
        this._notify.error(err.error.error);
      }
    );
  }

}
