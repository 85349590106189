import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import {MyprofileService} from "../../services/myprofile.service";

@Component({
  selector: 'app-bonus',
  templateUrl: './bonus.component.html',
  styleUrls: ['./bonus.component.scss']
})
export class BonusComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  isLoader = false;
  bonusData: any;

  constructor (private profileServices:MyprofileService,) {

  }

  ngOnInit(): void {
    this.isLoader = true;
    this.getLiveGame();
  }

  getLiveGame() {
    this.profileServices.getPromotion().subscribe((res)=>{
      this.bonusData =res.result;
      this.isLoader = false;
      console.log("!!!!!!!!!!!! bonusData", this.bonusData)
    })
    }

  openGame(item) {
    console.log('!!!! Open', item);
    this.isLoader = false;
  }
}
