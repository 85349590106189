import {HttpClient, HttpHeaders,HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { tap, retry, shareReplay, timeout } from 'rxjs/operators';
import { Observable, BehaviorSubject, empty, finalize, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import {User} from '../shared/models/user';
import {RequestTrackerService} from "./request-tracker.service";
import { NotifyServiceService } from '../notify/notify-service.service';
import { GoogleConfigResponse } from '../models/google-config.interface';
type TODO = any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  versionPopUpOpen = false;
  updateKycPopUp = false

  public currentUserSubject: BehaviorSubject<any>;
  currentUserData$: Observable<any>;
  public currentUser: Observable<any>;
  private currentIsLoggedInsubject: BehaviorSubject<boolean>;
  public currentIsloggedIn: Observable<boolean>;
  loggedToken: any;
  API_ODDS_URL = environment.API_ODDS_URL;
  API_URL = environment.API_URL;
 API_GAME_URL = environment.API_GAME_URL;
 API_Event_URL= environment.API_Event_URL;
 membercode =localStorage.getItem('Member_code');
  private googleConfigSubject: BehaviorSubject<object | null> = new BehaviorSubject<object | null>(null);
  googleConfigObservable$: Observable<object | null> = this.googleConfigSubject.asObservable();
  public sportsId: any = 4;
  public updateSportsId$ = new Subject<void>();
  private sportsIdSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  sportsIdObservable$: Observable<any> = this.sportsIdSubject.asObservable();
  isMobile: boolean;
  detailsPageDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  detailsPageDataObservable$: Observable<any> = this.detailsPageDataSubject.asObservable();
  activeMenuItemId: BehaviorSubject<string>= new BehaviorSubject<string>(null);
  activeMenuItemIdObservable$: Observable<any> = this.activeMenuItemId.asObservable();

  private inplayState: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient, private toastr: NotifyServiceService, private requestTracker: RequestTrackerService) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userData')));
    this.currentUser = this.currentUserSubject.asObservable();
    //localStorage.removeItem('ROYALTOKEN_KEY');
    }

  updateDetailsPageData(newData: any) {
    this.detailsPageDataSubject.next(newData);
  }

  updateSportsId(newData: any) {
    this.sportsId = newData;
    this.sportsIdSubject.next(this.sportsId);
  }

  updateActiveMenuItem(newData: TODO) {
      this.activeMenuItemId.next(newData);
  }

    getvkbetsdata(sportId,eid) {
      if (this.requestTracker.isRequestPending('sma-event')) {
        return empty();
      }

      return this.requestTracker.trackRequest('sma-event',  this.http.get<any>(this.API_URL + 'exchange/odds/sma-event/'+ sportId + '/' + eid) // , {headers: headers}
        .pipe(
          retry(0),
          tap(res => {
            if ([444, 403].indexOf(res.code) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            return res;
          }),
          shareReplay(),
          finalize(() => {
            this.requestTracker.markRequestAsComplete('sma-event');
          })
        ))
    }

  getMatchSummeryData() {
    if (localStorage.getItem('ROYALTOKEN_KEY')) {
      return this.http.get<any>(this.API_Event_URL + 'exchange/odds/sma-menu') // , {headers: headers}
        .pipe(
          retry(0),
          tap(res => {
            if ([444, 403].indexOf(res.code) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            return res;
          }),
          shareReplay()
        );
    } else {
      return this.http.get<any>(this.API_URL + 'exchange/odds/sma-menu') // , {headers: headers}
        .pipe(
          retry(0),
          tap(res => {
            if ([444, 403].indexOf(res.code) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            return res;
          }),
          shareReplay()
        );
    }
  }

    getProfitandLoss(eid) {
      return this.http.get<any>(this.API_URL + 'exposure/pnl/'+ this.membercode + '/event/' + eid) // , {headers: headers}
        .pipe(
          retry(0),
          tap(res => {
            if ([444, 403].indexOf(res.code) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            return res;
          }),
          shareReplay()
        );
    }
    getNotification() {
      return this.http.get<any>(this.API_Event_URL + 'account/'+ this.membercode + '/notification/message') // , {headers: headers}
        .pipe(
          retry(0),
          tap(res => {
            if ([444, 403].indexOf(res.code) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            return res;
          }),
          shareReplay()
        );
    }
    getLadderdetail(eid) {
      return this.http.get<any>(this.API_URL + 'exposure/pnl/'+ this.membercode + '/markets/' + eid + '/ladder') // , {headers: headers}
        .pipe(
          retry(0),
          tap(res => {
            if ([444, 403].indexOf(res.code) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            return res;
          }),
          shareReplay()
        );
    }



  public get currentUserValue(): any {
      return this.currentUserSubject.value;
    }
  public setCurrentUserValue(user: any) {
    this.currentUserSubject.next(user);
  }

    Login(data): Observable<HttpResponse<any>>{
      return this.http.post(this.API_Event_URL + 'auth/login', data,{ observe: 'response' });
    }

  gLogin(data: any): Observable<HttpResponse<any>> {
    return this.http.post(this.API_Event_URL + 'auth/glogin', data, { observe: 'response' });
  }

    waLogin(data:any): Observable<HttpResponse<any>>{
      return this.http.post(this.API_Event_URL + 'auth/walogin', data,{ observe: 'response' });
    }


    Register(data): Observable<HttpResponse<any>>{
      let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*',
         'content-type': 'application/json',
         'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJDb2RlIjoiVDEwMTAyMDFNMDMiLCJ0b2tlbklkIjoiYWEzOGVkYWFmYjg0OTIzMTJlOWE4OGRkODhlM2JkZjA1NGJiZDljZjhmMGVkZDE2NTEyNjA0ZDYwNDk3OWRiZCIsImxvZ2luQ291bnRyeSI6IklOIiwic2Vzc2lvbklkIjoiMjczMTFlMzVmNGFhZmZmODAxNDcwYzMxNWU4ZTEwMjk5MTU1NDQxMTg2MzZkYWI0ZTFmMzY5MGI5ZGI1NzgwNiIsImFsbG93U2hha3RpUHJvIjpmYWxzZSwibGFzdExvZ2luVGltZSI6MTY3ODg4NzMxNjExNiwibmJmIjoxNjc4ODg4MTYzLCJsb2dpbk5hbWUiOiJ2YmV0MSIsImxvZ2luSVAiOiIyMDIuMTQ5LjIwNC43MiIsInRoZW1lIjoibG90dXMiLCJleHAiOjE2Nzg4OTE3NjMsImlhdCI6MTY3ODg4ODE2MywibWVtYmVySWQiOjMxOTkwODcsInVwbGluZXMiOnsiQ09ZIjp7InVzZXJJZCI6MSwidXNlckNvZGUiOiJhZG1pbi51c2VyIn0sIlNNQSI6eyJ1c2VySWQiOjY1NTU4MiwidXNlckNvZGUiOiJUMTAxIn0sIk1BIjp7InVzZXJJZCI6OTQwMTI3LCJ1c2VyQ29kZSI6IlQxMDEwMiJ9LCJBZ2VudCI6eyJ1c2VySWQiOjk0MDEzMCwidXNlckNvZGUiOiJUMTAxMDIwMSJ9fSwiY3VycmVuY3kiOiJJTlIiLCJpc0RlbW8iOmZhbHNlLCJtYSI6bnVsbCwiYiI6bnVsbCwicyI6bnVsbCwiYyI6bnVsbH0.cSNkeEE5H7Kd5d9F904fHlKWIa5aB7LkW_-FypMZswI',
         'x-app-version':  '4.0.19.2',
         'x-client':  'mobile',
         'x-client-id':  '476843353.1602776689',
         'x-client-info':  'f582f6103068ba74fe67f8ee1e0ee274',
         'x-log-timing':  'true',
         'x-user-id':  'HZ010101M1H',
         'x-xid':  'bc1cac1e-94d2-6b09-e09f-70f42ea11fd5'
        }  );
      return this.http.post(this.API_Event_URL + 'auth/registerNumber', data,{ observe: 'response' });
    }
  checkOTP(data): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
      'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJDb2RlIjoiVDEwMTAyMDFNMDMiLCJ0b2tlbklkIjoiYWEzOGVkYWFmYjg0OTIzMTJlOWE4OGRkODhlM2JkZjA1NGJiZDljZjhmMGVkZDE2NTEyNjA0ZDYwNDk3OWRiZCIsImxvZ2luQ291bnRyeSI6IklOIiwic2Vzc2lvbklkIjoiMjczMTFlMzVmNGFhZmZmODAxNDcwYzMxNWU4ZTEwMjk5MTU1NDQxMTg2MzZkYWI0ZTFmMzY5MGI5ZGI1NzgwNiIsImFsbG93U2hha3RpUHJvIjpmYWxzZSwibGFzdExvZ2luVGltZSI6MTY3ODg4NzMxNjExNiwibmJmIjoxNjc4ODg4MTYzLCJsb2dpbk5hbWUiOiJ2YmV0MSIsImxvZ2luSVAiOiIyMDIuMTQ5LjIwNC43MiIsInRoZW1lIjoibG90dXMiLCJleHAiOjE2Nzg4OTE3NjMsImlhdCI6MTY3ODg4ODE2MywibWVtYmVySWQiOjMxOTkwODcsInVwbGluZXMiOnsiQ09ZIjp7InVzZXJJZCI6MSwidXNlckNvZGUiOiJhZG1pbi51c2VyIn0sIlNNQSI6eyJ1c2VySWQiOjY1NTU4MiwidXNlckNvZGUiOiJUMTAxIn0sIk1BIjp7InVzZXJJZCI6OTQwMTI3LCJ1c2VyQ29kZSI6IlQxMDEwMiJ9LCJBZ2VudCI6eyJ1c2VySWQiOjk0MDEzMCwidXNlckNvZGUiOiJUMTAxMDIwMSJ9fSwiY3VycmVuY3kiOiJJTlIiLCJpc0RlbW8iOmZhbHNlLCJtYSI6bnVsbCwiYiI6bnVsbCwicyI6bnVsbCwiYyI6bnVsbH0.cSNkeEE5H7Kd5d9F904fHlKWIa5aB7LkW_-FypMZswI',
      'x-app-version': '4.0.19.2',
      'x-client': 'mobile',
      'x-client-id': '476843353.1602776689',
      'x-client-info': 'f582f6103068ba74fe67f8ee1e0ee274',
      'x-log-timing': 'true',
      'x-user-id': 'HZ010101M1H',
      'x-xid': 'bc1cac1e-94d2-6b09-e09f-70f42ea11fd5'
    });
    return this.http.post(this.API_Event_URL + 'auth/checkOtp', data, { observe: 'response' });
  }
    verifyOTP(data): Observable<HttpResponse<any>>{
      let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*',
         'content-type': 'application/json',
         'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJDb2RlIjoiVDEwMTAyMDFNMDMiLCJ0b2tlbklkIjoiYWEzOGVkYWFmYjg0OTIzMTJlOWE4OGRkODhlM2JkZjA1NGJiZDljZjhmMGVkZDE2NTEyNjA0ZDYwNDk3OWRiZCIsImxvZ2luQ291bnRyeSI6IklOIiwic2Vzc2lvbklkIjoiMjczMTFlMzVmNGFhZmZmODAxNDcwYzMxNWU4ZTEwMjk5MTU1NDQxMTg2MzZkYWI0ZTFmMzY5MGI5ZGI1NzgwNiIsImFsbG93U2hha3RpUHJvIjpmYWxzZSwibGFzdExvZ2luVGltZSI6MTY3ODg4NzMxNjExNiwibmJmIjoxNjc4ODg4MTYzLCJsb2dpbk5hbWUiOiJ2YmV0MSIsImxvZ2luSVAiOiIyMDIuMTQ5LjIwNC43MiIsInRoZW1lIjoibG90dXMiLCJleHAiOjE2Nzg4OTE3NjMsImlhdCI6MTY3ODg4ODE2MywibWVtYmVySWQiOjMxOTkwODcsInVwbGluZXMiOnsiQ09ZIjp7InVzZXJJZCI6MSwidXNlckNvZGUiOiJhZG1pbi51c2VyIn0sIlNNQSI6eyJ1c2VySWQiOjY1NTU4MiwidXNlckNvZGUiOiJUMTAxIn0sIk1BIjp7InVzZXJJZCI6OTQwMTI3LCJ1c2VyQ29kZSI6IlQxMDEwMiJ9LCJBZ2VudCI6eyJ1c2VySWQiOjk0MDEzMCwidXNlckNvZGUiOiJUMTAxMDIwMSJ9fSwiY3VycmVuY3kiOiJJTlIiLCJpc0RlbW8iOmZhbHNlLCJtYSI6bnVsbCwiYiI6bnVsbCwicyI6bnVsbCwiYyI6bnVsbH0.cSNkeEE5H7Kd5d9F904fHlKWIa5aB7LkW_-FypMZswI',
         'x-app-version':  '4.0.19.2',
         'x-client':  'mobile',
         'x-client-id':  '476843353.1602776689',
         'x-client-info':  'f582f6103068ba74fe67f8ee1e0ee274',
         'x-log-timing':  'true',
         'x-user-id':  'HZ010101M1H',
         'x-xid':  'bc1cac1e-94d2-6b09-e09f-70f42ea11fd5'
        }  );
      return this.http.post(this.API_Event_URL + 'auth/verifyOtp', data,{ observe: 'response' });
    }

  verifyForgotPasswordOtp(data): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'content-type': 'application/json',
      'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJDb2RlIjoiVDEwMTAyMDFNMDMiLCJ0b2tlbklkIjoiYWEzOGVkYWFmYjg0OTIzMTJlOWE4OGRkODhlM2JkZjA1NGJiZDljZjhmMGVkZDE2NTEyNjA0ZDYwNDk3OWRiZCIsImxvZ2luQ291bnRyeSI6IklOIiwic2Vzc2lvbklkIjoiMjczMTFlMzVmNGFhZmZmODAxNDcwYzMxNWU4ZTEwMjk5MTU1NDQxMTg2MzZkYWI0ZTFmMzY5MGI5ZGI1NzgwNiIsImFsbG93U2hha3RpUHJvIjpmYWxzZSwibGFzdExvZ2luVGltZSI6MTY3ODg4NzMxNjExNiwibmJmIjoxNjc4ODg4MTYzLCJsb2dpbk5hbWUiOiJ2YmV0MSIsImxvZ2luSVAiOiIyMDIuMTQ5LjIwNC43MiIsInRoZW1lIjoibG90dXMiLCJleHAiOjE2Nzg4OTE3NjMsImlhdCI6MTY3ODg4ODE2MywibWVtYmVySWQiOjMxOTkwODcsInVwbGluZXMiOnsiQ09ZIjp7InVzZXJJZCI6MSwidXNlckNvZGUiOiJhZG1pbi51c2VyIn0sIlNNQSI6eyJ1c2VySWQiOjY1NTU4MiwidXNlckNvZGUiOiJUMTAxIn0sIk1BIjp7InVzZXJJZCI6OTQwMTI3LCJ1c2VyQ29kZSI6IlQxMDEwMiJ9LCJBZ2VudCI6eyJ1c2VySWQiOjk0MDEzMCwidXNlckNvZGUiOiJUMTAxMDIwMSJ9fSwiY3VycmVuY3kiOiJJTlIiLCJpc0RlbW8iOmZhbHNlLCJtYSI6bnVsbCwiYiI6bnVsbCwicyI6bnVsbCwiYyI6bnVsbH0.cSNkeEE5H7Kd5d9F904fHlKWIa5aB7LkW_-FypMZswI',
      'x-app-version': '4.0.19.2',
      'x-client': 'mobile',
      'x-client-id': '476843353.1602776689',
      'x-client-info': 'f582f6103068ba74fe67f8ee1e0ee274',
      'x-log-timing': 'true',
      'x-user-id': 'HZ010101M1H',
      'x-xid': 'bc1cac1e-94d2-6b09-e09f-70f42ea11fd5'
    });
    return this.http.post(this.API_Event_URL + 'auth/verifyForgotPasswordOtp', data, { observe: 'response' });
  }

    RsendOTP(data): Observable<HttpResponse<any>>{
      let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*',
         'content-type': 'application/json',
         'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJDb2RlIjoiVDEwMTAyMDFNMDMiLCJ0b2tlbklkIjoiYWEzOGVkYWFmYjg0OTIzMTJlOWE4OGRkODhlM2JkZjA1NGJiZDljZjhmMGVkZDE2NTEyNjA0ZDYwNDk3OWRiZCIsImxvZ2luQ291bnRyeSI6IklOIiwic2Vzc2lvbklkIjoiMjczMTFlMzVmNGFhZmZmODAxNDcwYzMxNWU4ZTEwMjk5MTU1NDQxMTg2MzZkYWI0ZTFmMzY5MGI5ZGI1NzgwNiIsImFsbG93U2hha3RpUHJvIjpmYWxzZSwibGFzdExvZ2luVGltZSI6MTY3ODg4NzMxNjExNiwibmJmIjoxNjc4ODg4MTYzLCJsb2dpbk5hbWUiOiJ2YmV0MSIsImxvZ2luSVAiOiIyMDIuMTQ5LjIwNC43MiIsInRoZW1lIjoibG90dXMiLCJleHAiOjE2Nzg4OTE3NjMsImlhdCI6MTY3ODg4ODE2MywibWVtYmVySWQiOjMxOTkwODcsInVwbGluZXMiOnsiQ09ZIjp7InVzZXJJZCI6MSwidXNlckNvZGUiOiJhZG1pbi51c2VyIn0sIlNNQSI6eyJ1c2VySWQiOjY1NTU4MiwidXNlckNvZGUiOiJUMTAxIn0sIk1BIjp7InVzZXJJZCI6OTQwMTI3LCJ1c2VyQ29kZSI6IlQxMDEwMiJ9LCJBZ2VudCI6eyJ1c2VySWQiOjk0MDEzMCwidXNlckNvZGUiOiJUMTAxMDIwMSJ9fSwiY3VycmVuY3kiOiJJTlIiLCJpc0RlbW8iOmZhbHNlLCJtYSI6bnVsbCwiYiI6bnVsbCwicyI6bnVsbCwiYyI6bnVsbH0.cSNkeEE5H7Kd5d9F904fHlKWIa5aB7LkW_-FypMZswI',
         'x-app-version':  '4.0.19.2',
         'x-client':  'mobile',
         'x-client-id':  '476843353.1602776689',
         'x-client-info':  'f582f6103068ba74fe67f8ee1e0ee274',
         'x-log-timing':  'true',
         'x-user-id':  'HZ010101M1H',
         'x-xid':  'bc1cac1e-94d2-6b09-e09f-70f42ea11fd5'
        }  );
      return this.http.post(this.API_Event_URL + 'auth/resendOtp', data,{ observe: 'response' });
    }

    forgotPass(data): Observable<HttpResponse<any>>{
      let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*',
         'content-type': 'application/json',
         'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJDb2RlIjoiVDEwMTAyMDFNMDMiLCJ0b2tlbklkIjoiYWEzOGVkYWFmYjg0OTIzMTJlOWE4OGRkODhlM2JkZjA1NGJiZDljZjhmMGVkZDE2NTEyNjA0ZDYwNDk3OWRiZCIsImxvZ2luQ291bnRyeSI6IklOIiwic2Vzc2lvbklkIjoiMjczMTFlMzVmNGFhZmZmODAxNDcwYzMxNWU4ZTEwMjk5MTU1NDQxMTg2MzZkYWI0ZTFmMzY5MGI5ZGI1NzgwNiIsImFsbG93U2hha3RpUHJvIjpmYWxzZSwibGFzdExvZ2luVGltZSI6MTY3ODg4NzMxNjExNiwibmJmIjoxNjc4ODg4MTYzLCJsb2dpbk5hbWUiOiJ2YmV0MSIsImxvZ2luSVAiOiIyMDIuMTQ5LjIwNC43MiIsInRoZW1lIjoibG90dXMiLCJleHAiOjE2Nzg4OTE3NjMsImlhdCI6MTY3ODg4ODE2MywibWVtYmVySWQiOjMxOTkwODcsInVwbGluZXMiOnsiQ09ZIjp7InVzZXJJZCI6MSwidXNlckNvZGUiOiJhZG1pbi51c2VyIn0sIlNNQSI6eyJ1c2VySWQiOjY1NTU4MiwidXNlckNvZGUiOiJUMTAxIn0sIk1BIjp7InVzZXJJZCI6OTQwMTI3LCJ1c2VyQ29kZSI6IlQxMDEwMiJ9LCJBZ2VudCI6eyJ1c2VySWQiOjk0MDEzMCwidXNlckNvZGUiOiJUMTAxMDIwMSJ9fSwiY3VycmVuY3kiOiJJTlIiLCJpc0RlbW8iOmZhbHNlLCJtYSI6bnVsbCwiYiI6bnVsbCwicyI6bnVsbCwiYyI6bnVsbH0.cSNkeEE5H7Kd5d9F904fHlKWIa5aB7LkW_-FypMZswI',
         'x-app-version':  '4.0.19.2',
         'x-client':  'mobile',
         'x-client-id':  '476843353.1602776689',
         'x-client-info':  'f582f6103068ba74fe67f8ee1e0ee274',
         'x-log-timing':  'true',
         'x-user-id':  'HZ010101M1H',
         'x-xid':  'bc1cac1e-94d2-6b09-e09f-70f42ea11fd5'
        }  );
      return this.http.post(this.API_Event_URL + 'auth/forgotPassword', data,{ observe: 'response' });
    }

    verifyforgotPass(data): Observable<HttpResponse<any>>{
      let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*',
         'content-type': 'application/json',
         'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJDb2RlIjoiVDEwMTAyMDFNMDMiLCJ0b2tlbklkIjoiYWEzOGVkYWFmYjg0OTIzMTJlOWE4OGRkODhlM2JkZjA1NGJiZDljZjhmMGVkZDE2NTEyNjA0ZDYwNDk3OWRiZCIsImxvZ2luQ291bnRyeSI6IklOIiwic2Vzc2lvbklkIjoiMjczMTFlMzVmNGFhZmZmODAxNDcwYzMxNWU4ZTEwMjk5MTU1NDQxMTg2MzZkYWI0ZTFmMzY5MGI5ZGI1NzgwNiIsImFsbG93U2hha3RpUHJvIjpmYWxzZSwibGFzdExvZ2luVGltZSI6MTY3ODg4NzMxNjExNiwibmJmIjoxNjc4ODg4MTYzLCJsb2dpbk5hbWUiOiJ2YmV0MSIsImxvZ2luSVAiOiIyMDIuMTQ5LjIwNC43MiIsInRoZW1lIjoibG90dXMiLCJleHAiOjE2Nzg4OTE3NjMsImlhdCI6MTY3ODg4ODE2MywibWVtYmVySWQiOjMxOTkwODcsInVwbGluZXMiOnsiQ09ZIjp7InVzZXJJZCI6MSwidXNlckNvZGUiOiJhZG1pbi51c2VyIn0sIlNNQSI6eyJ1c2VySWQiOjY1NTU4MiwidXNlckNvZGUiOiJUMTAxIn0sIk1BIjp7InVzZXJJZCI6OTQwMTI3LCJ1c2VyQ29kZSI6IlQxMDEwMiJ9LCJBZ2VudCI6eyJ1c2VySWQiOjk0MDEzMCwidXNlckNvZGUiOiJUMTAxMDIwMSJ9fSwiY3VycmVuY3kiOiJJTlIiLCJpc0RlbW8iOmZhbHNlLCJtYSI6bnVsbCwiYiI6bnVsbCwicyI6bnVsbCwiYyI6bnVsbH0.cSNkeEE5H7Kd5d9F904fHlKWIa5aB7LkW_-FypMZswI',
         'x-app-version':  '4.0.19.2',
         'x-client':  'mobile',
         'x-client-id':  '476843353.1602776689',
         'x-client-info':  'f582f6103068ba74fe67f8ee1e0ee274',
         'x-log-timing':  'true',
         'x-user-id':  'HZ010101M1H',
         'x-xid':  'bc1cac1e-94d2-6b09-e09f-70f42ea11fd5'
        }  );
      return this.http.post(this.API_Event_URL + 'auth/verifyforgotPassword', data,{ observe: 'response' });
    }

    geteventdata() {
      if (this.requestTracker.isRequestPending('inPlayAndPopularEvents')) {
        return empty();
      }
      return this.requestTracker.trackRequest('inPlayAndPopularEvents', this.http.get<any>(this.API_URL + 'exchange/odds/inPlayAndPopularEvents') // , {headers: headers}
        .pipe(
          retry(0),
          tap(res => {
            if ([444, 403].indexOf(res.code) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            this.inplayState.next(res)
            return res;
          }),
          shareReplay(),
          finalize(() => {
            this.requestTracker.markRequestAsComplete('inPlayAndPopularEvents');
          })
        ))
    }
    getEventState(): Observable<any> {
      return this.inplayState.asObservable();
    }

    getConfig() {
      // dev
      // return this.http.get<any>('https://api.gemexch.co/api/config/google-config') // , {headers: headers}
      // prod
      return this.http.get<any>(this.API_Event_URL + 'config/google-config') // , {headers: headers}
        .pipe(
          retry(0),
          tap(res => {
            if ([444, 403].indexOf(res.code) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            this.googleConfigSubject.next(res);
            return res;
          }),
          shareReplay()
        );
    }
  // getsportdata(id) {
  //   return this.http.get<TODO>(`${this.API_URL}exchange/odds/inplayEvents/${id}`) // , {headers: headers}
  //     .pipe(
  //       retry(0),
  //       tap(res => {
  //         if ([444, 403].indexOf(res.code) !== -1) {
  //           // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
  //         }
  //         return res;
  //       }),
  //       shareReplay()
  //     );
  // }
    showSuccess(data:string) {
    this.toastr.success(data + '', 5000);
    }
    showError(data:string) {
    this.toastr.error(data + '', 5000 );
    }
    getUpcomingdata(id) {
      if (this.requestTracker.isRequestPending('eventType')) {
        return empty();
      }

      return this.requestTracker.trackRequest('eventType',this.http.get<any>(this.API_URL + 'exchange/odds/eventType/' + id) // , {headers: headers}
        .pipe(
          retry(0),
          tap(res => {
            if ([444, 403].indexOf(res.code) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            return res;
          }),
          shareReplay(),
          finalize(() => {
            this.requestTracker.markRequestAsComplete('eventType');
          })
        ))
    }
    getHorseandGreyRacing(id) {
      return this.http.get<any>(this.API_URL + 'exchange/odds/races/' + id) // , {headers: headers}
        .pipe(
          retry(0),
          tap(res => {
            if ([444, 403].indexOf(res.code) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            return res;
          }),
          shareReplay()
        );
    }
    getHorseandGreyRacingdet(id,mid) {
      if (this.requestTracker.isRequestPending('odds-race')) {
        return empty();
      }

      return this.requestTracker.trackRequest('odds-race', this.http.get<any>(this.API_URL + 'exchange/odds/race/' + id +'/' + mid) // , {headers: headers}
        .pipe(
          retry(0),
          tap(res => {
            if ([444, 403].indexOf(res.code) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            return res;
          }),
          shareReplay(),
          finalize(() => {
            this.requestTracker.markRequestAsComplete('odds-race');
          })
        ))
    }
    getTvURl(data) {
         let id= data.eventId ;
       let sportId=data.sportId;
    return this.http.get<any>(this.API_Event_URL + 'match-center/stats/' + sportId + '/' + id + '/video') // , {headers: headers}
        .pipe(
          retry(0),
          tap(res => {
            if ([444, 403].indexOf(res.code) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            return res;
          }),
          shareReplay()
        );
    }

    getTvstaus(data) {
      let id= data.eventId ;
    let sportId=data.sportId;
    this.loggedToken = JSON.parse(localStorage.getItem('ROYALTOKEN_KEY'));
    if(this.loggedToken==null){
      return this.http.get<any>('https://7exch.com/api/public/match-center/stats/' + sportId + '/' + id) // , {headers: headers}
      .pipe(
        retry(0),
        tap(res => {
          if ([444, 403].indexOf(res.code) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          }
          return res;
        }),
        shareReplay()
      );
    }else{
   return this.http.get<any>('https://7exch.com/api/match-center/stats/' + sportId + '/' +id) // , {headers: headers}
     .pipe(
       retry(0),
       tap(res => {
         if ([444, 403].indexOf(res.code) !== -1) {
           // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
         }
         return res;
       }),
       shareReplay()
     );
 }
  }

  getStats(data) {
    let id= data.eventId ;
    let sportId=data.sportId;
    this.loggedToken = JSON.parse(localStorage.getItem('ROYALTOKEN_KEY'));
    if(this.loggedToken==null){
    return this.http.post<any>('https://7exch.com/api/open/match-center/stats/access-token/', { eventTypeId: sportId, eventId: id }) // , {headers: headers}
      .pipe(
        retry(0),
        tap(res => {
          if ([444, 403].indexOf(res.code) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          }
          return res;
        }),
        shareReplay()
      );
    } else {
    return this.http.post<any>('https://7exch.com/api/match-center/stats/access-token/', {eventTypeId: sportId, eventId: id}) // , {headers: headers}
      .pipe(
        retry(0),
        tap(res => {
          if ([444, 403].indexOf(res.code) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          }
          return res;
        }),
        shareReplay()
      );
  }
  }

    betplace(data) {
      /*let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*',
         'content-type': 'application/json',
         'Authorization': JSON.parse(localStorage.getItem('ROYALTOKEN_KEY')),
         'x-app-version':  '4.0.19.2',
         'x-client':  'mobile',
         'x-client-id':  '476843353.1602776689',
         'x-client-info':  'f582f6103068ba74fe67f8ee1e0ee274',
         'x-log-timing':  'true',
         'x-user-id':  'HZ010101M1H',
         'x-xid':  'bc1cac1e-94d2-6b09-e09f-70f42ea11fd5'
        }  );*/
      return this.http.post(this.API_Event_URL + 'exchange/order/',data) // , {headers: headers}
      .pipe(
        retry(0),
        tap((res: any) => {
          if ([444, 403].indexOf(res.code) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
           // location.reload(true);
          }
          return res;
        }),
        //shareReplay()
      );
    }

    openSportbook() {
      const data={"id":"1444005","type":"SPORTSBOOK"};
      return this.http.post(this.API_Event_URL + 'casino/live/session/open',data) // , {headers: headers}
      .pipe(
        retry(0),
        tap((res: any) => {
          if ([444, 403].indexOf(res.code) !== -1) {
          }
          return res;
        }),
      );
    }

    editStake(data) {
      let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*',
         'content-type': 'application/json',
         'Authorization': JSON.parse(localStorage.getItem('ROYALTOKEN_KEY')),
         'x-app-version':  '4.0.19.2',
         'x-client':  'mobile',
         "x-mock-response-code":  '200',
         'x-mock-match-request-body':  'true',
         'x-log-timing':  'true',
         'x-user-id':  'HZ010101M1H',
         'x-xid':  'bc1cac1e-94d2-6b09-e09f-70f42ea11fd5'
        }  );
      return this.http.post(this.API_Event_URL + 'account/T1010201M03/settings/stakes',data,{'headers': headers}) // , {headers: headers}
      .pipe(
        retry(0),
        tap((res: any) => {
          if ([444, 403].indexOf(res.code) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
           // location.reload(true);
          }
          return res;
        }),
        shareReplay()
      );
    }
    deletebnkaccount(id) {
      return this.http.delete<any>(this.API_Event_URL + 'payments/userid/delete-bank?accountId=' + id) // , {headers: headers}
        .pipe(
          retry(0),
          tap(res => {
            if ([444, 403].indexOf(res.code) !== -1) {
              // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            return res;
          }),
          shareReplay()
        );
    }

  // cancel bet for match for betId
  cancelBet(betId: number) {
    return this.http.delete<any>(this.API_Event_URL + 'exchange/order/' + betId)
      .pipe(
        retry(0),
        tap(res => {
          if ([444, 403].indexOf(res.code) !== -1) {
          }
          return res;
        }),
        shareReplay()
      );
  }

  betSettings(data: any) {
    return this.http.post(this.API_Event_URL + 'exchange/order/settings/', data)
      .pipe(
        retry(0),
        tap((res: any) => {
          if ([444, 403].indexOf(res.code) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            // location.reload(true);
          }
          return res;
        }),
      );
  }


  //function for confirmation before bet placement
  confirmBet(data: { confirmation: boolean }) {
    return this.http.post(this.API_Event_URL + 'account/T1010201M03/settings/preferences', data)
      .pipe(
        retry(0),
        tap((res: any) => {
          if ([444, 403].indexOf(res.code) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            // location.reload(true);
          }
          return res;
        }),
      );
  }

  generalRules(eventTypeId:any, competitionId:any, usercode:any){
    if (usercode) {
      return this.http.get(this.API_Event_URL + 'rules/event/'+usercode+'/'+competitionId+'/'+eventTypeId)
        .pipe(
          retry(0),
          tap((res: any) => {
            if ([444, 403].indexOf(res.code) !== -1) {
            }
            return res;
          }),
        );
    }else{
      return this.http.get(this.API_URL +'rules/event/'+usercode+'/'+competitionId+'/'+eventTypeId)
      .pipe(
        retry(0),
        tap((res: any) => {
          if ([444, 403].indexOf(res.code) !== -1) {
          }
          return res;
        }),
      );
    }
  }

//function for cashout bet
cashOut(marketId: any) {
  return this.http.get(this.API_Event_URL + 'exchange/order/cashout?marketId=' + marketId)
    .pipe(
      retry(0),
      tap((res: any) => {
        if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // location.reload(true);
        }
        return res;
      }),
      //shareReplay()
    );
}

  getCricktMatchData(matchTypeId: number, compitionId: number) {
    return this.http.get(this.API_URL + `exchange/odds/group/${matchTypeId}/${compitionId}?includeOutrights=true&includeToQualify=true`);
  }

}
