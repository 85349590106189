import { Component, OnInit, SecurityContext } from '@angular/core';
import { BonusService } from '../../bonus.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MyprofileService } from 'src/app/services/myprofile.service';

@Component({
  selector: 'app-how-to-bonus2',
  templateUrl: './how-to-bonus2.component.html',
  styleUrls: ['./how-to-bonus2.component.scss']
})
export class HowToBonus2Component implements OnInit {

  fullView = true;
  
  sanitizeHead = this.sanitizer.bypassSecurityTrustHtml('');
  sanitizeAmount = this.sanitizer.bypassSecurityTrustHtml('');
  sanitizeInfoRules = this.sanitizer.bypassSecurityTrustHtml('');
  

  constructor(private sanitizer: DomSanitizer, public _bonus : BonusService, private myProfileService:MyprofileService) { }
  
  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }

  ngOnInit(): void {
    // console.log(this._bonus.showHowTo.promoDetails);
    // this._bonus.showHowTo.promoDetails['infoHead']
    this.sanitizeHead = this.sanitizeHtml(this._bonus.showHowTo.promoDetails['infoHead']);
    this.sanitizeAmount = this.sanitizeHtml(this._bonus.showHowTo.promoDetails['infoHeadAmount']);
    this.sanitizeInfoRules = this.sanitizeHtml(this._bonus.showHowTo.promoDetails['infoRules']);
    this.myProfileService.isHeaderFixed = true;
  }

  ViewMore(){
    this.fullView = true;
  }

  hide(){
    this._bonus.showHowTo2 = undefined;
  }

  ngOnDestroy() { 
    this.myProfileService.isHeaderFixed = false;
  }

}
