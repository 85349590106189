<div class="shadow max-width-550 Modal-Background">
        <div class="card-header">
            <span (click)="hide()" class="material-symbols-outlined" style="float: left;filter: invert(1);margin-right:2px">
                <img src="../../../assets/images/arrow-left.png" alt="back-arrow">
            </span>
        <h2 style="margin-bottom: 0;">Bonuses</h2>
    </div>
    <div class="total-claim">
        <!-- <div class="total-bonus-cards">
            <div class="rect-1"></div>
            <div class="rect-2"></div>
            <div class="rect-3"></div>
            <div class="claim-card">
                <img src="../../../assets/images/bonus-wallet.svg" alt="">
                <div class="claim-card-title">
                    <span class="title">Total Bonus Claimed</span>
                    <span class="amount-text">₹500</span>
                </div>
            </div>
            <div class="vip-boxes">
                <div class="vip-bonus">
                    <div class="vip-text">
                        <span>Total VIP Bonus</span>
                    </div>
                    <span class="amount-title">₹500</span>
                </div>
                <div class="vip-general">
                    <div class="vip-text">
                        <span>Total General Bonus</span>
                    </div>
                    <span class="amount-title">₹500</span>
        </div>
                <div class="vip-special">
                    <div class="vip-text">
                        <span>Total Special Bonus</span>
                    </div>
                    <span class="amount-title">₹500</span>
                </div>
            </div>
        </div> -->

        <div class="card-header-1">
            <p class="btnx" [ngClass]="{'btnActive': bonusType == 'All'}" (click)="activate('All')">All Bonuses</p>
            <p class="btnx" *ngIf="isLogedin()" [ngClass]="{'btnActive': bonusType == 'Active'}"
                (click)="activate('Active')">Active</p>
            <p class="btnx" *ngIf="isLogedin()" [ngClass]="{'btnActive': bonusType == 'History'}"
                (click)="activate('History')">History</p>
        </div>
    </div>
    <div class="card">

        <div class="card-body">
            <ul>
                <li *ngIf="bonusType === 'All'">
                    <app-redeem-gift-card class="bonus-card"></app-redeem-gift-card>
                </li>
                <li *ngIf="this._bonusService.bonuses.length == 0 && this._bonusService.promotions.length == 0">
                    <!-- if there is no bouns and promotions -->
                    <app-no-more-bonus-card class="bonus-card"></app-no-more-bonus-card>
                </li>

                <!-- if there is bonus found and active -->
                <ng-container *ngIf="this._bonusService.bonuses.length > 0">
                    <ng-container *ngFor="let bonus of this._bonusService.bonuses">

                        <ng-container *ngIf="bonusType == 'All' || bonusType == 'Active'">

                            <li *ngIf="bonus.STS_ID == 20 && !bonus.wagered?.claimAvailable">
                                <app-active-bonus-card [bonus]="bonus" class="bonus-card"></app-active-bonus-card>
                            </li>

                            <li *ngIf="bonus.STS_ID === 20 && bonus.wagered?.claimAvailable">
                                <!-- if bonus is active and claim is available -->
                                <app-claim-bonus-card [bonus]="bonus" class="bonus-card"></app-claim-bonus-card>
                            </li>
                        </ng-container>

                    </ng-container>
                    <ng-container *ngFor="let bonus of this._bonusService.bonuses">

                        <ng-container *ngIf="bonusType == 'All' || bonusType == 'Active'">
                            <li *ngIf="bonus.STS_ID === 10">
                                <app-pending-bonus-card [bonus]="bonus" class="bonus-card"></app-pending-bonus-card>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="bonusType == 'All' || bonusType == 'History'">
                            <li *ngIf="bonus.STS_ID === 30 && bonus?.wagered?.claimAmount <= 0">
                                <app-expired-bonus-card [bonus]="bonus" class="bonus-card"></app-expired-bonus-card>
                            </li>
                            <li *ngIf="bonus.STS_ID === 30 && bonus?.wagered?.claimAmount > 0">
                                <app-win-bonus-card [bonus]="bonus" class="bonus-card"></app-win-bonus-card>
                            </li>

                            <li *ngIf="bonus.STS_ID === 20 && bonus.PROMO_CODE == 'AUTO-DEPOSIT-Z9'">
                                <!-- if bonus is active and claim is available -->
                                <app-no-wagering-claimed [bonus]="bonus" class="bonus-card"></app-no-wagering-claimed>
                            </li>



                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="this._bonusService.promotions.length > 0 && bonusType == 'All' ">

                    <ng-container *ngFor="let promotion of this._bonusService.promotions">
                        <li>
                            <app-deposit-now-card2 [promotion]="promotion" class="bonus-card"></app-deposit-now-card2>
                        </li>
                    </ng-container>

                </ng-container>

            </ul>


        </div>

    </div>
</div>
