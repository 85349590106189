import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, SimpleChanges, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotifyServiceService } from 'src/app/notify/notify-service.service';
import { BettingHelperService } from 'src/app/services/betting-helper.service';
import { EventService } from 'src/app/services/broadcast.service';
import { MyprofileService } from 'src/app/services/myprofile.service';

// import { Location } from '@angular/common';
import { AuthService } from '../../services/auth.service';

import { Result } from './tennis.stats';
import { SingleRace, Runner, SingleRaceResult } from 'src/app/models/single-race.interface';
import { EventTypes, EventResult } from '../live/eventType.interface';

declare var SIR: any;
declare var $;
@Component({
  selector: 'app-detailspage',
  templateUrl: './detailspage.component.html',
  styleUrls: ['./detailspage.component.scss'],
})
export class DetailspageComponent implements OnInit, OnDestroy {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  // public sList: any = [];
  public sid: string;  // sports Id
  showhidemarket: any = true;
  public ename: any = [];
  public eid: string;
  // public matchData: any = [];
  public AllMarket: EventResult[] = [];
  public profitAL: any;
  public fancyprofitAL: any;
  public fancyprofit = [];

  public isBack: boolean;
  public isLoader: boolean = false;
  public isLoaderf: boolean = true;
  public interval300: any;
  public intervalScore: any;
  public marketId: string;
  public markeName: string;

  private eventSubscription: Subscription;
  public stats: any;

  marketExposure = {}
  //new variables for new menu
  activeNav: string;
  whoWillWintheMatch: EventResult[] = [];
  toWintheToss: EventResult[] = [];
  matchOdds: EventResult[] = [];
  completeMatchCard: any = [];
  otherBms: EventResult[] = [];
  fancyMarket: EventResult[] = []; // MARK: need to check the type
  premiumMarket: EventResult[] = [];
  activetabGroup = 'Fancy';
  // openBetSlipId: any; //for opening bet slip card
  // betData: any; //for passing betData to bet slip card
  winner: EventResult[] = [];
  // scoreData: any = [];
  fancyBetData: any; // for passing betData to fancy bet slip card
  premiumBetData: any; // for passing betData to premium bet slip card
  applyBackAnimation: any = [];
  // applyLayAnimation: any = [];
  previousBackPrices: any = [];
  previousLayPrices: any = [];
  videoStats: any;
  scoreCard: any;
  rulesPopup: boolean = false;
  rulesPopupclose: boolean = false;
  showRulesPage: Array<any> = [];
  generalRulesData: any;
  betCount: any;
  tiedMatch: EventResult[];
  raceRunners: Runner[];  //for horse racing and greyhound racing
  raceData: SingleRaceResult;  //for horse racing and greyhound racing
  matchDetails: any;
  isRaceDataAvailable = true;

  statuses = {
    "InPlay": "In play",
    "BetweenInnings": "Innings Break",
    "Review": "REVIEW",
    "Drinks": "Drinks",
    "Rain": "Rain",
    "BadLight": "Bad light stopped play",
    "Injury": "Injury timeout",
    "Other": "Delay",
  }

  liveDotLottieOptions:
    any = { path: 'assets/static/media/live.json', autoplay: true, loop: true };

  notliveDotLottieOptions:
    any = { path: 'assets/static/media/live.json', autoplay: false, loop: false };

  // public scoreData: null;

  @ViewChild('tvIframe') tvIframe: ElementRef;

  constructor(
    public authService: AuthService,
    // private changedRef: ChangeDetectorRef,
    public myProfileService: MyprofileService,
    private route: ActivatedRoute,
    private eventService: EventService,
    public betHelper: BettingHelperService,
    private notify: NotifyServiceService,
    private router: Router) {
    this.eid = this.route.snapshot.params['id'];
    this.sid = this.route.snapshot.params['sid'];
    this.authService.sportsId = this.sid;
    this.eventSubscription = this.eventService.getEvent().subscribe(key => {
      this.getPNl();
    })
  }

  ngOnInit(): void {
    this.getPNl();
    this.isLoaderf = true;
    this.getGeneralRules();
    if (this.sid == '4' || this.sid == '1' || this.sid == '2' || this.sid == '2378962') {
      this.getMatchData();  // for cricket, tennis and football
      this.getScore();      // for cricket, tennis and football
      this.getBetCount();
    } else {
      this.activeNav = 'market';
      this.getRacingDetails();
    }

    setTimeout(() => { this.getPNl() }, 2000);
    this.authService.detailsPageDataObservable$.subscribe((res: any) => {
      this.matchDetails = res;
    });
  }

  tossWinnerData = (stats: any) => {
    const e = stats;
    const i = {
      message: "",
      statusMessage: "",
      statusStyle: "Review" === e.status ? "review" : ""
    };

    if (["BetweenInnings", 2].includes(e.currentInning)) {
      if (e.status !== "Completed") {
        if (e.battingTeam.requiredRuns || e.battingTeam.target) {
          const r = {
            team: e.battingTeam.name,
            requiredScore: e.battingTeam.requiredRuns || e.battingTeam.target
          };
          i.message = `${e.battingTeam.name} need ${r.requiredScore} runs`;
          if (e.battingTeam.requiredRuns > 0 && e.remainingBalls <= 60) {
            i.message += ` from ${e.remainingBalls} balls`;
          }
        }
      } else if (e.result && e.result.type === "Won") {
        i.message = `Match won by ${e.result.winnerName}`;
      }
    } else if (e.tossWinner?.name && e.tossWinner?.decision) {
      if (e.tossWinner.decision === 'optToBowl') {
        if (e.tossWinner.name.includes('opt')) {
          i.message = `${e.tossWinner.name}`;
        } else {
          i.message = `${e.tossWinner.name} opt to bowl`;
        }
      } else {
        i.message = `${e.tossWinner.name} opt to bat`;
      }
    }

    if (this.statuses.hasOwnProperty(e.status)) {
      i.statusMessage = `${this.statuses[e.status]}${i.message.length ? " - " : ""}`;
    }

    if (e.adjustedOvers && e.status !== "Completed" && i.message.length) {
      i.message += ` - reduced to ${e.oversPerInning || 0} overs`;
    }

    return i?.statusMessage + i?.message;
  };

  getInPlayStatus(): string {
    // Perform your logic to determine the "In Play" status
    if (this.sid === '4') {
      if (this.stats?.status || this.scoreCard?.isBRScore || this.scoreCard?.hasVideo) {
        return this.statuses[this.stats?.status] || 'In Play';
      } else {
        return 'Upcoming';
      }
    } else if (this.sid === '2' || this.sid === '1') {
      return this.stats || this.scoreCard?.isBRScore ? 'In Play' : 'Upcoming';
    } else {
      return '';
    }
  }

  formatBattingTeamStats(stats: any): string {
    const battingTeam = stats?.battingTeam || {};
    const name = battingTeam.name || '0';
    const score = battingTeam.score || '0';
    const wickets = battingTeam.wickets || '0';
    const overs = battingTeam.overs || '0';
    const balls = battingTeam.balls || '0';

    return `${name}: ${score}-${wickets} (${overs}.${balls} Ovs)`;
  }
  getBetCount() {
    this.betHelper.betHistoryData.subscribe((res: any) => {
      if (localStorage.getItem('ROYALTOKEN_KEY') != null) {
        const matchedData: any = Object.values(res?.matched).filter((item: any) => item?.eventId == this.eid);
        const unmatchedData: any = Object.values(res?.unmatched).filter((item: any) => item?.eventId == this.eid);
        let matchLenght = 0;
        for (let i = 0; i < matchedData.length; i++) {
          const markets = matchedData[i]?.markets;
          for (let j = 0; j < markets.length; j++) {
            const selections = markets[j]?.selections;
            for (let k = 0; k < selections.length; k++) {
              matchLenght += selections[k]?.length ? parseInt(selections[k].length) : 0;
            }
          }
        };
        let unmatchedLenght = 0;
        for (let i = 0; i < unmatchedData.length; i++) {
          const markets = unmatchedData[i]?.markets;
          for (let j = 0; j < markets.length; j++) {
            const selections = markets[j]?.selections;
            for (let k = 0; k < selections.length; k++) {
              unmatchedLenght += selections[k]?.length ? parseInt(selections[k].length) : 0;
            }
          }
        };
        this.betCount = matchLenght + unmatchedLenght;
      }
    });
  }


  // ngOnChanges(changes: SimpleChanges) {
  //   const changesLength = Object.keys(changes).length;
  //   for (let i = 0; i < changesLength; i++) {
  //     const change = changes[i];
  //     if (change && change['runners']) {

  //       const backPrices = change['runners'].currentValue?.back[0]?.line || change['runners'].currentValue?.back[0]?.price;
  //       this.previousBackPrices[i] == undefined ? backPrices : this.previousBackPrices[i]?.line || this.previousBackPrices[i]?.price;

  //       if (this.previousBackPrices[i] !== backPrices) {
  //         this.applyBackAnimation[i] = true;
  //         this.previousBackPrices[i] = backPrices;
  //         setTimeout(() => {
  //           this.applyBackAnimation[i] = false;
  //         }, 300);
  //       }

  //       // do the same for lay prices
  //       const layPrices = change['runners'].currentValue?.lay[0]?.line || change['runners'].currentValue?.lay[0]?.price;
  //       this.previousLayPrices[i] == undefined ? layPrices : this.previousLayPrices[i]?.line || this.previousLayPrices[i]?.price;

  //       if (this.previousLayPrices[i] !== layPrices) {
  //         this.applyLayAnimation[i] = true;
  //         this.previousLayPrices[i] = layPrices;
  //         setTimeout(() => {
  //           this.applyLayAnimation[i] = false;
  //         }, 300);
  //       }
  //     }
  //   }

  // }


  showmarket(item) {
    if (this.getToken()) {

      this.authService.getLadderdetail(item?.id).subscribe(
        res => {
          this.fancyprofitAL = res?.result;
          this.fancyprofit = [];
          if (this.fancyprofitAL[item?.id] != null) {
            this.showhidemarket = !this.showhidemarket;
            for (let item1 of this.fancyprofitAL[item?.id])
              this.fancyprofit.push(item1);
            this.markeName = item.name;
          }
        }, error => { });
    } else {
      document.getElementById('demo').click();
    }
  }



  closeMarket(item) {
    this.showhidemarket = !this.showhidemarket;

  }


  getScore() {
    const data = { 'sportId': this.sid, 'eventId': this.eid };
    this.authService.getTvstaus(data).subscribe(
      (res) => {
        this.stats = res?.result?.stats;
        this.videoStats = res?.result;
        this.scoreCard = res?.result;
        if (this.videoStats?.isBRScore || this.stats) {
          if (this.activeNav == undefined) {
            this.activeNav = 'match-info';
          }
        } else {
          if (this.activeNav == undefined) {
            this.activeNav = 'market';
          }
        }
      },
      (error) => {
        console.error(error);
      });

    this.intervalScore = setTimeout(() => {
      this.getScore();
    }, 2000);
  }


  getMatchData() {
    this.isLoader = true;
    this.authService.getvkbetsdata(this.sid, this.eid).subscribe(
      async (res: EventTypes) => {
        this.AllMarket = await res?.result.filter((x) => !x?.hidden);
        const cricketOtherBms = this.AllMarket?.filter((item) => item?.id?.endsWith('_SB') && item?.name !== 'To Win The Toss');
        const footballOtherBms = this.AllMarket?.filter((item) => item?.name !== 'To Win The Toss'); // for showing football league data
        this.matchOdds = await this.AllMarket?.filter((item) => item?.name === 'Match Odds');
        this.otherBms = this.sid === '1' ? footballOtherBms : cricketOtherBms;
        this.toWintheToss = await this.AllMarket?.filter((item) => item?.id?.endsWith('_SB') && item?.name === 'To Win The Toss');
        this.whoWillWintheMatch = await this.AllMarket?.filter((item) => item?.id?.endsWith('.340._BR'));
        this.winner = await this.AllMarket?.filter((item) => item?.mtype === 'TOURNAMENT_WINNER' && item?.name === 'Winner');
        this.tiedMatch = await this.AllMarket?.filter((item) => item?.id?.endsWith('.342._BR'));

        if (this.sid == '4') {
          this.fancyMarket = await this.AllMarket?.filter((item) => item?.id?.endsWith('.FY') && item?.tabGroupName === 'Fancy');
          this.premiumMarket = await this.AllMarket?.filter((item) => item?.id?.endsWith('_BR') && item?.tabGroupName === 'Premium Cricket' && !item?.id?.endsWith('.342._BR'));
        } else {
          this.fancyMarket = await this.AllMarket?.filter((item) => item?.id?.endsWith('.FY'));
          this.premiumMarket = await this.AllMarket?.filter((item) => item?.id?.endsWith('_BR') && !item?.id?.endsWith('.342._BR'));
        }
        if (this.fancyMarket?.length === 0 && this.premiumMarket?.length !== 0) {
          this.activetabGroup = 'Premium Cricket';
        }
        // this.ngOnChanges(this.fancyMarket);
        this.isLoaderf = false;
      },
      error => {
        this.isLoader = false;
        console.error(error);
      }
    );
    this.interval300 = setTimeout(() => {
      this.getMatchData();
      this.isLoader = false;
    }, 1000)
  }
  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }

  trackByFn(index
    :
    number, item
      :
      any
  ):
    any {
    return item?.market?.id;
  }

  getPNl() {
    this.isLoader = false;
    if (this.getToken()) {
      this.authService.getProfitandLoss(this.eid).subscribe(
        res => {
          this.isLoader = false;
          this.betHelper.setCurrentExposure(this.marketExposure);
          this.marketExposure = res.result;
          this.profitAL = res.result?.pnlBySelection;
        },
        error => {
          this.isLoader = false;
        }
      );
    }
  }

  openSign() {
    document.getElementById('demo').click();
  }



  backClicked() {
    if (window.history.state.navigationId < 5) {
      this.router.navigate(['/main']);
      this.myProfileService.updateActiveMenuItem('/main');
    } else {
      window.history.back();
    }
  }


  //new functions
  showActiveNav(active: any) {
    if (active == 'market') {
      this.activeNav = 'market';
    } else if (active == 'match-info') {
      this.activeNav = 'match-info';
    } else if (active === 'live-video') {
      if (localStorage.getItem('ROYALTOKEN_KEY')) {
        this.activeNav = 'live-video';
      } else {
        document.getElementById('demo').click();
      }
    } else if (active === 'live-bets') {
      this.activeNav = 'live-bets';
    }
  }

  switchTabGroup(activeTab
    :
    any
  ) {
    if (activeTab === 'Fancy') {
      this.activetabGroup = 'Fancy';
    } else if (activeTab === 'Premium Cricket') {
      this.activetabGroup = 'Premium Cricket';
    }
  }

  //
  ngOnDestroy()
    :
    void {
    clearInterval(this.interval300
    )
      ;
    clearInterval(this.intervalScore);
    this.eventSubscription.unsubscribe();
  }
  ngAfterViewInit() {
    //this.getIframe();
    //    this.setDesktopTV();
  }

  closeBetSlip() {
    this.betHelper.cardId = null;
    this.betHelper.itemId = null;
    this.betHelper.selectedCard = null;
    this.betHelper.selectedTeam = null;
    this.betHelper.selectedMatchCard = null;
    this.betHelper.stakeOnBet = null;
    this.betHelper.profitAndLiability = null;
  }


  trackByMethod(index
    :
    number, el
      :
      any
  ):
    number {
    return el.id;
  }

  splitCamelCase(inputString
    :
    any
  ) {
    const words = inputString?.replace(/([a-z])([A-Z])/g, '$1 $2');
    return words?.charAt(0)?.toUpperCase() + words?.slice(1);
  }


  statusShow(item
    :
    any
  ) {
    const statusMappings = {
      'InPlay': 'In play',
      'BetweenInnings': 'Innings Break',
      'Review': 'REVIEW',
      'Drinks': 'Drinks',
      'Rain': 'Rain',
      'BadLight': 'Bad light stopped play',
      'Injury': 'Injury timeout',
      'Other': 'Delay',
      'Completed': 'Match Ended',
    };
    if (item in statusMappings) {
      return statusMappings[item];
    } else {
      return null;
    }
  }

  openRulePopup() {
    this.rulesPopup = true;
  }

  openRulePage(index: number) {
    this.showRulesPage[index] = !this.showRulesPage[index];
  }

  closePopup() {
    this.rulesPopupclose = true;
    setTimeout(() => {
      this.rulesPopup = false;
      this.rulesPopupclose = false;
    }, 350)
  }

  getGeneralRules() {
    const usercode = localStorage.getItem('Member_code');
    this.authService.generalRules(this.eid, this.sid, usercode)
      .subscribe((res: any) => {
        this.generalRulesData = res?.result;
        this.generalRulesData?.forEach((element: any) => {
          this.showRulesPage.push(false);
        });
      }, (err: any) => { console.error(err) })
  }

  cashOut(marketId: any) {
    if (!this.getToken()) {
      document.getElementById('demo').click();
      return;
    }
    let confirmCashOut = confirm('Are you sure you want to cash out?');
    if (confirmCashOut) {
      this.isLoader = true;
      this.authService.cashOut(marketId).subscribe((res: any) => {
        this.isLoader = false;
        let sizeMatched = res?.result?.sizeMatched;
        if (sizeMatched) {
          if (sizeMatched) {
            this.notify.success('Cashout successfully');
          }
        }
      },
        (err: any) => {
          this.isLoader = false;
          let msg = err?.error?.error[0][0].description;
          if (msg) {
            this.notify.error(msg);
          } else {
            this.notify.error('Something went wrong');
          }
        });
    }
  }

  getRacingDetails() {
    this.authService.getHorseandGreyRacingdet(this.sid, this.eid).subscribe((res: SingleRace) => {
      if (res?.result?.length === 0) {
        this.isRaceDataAvailable = false;
        return;
      }
      this.raceData = res?.result[0];
      this.raceRunners = this.raceData?.runners;
      this.isLoader = false;
      this.isRaceDataAvailable = true;
    }, (err) => {
      this.isLoader = false;
      this.isRaceDataAvailable = false;
      console.error(err);
    }); //need to implement

    this.intervalScore = setTimeout(() => {
      this.getRacingDetails();
    }, 1000);
  }

  handleToBetInstant(value: any) {
    if (value) {
      this.activeNav = 'market';
    }
  }
}
