<app-accountheader></app-accountheader>
<div class="u-i-p-c-body-bc">
  <div class="back-nav-bc" routerLink="/shared/promotions">
    <i class="back-nav-icon-bc bc-i-round-arrow-left"></i><span class="back-nav-title-bc ellipsis">{{'Offers'|translate}}</span>
  </div>
  <div class="bonusDetailes_container">
    <img loading="lazy" [src]="bonusImage" alt="Banner" class="bonusDetailes_container-image" />
    <div class="bonusDetailes_container_info">
      <div [innerHTML]="sanitizeHtml(bonusInfo)"></div>
    </div>
    <div class="bonusDetailes_container_buttonsRow">
      <div class="bonusDetailes_container_buttonsRow-claimButton" [routerLink]="['/balancemanagement','Deposit']">
        <span>{{'Claim now'|translate}}</span>
      </div>
      <div class="bonusDetailes_container_buttonsRow-helpButton">
        <img loading="lazy" src="../../../../../assets/images/img/info.svg" alt="">
        <span>{{'Help'|translate}}</span>
      </div>
    </div>
  </div>
</div>
