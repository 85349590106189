import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { tap, retry, shareReplay } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasinoService {
  API_URL = environment.API_URL;
  API_Event_URL = environment.API_Event_URL;
  liveamesurl = "https://casino-api.7exch.com/casino/banner";
  casinogamesurl = "https://7exch.com/api/";
  // casinogamesurl = "https://api.gemexch.co/api/";
  jiligamesurl = "https://casino-l.route2222.com/api/";
  LIVE_GAME_2_URL = 'https://d2.fawk.app/#/splash-screen/';
  private casinoURLSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  casinoURLObservable$: Observable<string | null> = this.casinoURLSubject.asObservable();


  constructor(private http: HttpClient) { }

  casinogames(games: any): Observable<any> {
    return this.http.get<any>(this.API_URL + 'casino/v2/casino-lobby')
      .pipe(
        retry(0),
        tap(res => {
          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }

  slotGames(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'casino/v2/slotgames-lobby')
      .pipe(
        retry(0),
        tap(res => {
          return res;
        }),
        shareReplay()
      );

  }

  getCategoryWiseCasinoGames(id: any) {
    return this.http.get<any>(this.API_URL + 'casino/v2/games/' + id)
      .pipe(
        retry(0),
        tap(res => {
          return res;
        }),
        shareReplay()
      );
  }

  getCategoryWiseSlotGames(id: any) {
    return this.http.get<any>(this.API_URL + 'casino/v2/slot-games/' + id)
      .pipe(
        retry(0),
        tap(res => {
          return res;
        }),
        shareReplay()
      );
  }

  getTeenPattiGameUrl(data: any) {
    return this.http.post<any>(this.casinogamesurl + 'casino/live/session/open', data)
      .pipe(
        retry(0),
        tap((res: any) => {
          if ([444, 403].indexOf(res.code) !== -1) {

          }
          this.casinoURLSubject.next(res?.result?.url);
          return res;
        }),
        //shareReplay()
      );
  }


  getCasinoUrl(eid) {
    return this.http.get<any>(this.casinogamesurl + 'casino/live/table-w/' + eid) // , {headers: headers}
      .pipe(
        retry(0),
        tap(res => {
          if ([444, 403].indexOf(res.code) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          }
          this.casinoURLSubject.next(res?.result?.url);
          return res;
        }),
        shareReplay()
      );
  }
  getTrendingGame() {
    return this.http.get<any>(this.API_Event_URL + 'casino/trendingGame') // , {headers: headers}
      .pipe(
        retry(0),
        tap(res => {
          if ([444, 403].indexOf(res.code) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          }
          this.casinoURLSubject.next(res?.result?.url);
          return res;
        }),
        shareReplay()
      );
  }
  count = 0
  clearCasinoURL(from?: string) {
    console.log("clearCasinoURL", from)
    this.casinoURLSubject.next(null);
  }
  jiliDeposit(data) {
    return this.http.post(this.jiligamesurl + 'casino/live/funds/deposit', data) // , {headers: headers}
      .pipe(
        retry(0),
        tap((res: any) => {
          if ([444, 403].indexOf(res.code) !== -1) {

          }
          return res;
        }),
        //shareReplay()
      );
  }
  jiliGame(id) {
    return this.http.get(this.API_Event_URL + 'casino/live/table/' + id) // , {headers: headers}
      .pipe(
        retry(0),
        tap((res: any) => {
          if ([444, 403].indexOf(res.code) !== -1) {

          }
          this.casinoURLSubject.next(res?.result?.url);
          return res;
        }),
        //shareReplay()
      );
  }

  teenpattiBanner() {
    return this.http.get(this.API_Event_URL + 'mbanners') // , {headers: headers}
      .pipe(
        retry(0),
        tap((res: any) => {
          if ([444, 403].indexOf(res.code) !== -1) {

          }
          this.casinoURLSubject.next(res?.result?.url);
          return res;
        }),
        //shareReplay()
      );
  }

  liveGame() {
    return this.http.get(this.liveamesurl) // , {headers: headers}
      .pipe(
        retry(0),
        tap((res: any) => {
          if ([444, 403].indexOf(res.code) !== -1) {

          }
          return res;
        }),
        //shareReplay()
      );
  }

  getliveGameUrl(data, demo = false) {
    if (demo) {
      this.casinoURLSubject.next(data);
      return data
    }
    return this.http.post(this.API_Event_URL + 'casino/live/session/open', data) // , {headers: headers}
      .pipe(
        retry(0),
        tap((res: any) => {
          if ([444, 403].indexOf(res.code) !== -1) {

          }
          this.casinoURLSubject.next(res?.result?.url);
          return res;
        }),
        //shareReplay()
      );
  }

  equipment: any[] = [];

  favouritegame(newRecord: any) {
    this.equipment = [...this.equipment, newRecord];

    localStorage.setItem("gamelist", JSON.stringify(this.equipment));

  }

  favtest(newRecord: any) {
    this.equipment = [...this.equipment, newRecord,];
    localStorage.setItem("gamelist", JSON.stringify(this.equipment));

  }



}
