import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-versionPopUp',
  templateUrl: './versionPopUp.html',
  styleUrls: ['./versionPopUp.scss']
})

export class VersionPopUp implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  @Output() close = new EventEmitter(true);
  @Input() version = '1'
  isLoader: boolean = false;

  closePopup() {
    this.close.emit(false);
    this.clearCacheData()
  }

  update() {
    this.isLoader = true;
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
      localStorage.setItem("versionNow", this.version)
      this.close.emit();
      setTimeout(() => {window.location.reload();}, 300);
    }
  }
  clearCacheData() {
    caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
            caches.delete(cacheName);
        });
    })
    // setTimeout( () => { window.location.reload() }, 300)
};

  ngOnInit(): void {
  }

}
