import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { CasinoService } from 'src/app/services/casino.service';
import { LandingService } from 'src/app/services/landing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

declare var $: any
@Component({
  selector: 'app-predictor',
  templateUrl: './predictor.component.html',
  styleUrls: ['./predictor.component.scss']
})
export class PredictorComponent implements OnInit, OnDestroy {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  istoken: any;
  allgames: any = [];
  public userToken: string;
  public operaterId = '9414';
  casinoUrl:string;
  isActivefavlive:any;
  isLoader:boolean=false;
  isScroll:boolean=false
  favouriteGamelistcasino:any=[];
  filteredArray: any;
  sliderImage:any;
  Images: any;
  urlIframe: string;
  gameId: any
  public yt: any;
  private urlIframeSubscription: Subscription;
  @ViewChild('iframeLoad', {static: false}) iframeLoad: ElementRef;
  isIphoneChrome = (navigator.userAgent.includes("iPhone") && navigator.userAgent.includes('CriOS'));
  isIphoneSafari = (navigator.userAgent.includes("iPhone") && !navigator.userAgent.includes('CriOS'));
  // @ts-ignore
  isSafariPWA = navigator?.standalone
  title:string
  isMobile: boolean = false;
  sliderlConfig = {
    slidesToShow: 3,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,

        }
      }

    ]
  };
  constructor(private authService: AuthService,private api:LandingService, private casinoServices: CasinoService,private faverate:LandingService, private router: Router, private route: ActivatedRoute) {
    if (this.authService.currentUserValue == null) {
      this.istoken = true;
    }
    else {
      this.istoken = false;
    }
  }

  ngOnInit(): void {
    this.isLoader=true;
    this.checkIfMobile();
    this.urlIframeSubscription = this.casinoServices?.casinoURLObservable$?.subscribe((url: string | null) => {
      if (!url && this.urlIframe) {
        this.clearIframe()
      }
      if (url) {
        this.openGame(url + '?opentable='+this.gameId)
      }
      this.urlIframe = url;
      if (!this.iframeLoad.nativeElement){
        this.clearGameIdQueryParam()
      }
    });
    this.api.sliderimage().subscribe((res:any)=>{
      res;
      this.sliderImage=res;
      this.Images=res.data;
      let filteredArray = this.Images?.filter((item) => item.ID == 7);
      this.filteredArray=filteredArray

      this.liveGame();
      this.isLoader=false;
    })

    this.favouriteGame(null,null)

  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 1024;
  }
  getLiveGame(Id, game = null) {
    this.gameId = Id
    if ( this.iframeLoad &&  this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML=''
    }
    if (game) {
      this.title = game?.name
    }
      if(this.getToken()){
        const data= {
          "id": '1444012',
          "type": "FAWK"
      };
        this.casinoServices.getliveGameUrl(data).subscribe((res: any) => {
         let url;
         // url= res.success + '?opentable='+Id;
         // this.urlIframe = url
          // console.log("urlIframe", this.urlIframe)
         // window.open(url);
        //  this.router.navigate([], {
        //   relativeTo: this.route,
        //   queryParams: { game_id: '22' },
        //   // queryParamsHandling: 'merge',
        //   skipLocationChange: true,
        // });
        //   this.openGame(url)
        })
      }
      else{
        document.getElementById("demo").click();
      }
  }

  clearGameIdQueryParam() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { game_id: null },
      queryParamsHandling: 'merge',
      skipLocationChange: true,
    });
  }
  clearIframe() {
    if ( this.iframeLoad &&  this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML=''
    }
    this.title = ""
    this.urlIframe = ""
    this.clearGameIdQueryParam();
    this.casinoServices.clearCasinoURL()
  }
  openGame(iurl) { // INIT IFRAME
    // this.loaderShow = false;
    console.log('Inside openCasino of casino component');
    this.yt = '<iframe  scrolling="auto"  style="width: 100%; height: 100%" class="exchange-iframe" src="' + iurl + '" frameborder="0" id="casino-fram" allow="autoplay; encrypted-media"  allowfullscreen></iframe>';
    this.iframeLoad.nativeElement.innerHTML = this.yt;
  }

  liveGame() {

    const casinocat = {
      "casino_cat_ids": ["51", "52", "53", "54", "55", "56", "57"]

    }
    this.casinoServices.liveGame().subscribe((res: any) => {
      // debugger;
      this.allgames = res.data;
    })

  }

  goToGame(id){
    let cId= id.slice(0,4);

    /*if(cId=='jili' && this.getToken()){
      this.jiliId=id;
      this.showhidecasinopopup=!this.showhidecasinopopup;
      //console.log('Inside casinogame value of goToGame id: ' + cId);
    }else*/
     if(this.getToken()){
      this.casinoServices.getCasinoUrl(id).subscribe(
        res => {
          this.casinoUrl=res.result.url;
        //  console.log("insidde getPNl value of res:" +- JSON.stringify(this.casinoUrl));
          // this.urlIframe = res.result.url;
          window.open(this.casinoUrl);
        },
        error => {
        }
      );
    }
    else{
      document.getElementById("demo").click();
    }

}
getToken() {
  return localStorage.getItem('ROYALTOKEN_KEY');
}
// favouriteGamelive(selectedgames:any,imageurl:any){

//   this.isActivefavlive =selectedgames;
//   this.casinoServices.favouritegame(imageurl);
//   this.favouriteGamelistcasino.push(imageurl);
//   this.faverate.favouriteimage.next(this.favouriteGamelistcasino);

// }
favouriteGame(selectedgames: any, imageurl: any ) {

  var gamesl = localStorage.getItem("gamelist");


  if((gamesl == null)  &&  (imageurl != null)){
    this.casinoServices.equipment =[]

// this.isActivefav = selectedgames;
this.casinoServices?.favouritegame(imageurl);
this.favouriteGamelistcasino = this.casinoServices.equipment;
this.faverate?.favouriteimage?.next(this.favouriteGamelistcasino);
}else if (gamesl.includes(imageurl)) {


    const list =  this.casinoServices.equipment;

    const index =  list.indexOf(imageurl);

    this.casinoServices.equipment.splice(index,1);
    // this.casinoServices.favouritegame(list);
    localStorage.setItem("gamelist",JSON.stringify(this.casinoServices.equipment));
    this.favouriteGamelistcasino = this.casinoServices.equipment;
    this.faverate.favouriteimage.next(this.favouriteGamelistcasino);



  // }




}else if((imageurl !=null) && (gamesl != null)){



  this.casinoServices?.favouritegame(imageurl);
  this.favouriteGamelistcasino = this.casinoServices.equipment;
  this.faverate.favouriteimage.next(this.favouriteGamelistcasino);
  }else{

  }

}


setScrollbutton() {
  const $window = $(window);
  $window.on('scroll', () => {
    const windowScrollTop = $window.scrollTop();
    const windowheight = $window.outerHeight();;
    //console.log('Inside setDesktopTV of detailpage component windowScrollTop' + windowScrollTop);
    //console.log('Inside setDesktopTV of detailpage component windowScrollTop' + windowheight);

    if (windowScrollTop > windowheight) {
      this.scrollToTop();
      this.isScroll=true;
    } else {
      this.isScroll=false;
    }
  });
}
scrollToTop() {
  //console.log('Inside scrollToTop of casino component');
  $("#button").on("click", function() {
    //console.log('Inside button scrollToTop of casino component');
    $(window).scrollTop(0);
});
}
ngAfterViewInit(){
  this.scrollToTop();
  this.setScrollbutton();
}
ngOnDestroy(){
  this.clearGameIdQueryParam(); // Clear the game_id query parameter
  this.scrollToTop();
  this.setScrollbutton();
 }
}
