import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-loyalty-program',
  templateUrl: './loyalty-program.component.html',
  styleUrls: ['./loyalty-program.component.scss']
})
export class LoyaltyProgramComponent implements OnInit {

  loyaltyPopup: boolean = false;
  showPopup: boolean = false;
  loyaltyProgramPopup: boolean = false;

  constructor(private _router:Router) { }

  ngOnInit(): void {
  }

  closePopup() {
    this.showPopup = false;
  }

  openPopup() {
    this.showPopup = true;
  }

  closeLoyaltyPopup() {
    // go back
    // window.history.back();
    this._router.navigate(['/account']);
    
  }
}
