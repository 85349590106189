import { Component, Input, OnInit } from '@angular/core';
import { BonusService } from '../bonus.service';
import { MyprofileService } from 'src/app/services/myprofile.service';

@Component({
  selector: 'app-active-bonus-card',
  templateUrl: './active-bonus-card.component.html',
  styleUrls: ['./active-bonus-card.component.scss']
})
export class ActiveBonusCardComponent implements OnInit {

  @Input() bonus: any;

  totalWagered = 0;
  wageredCap = 0;
  remainWagered = 0;
  percent = 6;

  EXPIRY_DATE: Date;

  remainDays = 0;
  remainHours = 0;


  constructor(private _bonus: BonusService, private myProfileService:MyprofileService) { }

  ngOnInit(): void {
    if (this.bonus) {
      this.totalWagered = this.bonus?.wagered?.totalWagered;
      this.wageredCap = this.bonus?.wagered?.wageredCap;
      this.remainWagered = this.wageredCap - this.totalWagered;
      this.percent = this.totalWagered / this.wageredCap * 100;
      this.percent = this.percent < 6 ? 6 : this.percent;
      this.EXPIRY_DATE = new Date(this.bonus?.EXPIRY_DATE);
      this.remainDays = Math.floor((this.EXPIRY_DATE.getTime() - new Date().getTime()) / (1000 * 3600 * 24));
      this.remainHours = Math.floor((this.EXPIRY_DATE.getTime() - new Date().getTime()) / (1000 * 3600) % 24);
      this.myProfileService.isHeaderFixed = true;
    }
  }

  showHowTo() {
    console.log(this.bonus);
    // this._bonus.showHowTo = this.bonus;
    // this._bonus.showHowTo = this.bonus;

    if(this.bonus.PROMO_CODE == "AUTO-DEPOSIT-Z9" || this.bonus.promoCode == "AUTO-DEPOSIT-Z9"){
      // document.getElementById('closedemo')?.click();
      this._bonus.showHowTo2 = true;
    }
    if(this.bonus.PROMO_CODE == 'WELCOME500' || this.bonus.promoCode == 'WELCOME500'){
      // document.getElementById('closedemo')?.click();
      this._bonus.showHowTo = this.bonus;
    }

  }

  ngOnDestroy() {
    this.myProfileService.isHeaderFixed = false;
  }

}
