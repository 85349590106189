<div class="betslip-bc Quick first-bet " style="transform: translateY(0px); opacity: 1;">
    <div id="betslip_container">
        <div class="betslip-first-bet-content-bc quickBetWrapper-bc betplacedetail backdetails">
            <div class="bs-bet-item-bc multiple deleted">
                <div class="bs-bet-item-bg-c-bc">
                    <div class="bs-bet-i-head-bc">
                        <div class="bs-bet-i-h-cell-bc title"><span class="bs-bet-i-h-title-bc t-1"><span
                                    class="bs-item-date-v-bc bs-bet-i-h-title-bc-text"> </span></span>
                        </div>
                        <div class="bs-bet-i-h-cell-bc icon">
                            <div class="bs-bet-i-h-c-icon-holder-bc"><i
                                    class="bs-bet-i-h-c-icon-bc remove bc-i-close-remove" id="close_bet" (click)="closebet()"></i>
                            </div>
                        </div>
                    </div>
                    <div class="bs-bet-i-body-bc">
                        <div class="bs-bet-i-b-section-team-bc">

                            <div class="bs-bet-i-b-row-bc">
                                <div class="bs-bet-i-b-cell-bc title"><span
                                        class="bs-bet-i-b-title-bc t-1">{{eventName}}</span></div>
                                <div class="bs-bet-i-b-cell-bc value"></div>
                            </div>
                            <div class="bs-bet-i-b-row-bc">
                                <div class="bs-bet-i-b-cell-bc title"><span
                                        class="bs-bet-i-b-title-bc t-2"><span>{{marketName}}
                                        </span></span>
                                </div><span class="bs-bet-i-b-coefficient-bc c-2">{{price}}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="bet-slip-
            switcher-wrapper-bc"></div>
            <div class="bs-bet-i-b-section-bc">
                <div class="bs-bet-i-b-row-bc">
                    <div class="bs-bet-i-b-cell-bc title"><span class="bs-bet-i-b-title-bc t-2 ellipsis">{{'Possible win:'|translate}}</span></div>
                    <div class="bs-bet-i-b-cell-bc value"><span class="bs-bet-i-b-amount-bc"
                            *ngIf="matchOdd2profit!=0">{{matchOdd2profit | number:'1.1-1'}}</span></div>
                </div>
            </div>
            <div class="quickBetTaxInputRow-bc">
                <ul class="bs-bet-i-b-stake-bc">
                    <li data-numpad-open="" class="bs-bet-i-b-s-i-block-bc bs-bet-i-b-s-i-holder-bc px-0">
                        <input class="form-control" type="text" maxlength="5" readonly id="odd1price" name="odd1price"
                            #odd1price="ngModel" [(ngModel)]="matchOdd2price">
                    </li>

                </ul>

                <div class="quickBetButton-bc " *ngIf="getToken()">
                    <div class="quickBetButtonEl-bc btn a-color a-" (click)="placebet()">
                        <p>{{'Bet Now'|translate}}</p>
                    </div>
                </div>
                <div class="quickBetButton-bc " (click)="openSign()" *ngIf="!getToken()">
                    <div class="quickBetButtonEl-bc btn a-color a-">
                        <p>{{'Sign In'|translate}}</p>
                    </div>
                </div>
            </div>

            <div class="bs-numbers-bc ">
                <div class="bs-number-buttons-bc"><span class="bs-number-button-bc i-1" data-value="1"
                        (click)="keyboard('1', 'match_odd2')">1</span><span class="bs-number-button-bc i-1"
                        data-value="2" (click)="keyboard('2', 'match_odd2')">2</span><span
                        class="bs-number-button-bc i-1" data-value="3"
                        (click)="keyboard('3', 'match_odd2')">3</span><span class="bs-number-button-bc i-1"
                        data-value="4" (click)="keyboard('4', 'match_odd2')">4</span><span
                        class="bs-number-button-bc i-1" data-value="5"
                        (click)="keyboard('5', 'match_odd2')">5</span><span class="bs-number-button-bc i-1"
                        data-value="6" (click)="keyboard('6', 'match_odd2')">6</span><span
                        class="bs-number-button-bc i-1" data-value="7"
                        (click)="keyboard('7', 'match_odd2')">7</span><span class="bs-number-button-bc i-1"
                        data-value="8" (click)="keyboard('8', 'match_odd2')">8</span><span
                        class="bs-number-button-bc i-1" data-value="9"
                        (click)="keyboard('9', 'match_odd2')">9</span><span class="bs-number-button-bc i-2"
                        data-value=".">.</span><span class="bs-number-button-bc i-1" data-value="0"
                        (click)="keyboard('0', 'match_odd2')">0</span><span class="bs-number-button-bc i-2"><i
                            class="bs-number-button-icon-bc bc-i-delete"
                            (click)="keyboard('back', 'MATCH_ODDS')"></i></span>
                </div>
                <div class="bs-editable-number-buttons-bc">
                    <span class="bs-number-button-bc i-2 ellipsis" (click)="keyboard('100', 'bigOh')">100</span><span
                        class="bs-number-button-bc i-2 ellipsis" (click)="keyboard('250', 'bigOh')">250</span><span
                        class="bs-number-button-bc i-2 ellipsis" (click)="keyboard('500', 'bigOh')">500</span><span
                        class="bs-number-button-bc i-2 ellipsis" (click)="keyboard('2500', 'bigOh')">2500</span>
                    <!-- <span class="bs-number-button-bc i-2 "
                        title="Edit stakes"><i class="bs-number-button-icon-bc bc-i-edit"></i></span>
                        <span
                        class="bs-number-button-bc i-2 hidden"><i
                            class="bs-number-button-icon-bc bc-i-checked "></i></span> -->
                </div>
            </div>
        </div>
    </div>
</div>