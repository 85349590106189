import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-betplace',
  templateUrl: './betplace.component.html',
  styleUrls: ['./betplace.component.scss']
})
export class BetplaceComponent implements OnInit {
  public matchOdd2profit = 0;
  public matchOdd2price: any ;
  public marketType:any;
  public betdata: any ;
  @Input() eventName:any;
  @Input() marketName:any;
  @Input() price:any;
  public placeBet: any = {};
  constructor() { }

  ngOnInit(): void {
    this.matchOdd2price = undefined;
    this.matchOdd2profit= 0;
  }
  closebet(){
    this.matchOdd2profit= 0;
    this.matchOdd2price = undefined;
    const doc=<HTMLElement> document.getElementsByClassName('betplacedetail')[0];
    doc.style.display='none';
  }
  keyboard(action: string, type: string) {
    //console.log('Inside keyboard of kbetmview of detailpage component' + this.matchOdd2price);
    let saction = this.placeBet.m_type;
    this.marketType=type;
    if ((this.matchOdd2price == undefined || this.matchOdd2price =='')&& action!='back') {
      this.matchOdd2price = action;
    }
   else if ( this.matchOdd2price.length>=1 && action!='back' && type!='bigOh') {
    //console.log('Inside else if');
    //this.matchOdd2price = this.matchOdd2price*10;
    //console.log('Inside else if' + this.matchOdd2price);
    this.matchOdd2price = this.matchOdd2price + action;
    //console.log('Inside else if' + this.matchOdd2price);
    }
    else if (action!='back' && type=='bigOh') {
      this.matchOdd2price = Number(this.matchOdd2price) + Number(action);
      //console.log('Inside else if of bigOh' + this.matchOdd2price);
      }
      else if (action!='back' && type!='bigOh') {
        this.matchOdd2price = this.matchOdd2price + action;
        //console.log('Inside else if of bigOh' + this.matchOdd2price);
        }
    else {
    }
    let tmp = 0;
    let profit = 0;
    this.matchOdd2profit=(this.price-1)*this.matchOdd2price;
    switch (this.marketType) {
      case 'MATCH_ODDS':
        if(action=='back'){
          this.matchOdd2price = String(this.matchOdd2price).slice(0, -1);
          this.matchOdd2profit=(this.price-1)*this.matchOdd2price;
          //console.log('Inside keyboard of betplace component' + this.matchOdd2price);
          //this.matchOdd2price =Math.floor(123 / 1e11);
        }
       else if (action != '0' && action != '00' && action != '.' && action!='back') {
          this.matchOdd2profit=(this.price-1)*this.matchOdd2price;
        }
        else{
          
        }
        break;
      case 'INNINGS_RUNS':
        if(action=='back'){
          this.matchOdd2price = Math.floor(this.matchOdd2price / 10);
        }
      else if (action != '0' && action != '00' && action != '.') {
          this.matchOdd2profit=(this.betdata.price/100)*this.matchOdd2price;
        }
        else{
  
        } 
        break;
      case 'premium':
        if(action=='back'){
          this.matchOdd2price = Math.floor(this.matchOdd2price / 10);
        }
        else if (action != '0' && action != '00' && action != '.') {
          this.matchOdd2profit=(this.betdata.price-1)*this.matchOdd2price;
        }
        else{
  
        }
        break;
        case 'Bookmaker':
          if(action=='back'){
            this.matchOdd2price = Math.floor(this.matchOdd2price.num / 10);
          }
        else if (action != '0' && action != '00' && action != '.') {
          this.matchOdd2profit=(this.betdata.price/100)*this.matchOdd2price;    
        }
        else{
  
        }
        break;
    }
  }c

  openSign(){
    document.getElementById("demo").click();
   // console.log('Inside openSign');
  }

  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }
  placebet(){
    
  }
}
