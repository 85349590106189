import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BasicAuthInterceptor } from './auth.interceptor';
import { AuthService } from './services/auth.service';
import { AccountModule } from './shared/account/account.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from "ng-recaptcha";
// import { ToastrModule } from 'ngx-toastr';
import { ClickOutsideModule } from 'ng-click-outside';
import { LottieModule } from 'ngx-lottie';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ClipboardModule } from 'ngx-clipboard';
import { PixelModule } from "ngx-multi-pixel";
import { GtmModule } from "ngx-gtm";


//import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { indianCurrency } from './pipes/cust-inr.pipe';
import { NotifyComponent } from './notify/notify.component';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [AppComponent, indianCurrency, NotifyComponent],
  imports: [
    BrowserModule,
    RecaptchaModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    // ToastrModule.forRoot(),
    FormsModule,
    ClickOutsideModule,
    ClipboardModule,
    ReactiveFormsModule,
    HttpClientModule,
    AccountModule,
    SharedModule,
    LottieModule.forRoot({ player: playerFactory }),
    PixelModule.forRoot({ enabled: true, pixelId: ["2706155189693721", "1190972091651303", "586379523499553", "832160437968868"]}),
    GtmModule.forRoot({
      gtmId:'GTM-NLRKJSV8',
      isEnabled: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: any) => new TranslateHttpLoader(http),
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  exports: [TranslateModule],
  providers: [AuthService, { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] // Add this line
})
export class AppModule { }
