<!-- spinning loader -->
<div class="overlay" *ngIf="isLoader">
    <div class="loader-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <!-- <img loading="lazy" class="loader1" src="assets/images/loader-gif.gif" alt="Your Image"> -->
        <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>
    </div>
</div>
<!-- spinning loader -->

<app-accountheader></app-accountheader>
<div class="u-i-p-c-body-bc">
    <div (click)="closeCalender()" class="back-header">
        <svg (click)="backToProfitLoss()" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"
            fill="none">
            <path
                d="M13.8147 17.646L6.94336 10.7747L13.8147 3.90332L14.7767 4.88249L8.88451 10.7747L14.7767 16.6668L13.8147 17.646Z"
                fill="#D71082" />
        </svg>
        <span *ngIf="!profitLoss" class="white-large-text">{{'Betting Profit & Loss'|translate}}</span>
        <span *ngIf="profitLoss" class="white-large-text">{{'Betting Detail'|translate}}</span>
    </div>
    <div class="profit-lose-section">
        <div class="bettingDetail" style="min-height:30vh;" *ngIf="bettingDetail">
            <div class="date-box">
                <div class="rect-1"></div>
                <div class="rect-2"></div>
                <div class="date-card">
                    <div class="date-rang">
                        <div (click)="handleActiveStartDate()" class="date-input"
                            [ngClass]="{'active':activeStartDate, 'border':startDate}">
                            <span *ngIf="!startDate" class="gray-large-text">{{'Start Date'|translate}}</span>
                            <span *ngIf="startDate" class="gray-large-text white">{{startDate}}</span>
                            <img src="/assets/images/calender-icon.svg" alt="calender">
                        </div>
                        <div (click)="handleActiveEndDate()" class="date-input"
                            [ngClass]="{'active':activeEndDate, 'border':endDate}">
                            <span *ngIf="!endDate" class="gray-large-text">{{'End Date'|translate}}</span>
                            <span *ngIf="endDate" class="gray-large-text white">{{endDate}}</span>
                            <img src="/assets/images/calender-icon.svg" alt="calender">
                        </div>
                    </div>
                    <div class="right-arrow" (click)="applyFilter()" [ngClass]="{'right-arrow-op':activeApplyBtn}">
                        <img src="/assets/images/right-arrow.svg" alt="right">
                    </div>
                </div>
            </div>
            <div (clickOutside)="closeCalender()" class="calender-section" [ngClass]="{'Modal-background':showCalendar}"
                *ngIf="showCalendar">
                <app-calendar (selectedStartDateChange)="handleStartDate($event)"
                    (selectedEndDateChange)="handleEndDate($event)" [startDateInput]="startDate"
                    [endDateInput]="endDate"></app-calendar>
            </div>

            <ng-container *ngIf="sortedProfitAndLossData?.length>0">
                <ng-container *ngFor="let group of sortedProfitAndLossData">
                    <div class="p-l-card">
                        <span class="white-md-text">{{dateFormate1(group?.date)}}</span>
                        <div class="p-l-text">
                            <span class="white-tw-text">P&L :</span>
                            <span [ngClass]="{'green': getPnl(group?.items) >= 0, 'red': getPnl(group?.items) < 0}"
                                class="lg-text">{{getPnl(group?.items)}}</span>
                            <img *ngIf="getPnl(group?.items) >= 0" src="/assets/images/green-up-arrow.svg" alt="up">
                            <img *ngIf="getPnl(group?.items) < 0" src="/assets/images/red-down-arrow.svg" alt="down">
                        </div>
                    </div>
                    <ng-container *ngFor="let item of group?.items">
                        <div class="pl-card overflow-hidden"
                            (click)="showdetails(item?.marketId, item?.marketComm, item)">
                            <div class="profit-loss-card">
                                <div class="rect-1"></div>
                                <div class="rect-2"></div>
                                <div class="profit-loss-header">
                                    <div class="logo">
                                        <img *ngIf="item?.eventTypeId === '4'" src="/assets/images/menu-cricket.png"
                                            alt="cricket">
                                        <!-- <img src="/assets/images/bank-logo.svg" alt="bank"> -->
                                        <img *ngIf="item?.eventName == 'Blastoff'" src="/assets/images/menu-aviator.svg"
                                            alt="Blastoff">
                                        <!-- <img src="/assets/images/menu-badminton.svg" alt="bank"> -->
                                        <!-- <img src="/assets/images/menu-baseBall.png" alt="bank"> -->
                                        <!-- <img src="/assets/images/menu-basketBall.svg" alt="bank"> -->
                                        <img *ngIf="item?.eventTypeId === '2378962'"
                                            src="/assets/images/menu-politics.svg" alt="">
                                        <img *ngIf="item?.eventTypeId === '4339'"
                                            src="/assets/images/menu-greyhord-racing.svg" alt="greyhound">
                                        <img *ngIf="item?.eventTypeId === '7'"
                                            src="/assets/images/menu-horse-racing.svg" alt="Horse">
                                        <img *ngIf="item?.eventTypeId === '1'" src="/assets/images/menu-tennis.svg"
                                            alt="Tennis">
                                        <img *ngIf="item?.eventName == 'Live Teenpatti'"
                                            src="/assets/images/menu-teenPatti.png" alt="Teenpatti">
                                        <img *ngIf="item?.eventTypeId === '2'"
                                            src="/assets/images/menu-sports-option.svg" alt="Football">
                                        <img *ngIf="item?.eventName == 'Spingame'"
                                            src="/assets/images/menu-roulette-option.png" alt="Spin">
                                    </div>
                                    <span class="white-lg-text">{{item?.eventName}} - {{item?.marketName}}</span>
                                </div>
                                <div class="profit-loss-body">
                                    <div class="comission-net-box">
                                        <div class="comission-card">
                                            <span class="white-tw-text">{{'Commission'|translate}} :</span>
                                            <small [ngClass]="{'green':item?.memberComm >= 0}"
                                                class="md-text red">{{item?.memberComm}}</small>
                                        </div>
                                        <div class="line"></div>
                                        <div class="win-card">
                                            <span class="white-tw-text">{{'Net Win'|translate}} : </span>
                                            <small [ngClass]="{'green':item?.memberWin >= 0}" class="lg-text red">
                                                {{item?.memberWin}}</small>
                                        </div>
                                    </div>
                                    <div class="date-body">
                                        <div class="date">
                                            <span class="gray-md-text">{{'Start time'|translate}} :
                                                {{getDateAndTime(item?.marketTime)}}</span>
                                            <span class="gray-md-text">{{'Settled time'|translate}} :
                                                {{getDateAndTime(item?.settledTime)}}</span>
                                        </div>
                                        <img src="/assets/images/arrow-right-pl.svg" alt="arrow">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="sortedProfitAndLossData?.length == 0">
                <div></div>
                <br>
                <span class="pt-2 white-large-text">No Data of Profit & Loss Available For Selected Date Range.</span>
            </ng-container>
        </div>

        <ng-container *ngIf="viewBettingDetail">
            <div class="betting-detail">
                <div class="pl-card">
                    <div class="profit-loss-card">
                        <div class="rect-1"></div>
                        <div class="rect-2"></div>
                        <div class="profit-loss-header header-border">
                            <div class="logo">
                                <img src="/assets/images/menu-cricket.png" alt="cricket">
                            </div>
                            <span class="white-lg-text">{{showindividualdata[0]?.eventName}} -
                                {{showindividualdata[0]?.marketName}}</span>
                        </div>
                        <ng-container *ngFor="let data of showindividualdata; let i=index">
                            <div class="betting-second-card"
                                [ngClass]="{'header-border':showindividualdata?.length-1 !==i}">
                                <div class="first-card">
                                    <span class="gray-tw-text">{{'Selection'|translate}}</span>
                                    <span class="white-tw-text"
                                        *ngIf="data?.marketBettingType !== 'LINE'">{{data?.selectionName}}</span>
                                    <span class="white-tw-text"
                                        *ngIf="data?.marketBettingType === 'LINE'">{{data?.selectionName}}
                                        (Runs: {{data?.line}})</span>
                                </div>
                                <div class="second-card">
                                    <div class="profit-loss-input">
                                        <span class="gray-tw-text">P&L</span>
                                        <small [ngClass]="{'green':data?.profitLoss >= 0}"
                                            class="lg-text red">₹{{data?.profitLoss}}</small>
                                        <!-- <small class="red md-text">₹500</small> -->
                                    </div>
                                    <div class="won-input win"
                                        [ngClass]="{'win':data?.outcome == 'WON', 'lost':data?.outcome == 'LOST'}">
                                        <span class="gray-tw-text">{{'Status'|translate}}</span>
                                        <small *ngIf="data?.outcome == 'WON'"
                                            class="green sm-text">{{'WON'|translate}}</small>
                                        <!-- <small class="orange sm-text">Void</small> -->
                                        <small *ngIf="data?.outcome == 'LOST'"
                                            class="red sm-text">{{'LOST'|translate}}</small>
                                    </div>
                                </div>
                                <div class="third-card">
                                    <div class="first">
                                        <span class="gray-tw-text">{{'Bet ID'|translate}}</span>
                                        <span class="white-tw-text">{{data?.apolloBetId}}</span>
                                    </div>
                                    <div class="first">
                                        <span class="gray-tw-text">{{'Placed'|translate}} </span>
                                        <span class="white-tw-text">{{betPlacedDate(data?.betPlacedDate)}}</span>
                                    </div>
                                    <div class="third">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th class="first">{{'Type'|translate}}</th>
                                                    <th class="second">{{'Odds'|translate}}</th>
                                                    <th class="third">{{'Stake'|translate}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td *ngIf="data?.side == 0" class="fourth">Back</td>
                                                    <td *ngIf="data?.side == 1" class="fourth-1">Lay</td>
                                                    <td class="fifth">{{data?.marketName == "BOOKMAKER" ?
                                                        getBookmakerAvgOdds(data?.averagePrice,data?.sizeMatched):
                                                        data?.averagePrice}}</td>
                                                    <td class="sixth">₹ {{data?.sizeMatched}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>


            <div class="profit-loss-card" *ngIf="showProfitLossTotal">
                <div class="rect-1"></div>
                <div class="rect-2"></div>
                <div class="betting-second-card-1">
                    <div class="first-card">
                        <span class="large-text-color">{{'Back subtotal'|translate}}</span>
                        <span class="white large-text">₹
                            {{getBackSubTotal()}}</span>
                    </div>
                    <div class="first-card">
                        <span class="large-text-color">{{'Lay subtotal'|translate}}</span>
                        <span class="white large-text">₹
                            {{getLaySubTotal()}}</span>
                    </div>
                    <div class="first-card">
                        <span class="large-text-color">{{'Market subtotal'|translate}}</span>
                        <span class="white large-text">₹
                            {{getMarketSubTotal()}}</span>
                    </div>
                    <div class="first-card">
                        <span class="large-text-color">{{'Commission'|translate}}</span>
                        <span class="white large-text">₹
                            {{0}}</span>
                    </div>
                    <div class="hr-line"></div>
                    <div class="first-card">
                        <span class="large-text-color">{{'Net Market Total'|translate}}</span>
                        <span class="red lg-text" [ngClass]="{'green':getMarketSubTotal()>=0}">₹
                            {{getNetMarketTotal()}}</span>
                        <!-- <span class="orange lg-text">₹ {{getNetMarketTotal()}}</span> -->
                    </div>

                </div>
            </div>
        </ng-container>
    </div>
</div>
<!-- <div *ngFor="let s of profitlostdatass">
<p class="text-white"> {{s.eventName + s.marketName}}</p>
<p class="text-white"> {{s.marketTime }}</p>
<p class="text-white"> {{s.settledTime |date:'short'}}</p>
<p class="text-white"> {{s.memberWin }}</p>
<p class="text-white"> {{s.memberComm }}</p>
</div> -->
