import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-deposit-withdraw-report-popup',
  templateUrl: './deposit-withdraw-report-popup.component.html',
  styleUrls: ['./deposit-withdraw-report-popup.component.scss']
})
export class DepositWithdrawReportPopupComponent implements OnInit {

  @Output() closeClick: EventEmitter<any> = new EventEmitter();
  @Input() title: string;
  popupClosed: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  
  closeClicked() {
    this.popupClosed = true;
    setTimeout(() => {
      this.closeClick.emit(true);
    },300);
  }
}
