<div class="whatsApp-wrapper">
  <div class="whatsApp-content" (click)="openWhatsApp()">
    <img loading="lazy" class="whatsApp-content_img" src="../../../assets/images/watsApp.svg" alt="" />
    <div class="circleIconPanel" [ngClass]="{'showMessage': animAppOpen, 'hideMessage': animAppClose }">
      <div>{{'live chat'|translate}}</div>
      <div>{{'whatsapp'|translate}}</div>
    </div>
  </div>
</div>
<div class="intercom">
  <div class="intercom-content">
    <img loading="lazy" (click)="clickOpenIntercom()" class="intercom_img" src="../../../assets/images/intercom.svg"
      alt="" />
    <!--    <img (click)="clickOpenIntercom()" *ngIf="intercomBtn" class="intercom_img" src="../../../assets/images/intercom.svg" alt="" />-->
  </div>
</div>