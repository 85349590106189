import { Component, OnInit, Output, Input, forwardRef, ChangeDetectionStrategy, EventEmitter, HostListener, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

const UI_SWITCH_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CricketCardComponent),
  multi: true
};

@Component({
  selector: 'app-cricket-card',
  templateUrl: './cricket-card.component.html',
  styleUrls: ['./cricket-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [UI_SWITCH_CONTROL_VALUE_ACCESSOR]
})
export class CricketCardComponent implements OnInit {

  @Input() data: any; //match data
  @Input() showOdds: boolean = true; //show odds
  @Output() openBetSlip = new EventEmitter<any>(); //emits bet slip data
  @Input() isLastItem: boolean = false; //check if last item
  isMatchInPast: boolean = false; //check if match is in past
  date: any; //match date
  betSlipCard: boolean = false; //show bet slip card
  odds: any; //odds data
  applyBackAnimation: any = [false, false, false];

  applyLayAnimation: any = [false, false, false];

  liveDotLottieOptions: any = {
    path: 'assets/static/media/live.json',
    autoplay: true,
    loop: true
  };

  constructor() { }

  ngOnInit(): void {
    this.date = this.getMatchDate(this.data?.market?.event?.openDate);
    this.odds = this.data?.market?.consolidatedRunner;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].currentValue && changes['data'].previousValue) {
      const backPrices: number[] = changes['data']?.currentValue?.market?.consolidatedRunner?.back.map(item => item?.price);
      const previousBackPrices: number[] = changes['data']?.previousValue?.market?.consolidatedRunner?.back.map(item => item?.price);

      for (let i = 0; i < backPrices?.length; i++) {
        const currentPrice = backPrices[i];
        const previousPrice = previousBackPrices[i];

        if (currentPrice !== previousPrice) {
          this.applyBackAnimation[i] = true;
          setTimeout(() => {
            this.applyBackAnimation[i] = false;
          }, 1000);
        }
      }

      // do the same for lay prices
      const layPrices: number[] = changes['data']?.currentValue?.market?.consolidatedRunner?.lay.map(item => item?.price);
      const previousLayPrices: number[] = changes['data']?.previousValue?.market?.consolidatedRunner?.lay.map(item => item?.price);

      for (let i = 0; i < layPrices?.length; i++) {
        const currentPrice = layPrices[i];
        const previousPrice = previousLayPrices[i];

        if (currentPrice !== previousPrice) {
          this.applyLayAnimation[i] = true;
          setTimeout(() => {
            this.applyLayAnimation[i] = false;
          }, 1000);
        }
      }
    }
    this.ngOnInit();
  }

  getMatchDate(date:any) {
    // check if date is in past
    this.isMatchInPast = moment().isAfter(date);
    return moment(date).fromNow();
  }

  triggerBetSlip(bet: number, isBack: boolean, oddIndex: number) {
    //currently return as this is diabled on landing page
    return;
    //check if market status is open and and market is bettable
    if (bet == null) {
      return;
    }
    if (this.data?.market?.status == 'OPEN' && this.data?.market?.isBettable) {
      this.openBetSlip.emit(
        {
          eventId: this.data?.event?.id,
          bet: bet,
          sportsId: this.data?.eventTypeId,
          backLay: this.data?.market?.consolidatedRunner,
          isBack,
          oddIndex,
          marketId: this.data?.market?.id,
          betDelay: this.data?.market?.betDelay,
          runnerId: isBack ? this.data?.market?.consolidatedRunner?.back[oddIndex]?.runner?.id : this.data?.market?.consolidatedRunner?.lay[oddIndex]?.runner?.id,
          runnerName: isBack ? this.data?.market?.consolidatedRunner?.back[oddIndex]?.runner?.name : this.data?.market?.consolidatedRunner?.lay[oddIndex]?.runner?.name
        }
      );
    }
  }


}
