<!--
<div class="card bonus-card orange-card"
[ngStyle]="{'background-image': 'url(' + promotion['promoImageUrl'] + ')'}"
>
    <div class="card-body">
        <p class="card-title russo"> {{promotion['short-info']}} </p>
        <p class="card-title russo sm-txt"> {{promotion['short-info-2']}} </p>
        <button class="btn" (click)="depositNow()">Deposit now</button>
    </div>
</div> -->


<div class="card" [ngStyle]="{'background-image': 'url(' + promotion['promoImageUrl'] + ')'}">
    <p class="card-title russo"> {{promotion['short-info']}} </p>
    <p class="card-title russo sm-txt"> {{promotion['short-info-2']}} </p>
    <button
        *ngIf="loggedUsername?.startsWith('b06.91') || mailLoginName?.includes('@') || loggedUsername?.includes('_')"
        class="btn" (click)="depositNow()">{{'Deposit now'|translate}}</button>
    <button
        *ngIf="!loggedUsername?.startsWith('b06.91') && !mailLoginName?.includes('@') && !loggedUsername?.includes('_')"
        class="btn" (click)="whatsappAccountRegistration()">{{'Deposit now'|translate}}</button>
</div>
