import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { CasinoService } from 'src/app/services/casino.service';
import { LandingService } from 'src/app/services/landing.service';
import { MyprofileService } from '../../services/myprofile.service';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
declare var $: any
@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  filteredArray: any;
  sliderImage: any;
  Images: any;
  allgames: any = [];
  gameCategory: any = [];
  gameCategoryAndProvideid: any = [];
  selcetedgamesactive: any = 'all';
  gamebyproviderid: any = [];
  public form: FormGroup;
  casinoUrl: string;
  isScroll: boolean = false;
  isLoader: boolean = false;
  balanceamounts: any = {};
  jiliId: string;
  jiliGID: string;
  favouriteGamelistcasino: any = [];
  showhidecasinopopup: any = true;
  isActivefav: any;
  pointPopUpOpen = true;
  isActivefavlive: any;
  gamepath = environment.gamespath;
  isMobile: boolean = false;
  private urlIframeSubscription: Subscription;
  sliderlConfig = {
    slidesToShow: 3,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,

        }
      }

    ]
  };

  urlIframe: string;
  public yt: any;
  @ViewChild('iframeLoad', { static: false }) iframeLoad: ElementRef;
  isIphoneChrome = (navigator.userAgent.includes("iPhone") && navigator.userAgent.includes('CriOS'));
  isIphoneSafari = (navigator.userAgent.includes("iPhone") && !navigator.userAgent.includes('CriOS'));
  // @ts-ignore
  isSafariPWA = navigator?.standalone

  title: string

  constructor(private api: LandingService, private myprofile: MyprofileService, private casinoServices: CasinoService, private formBuilder: FormBuilder, private faverate: LandingService) { }

  ngOnInit(): void {
    this.urlIframeSubscription = this.casinoServices.casinoURLObservable$.subscribe((url: string | null) => {
      if (!url && this.urlIframe) {
        this.clearIframe()
      }
      if (url) {
        this.openGame(url)
      }
      this.urlIframe = url;
    })
    this.checkIfMobile();
    this.isLoader = true;
    this.api.sliderimage().subscribe((res: any) => {
      res;
      this.sliderImage = res;
      this.Images = res.data;
      this.filteredArray = res?.result?.inPlaySlidingBanners;
      this.isLoader = false;

    })
    this.createForm();
    // $(".slick-sliderg").slick({
    //   slidesToShow: 1,
    //   infinite:true,
    //   slidesToScroll: 1,
    //   autoplay: true,
    //   autoplaySpeed: 2500
    //  });
    this.casinogame();
    this.myprofile.getBalanceData().subscribe((data) => {
      this.balanceamounts = data.result;
      if (data?.result?.useBWallet == true) {
        this.balanceamounts = data.result.bWallet;
      }
      else if (data?.result?.useSWallet == true) {
        this.balanceamounts = data.result.sWallet;
      }
      else if (data?.result?.useCWallet == true) {
        this.balanceamounts = data.result.cWallet;
      }
      else {
        this.balanceamounts = data.result;
      }
    })

    this.favouriteGame(null, null)
  }
  casinogame() {
    this.casinoServices.getCategoryWiseCasinoGames(12).subscribe((res: any) => {
      this.gameCategory = res?.result;
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 1024;
  }
  goToGame(games) {
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = ''
    }
    if (games) {
      this.title = games?.name || games?.Trans.en
    }
    let id = games?.id;
    let cId = id.slice(0, 4);
    //console.log("insidde getPNl value of res:" + JSON.stringify(id));
    if (cId == 'jili' && this.getToken()) {
      this.form.reset();
      this.jiliId = games.Trans.en;
      this.jiliGID = games.ID;
      this.showhidecasinopopup = !this.showhidecasinopopup;
      //  console.log('Inside casinogame value of goToGame id: ' + cId);
    } else if (this.getToken()) {
      //console.log("insidde getPNl value of res:" +- JSON.stringify(id));
      this.casinoServices.getCasinoUrl(id).subscribe(
        res => {
          // this.casinoUrl=res.result.url;
          //  console.log("insidde getPNl value of res:" +- JSON.stringify(this.casinoUrl));
          //   window.open(this.casinoUrl);
          //   this.urlIframe = res?.result?.url;
          //   this.openGame(this.casinoUrl)
        },
        error => {
        }
      );
    }
    else {
      document.getElementById("demo").click();
    }

  }

  clearIframe() {
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = ''
    }
    this.title = ""
    this.urlIframe = ""
    this.casinoServices.clearCasinoURL()
  }
  openGame(iurl) { // INIT IFRAME
    this.yt = '<iframe style="width: 100%; height: 100%" class="games-new-iframe" src="' + iurl + '" frameborder="0" id="casino-fram" allow="autoplay; encrypted-media"  allowfullscreen></iframe>';
    this.iframeLoad.nativeElement.innerHTML = this.yt;
  }

  createForm() {
    this.form = this.formBuilder.group({
      amount: ['', [Validators.required]],
    });

  }

  showcasino() {
    this.showhidecasinopopup = !this.showhidecasinopopup;
  }
  depositJili() {
    let amt = this.form.value;
    //console.log('Inside depositJili value of amt is:' + JSON.stringify(amt));
    const data = [
      {
        "line": null,
        "marketId": "7.7",
        "oddsType": null,
        "price": 1,
        "selectionId": 1,
        "side": 1,
        "totalSize": amt.amount
      }
    ];
    this.casinoServices.jiliDeposit(data).subscribe(
      res => {
      },
      error => {
      }
    );
    this.casinoServices.jiliGame(this.jiliGID).subscribe(
      res => {
        this.showhidecasinopopup = !this.showhidecasinopopup;
        this.casinoUrl = res.result.url;
        // window.open(this.casinoUrl);

        this.urlIframe = res?.result?.url;
        this.openGame(this.casinoUrl)

      },
      error => {
      }
    );

  }
  scrollToTop() {
    //console.log('Inside scrollToTop of casino component');
    $("#button").on("click", function () {
      //console.log('Inside button scrollToTop of casino component');
      $(window).scrollTop(0);
    });
  }
  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }
  getMember() {
    return localStorage.getItem('Member_code');
  }
  favouriteGame(selectedgames: any, imageurl: any) {

    var gamesl = localStorage.getItem("gamelist");

    if ((gamesl == null) && (imageurl != null)) {
      this.casinoServices.equipment = []

      this.isActivefav = selectedgames;
      this.casinoServices.favouritegame(imageurl);
      this.favouriteGamelistcasino = this.casinoServices.equipment;
      this.faverate.favouriteimage.next(this.favouriteGamelistcasino);
    } else if (gamesl.includes(imageurl)) {


      const list = this.casinoServices.equipment;

      const index = list.indexOf(imageurl);

      this.casinoServices.equipment.splice(index, 1);
      // this.casinoServices.favouritegame(list);
      localStorage.setItem("gamelist", JSON.stringify(this.casinoServices.equipment));
      this.favouriteGamelistcasino = this.casinoServices.equipment;
      this.faverate.favouriteimage.next(this.favouriteGamelistcasino);

      // }




    } else if ((imageurl != null) && (gamesl != null)) {


      this.isActivefav = selectedgames;

      // this.favouriteGamelistcasino.push(imageurl);

      this.casinoServices.favouritegame(imageurl);
      this.favouriteGamelistcasino = this.casinoServices.equipment;
      this.faverate.favouriteimage.next(this.favouriteGamelistcasino);
    } else {

    }


  }
  setScrollbutton() {
    const $window = $(window);
    $window.on('scroll', () => {
      const windowScrollTop = $window.scrollTop();
      const windowheight = $window.outerHeight();;
      //console.log('Inside setDesktopTV of detailpage component windowScrollTop' + windowScrollTop);
      //console.log('Inside setDesktopTV of detailpage component windowScrollTop' + windowheight);

      if (windowScrollTop > windowheight) {
        this.scrollToTop();
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
    });
  }
  ngAfterViewInit() {
    this.scrollToTop();
    this.setScrollbutton();
  }
  ngOnDestroy() {
    this.scrollToTop();
    this.setScrollbutton();
  }
  closePointPopUp() {
    this.pointPopUpOpen = false
  }
}
