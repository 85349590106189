import {Component, OnInit, Input, SimpleChanges, Output, EventEmitter} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifyServiceService} from 'src/app/notify/notify-service.service';
import {AuthService} from 'src/app/services/auth.service';
import {BettingHelperService} from 'src/app/services/betting-helper.service';
import { EventService } from 'src/app/services/broadcast.service';
import {MyprofileService} from 'src/app/services/myprofile.service';
import {BetSlipData} from "../../../models/BetSlipData";

@Component({
  selector: 'app-bet-slip-card',
  templateUrl: './bet-slip-card.component.html',
  styleUrls: ['./bet-slip-card.component.scss']
})
export class BetSlipCardComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  @Input() betData: any = {};
  @Input() matchData: any = {};
  @Input() eventId: any;  //this variable is defined for purpose of scrolling to the bet slip card into view
  // @Input() market: any = {};

  @Input() index = -1;

  @Output() closeBetSlip = new EventEmitter();
  closeBet: boolean = false;
  // eventId: any;
  sportsId: any;
  marketId: any;
  runnerId: any;
  oddIndex: number;
  isBack: boolean;
  value: any;
  betDelay: any;
  confirmPopup = false;
  betSettings: any;
  betStake: any;
  profitLoss: any;
  bType: any;
  oddsType: any;
  isLoader: boolean = false; //for displaying loader in bet slip card
  allowEditOdds: boolean = true; //for allowing edit odds in bet slip card
  stakeArray = [300, 1000, 5000, 10000];
  userSettings: any;
  loggerUserData: any;
  betslipData: BetSlipData;
  isConfirmEnabled: any = true;
  constructor(private authService: AuthService,
    private notify: NotifyServiceService,
    private betHelper: BettingHelperService,
    public myProfileService: MyprofileService) { }

  ngOnInit(): void {
    let stakeData = localStorage.getItem('betstack');
    if (stakeData) {
      this.stakeArray = stakeData.split(',')
        .map((item) => parseInt(item))
        .slice(0, 4);
    }
    //check for class = whatsapp and if found remove element
    let element = document.getElementsByClassName("whatsapp");
    if (element.length > 0) {
      element[0].remove();
    }
    if (this.loggerUserData == null) {
      this.loggerUserData = JSON.parse(localStorage.getItem('userData'));
      this.userSettings = this.loggerUserData?.settings;
    } else {
      this.userSettings = this.loggerUserData?.settings;
    }
    console.log("new line", this.matchData);
    if (this.betData) {
      this.allowEditOdds = !(this.matchData?.mtype == 'INNINGS_RUNS' || this.matchData?.mtype == 'MATCH_ODDS_SB') ? true : false;
      this.oddIndex = this.betData?.oddIndex;
      this.isBack = this.betData?.isBack;
      this.runnerId = this.betData?.runnerId;
      this.betDelay = this.betData?.betDelay;
      this.marketId = this.betData?.marketId;
      this.bType = this.matchData?.btype;
      this.oddsType = this.matchData?.oddsType;
      this.betForm.get('betOdds')?.setValue(this.betData?.bet);
      this.betForm.get('betStake')?.setValue(this.betData?.stake);
      this.betHelper.stakes = this.betData?.stake;
      this.profitLoss = null;
      this.betHelper.profitAndLiability = null;
      this.betHelper.stakeOnBet = null;



      if (this.getToken()) {
        this.authService.betSettings({marketId: this.marketId, runnerId: this.runnerId}).subscribe(res => {
          this.betSettings = res?.result;
          // console.log(this.betSettings);
        });
        let userData = JSON.parse(localStorage.getItem('userData'));
        this.isConfirmEnabled = userData['settings']['preferences']['confirmation'];
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['betData'] && !changes['betData'].firstChange) {
      // Update component properties here based on the new betData
      this.ngOnInit();
      // Update other properties...
    }
  }

  //formgroup
  betForm: FormGroup = new FormGroup({
    betOdds: new FormControl(this.betData?.bet),
    betStake: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(9)]),
  });


  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }

  confirm() {
    //check if bet stake is valid and if less than max stake
    let data = {
      confirmation: !this.isConfirmEnabled
    }
    this.authService.confirmBet(data).subscribe(res => {
        if (res.success) {
          this.isConfirmEnabled = data.confirmation;
          let userData = JSON.parse(localStorage.getItem('userData'));
          userData['settings']['preferences']['confirmation'] = this.isConfirmEnabled
          localStorage.setItem('userData', JSON.stringify(userData));
          this.authService.currentUserSubject.next(userData);
        }
      },
      err => {
        console.log(err);
      });
  }

  formatToIndianCurrency(value: number): string {
    return value.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).replace('₹', '').replace('.00', '');
  }

  onInput(event: Event) {
    let maxLimit = (event.target as HTMLInputElement).value.replace(/[^0-9]/g, '');
    if (maxLimit.length > 9) {
      maxLimit = maxLimit.substring(0, 9);
    }
    (event.target as HTMLInputElement).value = maxLimit;
    this.calculateProfitLoss();
  }

  onStakeInput(event: Event) {
    let maxLimit = (event.target as HTMLInputElement).value;
    //remove all characters except numbers and .
    maxLimit = maxLimit.replace(/[^0-9.]/g, '');
    if (maxLimit.length > 4) {
      maxLimit = maxLimit.substring(0, 4);
    }
    (event.target as HTMLInputElement).value = maxLimit;
    this.calculateProfitLoss();
  }

  updateStake(value: any) {

    if (this.betForm?.value?.betStake == undefined) {
      this.betForm.get('betStake')?.setValue(0);
    }
    this.betForm.get('betStake')?.setValue(Number(this.betForm?.value?.betStake) + value);
    this.calculateProfitLoss();
    //set dirty
    this.betForm.get('betStake')?.markAsDirty();
  }

  //calculate profit loss
  //if back then profit = (odds * stake) - stake
  //if lay then profit = stake - (odds * stake)
  calculateProfitLoss() {
    const stake = parseFloat(this.betForm?.value?.betStake);
    const odds = parseFloat(this.betForm?.value?.betOdds);
    const isFancy = this.matchData?.mtype === 'INNINGS_RUNS' && this.bType === 'LINE';

    if (isFancy) {
      this.profitLoss = stake ? parseFloat((stake * odds / 100).toFixed(2)) : 0;
    } else {
      this.profitLoss = this.isBack ? (odds - 1) * stake : (1 - odds) * stake * -1;
    }

    this.profitLoss = parseFloat(this.profitLoss.toFixed(2));
    this.betHelper.stakeOnBet = this.profitLoss > 0 ? stake * -1 : stake;
    this.betHelper.profitAndLiability = this.profitLoss;
    this.betHelper.price = odds;
    this.betHelper.stakes = stake;

    this.betslipData = new BetSlipData();
    this.betslipData.cardId = this.betData.marketId;
    this.betslipData.price = odds;
    this.betslipData.stakes = stake;
    this.betHelper.setCurrentBetslip(this.betslipData);
  }

  // increase-decrease odds by 0.02
  increaseOdds(operation: string) {
    let value = this.betForm.value.betOdds;
    if (operation == 'add') {
      value += 0.02;
      value.toFixed(1);
    } else if (operation == 'minus') {
      value -= 0.02;
      value.toFixed(1);
    }
    const roundedValue = Number(value.toFixed(2));

    this.betForm.get('betOdds')?.setValue(roundedValue);
    this.calculateProfitLoss();
  }

  ngAfterViewInit() {
    if (this.betData != undefined) {
      const elementScroll = document.getElementById('elementToScroll');
      if (elementScroll) {
        elementScroll.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }
  }

  isConfirmation() {
    if(Number(this.betForm.value.betOdds) > this.betSettings?.maxWinPerMarket || Number(this.betForm?.value?.betStake) > this.betSettings?.maxBet){
      this.notify.error("Stake cannot be greater than max stake allowed");
      return;
    }
    if (this.isConfirmEnabled) {
      this.confirmPopup = true;
    } else {
      this.placeBet();
    }
  }


  placeBet() { //need to implement loader for this
    this.isLoader = true;
    //set betForm.get('betStake') to invalid && pristine
    this.betForm.get('betStake')?.markAsPristine();
    this.betForm.get('betStake')?.markAsUntouched();
    let data = {
      'side': this.isBack ? 0 : 1, //back = 0, lay = 1
      'price': this.betForm?.value?.betOdds, // betOdds
      'selectionId': this.runnerId, // this.betData?.backLay?.back[this.betData?.oddIndex]?.runner?.id
      'btype': this.matchData.btype, //from inplay
      'oddsType': this.matchData.oddsType, //from inplay
      'eventTypeId': this.betSettings?.eventTypeId, //from inplay
      'marketId': this.marketId, //market.id
      'totalSize': Number(this.betForm?.value?.betStake), //stake
      'betSlipRef': 0, //normally 0
      'fromOneClick': 0, //normally 0
      'betDelay': this.betData?.betDelay, //from inplay
      'line': this.matchData?.mtype == 'INNINGS_RUNS' && this.bType == 'LINE' ? this.matchData?.runners?.[0][this.isBack ? 'back' : 'lay'][this.oddIndex]?.line : null,  // if bType ='line' INNING_RUNS and for fancy,
    'runner': this.betData?.runnerName //runner.name
  }
  let obj = {
    runner: this.betData?.runnerName,
    stake: Number(this.betForm?.value?.betStake),
    type: this.isBack,
    marketType: this.matchData?.mtype,
    marketId: this.marketId,
    btype: this.bType,
  } //for passing to function getPlacingText for custom toaster message.
  console.log(data);
  // return;
  this.authService.betplace([data]).subscribe(res => {
        this.isLoader = false;
        let message = this.getPlacingText(obj, res);
        this.confirmPopup = false;
        this.notify.success(message);
        this.getBetHistory();
        this.closeBetSlip.emit(true);
      },
      err => {
        this.isLoader = false;
        this.closeBetSlip.emit(true);
        this.confirmPopup = false;
        this.notify.error(err.error.error[0][0].description);
      })
  }


  //custom toaster message
  getPlacingText = (bet, res) => {
    const matched = res.result?.result?.placed?.[0].sizeMatched
    const stake = Number(bet.stake)
    const odds = res.result?.result?.placed?.[0].averagePriceMatched //bet.priceActual
    const runner = bet.runner
    const type = bet.type ? 'Back' : 'Lay'
    const isFancy = bet.marketType == "INNINGS_RUNS" && bet.bType == "LINE"

    const getPriseFor_SB = (marketId, price) => {
      return (marketId && marketId.slice(marketId.length - 3) === '_SB' ? ((price - 1) * 100).toFixed(2).replace('.00', '') : price);
    }

    const priceOdds = getPriseFor_SB(bet.market, odds)


    if ((matched || matched === 0) && stake) {
      if (isFancy) {
        return `Bet Matched. ${bet.line} runs - ${runner} - ${bet.isBack ? 'Yes' : 'No'} for ${stake} @ ${odds}`
      } else {
        if (stake - matched == 0) return `Bet Matched. ${type} - ${runner} - ${stake} at odds ${priceOdds}`
        else if (stake - matched == stake) return `Bet Unmatched. ${type} - ${runner} - ${stake} at odds ${priceOdds}`
        else if (stake - matched > 0) return `Bet Partially Matched. ${type} - ${runner} - ${stake} at odds ${priceOdds}`
      }
    }
    return ''
  }

  closeBetSlipCard() {
    this.closeBet = true;
    setTimeout(() => {
      this.closeBetSlip.emit(true);
      this.closeBet = false;
    }, 250);
  }


  goToLogin() {
    document.getElementById("demo").click();
  }

  getBetHistory() {
    this.myProfileService.betHistory(null).subscribe((res: any) => {
      this.betHelper.betHistorySubject.next(res?.result);
    }, (err: any) => {
      console.log(err)
    })

    this.myProfileService.betHistoryCount().subscribe((res: any) => {
      this.myProfileService.totalBetCount = res?.result?.count;
    }
    );
  }

  calculatePrice(item: any) {
    if (item && item > 0) {
      const calculatedPrice = ((item - 1) * 100).toFixed(2);
      const formattedPrice = calculatedPrice.replace(/\.00$/, '');
      return formattedPrice;
    } else {
      return '-';
    }
  }

}
