<div class="shadow">

    <div class="card">
        <div class="close" (click)="claimDone()">
            X
        </div>

        <img loading="lazy" class="checkimg" src="../../../../assets/images/checkstars.png" alt="">
        
        <div class="container">  
            <h1>{{'Congratulations!'|translate}}</h1>
            <h3>{{"You've won"|translate}} {{this._bonus.lastClaimBonus| currency :'INR'}} {{'as bonus in your wallet.'|translate}}</h3>
            <button class="btn btn-success" (click)="claimDone()" >{{'Done'|translate}}</button>
        </div>

    </div>
</div>
