import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LoginComponent } from 'src/app/authentication/login/login.component';
import { AuthService } from 'src/app/services/auth.service';
import { MyprofileService } from 'src/app/services/myprofile.service';
import * as moment from 'moment';
import { ProfitLossResult } from 'src/app/models/profit-loss.interface';



@Component({
  selector: 'app-betbuilder',
  templateUrl: './betbuilder.component.html',
  styleUrls: ['./betbuilder.component.scss']
})
export class BetbuilderComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }


  hideaccordion:any=false;
  showhidefilter:any=false;
  showbwthistorydetail:any=true;
  profitandlost:any;
  profitlostdata:any;
  temp5:any=[];
  temp:any;
  profitlostdatass:any=[];
  showindividualdata: ProfitLossResult[] = [];
  netmartkehide:any = true;
 markettimes:any=[];
 profitlossHeadline:any=[];
 profitlossContens:any=[];
 Backsubtotal:any;
Laysubtotal:any;
Marketsubtotal:any;
NetmarketTotal:any;
CommissionTotal:any;
CommissionTotals:any;
 temps:any=[];
 totalpnl=0;
totalv:any;
casinodatshowonly:any =true;
filterpnl:any=0;
range = new FormGroup({
  start: new FormControl(),
  end: new FormControl(),
});

  bettingDetail: any = true;
  viewBettingDetail: any = false;
  profitLoss: any = false;


profitfilter:any[];
profitfilterdata:any[];

  //new variables
  showCalendar: boolean = false;
  sortedProfitAndLossData: any;
  showProfitLossTotal: boolean = false;
  backSubTotal: number = 0;
  laySubTotal: number = 0;
  marketSubTotal: number = 0;
  netMarketTotal: number = 0;
  commissionTotal: number = 0;
  isLoader: boolean = false;
  startDate: any = null;
  endDate: any = null;
  activeStartDate: any;
  activeEndDate: any;
  activeApplyBtn: boolean = false;


  constructor( private myprofile:MyprofileService,private authsrvic:AuthService) { }

  ngOnInit(): void {
    this.profitAndLossData();
    this.startDate = this.convertDateToDDMMYYYY(new Date(new Date().setDate(new Date().getDate() - 7)));
    this.endDate = this.convertDateToDDMMYYYY(new Date());
  }

  convertDateToDDMMYYYY(date: any) {
    return moment(date).format('DD/MM/YYYY');
  }


  showdetails(markdetid:any,commission:any,items:any ){
    this.showCalendar = false;
    this.isLoader = true;
    this.casinodatshowonly = true;
    if(items.eventypeid == 1444000){
      this.netmartkehide = false;
      this.CommissionTotals =' ';
      this.myprofile.accountstatementsdetailsCasino(markdetid).subscribe((res)=>{
        this.isLoader = false;
        this.hideaccordion=!this.hideaccordion;
        this.showbwthistorydetail=!this.showbwthistorydetail;


        this.showindividualdata = res.result;
        if(this.showbwthistorydetail){
          this.casinodatshowonly = true;

        }
        else{
          this.casinodatshowonly = false;

        }


    },(err)=>{
        console.error(err);
        this.isLoader = false;
      this.showindividualdata =[];


    })
    }else{

    this.CommissionTotals = commission;
    this.showbwthistorydetail = markdetid;
    this.netmartkehide = false;
    this.myprofile.accountstatementsdetails(markdetid).subscribe((res)=>{
        this.isLoader = false;
        this.activeApplyBtn = false;
        this.bettingDetail = false;
        this.viewBettingDetail = true;
        this.profitLoss = true;
        this.showProfitLossTotal = true;
      this.showindividualdata = res.result;
      }, (err) => {
        console.error(err);
    })
  }

  }



  getBackSubTotal() {
    return this.showindividualdata.filter((item: any) => item.side == 0).reduce((acc: any, val: any) => acc + val.memberWin, 0).toFixed(2);
  }

  getLaySubTotal() {
    return this.showindividualdata.filter((item: any) => item.side == 1).reduce((acc: any, val: any) => acc + val.memberWin, 0).toFixed(2);
       }

  getMarketSubTotal() {
    return this.showindividualdata.reduce((acc: any, val: any) => acc + val.memberWin, 0).toFixed(2);
      }

  getNetMarketTotal() {
    const backTotal = this.showindividualdata.filter((item: any) => item.side == 0).reduce((acc: any, val: any) => acc + val.memberWin, 0);
    const layTotal = this.showindividualdata.filter((item: any) => item.side == 1).reduce((acc: any, val: any) => acc + val.memberWin, 0);
    return (backTotal + layTotal).toFixed(2);
  }

  getCommissionTotal() {
    return this.showindividualdata.reduce((acc: any, val: any) => acc + val.memberWin, 0).toFixed(2);
  }

  parseDate = (dateStr: any) => {
    const [day, month, year] = dateStr?.split("/");
    return new Date(`${month}/${day}/${year}`);
  };

  profitAndLossData(startDate?: any, endDate?: any) {
    this.isLoader = true;
    if (startDate && endDate) {
      startDate = this.parseDate(startDate);
      endDate = this.parseDate(endDate);
    }
    startDate = startDate ? new Date(startDate) : new Date(new Date().setDate(new Date().getDate() - 7));
    endDate = endDate ? new Date(endDate) : new Date();

    startDate = startDate.getTime();
    endDate = endDate.getTime();

    this.myprofile.betprofitandlost(startDate, endDate).subscribe((res) => {
      this.isLoader = false;
      this.activeApplyBtn = false;
      if (res?.status?.statusDesc === 'Success') {
        const profitAndLossList = res.result;
        const sortedGroupedData = Object.values(profitAndLossList)
          .flatMap((val: any) => val?.data)
          .reduce((acc: any, item: any) => {
            const dateKey = new Date(item.marketTime).toISOString().split('T')[0];
            acc[dateKey] = acc[dateKey] || [];
            acc[dateKey].push(item);
        return acc;
          }, {});
        const data = Object.entries(sortedGroupedData).map(([date, items]) => ({ date, items }));
        data.sort((a: any, b: any) => b.date.localeCompare(a.date));
        this.sortedProfitAndLossData = data;
      }
    }, (err) => {
      this.isLoader = false;
      console.error(err);
    });
  }

  getBookmakerAvgOdds(averagePrice: number, matchedSize: number): number {
    let betAvg = averagePrice * matchedSize;
    return Number((betAvg -= matchedSize).toFixed(0));
     }

  dateFormate(date: any) {
    return moment(date).format('MMM DD, YYYY | h:mm a');
     }

  dateFormate1(date: any) {
    const today = new Date().toDateString();
    const date1 = new Date(date).toDateString();
    if (today == date1) {
      return "Today";
  }
    //if date is yesterday's date then return yesterday
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toDateString();
    if (yesterday == date1) {
      return "Yesterday";
        }
    return moment(date).format('MMM DD, YYYY');
      }

  getPnl(item: any) {
    //item is array of profit and loss data for a particular date
    return item.reduce((acc: any, val: any) => acc + val.pnl, 0).toFixed(2);
    }

  getDateAndTime(date: any) {
    return moment(date).format('DD/MM/YYYY, hh:mm:ss A');
        }

  betPlacedDate(date: any) {
    return moment(date).format('MMM MM, YYYY | hh:mm');
        }

  openBettingDetail() {
    this.bettingDetail = false;
    this.viewBettingDetail = true;
    this.profitLoss = true;
}

  openCalendar() {
    this.showCalendar = !this.showCalendar;
}

  handleStartDate(date: any) {
    this.startDate = date;
    this.activeEndDate = false;
    this.activeStartDate = true;
        }

  backToProfitLoss() {
    if (!this.profitLoss) {
      window.history.back();
    }
    this.bettingDetail = true;
    this.viewBettingDetail = false;
    this.profitLoss = false;
  }

  handleEndDate(date: any) {
    this.endDate = date;
    this.activeStartDate = false;
    this.activeEndDate = true;
    this.activeApplyBtn = true;
      }

  async getProfitLossStatement() {
    if (!this.startDate || !this.endDate) {
      return;
    }
    this.showCalendar = false;
    this.profitAndLossData(this.startDate, this.endDate);
    this.activeEndDate = false;
    this.activeStartDate = false;
  }

  handleActiveStartDate() {
    this.activeStartDate = true;
    this.activeEndDate = false;
    this.showCalendar = !this.showCalendar;
        }

  handleActiveEndDate() {
    this.activeStartDate = false;
    this.activeEndDate = true;
    this.showCalendar = !this.showCalendar;
  }

  applyFilter() {
    this.getProfitLossStatement();
      }

  closeCalender() {
    if (this.showCalendar) {
      this.showCalendar = false;
}
  }
}

