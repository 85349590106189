<div class="deposit-details-card" *ngIf="detailsWithdrawDeposit?.type==='Deposit'">
    <div class="card">
        <div class="card-head">
            <span class="card-head-left">{{'Request IDs'|translate}}</span>
            <span class="card-head-right">{{detailsWithdrawDeposit.requestId}}</span>
        </div>
        <div class="box1">
            <div class="box1-top">
                <div class="left">
                    <span class="symbol">
                        <img src="../../../../../../assets/images/deposit-icon-deposit-report.svg" alt="deposit-icon">
                    </span>
                    <span class="text">
                        <span class="top">{{'Type'|translate}}</span>
                        <span class="bottom">{{detailsWithdrawDeposit?.type}}</span>
                    </span>
                </div>
                <div class="left">
                    <span class="symbol">
                        <img src="../../../../../../assets/images/calender-deposit-report.svg" alt="deposit-icon">
                    </span>
                    <span class="text">
                        <span class="top">{{'Date'|translate}}</span>
                        <span class="bottom"> {{dateFormate(detailsWithdrawDeposit.createdAt)}}</span>
                    </span>
                </div>
                <div class="left">
                    <span class="symbol-1">
                        <span>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.4942 8.23618L7.54222 6.77219V3.79003C7.54222 3.49018 7.29984 3.2478 6.99999 3.2478C6.70014 3.2478 6.45776 3.49018 6.45776 3.79003V7.04333C6.45776 7.21412 6.53802 7.37518 6.67465 7.47711L8.84351 9.10377C8.9411 9.17697 9.05498 9.21221 9.16829 9.21221C9.33367 9.21221 9.49634 9.13792 9.60262 8.99478C9.78268 8.75563 9.73387 8.41566 9.4942 8.23618Z"
                                    fill="url(#paint0_linear_500_23105)" />
                                <path
                                    d="M7 0C3.13996 0 0 3.13996 0 7C0 10.86 3.13996 14 7 14C10.86 14 14 10.86 14 7C14 3.13996 10.86 0 7 0ZM7 12.9156C3.73857 12.9156 1.08443 10.2614 1.08443 7C1.08443 3.73857 3.73857 1.08443 7 1.08443C10.262 1.08443 12.9156 3.73857 12.9156 7C12.9156 10.2614 10.2614 12.9156 7 12.9156Z"
                                    fill="url(#paint1_linear_500_23105)" />
                                <defs>
                                    <linearGradient id="paint0_linear_500_23105" x1="6.45776" y1="3.7358" x2="10.0971"
                                        y2="4.00544" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#36E2FA" />
                                        <stop offset="1" stop-color="#EBFFBF" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_500_23105" x1="-1.68393e-07" y1="1.14546"
                                        x2="15.4607" y2="3.24542" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#36E2FA" />
                                        <stop offset="1" stop-color="#EBFFBF" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </span>
                    </span>
                    <span class="text">
                        <span class="top">{{'Time'|translate}}</span>
                        <span class="bottom">{{timeFormate(detailsWithdrawDeposit.createdAt)}}</span>
                    </span>
                </div>
            </div>
            <div class="box1-bottom">
                <div class="box1-bottom-left">
                    <span class="bottom-left">
                        {{'Amount'|translate}}
                    </span>
                    <span class="bottom-right">
                        ₹ {{detailsWithdrawDeposit.amount}}
                    </span>
                </div>
                <div class="box1-bottom-right"
                    [ngClass]="{'box1-bottom-right-approved':detailsWithdrawDeposit.status==='Approved'||detailsWithdrawDeposit.status==='Completed' || detailsWithdrawDeposit.status==='Complete', 'box1-bottom-right-pending':detailsWithdrawDeposit.status==='Pending', 'box1-bottom-right-cancelled':detailsWithdrawDeposit?.status==='Cancelled'}">
                    <span class="text">
                        {{'Status'|translate}}
                    </span>
                    <span class="status">
                        <svg *ngIf="detailsWithdrawDeposit.status=='Initiated'" width="10" height="10"
                            viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_516_23126)">
                                <path
                                    d="M6.78156 5.88295L5.38728 4.83725V2.70713C5.38728 2.49295 5.21416 2.31982 4.99998 2.31982C4.7858 2.31982 4.61267 2.49295 4.61267 2.70713V5.03092C4.61267 5.15291 4.66999 5.26795 4.76759 5.34076L6.31677 6.50266C6.38648 6.55494 6.46783 6.58012 6.54876 6.58012C6.66689 6.58012 6.78308 6.52705 6.859 6.4248C6.98761 6.25398 6.95275 6.01115 6.78156 5.88295Z"
                                    fill="#00B2FF" />
                                <path
                                    d="M5 0C2.24283 0 0 2.24283 0 5C0 7.75717 2.24283 10 5 10C7.75717 10 10 7.75717 10 5C10 2.24283 7.75717 0 5 0ZM5 9.22541C2.67041 9.22541 0.77459 7.32959 0.77459 5C0.77459 2.67041 2.67041 0.77459 5 0.77459C7.32998 0.77459 9.22541 2.67041 9.22541 5C9.22541 7.32959 7.32959 9.22541 5 9.22541Z"
                                    fill="#00B2FF" />
                            </g>
                            <defs>
                                <clipPath id="clip0_516_23126">
                                    <rect width="10" height="10" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <svg *ngIf="detailsWithdrawDeposit.status=='Pending'" width="10" height="10" viewBox="0 0 10 10"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_516_23126)">
                                <path
                                    d="M6.78156 5.88295L5.38728 4.83725V2.70713C5.38728 2.49295 5.21416 2.31982 4.99998 2.31982C4.7858 2.31982 4.61267 2.49295 4.61267 2.70713V5.03092C4.61267 5.15291 4.66999 5.26795 4.76759 5.34076L6.31677 6.50266C6.38648 6.55494 6.46783 6.58012 6.54876 6.58012C6.66689 6.58012 6.78308 6.52705 6.859 6.4248C6.98761 6.25398 6.95275 6.01115 6.78156 5.88295Z"
                                    fill="#F5A524" />
                                <path
                                    d="M5 0C2.24283 0 0 2.24283 0 5C0 7.75717 2.24283 10 5 10C7.75717 10 10 7.75717 10 5C10 2.24283 7.75717 0 5 0ZM5 9.22541C2.67041 9.22541 0.77459 7.32959 0.77459 5C0.77459 2.67041 2.67041 0.77459 5 0.77459C7.32998 0.77459 9.22541 2.67041 9.22541 5C9.22541 7.32959 7.32959 9.22541 5 9.22541Z"
                                    fill="#F5A524" />
                            </g>
                            <defs>
                                <clipPath id="clip0_516_23126">
                                    <rect width="10" height="10" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <svg *ngIf="detailsWithdrawDeposit.status=='Approved'||detailsWithdrawDeposit.status=='Completed' || detailsWithdrawDeposit.status=='Complete'"
                            xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10ZM0.78125 5C0.78125 7.32995 2.67005 9.21875 5 9.21875C7.32995 9.21875 9.21875 7.32995 9.21875 5C9.21875 2.67005 7.32995 0.78125 5 0.78125C2.67005 0.78125 0.78125 2.67005 0.78125 5ZM4.58968 6.58651C4.43713 6.73906 4.1898 6.73906 4.03725 6.58651L2.78973 5.33901C2.63718 5.18647 2.63718 4.93914 2.78973 4.78659C2.94228 4.63404 3.1896 4.63403 3.34215 4.78658L4.31346 5.75787L6.65784 3.4135C6.81038 3.26095 7.05771 3.26095 7.21026 3.4135C7.36281 3.56605 7.36281 3.81338 7.21026 3.96592L4.58968 6.58651Z"
                                fill="#17C964" />
                        </svg>
                        <img src="../../../../../../assets/images/cancel-deposit-icon.svg"
                            *ngIf="detailsWithdrawDeposit.status=='Cancelled'" alt="">
                        <span
                            [ngClass]="{'status-pending':detailsWithdrawDeposit.status=='Pending','status-approved':detailsWithdrawDeposit.status=='Approved' || detailsWithdrawDeposit.status=='Completed', 'status-cancelled':detailsWithdrawDeposit.status=='Cancelled' }"
                            *ngIf="detailsWithdrawDeposit.status!=='Complete'">
                            {{detailsWithdrawDeposit.status}}
                        </span>
                        <span
                            [ngClass]="{'status-pending':detailsWithdrawDeposit.status=='Pending','status-approved':detailsWithdrawDeposit.status=='Approved' || detailsWithdrawDeposit.status=='Completed'|| detailsWithdrawDeposit.status=='Complete', 'status-cancelled':detailsWithdrawDeposit.status=='Cancelled' }"
                            *ngIf="detailsWithdrawDeposit.status=='Complete'">
                            {{'Completed'|translate}}
                        </span>
                    </span>
                </div>

            </div>
        </div>
        <div class="box2">
            <div class="info">
                <span class="left">{{'UTR Number'|translate}}</span>
                <span class="right">{{detailsWithdrawDeposit.utr || '-'}}</span>
            </div>
            <div class="info">
                <span class="left">{{'Payment Type'|translate}}</span>
                <span class="right">{{detailsWithdrawDeposit.gateway.name || '-'}}</span>
            </div>
            <div class="info">
                <span class="left">{{'Account Number/UPI ID'|translate}}</span>
                <span class="right">{{detailsWithdrawDeposit?.gateway?.acNo || '-'}}</span>
            </div>
            <div class="info">
                <span class="left">{{'Account Name'|translate}}</span>
                <span class="right">-</span>
            </div>
            <div class="info">
                <span class="left">{{'Remark/Reason'|translate}}</span>
                <span class="right">{{detailsWithdrawDeposit?.remarks || '-'}}</span>
            </div>
        </div>
        <div class="btns" *ngIf="detailsWithdrawDeposit?.status !=='Initiated'">
            <button class="cancel-btn"
                *ngIf="detailsWithdrawDeposit.status !== 'Approved' && detailsWithdrawDeposit.status !== 'Cancelled' && detailsWithdrawDeposit.status !== 'Complete'"
                (click)="cancelDepositFun()"><span>{{'Cancel Request'|translate}}</span></button>
            <button class="edit-btn"
                *ngIf="detailsWithdrawDeposit.status !== 'Approved' && detailsWithdrawDeposit.status !== 'Cancelled' && detailsWithdrawDeposit.status !== 'Complete'"
                (click)="editDepositFun()"><span>{{'Edit Request'|translate}}</span></button>
        </div>
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
    </div>
</div>

<div class="deposit-details-card" *ngIf="detailsWithdrawDeposit?.type==='Withdrawal'">
    <div class="card">
        <div class="card-head">
            <span class="card-head-left">{{'Request IDs'|translate}}</span>
            <span class="card-head-right">{{detailsWithdrawDeposit.requestId}}</span>
        </div>
        <div class="box1">
            <div class="box1-top">
                <div class="left">
                    <span class="symbol">
                        <img src="../../../../../../assets/images/deposit-icon-deposit-report.svg" alt="deposit-icon">
                    </span>
                    <span class="text">
                        <span class="top">{{'Type'|translate}}</span>
                        <span class="bottom">{{detailsWithdrawDeposit?.type}}</span>
                    </span>
                </div>
                <div class="left">
                    <span class="symbol">
                        <img src="../../../../../../assets/images/calender-deposit-report.svg" alt="deposit-icon">
                    </span>
                    <span class="text">
                        <span class="top">{{'Date'|translate}}</span>
                        <span class="bottom"> {{dateFormate(detailsWithdrawDeposit.createdAt)}}</span>
                    </span>
                </div>
                <div class="left">
                    <span class="symbol-1">
                        <span>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.4942 8.23618L7.54222 6.77219V3.79003C7.54222 3.49018 7.29984 3.2478 6.99999 3.2478C6.70014 3.2478 6.45776 3.49018 6.45776 3.79003V7.04333C6.45776 7.21412 6.53802 7.37518 6.67465 7.47711L8.84351 9.10377C8.9411 9.17697 9.05498 9.21221 9.16829 9.21221C9.33367 9.21221 9.49634 9.13792 9.60262 8.99478C9.78268 8.75563 9.73387 8.41566 9.4942 8.23618Z"
                                    fill="url(#paint0_linear_500_23105)" />
                                <path
                                    d="M7 0C3.13996 0 0 3.13996 0 7C0 10.86 3.13996 14 7 14C10.86 14 14 10.86 14 7C14 3.13996 10.86 0 7 0ZM7 12.9156C3.73857 12.9156 1.08443 10.2614 1.08443 7C1.08443 3.73857 3.73857 1.08443 7 1.08443C10.262 1.08443 12.9156 3.73857 12.9156 7C12.9156 10.2614 10.2614 12.9156 7 12.9156Z"
                                    fill="url(#paint1_linear_500_23105)" />
                                <defs>
                                    <linearGradient id="paint0_linear_500_23105" x1="6.45776" y1="3.7358" x2="10.0971"
                                        y2="4.00544" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#36E2FA" />
                                        <stop offset="1" stop-color="#EBFFBF" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_500_23105" x1="-1.68393e-07" y1="1.14546"
                                        x2="15.4607" y2="3.24542" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#36E2FA" />
                                        <stop offset="1" stop-color="#EBFFBF" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </span>
                    </span>
                    <span class="text">
                        <span class="top">{{'Time'|translate}}</span>
                        <span class="bottom">{{timeFormate(detailsWithdrawDeposit.createdAt)}}</span>
                    </span>
                </div>
            </div>
            <div class="box1-bottom">
                <div class="box1-bottom-left">
                    <span class="bottom-left">
                        {{'Amount'|translate}}
                    </span>
                    <span class="bottom-right">
                        ₹ {{detailsWithdrawDeposit.amount}}
                    </span>
                </div>
                <div class="box1-bottom-right"
                    [ngClass]="{'box1-bottom-right-approved':detailsWithdrawDeposit.status==='Approved'||detailsWithdrawDeposit.status==='Completed', 'box1-bottom-right-pending':detailsWithdrawDeposit.status==='Pending', 'box1-bottom-right-cancelled':detailsWithdrawDeposit?.status==='Cancelled'}">
                    <span class="text">
                        {{'Status'|translate}}
                    </span>
                    <span class="status">
                        <svg *ngIf="detailsWithdrawDeposit.status=='Initiated'" width="10" height="10"
                            viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_516_23126)">
                                <path
                                    d="M6.78156 5.88295L5.38728 4.83725V2.70713C5.38728 2.49295 5.21416 2.31982 4.99998 2.31982C4.7858 2.31982 4.61267 2.49295 4.61267 2.70713V5.03092C4.61267 5.15291 4.66999 5.26795 4.76759 5.34076L6.31677 6.50266C6.38648 6.55494 6.46783 6.58012 6.54876 6.58012C6.66689 6.58012 6.78308 6.52705 6.859 6.4248C6.98761 6.25398 6.95275 6.01115 6.78156 5.88295Z"
                                    fill="#00B2FF" />
                                <path
                                    d="M5 0C2.24283 0 0 2.24283 0 5C0 7.75717 2.24283 10 5 10C7.75717 10 10 7.75717 10 5C10 2.24283 7.75717 0 5 0ZM5 9.22541C2.67041 9.22541 0.77459 7.32959 0.77459 5C0.77459 2.67041 2.67041 0.77459 5 0.77459C7.32998 0.77459 9.22541 2.67041 9.22541 5C9.22541 7.32959 7.32959 9.22541 5 9.22541Z"
                                    fill="#00B2FF" />
                            </g>
                            <defs>
                                <clipPath id="clip0_516_23126">
                                    <rect width="10" height="10" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <svg *ngIf="detailsWithdrawDeposit.status=='Pending'" width="10" height="10" viewBox="0 0 10 10"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_516_23126)">
                                <path
                                    d="M6.78156 5.88295L5.38728 4.83725V2.70713C5.38728 2.49295 5.21416 2.31982 4.99998 2.31982C4.7858 2.31982 4.61267 2.49295 4.61267 2.70713V5.03092C4.61267 5.15291 4.66999 5.26795 4.76759 5.34076L6.31677 6.50266C6.38648 6.55494 6.46783 6.58012 6.54876 6.58012C6.66689 6.58012 6.78308 6.52705 6.859 6.4248C6.98761 6.25398 6.95275 6.01115 6.78156 5.88295Z"
                                    fill="#F5A524" />
                                <path
                                    d="M5 0C2.24283 0 0 2.24283 0 5C0 7.75717 2.24283 10 5 10C7.75717 10 10 7.75717 10 5C10 2.24283 7.75717 0 5 0ZM5 9.22541C2.67041 9.22541 0.77459 7.32959 0.77459 5C0.77459 2.67041 2.67041 0.77459 5 0.77459C7.32998 0.77459 9.22541 2.67041 9.22541 5C9.22541 7.32959 7.32959 9.22541 5 9.22541Z"
                                    fill="#F5A524" />
                            </g>
                            <defs>
                                <clipPath id="clip0_516_23126">
                                    <rect width="10" height="10" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <svg *ngIf="detailsWithdrawDeposit.status=='Approved'||detailsWithdrawDeposit.status=='Completed' || detailsWithdrawDeposit.status=='Complete'"
                            xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10ZM0.78125 5C0.78125 7.32995 2.67005 9.21875 5 9.21875C7.32995 9.21875 9.21875 7.32995 9.21875 5C9.21875 2.67005 7.32995 0.78125 5 0.78125C2.67005 0.78125 0.78125 2.67005 0.78125 5ZM4.58968 6.58651C4.43713 6.73906 4.1898 6.73906 4.03725 6.58651L2.78973 5.33901C2.63718 5.18647 2.63718 4.93914 2.78973 4.78659C2.94228 4.63404 3.1896 4.63403 3.34215 4.78658L4.31346 5.75787L6.65784 3.4135C6.81038 3.26095 7.05771 3.26095 7.21026 3.4135C7.36281 3.56605 7.36281 3.81338 7.21026 3.96592L4.58968 6.58651Z"
                                fill="#17C964" />
                        </svg>
                        <img src="../../../../../../assets/images/cancel-deposit-icon.svg"
                            *ngIf="detailsWithdrawDeposit.status=='Cancelled'" alt="">
                        <span
                            [ngClass]="{'status-pending':detailsWithdrawDeposit.status=='Pending','status-approved':detailsWithdrawDeposit.status=='Approved' || detailsWithdrawDeposit.status=='Completed', 'status-cancelled':detailsWithdrawDeposit.status=='Cancelled' }"
                            *ngIf="detailsWithdrawDeposit.status!=='Complete'">
                            {{detailsWithdrawDeposit.status}}
                        </span>
                        <span
                            [ngClass]="{'status-pending':detailsWithdrawDeposit.status=='Pending','status-approved':detailsWithdrawDeposit.status=='Approved' || detailsWithdrawDeposit.status=='Completed'|| detailsWithdrawDeposit.status=='Complete', 'status-cancelled':detailsWithdrawDeposit.status=='Cancelled' }"
                            *ngIf="detailsWithdrawDeposit.status=='Complete'">
                            {{'Completed'|translate}}
                        </span>
                    </span>
                </div>

            </div>
        </div>
        <div class="box2">
            <div class="info">
                <span class="left">{{'Account No/UPI ID'|translate}}</span>
                <span class="right">{{detailsWithdrawDeposit?.account?.accountNo}}</span>
            </div>
            <div class="info">
                <span class="left">{{'IFSC'|translate}}</span>
                <span class="right">{{detailsWithdrawDeposit?.account?.IFSC |uppercase}}</span>
            </div>
            <!-- <div class="info">
                <span class="left">Payment Type</span>
                <span class="right">-</span>
            </div> -->
            <div class="info">
                <span class="left">{{'Account Name'|translate}}</span>
                <span class="right">{{detailsWithdrawDeposit?.account?.accountHolderName}}</span>
            </div>
            <div class="info">
                <span class="left">{{'Bank Name'|translate}}</span>
                <span class="right">{{detailsWithdrawDeposit?.account?.bankName}}</span>
            </div>
        </div>
        <div class="btns">
            <button class="cancel-btn"
                *ngIf="detailsWithdrawDeposit.status !== 'Approved' && detailsWithdrawDeposit.status !== 'Cancelled' && detailsWithdrawDeposit.status !== 'Completed'"
                (click)="cancelDepositFun()"><span>{{'Cancel Request'|translate}}</span></button>
        </div>
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
    </div>
</div>

<div class="Modal-background card-cancel max-width-550">
    <app-deposit-withdraw-report-popup *ngIf="cancelDeposit && detailsWithdrawDeposit?.type==='Deposit'"
        title="Cancel Deposit" (closeClick)="cancelDeposit=false">
        <form [formGroup]="cancelDepositForm" style="width: 100%;">
            <textarea formControlName="reason" placeholder="Please Enter Reason For Cancelling"></textarea>
            <button class="submit-btn" [disabled]="cancelDepositForm.invalid"
                (click)="submitReasonForCancel(detailsWithdrawDeposit.requestId)"><span>{{'Submit Changes'|translate}}</span></button>
        </form>
    </app-deposit-withdraw-report-popup>
</div>

<div class="Modal-background card-cancel max-width-550">
    <app-deposit-withdraw-report-popup *ngIf="cancelDeposit && detailsWithdrawDeposit?.type==='Withdrawal'"
        title="Cancel Withdraw" (closeClick)="cancelDeposit=false">
        <form [formGroup]="cancelWithdrawlForm" style="width: 100%;">
            <textarea formControlName="reason" placeholder="Please Enter Reason For Cancelling"></textarea>
            <button class="submit-btn" [disabled]="cancelWithdrawlForm.invalid"
                (click)="submitReasonForCancelWithdraw(detailsWithdrawDeposit.requestId)"><span>{{'Submit Changes'|translate}}</span></button>
        </form>
    </app-deposit-withdraw-report-popup>
</div>

<div class="Modal-background card-cancel max-width-550" *ngIf="editDeposit">
    <app-deposit-withdraw-report-popup title="Edit Deposit Details" (closeClick)="editDeposit=false">
        <form [formGroup]="editDepositForm" style="width: 100%;">
            <div class="ref-no">
                <span class="text">{{'Reference Number'|translate}}</span>
                <span class="amount">{{detailsWithdrawDeposit.requestId}}</span>
            </div>
            <div class="utr-no">
                <span>{{'UTR NO'|translate}}</span>
                <input type="text" formControlName="UTR" [minLength]="6" [maxLength]="22">
            </div>
            <span class="errormessage"
                *ngIf="editDepositForm.get('UTR').value.length===0 && editDepositForm.get('UTR').dirty">{{"* UTR No can't be empty."|translate}}</span>
            <span class="errormessage"
                *ngIf="editDepositForm.get('UTR').value.length < 6 && editDepositForm.get('UTR').value.length >0 && editDepositForm.get('UTR').dirty">*
                {{'UTR No length must be at least 6 characters.'|translate}}</span>
            <span class="errormessage"
                *ngIf="editDepositForm.get('UTR').value.length > 22 && editDepositForm.get('UTR').dirty">{{'* UTR No length must be less than 22 characters.'|translate}}</span>
            <div class="utr-no">
                <span>{{'Amount'|translate}}</span>
                <input type="number" (keyup)="preventDecimalInput($event)"  (input)="preventDecimalInput($event)" formControlName="amount" inputmode="numeric">
            </div>
            <span class="errormessage" *ngIf="editDepositForm.get('amount').hasError('max')">{{'* The amount must be less than 1 crore.'|translate}}</span>
            <span class="errormessage" *ngIf="editDepositForm.get('amount').hasError('required') && editDepositForm.get('amount').dirty">{{'* The amount must not be empty.'|translate}}</span>
            <span class="errormessage" *ngIf="editDepositForm.get('amount').hasError('min')">{{'* The minimum amount must be 100.'|translate}}</span>
            <button class="submit-btn" (click)="submitEditedForm(detailsWithdrawDeposit.requestId)"
                [disabled]="editDepositForm.get('UTR').invalid || editDepositForm.get('amount').invalid || editDepositForm.pristine"><span>{{'Submit Changes'|translate}}</span></button>
        </form>
    </app-deposit-withdraw-report-popup>
</div>

<div class="blur-backdrop" *ngIf="cancelDeposit || editDeposit"></div>
