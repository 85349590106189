<div class="overlay" *ngIf="isLoader">
    <div class="loader-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <!-- <img loading="lazy" class="loader1" src="assets/images/loader-gif.gif" alt="Your Image"> -->
        <ng-lottie [options]="lottieOptions" width="75px" height="75px"></ng-lottie>

    </div>
</div>
<div *ngIf="!urlIframe" class="layout-content-holder-bc">

    <div class="center-bottom-btn" *ngIf="isScroll"><button class="btn nav-floating-btn bc-i-double-arrow-top "
            id="button" type="button" (click)="scrollToTop()"></button>
    </div>

    <div class="casino-search">
        <div class="casino-container" *ngIf="!expandSearch">
            <div class="first-box openPopup"
                [ngClass]="{'active-box':activeTab==='Live Casino', 'closePopup':expandSearchBoxAnimate}">
                <span class="white-large-text" (click)="setActiveTab('Live Casino')">Live Casino</span>
            </div>
            <div class="first-box openPopup"
                [ngClass]="{'active-box':activeTab==='Slot', 'closePopup':expandSearchBoxAnimate}">
                <span class="white-large-text" (click)="setActiveTab('Slot')">Slot</span>
            </div>
        </div>
        <svg *ngIf="expandSearch" (click)="contractSearchBox()" class="openPopup"
            [ngClass]="{'closePopup':expandSearchBoxAnimate}" xmlns="http://www.w3.org/2000/svg" width="10" height="18"
            viewBox="0 0 10 18" fill="none">
            <path d="M8.77193 18L0 9L8.77193 0L10 1.2825L2.47807 9L10 16.7175L8.77193 18Z" fill="#D71082" />
        </svg>
        <div class="search-box" [ngClass]="{'expandSearch':expandSearch, 'contractSearchBox1':contractInput}"
            (click)="expandSearchBox()">
            <input type="text" [value]="searchValue" (input)="handleSearch($event)" placeholder="Search...">
            <img src="/assets/images/search-icon.svg" alt="search">
        </div>
    </div>

    <ngx-slick-carousel *ngIf="!expandSearch" class="slick-sliderc carousel slide hm-row-bc has-slider"
        [ngClass]="{'closePopup':expandSearchBoxAnimate}" #slickModal="slick-carousel" [config]="sliderlConfig">
        <div class="sdr-item-holder-bc" *ngFor="let SliderImg of filteredArray" ngxSlickItem>
            <!-- <img src="../../../assets/images/casino-banner.png" alt=""> -->
            <img loading="lazy" src="{{SliderImg?.image}}" class="img" alt="...">
        </div>
    </ngx-slick-carousel>

    <!-- <div class="special-games" *ngIf="activeTab == 'Live Casino'">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20" fill="none">
            <path
                d="M13.875 12.1875C13.875 16.875 10.797 19.0625 7 19.0625C3.20305 19.0625 0.125 16.5625 0.125 12.1875C0.125 12.1875 0.125 9.6875 2 8.125C2 8.125 2 10.625 3.25 11.5625C3.25 11.5625 2.625 4.0625 9.8125 0.9375C9.8125 0.9375 7.3125 8.125 10.75 10.3125C10.75 10.3125 11.375 7.5 11.375 5.3125C11.375 5.3125 13.875 5.9375 13.875 12.1875Z"
                fill="#EB423F" />
            <path
                d="M10.75 15.3125C10.75 17.8987 9.13395 19.0625 7.03906 19.0625C4.94418 19.0625 3.25 17.7263 3.25 15.3125C3.25 15.3125 3.25 14.0625 4.1875 13.125C4.1875 13.125 4.12285 14.4828 4.8125 15C4.8125 15 5.125 11.25 7.9375 9.6875C7.9375 9.6875 7.29094 12.8556 9.1875 14.0625C9.1875 14.0625 9.5 12.7694 9.5 11.5625C9.5 11.5625 10.75 11.8642 10.75 15.3125Z"
                fill="#FFD33A" />
            <path d="M0.125 7.8125C0.125 7.8125 0.125 5.3125 2 4.6875C2 4.6875 1.6875 7.8125 0.125 7.8125Z"
                fill="#EB423F" />
            <path
                d="M13.5625 4.6875C13.5625 4.6875 13.5625 2.1875 11.6875 1.5625C11.6875 1.5625 12 4.6875 13.5625 4.6875Z"
                fill="#EB423F" />
        </svg>
        <span class="xtra-lg white">Special Games</span>
        </div> -->

    <!-- <div class="special-carousal" *ngIf="activeTab == 'Live Casino'">
        <ng-container *ngFor="let item of specialGamesArray">
            <img (click)="goToGame1(item?.ID, item)" class="casino-img"
                [src]="'https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/jeetoindia1' + item.ImageURL" alt="{{item?.Trans?.en}}" />
        </ng-container>
    </div> -->


    <div class="casino-topbar-1" [ngClass]="{'closePopup':expandSearchBoxAnimate}" *ngIf="!expandSearch">
        <ng-container *ngFor="let item of gameCategoryAndProvideid; let index=index">
            <div class="casino-btn-1" [id]="item?.name" (click)="slotgames(item?.id, index, item?.name)">
                <span class="gray-tw-text" [ngClass]="{'white':activeCasinoGamesArray[index]}">{{item?.name |
                    translate}}</span>
                <span *ngIf="activeCasinoGamesArray[index]" class="line-active"></span>
            </div>
        </ng-container>
    </div>
    <div class="casino-category-types casino-category-types-full" id="casino_games_container">
        <div class="infinite-scroll-component__outerdiv">
            <div class="infinite-scroll-component casino-category-games" style="height: auto; overflow: auto;">

                <div class="casino-game-item-content" *ngFor="let games of gameCategory; index as i">
                    <div class="casino-game-item" (click)="goToGame(games)" data-badge="">
                        <img loading="lazy" [src]="games?.image" class="casino-game-item-image-bc" alt="{{games?.name}}"
                            title="{{games?.name}}">
                    </div>

                </div>
            </div>
        </div>
    </div>


    <div [ngClass]="{'display-none':showhidecasinopopup}">
        <div>
            <div class="betslip-bc Full  full" style="transform: translateY(0px); opacity: 1;">
                <div id="betslip_container">
                    <div class="betslip-full-content-bc">
                        <div class="hdr-main-content-bc">
                            <div class="logo-container"><a class="logo" (click)="showcasino()"><img loading="lazy"
                                        class="hdr-logo-bc" src="../../../assets/images/img/11exch-logo-new.png"
                                        alt=""></a></div><i (click)="showcasino()"
                                class="hdr-user-close bc-i-close-remove"></i>
                        </div>

                        <div class="second-tabs-bc">
                            <div class="tab-bc selected-underline betslip active" title="BetSlip"><span>Transfer Fund
                                    for
                                    {{jiliId}}</span>
                            </div>
                        </div>
                        <form [formGroup]="form" (ngSubmit)="depositJili()">
                            <div class="bs-f-body-bc">
                                <div class="bs-scroll-container-bc casino-popup" data-scroll-lock-scrollable="">

                                    <p>To continue the game, TOP up your balance</p>

                                    <h3>Deposit=15sec, Withdrawl=2min After exit</h3>

                                    <div class="casino-popup-heighlighter">
                                        <h3>Balance : {{balanceamounts?.availBalance}}</h3>
                                        <div class="u-i-p-control-item-holder-bc">
                                            <div class="form-control-bc default "><label
                                                    class="form-control-label-bc inputs"><input type="number"
                                                        class="form-control-input-bc" formControlName="amount"
                                                        placeholder="balance" step="0" value=""><i
                                                        class="form-control-input-stroke-bc"></i></label></div>
                                        </div>
                                    </div>
                                    <div class="bs-f-footer ">
                                        <div class="bet-slip-switcher-wrapper-bc"></div>

                                        <div class="bet-button-wrapper-bc content-type-0"><button
                                                class="btn a-color deposit " type="submit" title="Bet Now"><span>Accept
                                                    and
                                                    Launch</span></button></div>
                                    </div>

                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="games-new-iframe"
    [ngClass]="{'userSafariPWA': isSafariPWA, 'userIphoneSafari': isIphoneSafari, 'userIphoneChrome': isIphoneChrome, 'hideIframe': !urlIframe}">
    <!-- <div *ngIf="urlIframe && !isMobile" class="iframe-title-games max-width-550">
        {{title}}
        <svg (click)="clearIframe()" width="31" height="31" viewBox="0 0 31 31" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.2045 10.0005C10.9659 10.0008 10.7328 10.0722 10.5349 10.2057C10.3371 10.3391 10.1835 10.5285 10.0938 10.7496C10.0042 10.9707 9.98244 11.2136 10.0314 11.4471C10.0804 11.6806 10.1979 11.8943 10.3689 12.0607L13.7205 15.4122L10.3689 18.7638C10.2538 18.8744 10.1618 19.0068 10.0985 19.1534C10.0351 19.2999 10.0017 19.4576 10.0001 19.6173C9.99844 19.7769 10.0287 19.9353 10.089 20.0831C10.1494 20.2309 10.2386 20.3652 10.3515 20.4781C10.4644 20.591 10.5987 20.6802 10.7465 20.7406C10.8943 20.8009 11.0527 20.8312 11.2123 20.8296C11.372 20.8279 11.5297 20.7945 11.6763 20.7311C11.8228 20.6678 11.9552 20.5758 12.0658 20.4607L15.4174 17.1091L18.7689 20.4607C18.8795 20.5758 19.012 20.6678 19.1585 20.7311C19.3051 20.7945 19.4628 20.8279 19.6224 20.8296C19.7821 20.8312 19.9405 20.801 20.0883 20.7406C20.2361 20.6803 20.3704 20.591 20.4833 20.4781C20.5962 20.3652 20.6854 20.2309 20.7457 20.0831C20.8061 19.9353 20.8363 19.7769 20.8347 19.6173C20.8331 19.4577 20.7996 19.2999 20.7363 19.1534C20.6729 19.0068 20.581 18.8744 20.4658 18.7638L17.1143 15.4122L20.4658 12.0607C20.6392 11.8921 20.7577 11.6751 20.8056 11.4381C20.8536 11.2011 20.8288 10.9551 20.7345 10.7324C20.6403 10.5097 20.481 10.3206 20.2774 10.19C20.0739 10.0595 19.8357 9.99339 19.5939 10.0005C19.2822 10.0098 18.9863 10.1401 18.7689 10.3638L15.4174 13.7154L12.0658 10.3638C11.9539 10.2487 11.8199 10.1573 11.672 10.0949C11.524 10.0325 11.3651 10.0004 11.2045 10.0005V10.0005Z"
                fill="white" />
        </svg>
    </div> -->
    <div style="height: 100%;" #iframeLoad > </div>
</div>
