import { Component, OnInit } from '@angular/core';
import { BonusService } from '../bonus.service';
import { MyprofileService } from 'src/app/services/myprofile.service';

@Component({
  selector: 'app-claim-done-popup',
  templateUrl: './claim-done-popup.component.html',
  styleUrls: ['./claim-done-popup.component.scss']
})
export class ClaimDonePopupComponent implements OnInit {

  constructor(public _bonus: BonusService, private myprofileService:MyprofileService) { }

  ngOnInit(): void {
    this.myprofileService.isHeaderFixed = true;
  }

  claimDone(){
    this._bonus.showClaimBonus = false;
    this._bonus.getAllBonuses("all");
  }

  ngOnDestroy() {
    this.myprofileService.isHeaderFixed = false;
  }
}
