import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-center-popup',
  templateUrl: './center-popup.component.html',
  styleUrls: ['./center-popup.component.scss']
})
export class CenterPopupComponent implements OnInit {

  constructor() { }
  @Output() closeClick: EventEmitter<any> = new EventEmitter();
  @Input() title: string;
  ngOnInit(): void {
    this.closeClicked
  }

  closeClicked() {
    this.closeClick.emit(true);
  }

}
