import { Component, Input, OnInit } from '@angular/core';
import { AfterLoginPopup } from 'src/app/models/m-banner.interface';
import { LandingService } from 'src/app/services/landing.service';

@Component({
  selector: 'app-update-popup',
  templateUrl: './update-popup.component.html',
  styleUrls: ['./update-popup.component.scss'],
})
export class UpdatePopupComponent implements OnInit {
  show = true;

  @Input() landingBanner : AfterLoginPopup[]; // '/assets/images/images/update-new.webp';

  constructor(private _landing: LandingService) { }

  ngOnInit(): void {
  }



  closePopup() {
    this.show = false;
  }

  redirect(url: string) {
    window.open(url, '_blank');
  }

}
