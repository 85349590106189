<div class="layout-content-holder-bc">
    <div class="special-games-button"><button class="btn s-small upcoming-matches "><i
                class="bc-i-upcoming"></i><span>{{'Upcoming Matches'|translate}}</span></button><button
            class="btn s-small popular-matches "><i class="bc-i-tournament"></i><span>{{'Popular Matches'|translate}}</span></button><button class="btn s-small popular-competitions "><i
                class="bc-i-tournament"></i><span>{{'Popular competitions'|translate}}</span></button><button
            class="btn s-small boosted-bets "><i class="bc-i-boost"></i><span>{{'Boosted Odds'|translate}}</span></button><button
            class="btn s-small multiple-of-the-day "><i class="bc-i-FlashGames"></i><span>{{'Multiple of the day'|translate}}</span></button></div>
    <div class="filter-sports-bc">
        <div class="form-control-bc select s-small has-icon filled"><label class="form-control-label-bc inputs"><i
                    class="select-icon-bc bc-i-time"></i><select class="form-control-select-bc ellipsis">
                    <option value="-1">{{'All'|translate}}</option>
                    <option value="0">{{'Today'|translate}}</option>
                    <option value="180">{{'3 hours'|translate}}</option>
                    <option value="360">{{'6 hours'|translate}}</option>
                    <option value="720">{{'12 hours'|translate}}</option>
                    <option value="1440">{{'24 hours'|translate}}</option>
                    <option value="2880">{{'48 hours'|translate}}</option>
                    <option value="4320">{{'72 hours'|translate}}</option>
                </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i></label></div><button
            class="btn s-small a-outline  " type="button"><i class="filter-icon-bc bc-i-bet-cloud"></i></button><button
            class="btn s-small a-outline  " type="button"><i class="filter-icon-bc bc-i-bet-builder"></i></button>
        <div class="sport-search-bc ">
            <div class="ss-icon-holder-bc"><i class="ss-icon-bc bc-i-search"></i></div>
        </div>
    </div>
    <div class="prematch-page-bc">
        <div class="horizontal-sl-list-container" data-scroll-lock-scrollable="">
            <div class="horizontal-sl-list pp-sport-type-holder-bc ext-2">
                <div class="horizontal-sl-item-bc Cricket active" data-id="Cricket" title="Cricket"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Cricket"></i>
                    <p class="horizontal-sl-title-bc">{{'Cricket'|translate}}</p><span class="horizontal-sl-count-bc">25</span>
                </div>
                <div class="horizontal-sl-item-bc Soccer " data-id="Soccer" title="Football"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Soccer"></i>
                    <p class="horizontal-sl-title-bc">{{'Football'|translate}}</p><span class="horizontal-sl-count-bc">1027</span>
                </div>
                <div class="horizontal-sl-item-bc Tennis " data-id="Tennis" title="Tennis"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Tennis"></i>
                    <p class="horizontal-sl-title-bc">{{'Tennis'|translate}}</p><span class="horizontal-sl-count-bc">255</span>
                </div>
                <div class="horizontal-sl-item-bc Basketball " data-id="Basketball" title="Basketball"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Basketball"></i>
                    <p class="horizontal-sl-title-bc">{{'Basketball'|translate}}</p><span class="horizontal-sl-count-bc">219</span>
                </div>
                <div class="horizontal-sl-item-bc TableTennis " data-id="TableTennis" title="Table Tennis"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-TableTennis"></i>
                    <p class="horizontal-sl-title-bc">{{'Table Tennis'|translate}}</p><span class="horizontal-sl-count-bc">249</span>
                </div>
                <div class="horizontal-sl-item-bc Volleyball " data-id="Volleyball" title="Volleyball"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Volleyball"></i>
                    <p class="horizontal-sl-title-bc">{{'Volleyball'|translate}}</p><span class="horizontal-sl-count-bc">87</span>
                </div>
                <div class="horizontal-sl-item-bc Baseball " data-id="Baseball" title="Baseball"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Baseball"></i>
                    <p class="horizontal-sl-title-bc">{{'Baseball'|translate}}</p><span class="horizontal-sl-count-bc">18</span>
                </div>
                <div class="horizontal-sl-item-bc AmericanFootball " data-id="AmericanFootball"
                    title="American Football"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-AmericanFootball"></i>
                    <p class="horizontal-sl-title-bc">{{'American Football'|translate}}</p><span
                        class="horizontal-sl-count-bc">11</span>
                </div>
                <div class="horizontal-sl-item-bc AustralianFootball " data-id="AustralianFootball"
                    title="Aussie Rules"><i class="horizontal-sl-icon-bc bc-i-default-icon bc-i-AustralianFootball"></i>
                    <p class="horizontal-sl-title-bc">{{'Aussie Rules'|translate}}</p><span class="horizontal-sl-count-bc">14</span>
                </div>
                <div class="horizontal-sl-item-bc AutoRacing " data-id="AutoRacing" title="Auto Racing"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-AutoRacing"></i>
                    <p class="horizontal-sl-title-bc">{{'Auto Racing'|translate}}</p><span class="horizontal-sl-count-bc">5</span>
                </div>
                <div class="horizontal-sl-item-bc Badminton " data-id="Badminton" title="Badminton"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Badminton"></i>
                    <p class="horizontal-sl-title-bc">{{'Badminton'|translate}}</p><span class="horizontal-sl-count-bc">28</span>
                </div>
                <div class="horizontal-sl-item-bc Boxing " data-id="Boxing" title="Boxing"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Boxing"></i>
                    <p class="horizontal-sl-title-bc">{{'Boxing'|translate}}</p><span class="horizontal-sl-count-bc">24</span>
                </div>
                <div class="horizontal-sl-item-bc Chess " data-id="Chess" title="Chess"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Chess"></i>
                    <p class="horizontal-sl-title-bc">{{'Chess'|translate}}</p><span class="horizontal-sl-count-bc">2</span>
                </div>
                <div class="horizontal-sl-item-bc Cycling " data-id="Cycling" title="Cycling"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Cycling"></i>
                    <p class="horizontal-sl-title-bc">{{'Cycling'|translate}}</p><span class="horizontal-sl-count-bc">4</span>
                </div>
                <div class="horizontal-sl-item-bc Darts " data-id="Darts" title="Darts"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Darts"></i>
                    <p class="horizontal-sl-title-bc">{{'Darts'|translate}}</p><span class="horizontal-sl-count-bc">8</span>
                </div>
                <div class="horizontal-sl-item-bc Formula1 " data-id="Formula1" title="Formula 1"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Formula1"></i>
                    <p class="horizontal-sl-title-bc">{{'Formula 1'|translate}}</p><span class="horizontal-sl-count-bc">3</span>
                </div>
                <div class="horizontal-sl-item-bc Futsal " data-id="Futsal" title="Futsal"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Futsal"></i>
                    <p class="horizontal-sl-title-bc">{{'Futsal'|translate}}</p><span class="horizontal-sl-count-bc">3</span>
                </div>
                <div class="horizontal-sl-item-bc Hockey " data-id="Hockey" title="Field hockey"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Hockey"></i>
                    <p class="horizontal-sl-title-bc">{{'Field hockey'|translate}}</p><span class="horizontal-sl-count-bc">1</span>
                </div>
                <div class="horizontal-sl-item-bc SpecialBets " data-id="SpecialBets" title="Special bets"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-SpecialBets"></i>
                    <p class="horizontal-sl-title-bc">{{'Special bets'|translate}}</p><span class="horizontal-sl-count-bc">1</span>
                </div>
                <div class="horizontal-sl-item-bc Floorball " data-id="Floorball" title="Floorball"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Floorball"></i>
                    <p class="horizontal-sl-title-bc">{{'Floorball'|translate}}</p><span class="horizontal-sl-count-bc">19</span>
                </div>
                <div class="horizontal-sl-item-bc FreestyleWrestling " data-id="FreestyleWrestling"
                    title="Freestyle Wrestling"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-FreestyleWrestling"></i>
                    <p class="horizontal-sl-title-bc">{{'Freestyle Wrestling'|translate}}</p><span
                        class="horizontal-sl-count-bc">5</span>
                </div>
                <div class="horizontal-sl-item-bc Golf " data-id="Golf" title="Golf"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Golf"></i>
                    <p class="horizontal-sl-title-bc">{{'Golf'|translate}}</p><span class="horizontal-sl-count-bc">86</span>
                </div>
                <div class="horizontal-sl-item-bc GaelicFootball " data-id="GaelicFootball" title="Gaelic football"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-GaelicFootball"></i>
                    <p class="horizontal-sl-title-bc">{{'Gaelic football'|translate}}</p><span class="horizontal-sl-count-bc">14</span>
                </div>
                <div class="horizontal-sl-item-bc Handball " data-id="Handball" title="Handball"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Handball"></i>
                    <p class="horizontal-sl-title-bc">{{'Handball'|translate}}</p><span class="horizontal-sl-count-bc">114</span>
                </div>
                <div class="horizontal-sl-item-bc IceHockey " data-id="IceHockey" title="Ice Hockey"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-IceHockey"></i>
                    <p class="horizontal-sl-title-bc">{{'Ice Hockey'|translate}}</p><span class="horizontal-sl-count-bc">113</span>
                </div>
                <div class="horizontal-sl-item-bc Hurling " data-id="Hurling" title="Hurling"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Hurling"></i>
                    <p class="horizontal-sl-title-bc">{{'Hurling'|translate}}</p><span class="horizontal-sl-count-bc">11</span>
                </div>
                <div class="horizontal-sl-item-bc Mma " data-id="Mma" title="MMA"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Mma"></i>
                    <p class="horizontal-sl-title-bc">{{'MMA'|translate}}</p><span class="horizontal-sl-count-bc">65</span>
                </div>
                <div class="horizontal-sl-item-bc Motorsport " data-id="Motorsport" title="Motorbikes"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Motorsport"></i>
                    <p class="horizontal-sl-title-bc">{{'Motorbikes'|translate}}</p><span class="horizontal-sl-count-bc">4</span>
                </div>
                <div class="horizontal-sl-item-bc Netball " data-id="Netball" title="Netball"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Netball"></i>
                    <p class="horizontal-sl-title-bc">{{'Netball'|translate}}</p><span class="horizontal-sl-count-bc">8</span>
                </div>
                <div class="horizontal-sl-item-bc Nascar " data-id="Nascar" title="NASCAR"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Nascar"></i>
                    <p class="horizontal-sl-title-bc">{{'NASCAR'|translate}}</p><span class="horizontal-sl-count-bc">1</span>
                </div>
                <div class="horizontal-sl-item-bc Politics " data-id="Politics" title="Politics"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Politics"></i>
                    <p class="horizontal-sl-title-bc">{{'Politics'|translate}}</p><span class="horizontal-sl-count-bc">3</span>
                </div>
                <div class="horizontal-sl-item-bc RugbyLeague " data-id="RugbyLeague" title="Rugby League"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-RugbyLeague"></i>
                    <p class="horizontal-sl-title-bc">{{'Rugby League'|translate}}</p><span class="horizontal-sl-count-bc">25</span>
                </div>
                <div class="horizontal-sl-item-bc RugbyUnion " data-id="RugbyUnion" title="Rugby Union"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-RugbyUnion"></i>
                    <p class="horizontal-sl-title-bc">{{'Rugby Union'|translate}}</p><span class="horizontal-sl-count-bc">21</span>
                </div>
                <div class="horizontal-sl-item-bc Yachting " data-id="Yachting" title="Sailing"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Yachting"></i>
                    <p class="horizontal-sl-title-bc">{{'Sailing'|translate}}</p><span class="horizontal-sl-count-bc">2</span>
                </div>
                <div class="horizontal-sl-item-bc Snooker " data-id="Snooker" title="Snooker"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Snooker"></i>
                    <p class="horizontal-sl-title-bc">{{'Snooker'|translate}}</p><span class="horizontal-sl-count-bc">64</span>
                </div>
                <div class="horizontal-sl-item-bc Speedway " data-id="Speedway" title="Speedway"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Speedway"></i>
                    <p class="horizontal-sl-title-bc">{{'Speedway'|translate}}</p><span class="horizontal-sl-count-bc">1</span>
                </div>
                <div class="horizontal-sl-item-bc SkiJumping " data-id="SkiJumping" title="Ski Jumping"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-SkiJumping"></i>
                    <p class="horizontal-sl-title-bc">{{'Ski Jumping'|translate}}</p><span class="horizontal-sl-count-bc">2</span>
                </div>
                <div class="horizontal-sl-item-bc Sumo " data-id="Sumo" title="Sumo"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Sumo"></i>
                    <p class="horizontal-sl-title-bc">{{'Sumo'|translate}}</p><span class="horizontal-sl-count-bc">8</span>
                </div>
                <div class="horizontal-sl-item-bc Squash " data-id="Squash" title="Squash"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Squash"></i>
                    <p class="horizontal-sl-title-bc">{{'Squash'|translate}}</p><span class="horizontal-sl-count-bc">4</span>
                </div>
                <div class="horizontal-sl-item-bc TVShowsAndMovies " data-id="TVShowsAndMovies"
                    title="TV Shows and Movies"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-TVShowsAndMovies"></i>
                    <p class="horizontal-sl-title-bc">{{'TV Shows and Movies'|translate}}</p><span
                        class="horizontal-sl-count-bc">5</span>
                </div>
                <div class="horizontal-sl-item-bc WaterPolo " data-id="WaterPolo" title="Water Polo"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-WaterPolo"></i>
                    <p class="horizontal-sl-title-bc">{{'Water Polo'|translate}}</p><span class="horizontal-sl-count-bc">9</span>
                </div>
                <div class="horizontal-sl-item-bc WWE " data-id="WWE" title="WWE"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-WWE"></i>
                    <p class="horizontal-sl-title-bc">{{'WWE'|translate}}</p><span class="horizontal-sl-count-bc">7</span>
                </div>
                <div class="horizontal-sl-item-bc CounterStrike " data-id="CounterStrike" title="Counter-Strike: GO"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-CounterStrike"></i>
                    <p class="horizontal-sl-title-bc">{{'Counter-Strike: GO'|translate}}</p><span
                        class="horizontal-sl-count-bc">31</span>
                </div>
                <div class="horizontal-sl-item-bc Dota2 " data-id="Dota2" title="Dota 2"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Dota2"></i>
                    <p class="horizontal-sl-title-bc">{{'Dota 2'|translate}}</p><span class="horizontal-sl-count-bc">22</span>
                </div>
                <div class="horizontal-sl-item-bc LeagueOfLegends " data-id="LeagueOfLegends" title="League of Legends">
                    <i class="horizontal-sl-icon-bc bc-i-default-icon bc-i-LeagueOfLegends"></i>
                    <p class="horizontal-sl-title-bc">{{'League of Legends'|translate}}</p><span
                        class="horizontal-sl-count-bc">77</span>
                </div>
                <div class="horizontal-sl-item-bc Valorant " data-id="Valorant" title="Valorant"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Valorant"></i>
                    <p class="horizontal-sl-title-bc">{{'Valorant'|translate}}</p><span class="horizontal-sl-count-bc">3</span>
                </div>
                <div class="horizontal-sl-item-bc KingOfGlory " data-id="KingOfGlory" title="King of Glory"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-KingOfGlory"></i>
                    <p class="horizontal-sl-title-bc">{{'King of Glory'|translate}}</p><span class="horizontal-sl-count-bc">10</span>
                </div>
                <div class="horizontal-sl-item-bc RainbowSix " data-id="RainbowSix" title="Rainbow Six"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-RainbowSix"></i>
                    <p class="horizontal-sl-title-bc">{{'Rainbow Six'|translate}}</p><span class="horizontal-sl-count-bc">11</span>
                </div>
                <div class="horizontal-sl-item-bc CyberFootball " data-id="CyberFootball" title="E-Football"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-CyberFootball"></i>
                    <p class="horizontal-sl-title-bc">{{'E-Football'|translate}}</p><span class="horizontal-sl-count-bc">1</span>
                </div>
                <div class="horizontal-sl-item-bc CrossFire " data-id="CrossFire" title="CrossFire"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-CrossFire"></i>
                    <p class="horizontal-sl-title-bc">{{'CrossFire'|translate}}</p><span class="horizontal-sl-count-bc">2</span>
                </div>
                <div class="horizontal-sl-item-bc Eurovision " data-id="Eurovision" title="Eurovision"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Eurovision"></i>
                    <p class="horizontal-sl-title-bc">{{'Eurovision'|translate}}</p><span class="horizontal-sl-count-bc">1</span>
                </div>
                <div class="horizontal-sl-item-bc Lacross " data-id="Lacross" title="Lacrosse"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-Lacross"></i>
                    <p class="horizontal-sl-title-bc">{{'Lacrosse'|translate}}</p><span class="horizontal-sl-count-bc">9</span>
                </div>
                <div class="horizontal-sl-item-bc RinkHockey " data-id="RinkHockey" title="Rink Hockey"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-RinkHockey"></i>
                    <p class="horizontal-sl-title-bc">{{'Rink Hockey'|translate}}</p><span class="horizontal-sl-count-bc">10</span>
                </div>
                <div class="horizontal-sl-item-bc StarCraft2 " data-id="StarCraft2" title="StarCraft 2"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-StarCraft2"></i>
                    <p class="horizontal-sl-title-bc">{{'StarCraft 2'|translate}}</p><span class="horizontal-sl-count-bc">6</span>
                </div>
                <div class="horizontal-sl-item-bc StarCraft " data-id="StarCraft" title="StarCraft"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-StarCraft"></i>
                    <p class="horizontal-sl-title-bc">{{'StarCraft'|translate}}</p><span class="horizontal-sl-count-bc">1</span>
                </div>
                <div class="horizontal-sl-item-bc MobileLegends " data-id="MobileLegends" title="Mobile Legends"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-MobileLegends"></i>
                    <p class="horizontal-sl-title-bc">{{'Mobile Legends'|translate}}</p><span class="horizontal-sl-count-bc">13</span>
                </div>
                <div class="horizontal-sl-item-bc WarcraftIII " data-id="WarcraftIII" title="Warcraft III"><i
                        class="horizontal-sl-icon-bc bc-i-default-icon bc-i-WarcraftIII"></i>
                    <p class="horizontal-sl-title-bc">{{'Warcraft III'|translate}}</p><span class="horizontal-sl-count-bc">2</span>
                </div>
            </div>
        </div>
        <div class="pp-sport-list-holder-bc">
            <div class="left-menu-scroll">
                <div class="sp-sub-list-bc  ">
                    <div class="sp-s-l-head-bc" title="India"><i class="sp-s-l-h-flag-bc flag-bc india"></i>
                        <div class="sp-s-l-h-title-content ellipsis">
                            <p class="sp-s-l-h-title-bc ellipsis">{{'India'|translate}}</p>
                        </div><span class="sp-s-l-b-c-i-g-league-c-bc">12</span><i
                            class="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"></i>
                    </div>
                    <div class="sp-s-l-b-content-bc">
                        <div class="sp-s-l-b-c-item-bc " title="Indian Premier League"><i
                                class="sp-s-l-b-c-i-fav-icon-bc bc-i-favorite"></i><span
                                class="sp-s-l-b-c-i-title-bc ellipsis">{{'Indian Premier League'|translate}}</span><span
                                class="sp-s-l-b-c-i-g-count-bc">9</span><i
                                class="competition-ico-arr-bc bc-i-small-arrow-right"></i></div>
                        <div class="sp-s-l-b-c-item-bc " title="Indian Premier League. Outright"><i
                                class="sp-s-l-b-c-i-fav-icon-bc bc-i-favorite"></i><span
                                class="sp-s-l-b-c-i-title-bc ellipsis">{{'Indian Premier League. Outright'|translate}}</span><span
                                class="sp-s-l-b-c-i-g-count-bc">1</span><i
                                class="competition-ico-arr-bc bc-i-small-arrow-right"></i></div>
                        <div class="sp-s-l-b-c-item-bc " title="T20 Premier League - Women"><i
                                class="sp-s-l-b-c-i-fav-icon-bc bc-i-favorite"></i><span
                                class="sp-s-l-b-c-i-title-bc ellipsis">{{'T20 Premier League - Women'|translate}}</span><span
                                class="sp-s-l-b-c-i-g-count-bc">2</span><i
                                class="competition-ico-arr-bc bc-i-small-arrow-right"></i></div>
                    </div>
                </div>
                <div class="sp-sub-list-bc  ">
                    <div class="sp-s-l-head-bc" title="World"><i class="sp-s-l-h-flag-bc flag-bc world"></i>
                        <div class="sp-s-l-h-title-content ellipsis">
                            <p class="sp-s-l-h-title-bc ellipsis">{{'World'|translate}}</p>
                        </div><span class="sp-s-l-b-c-i-g-league-c-bc">7</span><i
                            class="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"></i>
                    </div>
                    <div class="sp-s-l-b-content-bc">
                        <div class="sp-s-l-b-c-item-bc " title="Test matches"><i
                                class="sp-s-l-b-c-i-fav-icon-bc bc-i-favorite"></i><span
                                class="sp-s-l-b-c-i-title-bc ellipsis">{{'Test matches'|translate}}</span><span
                                class="sp-s-l-b-c-i-g-count-bc">1</span><i
                                class="competition-ico-arr-bc bc-i-small-arrow-right"></i></div>
                        <div class="sp-s-l-b-c-item-bc " title="One Day International"><i
                                class="sp-s-l-b-c-i-fav-icon-bc bc-i-favorite"></i><span
                                class="sp-s-l-b-c-i-title-bc ellipsis">{{'One Day International'|translate}}</span><span
                                class="sp-s-l-b-c-i-g-count-bc">3</span><i
                                class="competition-ico-arr-bc bc-i-small-arrow-right"></i></div>
                        <div class="sp-s-l-b-c-item-bc " title="ICC T20 World Cup. Outright"><i
                                class="sp-s-l-b-c-i-fav-icon-bc bc-i-favorite"></i><span
                                class="sp-s-l-b-c-i-title-bc ellipsis">{{'ICC T20 World Cup. Outright'|translate}}</span><span
                                class="sp-s-l-b-c-i-g-count-bc">1</span><i
                                class="competition-ico-arr-bc bc-i-small-arrow-right"></i></div>
                        <div class="sp-s-l-b-c-item-bc " title="Ashes Series. Outright"><i
                                class="sp-s-l-b-c-i-fav-icon-bc bc-i-favorite"></i><span
                                class="sp-s-l-b-c-i-title-bc ellipsis">{{'Ashes Series. Outright'|translate}}</span><span
                                class="sp-s-l-b-c-i-g-count-bc">1</span><i
                                class="competition-ico-arr-bc bc-i-small-arrow-right"></i></div>
                        <div class="sp-s-l-b-c-item-bc " title="ICC World Cup. Outright"><i
                                class="sp-s-l-b-c-i-fav-icon-bc bc-i-favorite"></i><span
                                class="sp-s-l-b-c-i-title-bc ellipsis">{{'ICC World Cup. Outright'|translate}}</span><span
                                class="sp-s-l-b-c-i-g-count-bc">1</span><i
                                class="competition-ico-arr-bc bc-i-small-arrow-right"></i></div>
                    </div>
                </div>
                <div class="sp-sub-list-bc   ">
                    <div class="sp-s-l-head-bc" title="Pakistan"><i class="sp-s-l-h-flag-bc flag-bc pakistan"></i>
                        <div class="sp-s-l-h-title-content ellipsis">
                            <p class="sp-s-l-h-title-bc ellipsis">{{'Pakistan'|translate}}</p>
                        </div><span class="sp-s-l-b-c-i-g-league-c-bc">3</span><i
                            class="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"></i>
                    </div>
                    <div class="sp-s-l-b-content-bc">
                        <div class="sp-s-l-b-c-item-bc " title="Super League"><i
                                class="sp-s-l-b-c-i-fav-icon-bc bc-i-favorite"></i><span
                                class="sp-s-l-b-c-i-title-bc ellipsis">{{'Super League'|translate}}</span><span
                                class="sp-s-l-b-c-i-g-count-bc">2</span><i
                                class="competition-ico-arr-bc bc-i-small-arrow-right"></i></div>
                        <div class="sp-s-l-b-c-item-bc " title="Super League. Outright"><i
                                class="sp-s-l-b-c-i-fav-icon-bc bc-i-favorite"></i><span
                                class="sp-s-l-b-c-i-title-bc ellipsis">{{'Super League. Outright'|translate}}</span><span
                                class="sp-s-l-b-c-i-g-count-bc">1</span><i
                                class="competition-ico-arr-bc bc-i-small-arrow-right"></i></div>
                    </div>
                </div>
                <div class="sp-sub-list-bc   ">
                    <div class="sp-s-l-head-bc" title="United Arab Emirates"><i
                            class="sp-s-l-h-flag-bc flag-bc uae"></i>
                        <div class="sp-s-l-h-title-content ellipsis">
                            <p class="sp-s-l-h-title-bc ellipsis">{{'United Arab Emirates'|translate}}</p>
                        </div><span class="sp-s-l-b-c-i-g-league-c-bc">3</span><i
                            class="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"></i>
                    </div>
                    <div class="sp-s-l-b-content-bc">
                        <div class="sp-s-l-b-c-item-bc " title="Sharjah Hundred League"><i
                                class="sp-s-l-b-c-i-fav-icon-bc bc-i-favorite"></i><span
                                class="sp-s-l-b-c-i-title-bc ellipsis">{{'Sharjah Hundred League'|translate}}</span><span
                                class="sp-s-l-b-c-i-g-count-bc">3</span><i
                                class="competition-ico-arr-bc bc-i-small-arrow-right"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="special-games-container"></div>
</div>