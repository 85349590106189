<div class="grid" style="    width: 100%;
    padding: 0 1.5rem;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 0.3rem;
    justify-content: space-between;
    margin-top: 0.5rem;">

    <div class="list_item">
        <div class="dotcircle" [ngClass]="{
            'green': step >= 1
        }">
            <div class="dot">
                {{step <= 1 ? '1' : '' }} <svg *ngIf="step > 1" class="check" xmlns="http://www.w3.org/2000/svg"
                    width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path
                        d="M4.31258 7.43734L1.97925 5.104L2.42716 4.65609L4.31258 6.5415L8.31258 2.5415L8.7605 2.98942L4.31258 7.43734Z"
                        stroke="#E7EAEF" stroke-width="0.8" />
                    </svg>
            </div>
        </div>
    </div>
    <div class="list_item" *ngIf="step <= 1">
        <svg xmlns="http://www.w3.org/2000/svg" width="83" height="2" viewBox="0 0 83 2" fill="none">
            <path d="M1 1H82" stroke="#D5D9DE" stroke-linecap="round" stroke-dasharray="2 2" />
        </svg>
    </div>

    <div class="list_item" *ngIf="step > 1">
        <svg xmlns="http://www.w3.org/2000/svg" width="83" height="2" viewBox="0 0 83 2" fill="none">
            <path d="M1 1H82" stroke="url(#paint0_linear_1882_24151)" stroke-linecap="round" stroke-dasharray="2 2" />
            <defs>
                <linearGradient id="paint0_linear_1882_24151" x1="2.55769" y1="1" x2="8.7467" y2="22.2247"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#48CD83" />
                    <stop offset="1" stop-color="#0A743A" />
                </linearGradient>
            </defs>
        </svg>
    </div>
    <div class="list_item">
        <div class="dotcircle" [ngClass]="{
            'green': step >= 2
        }">
            <div class="dot">
                {{step <= 2 ? '2' : '' }} <svg *ngIf="step > 2" class="check" xmlns="http://www.w3.org/2000/svg"
                    width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path
                        d="M4.31258 7.43734L1.97925 5.104L2.42716 4.65609L4.31258 6.5415L8.31258 2.5415L8.7605 2.98942L4.31258 7.43734Z"
                        fill="#E7EAEF" stroke="#E7EAEF" stroke-width="0.8" />
                    </svg>
            </div>
        </div>
    </div>
    <div class="list_item" *ngIf="step <= 2">
        <svg xmlns="http://www.w3.org/2000/svg" width="83" height="2" viewBox="0 0 83 2" fill="none">
            <path d="M1 1H82" stroke="#D5D9DE" stroke-linecap="round" stroke-dasharray="2 2" />
        </svg>
    </div>
    <div class="list_item" *ngIf="step > 2">
        <svg xmlns="http://www.w3.org/2000/svg" width="83" height="2" viewBox="0 0 83 2" fill="none">
            <path d="M1 1H82" stroke="url(#paint0_linear_1882_24151)" stroke-linecap="round" stroke-dasharray="2 2" />
            <defs>
                <linearGradient id="paint0_linear_1882_24151" x1="2.55769" y1="1" x2="8.7467" y2="22.2247"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#48CD83" />
                    <stop offset="1" stop-color="#0A743A" />
                </linearGradient>
            </defs>
        </svg>
    </div>

    <div class="list_item">
        <div class="dotcircle" [ngClass]="{
            'green': step >= 3
        }">
            <div class="dot">
                {{step <= 3 ? '3' : '' }} <svg *ngIf="step > 3" class="check" xmlns="http://www.w3.org/2000/svg"
                    width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path
                        d="M4.31258 7.43734L1.97925 5.104L2.42716 4.65609L4.31258 6.5415L8.31258 2.5415L8.7605 2.98942L4.31258 7.43734Z"
                        fill="#E7EAEF" stroke="#E7EAEF" stroke-width="0.8" />
                    </svg>
            </div>
        </div>
    </div>
</div>


<div class="grid"
    style="width: 100%;  display: grid; grid-template-columns: auto auto auto; gap: 0.75rem; justify-content: space-around;">
    <div class="steptxt2" [ngClass]="{
        'steptxt': step >= 1
    }">
        {{'Enter Amount'|translate}}
    </div>
    <div class="steptxt2" [ngClass]="{
        'steptxt': step >= 2
    }">
        {{'Select Account'|translate}}
    </div>
    <div class="steptxt2" [ngClass]="{
        'steptxt': step >= 3
    }">
        &nbsp; &nbsp; &nbsp; {{'Review'|translate}}
    </div>
</div>
