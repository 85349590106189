<div class="overlay" *ngIf="isLoader">
  <div class="loader-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <!-- <img  loading="lazy" class="loader1" src="assets/images/loader-gif.gif" alt="Your Image"> -->
        <ng-lottie [options]="lottieOption" width="150px" height="150px"></ng-lottie>

  </div>
</div>

<div class="layout-content-holder-bc">
    <div style="padding: 7px;">
        <h2 class="sports-name-header d-flex">
            <!-- <i class="bc-i-horsesStreak"></i> -->
            <!-- <img  loading="lazy" class="event-flag horse-icon" src="assets/images/img/racehorse.svg"> -->
            <ng-lottie [options]="horseRacing" width="50px" height="30px"></ng-lottie>
            {{'Horse Racing'|translate}}
        </h2>

        <div class="hdr-navigation-scrollable-bc-holder">
            <div class="hdr-navigation-scrollable-bc scroll-start" data-scroll-lock-scrollable="">
                <nav class="hdr-navigation-scrollable-content" *ngFor="let sitem of sList">

                    <a class="hdr-navigation-link-bc" [ngClass]="{'active': ActiveId === sitem?.id}" (click)="getdetail(sitem)"
                        target="_self">{{sitem?.startTime | date:"HH:mm"}} {{sitem?.venue}}</a>
                    <!--<a class="hdr-navigation-link-bc" target="_self">01:51 Woodbine</a>
                    <a class="hdr-navigation-link-bc" target="_self">01:51 Woodbine</a>
                    <a class="hdr-navigation-link-bc" target="_self">01:51 Woodbine</a>
                    <a class="hdr-navigation-link-bc" target="_self">01:51 Woodbine</a>
                    <a class="hdr-navigation-link-bc" target="_self">01:51 Woodbine</a>-->




                </nav>
            </div>
        </div>


        <div class="event-detail-page">
            <h3 *ngIf="activeItem"  class="event-details-name-header">
              {{activeItem?.startTime | date:"HH:mm"}} {{activeItem?.venue}}
            </h3>


            <div class="c-segment-holder-bc single-g-info-bc">
                <div class="sw-container-bc" style="transform: translateX(0px);" *ngFor="let sitem of detailList;let i = index">
                    <div class="c-segment-holder-bc markets">
                        <div class="c-row-bc bordered">
                            <div class="c-teams-bc">
                                <div class="c-teams-row-bc text-center"><span
                                        class="c-team-info-team-bc team event-name-info">2
                                    </span><br>

                                    <div class="market-coefficient-bc"><span class="market-odd-bc event-name-info">(3)
                                        </span></div>

                                </div>
                                <div class="c-teams-row-bc"><span class="c-team-info-team-bc team">{{sitem?.name}}
                                    </span><br>

                                    <div class="market-coefficient-bc"><span class="market-odd-bc event-name-info">
                                            {{sitem?.metadata?.JOCKEY_NAME}}</span></div>

                                </div>
                            </div>
                            <div *ngIf="eventData.status === 'CLOSED'" class="status-bet">{{'CLOSED'|translate}}</div>
                            <div *ngIf="eventData.status === 'SUSPENDED'" class="status-bet">{{'SUSPENDED'|translate}}</div>
                            <div *ngIf="eventData.status !== 'CLOSED' && eventData.status !== 'SUSPENDED' "
                                 class="c-odd-bc"
                                 [ngClass]="{
                                   'blink': sitem?.back[0]?.price !== previousAList?.[i]?.back?.[0]?.price,
                                   'blocked-stake': !eventData.isBettable
                                 }"
                            >
                              <div title="" class="sgm-market-g-i-cell-bc market-bc back">
                                <div class="market-coefficient-bc "
                                     (click)="openbet(0,sitem,sitem?.back[0]?.price)"><span
                                  class="market-odd-bc">{{sitem?.back[0]?.price || '-'}}</span><br><span
                                  class="market-odd-bc1">{{sitem?.back[0]?.size }}</span></div>
                              </div>
                            </div>
                            <div *ngIf="eventData.status !== 'CLOSED' && eventData.status !== 'SUSPENDED' "
                                 class="c-odd-bc"
                                 [ngClass]="{
                                    'blink': sitem?.lay[0]?.price !== previousAList?.[i]?.lay?.[0]?.price,
                                    'blocked-stake': !eventData.isBettable
                                 }"
                            >
                              <div title="" class="sgm-market-g-i-cell-bc market-bc lay">
                                <div class="market-coefficient-bc" (click)="openbet(1,sitem,sitem?.lay[0]?.price)">
                                  <span class="market-odd-bc">{{sitem?.lay[0]?.price || '-'}}</span><br><span
                                  class="market-odd-bc1">{{sitem?.lay[0]?.size}}</span></div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


<div class="betslip-bc Quick first-bet " style="transform: translateY(0px); opacity: 1;" *ngIf="isActiveBet==true">
    <div id="betslip_container">
        <div class="betslip-first-bet-content-bc quickBetWrapper-bc betplacedetail1 "
            [ngClass]="{'backdetails':isBack,'laydetails':!isBack }">
            <div class="bs-bet-item-bc multiple deleted">
                <div class="bs-bet-item-bg-c-bc">
                    <div class="bs-bet-i-head-bc">
                        <div class="bs-bet-i-h-cell-bc title"><span class="bs-bet-i-h-title-bc t-1"><span
                                    class="bs-item-date-v-bc bs-bet-i-h-title-bc-text"> </span></span>
                        </div>
                        <div class="bs-bet-i-h-cell-bc icon">
                            <div class="bs-bet-i-h-c-icon-holder-bc"><i
                                    class="bs-bet-i-h-c-icon-bc remove bc-i-close-remove" (click)="closebet()"></i>
                            </div>
                        </div>
                    </div>
                    <div class="bs-bet-i-body-bc">
                        <div class="bs-bet-i-b-section-team-bc">
                            <div class="bs-bet-i-b-row-bc">
                                <div class="bs-bet-i-b-cell-bc title"><span
                                        class="bs-bet-i-b-title-bc t-1">{{ename}}</span></div>
                                <div class="bs-bet-i-b-cell-bc value"></div>
                            </div>
                            <!--<div class="bs-bet-i-b-row-bc">
                                <div class="bs-bet-i-b-cell-bc title"><span
                                        class="bs-bet-i-b-title-bc t-2"><span>{{ename.rName}}</span><span></span></span>
                                </div>

                            </div>-->
                        </div>
                    </div>

                </div>
            </div>

            <div class="quickBetTaxInputRow-bc">
                <ul class="bs-bet-i-b-stake-bc">
                    <li data-numpad-open="" class="bs-bet-i-b-s-i-block-bc bs-bet-i-b-s-i-holder-bc px-0 bet-input">


                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" (click)="plus()">+</span>
                            </div>
                            <input type="text" class="form-control" maxlength="5" readonly id="odd1price"
                                name="odd1price" #odd1price="ngModel" [(ngModel)]="matchOdd3price">
                            <div class="input-group-append">
                                <span class="input-group-text" (click)="minus()">-</span>
                            </div>
                        </div>
                    </li>


                    <li data-numpad-open="" class="bs-bet-i-b-s-i-block-bc bs-bet-i-b-s-i-holder-bc px-0 bet-stack">

                        <input class="form-control ellipsis quickBetInput-text-bc" type="text" maxlength="5"
                            tabindex="2" id="odd1price" name="odd1price" #odd1price="ngModel"
                            [(ngModel)]="matchOdd2price" autofocus>

                    </li>

                </ul>


            </div>











            <div class="bs-numbers-bc ">
                <div class="bs-number-buttons-bc"><span class="bs-number-button-bc i-1" data-value="1"
                        (click)="keyboard('1', 'match_odd2')">1</span><span class="bs-number-button-bc i-1"
                        data-value="2" (click)="keyboard('2', 'match_odd2')">2</span><span
                        class="bs-number-button-bc i-1" data-value="3"
                        (click)="keyboard('3', 'match_odd2')">3</span><span class="bs-number-button-bc i-1"
                        data-value="4" (click)="keyboard('4', 'match_odd2')">4</span><span
                        class="bs-number-button-bc i-1" data-value="5"
                        (click)="keyboard('5', 'match_odd2')">5</span><span class="bs-number-button-bc i-1"
                        data-value="6" (click)="keyboard('6', 'match_odd2')">6</span><span
                        class="bs-number-button-bc i-1" data-value="7"
                        (click)="keyboard('7', 'match_odd2')">7</span><span class="bs-number-button-bc i-1"
                        data-value="8" (click)="keyboard('8', 'match_odd2')">8</span><span
                        class="bs-number-button-bc i-1" data-value="9" (click)="keyboard('9', 'match_odd2')">9</span>

                    <span class="bs-number-button-bc i-1" data-value="0"
                        (click)="keyboard('0', 'match_odd2')">0</span><span class="bs-number-button-bc i-2"><i
                            class="bs-number-button-icon-bc bc-i-delete"
                            (click)="keyboard('back', 'match_odd2')"></i></span>
                    <span class="bs-number-button-bc i-2 " title="Edit stakes" *ngIf="getToken()"
                        [routerLink]="['/myprofile','PersonalDetails']"><i
                            class="bs-number-button-icon-bc bc-i-edit"></i></span>
                    <span class="bs-number-button-bc i-2 " title="Edit stakes" *ngIf="!getToken()"
                        (click)="openSignIn()"><i class="bs-number-button-icon-bc bc-i-edit"></i></span>
                </div>
                <div class="bs-editable-number-buttons-bc" *ngIf="getToken()">
                    <span class="bs-number-button-bc i-2 ellipsis" *ngFor="let item of stackPriceSort"
                        (click)="keyboard(item, 'bigOh')">{{item}}</span>
                </div>
                <div class="bs-editable-number-buttons-bc" *ngIf="!getToken()">
                    <span class="bs-number-button-bc i-2 ellipsis" (click)="keyboard('100', 'bigOh')">100</span><span
                        class="bs-number-button-bc i-2 ellipsis" (click)="keyboard('250', 'bigOh')">250</span><span
                        class="bs-number-button-bc i-2 ellipsis" (click)="keyboard('500', 'bigOh')">500</span><span
                        class="bs-number-button-bc i-2 ellipsis" (click)="keyboard('2500', 'bigOh')">2500</span>
                </div>
            </div>

            <div class="quickBetButton-bc " *ngIf="getToken()">
                <button class="quickBetButtonEl-bc btn a-color a-"
                    [ngClass]="{'back1': matchOdd2price == undefined || matchOdd2price == ''}" (click)="placeBet()">
                    <p>Bet Now</p>
                    <div class="bs-bet-i-b-row-bc" *ngIf="matchOddprofit!=0">
                        <div class="bs-bet-i-b-cell-bc title"><span
                                class="bs-bet-i-b-title-bc t-2 ellipsis text-white">{{betText}}</span></div>
                        <div class="bs-bet-i-b-cell-bc value d-flex">

                            <span class="bs-bet-i-b-amount-bc">{{matchOddprofit}}</span>
                        </div>
                    </div>
                </button>


            </div>
            <div class="quickBetButton-bc " *ngIf="!getToken()">

                <button class="quickBetButtonEl-bc btn a-color a-"
                    [ngClass]="{'back1': matchOdd2price == undefined || matchOdd2price == ''}" (click)="openSign()">
                    <p>{{'Sign In'|translate}}</p>
                    <div class="bs-bet-i-b-row-bc" *ngIf="matchOddprofit!=0">
                        <!--<div class="bs-bet-i-b-cell-bc title"><span
                                class="bs-bet-i-b-title-bc t-2 ellipsis text-white">{{betText}}</span></div>-->
                        <div class="bs-bet-i-b-cell-bc value d-flex">

                            <span class="bs-bet-i-b-amount-bc">{{matchOddprofit}}</span>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
