import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotifyServiceService {

  messages: any[] = [];
  maxLimit: number = 3;

  constructor() {

    // setTimeout(() => {
    //   this.info('Welcome to the notification service!', 100000);
    // }, 1000);
    // setTimeout(() => {
    //   this.success('This is a success message!', 5000);
    // }, 2000);
    // setTimeout(() => {
    //   this.warning('This is a warning message!', 5000);
    // }, 3000);
    // setTimeout(() => {
    //   this.error('This is an error message!', 5000);
    // }, 4000);
  }

  info(text: string, timeOut = 2500, clearAll = false, autoClose = true, hideCloseButton = false, id = Math.random().toString(36).substr(2, 9)) {
    if (clearAll) this.closeAll();
    const notification = {
      id: id,
      type: 'info',
      text: text,
      isClosing: false,
      hideCloseButton: hideCloseButton,
      timeout: autoClose ? setTimeout(() => {
        this.close(id);
      }, timeOut) : null
    };
    this.addToList(notification);
    return notification.id;
  }

  success(text: string, timeOut = 2500, clearAll = false, autoClose = true, hideCloseButton = false, id = Math.random().toString(36).substr(2, 9)) {
    if (clearAll) this.closeAll();
    const notification = {
      id: id,
      type: 'success',
      text: text,
      isClosing: false,
      hideCloseButton: hideCloseButton,
      timeout: autoClose ? setTimeout(() => {
        this.close(id);
      }, timeOut) : null
    };
    this.addToList(notification);
    return notification.id;
  }

  warning(text: string, timeOut = 2500, clearAll = false, autoClose = true, hideCloseButton = false, id = Math.random().toString(36).substr(2, 9)) {
    if (clearAll) this.closeAll();
    const notification = {
      id: id,
      type: 'warning',
      text: text,
      isClosing: false,
      hideCloseButton: hideCloseButton,
      timeout: autoClose ? setTimeout(() => {
        this.close(id);
      }, timeOut) : null
    };
    this.addToList(notification);
    return notification.id;
  }

  error(text: string, timeOut = 2500, clearAll = false, autoClose = true, hideCloseButton = false, id = Math.random().toString(36).substr(2, 9)) {
    if (clearAll) this.closeAll();
    const notification = {
      id: id,
      type: 'error',
      text: text,
      isClosing: false,
      hideCloseButton: hideCloseButton,
      timeout: autoClose ? setTimeout(() => {
        this.close(id);
      }, timeOut) : null
    };
    this.addToList(notification);
    return notification.id;
  }

  addToList(notification) {
    if (this.messages.length >= 3) {
      const message = this.messages.find((message) => message.timeout);
      message.isClosing = true;
      if (message) {
        this.close(message.id);
      }
    }
    const old_message = this.messages.find((message) => message.text === notification.text && message.type === notification.type);
    if (old_message && old_message.clearAll) {
      // clearTimeout(old_message.timeout);
      old_message.timeOut = notification.timeOut;
    } else {
    this.messages.push(notification);
      this.pop();
    }
  }

  close(id): void {
    const message = this.messages.find((message) => message.id === id);
    if (!message) return;
    message.isClosing = true;
    if (message.timeout) {
      clearTimeout(message.timeout);
    }
    setTimeout(() => {
      this.messages = this.messages.filter((message) => message.id !== id);
    }, 200);
    this.zoop();
  }

  pause(id): void {
    const message = this.messages.find((message) => message.id === id);
    if (!message) return;
    if (message.timeout) {
      message.hideCloseButton = false;
      clearTimeout(message.timeout);
    }
  }

  closeAll(): void {
    this.messages.forEach((message) => {
      this.close(message.id);
    });
  }

  pop() {
    // if safari or mobile, don't play sound
    const pop = new Audio();
    pop.src = "assets/notify/pop.mp3";
    pop.load();
    pop.volume = 0.1;
    pop.play();
  }
  zoop() {
    if (navigator.userAgent.indexOf('iPhone') > -1 && navigator.userAgent.indexOf('chrome') == -1 || navigator.userAgent.indexOf('iPad') > -1 && navigator.userAgent.indexOf('chrome') == -1 || navigator.userAgent.indexOf('iPod') > -1 && navigator.userAgent.indexOf('chrome') == -1)
      return;
    const zoop = new Audio();
    zoop.src = "assets/notify/zoop.mp3";
    zoop.load();
    zoop.volume = 0.01;
    zoop.play();

  }
}
