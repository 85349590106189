<app-accountheader></app-accountheader>
<div class="u-i-p-c-body-bc">
    <div class="back-nav-bc"><i routerLink="/account" class="back-nav-icon-bc bc-i-round-arrow-left"></i><span
            class="back-nav-title-bc ellipsis">{{'Messages'|translate}}</span></div>
    <div class="messageBoxWrapper-bc">
        <ul class="messageBoxList-bc">
            <div class="message-block-bc" data-status="unread">
                <div class="message-title-block">
                    <h3 class="message-title-bc">{{'₹ 400 Free bonus on IPL!'|translate}}</h3><i class="message-remove-icon bc-i-clear"
                        title="Delete"></i>
                </div>
                <div class="message-more-time-bc"><time class="message-time-bc">11.03.2023, 06:11</time><i
                        class="message-more-icon bc-i-double-arrow-top"></i></div>
            </div>
        </ul>
    </div>
</div>