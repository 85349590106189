import { Component, OnInit } from '@angular/core';
import { MyprofileService } from 'src/app/services/myprofile.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-deposit-withdraw-report',
  templateUrl: './deposit-withdraw-report.component.html',
  styleUrls: ['./deposit-withdraw-report.component.scss'],
  animations: [
    trigger('fadeFromBottom', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(100%)' }),
        animate('0.5s', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
    ]),
  ],
})
export class DepositWithdrawReportComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  depositReportPopup = true;
  showDepositList: boolean = true;
  showWithdrawList: boolean = false;
  openDepositDetails: boolean = false;
  openWithdrawDetails: boolean = false;
  isLoader: boolean = true;
  depositList: any;
  withdrawList: any;
  depositDetails: any;
  withDrawDetails: any;
  groupDepositItems: any;
  groupWithdrawItems: any;
  maxVisibleIndex: number = 0; // Initial value
  dataUpdateSubscription: any;
  depositStatus: any;
  constructor(private myprofileServices: MyprofileService, private router: Router,) { }

  ngOnInit(): void {
    this.transactionReport();
    this.dataUpdateSubscription = this.myprofileServices.dataUpdated$.subscribe(() => {
      this.isLoader = true;
      this.transactionReport();
    });
    const token = localStorage.getItem('ROYALTOKEN_KEY');
    if (!token) {
      this.router.navigate(['/']);
    }
  }

  //check for the latest myprofileServices.transactionReports





  openDepositDetailsPage(item: any) {
    this.openDepositDetails = true;
    this.openWithdrawDetails = false;
    this.depositDetails = item;
  }

  openWithDrawDetailsPage(item: any) {
    this.openWithdrawDetails = true;
    this.openDepositDetails = false;
    this.withDrawDetails = item;
  }

  showDepositReport() {
    this.showDepositList = true;
    this.showWithdrawList = false;
  }

  showWithdrawReport() {
    this.showDepositList = false;
    this.showWithdrawList = true;
  }

  transactionReport() {
    this.myprofileServices.transactionreport().subscribe(async (res: any) => {
      //check the condition for nor res as null
      if (res?.result === null) {
        this.depositList = [];
        this.withdrawList = [];
        this.isLoader = false;
        return;
      } else if (res?.success) {
        //reverse res.resul
        const response = await res?.result?.reverse();
        this.depositList = await response?.filter((item: any) => item?.type == "Deposit");
        //sort above array by lastUpdateTime in descending order if there is no lastUpdateTime then sort by createdTAt
        this.depositList.sort((a: any, b: any) => {
          if (a.lastUpdateTime > b.lastUpdateTime) {
            return -1;
          }
          else if (a.lastUpdateTime < b.lastUpdateTime) {
            return 1;
          }
          else {
            if (a.createdAt > b.createdAt) {
              return -1;
            }
            else if (a.createdAt < b.createdAt) {
              return 1;
            }
            else {
              return 0;
            }
          }
        });

        const groupedDepositItems: { [key: string]: any[] } = {};

        this.depositList.forEach((item: any) => {
          const date = new Date(item.createdAt).toDateString();

          if (!groupedDepositItems[date]) {
            groupedDepositItems[date] = [];
          }

          groupedDepositItems[date].push(item);
        });

        this.groupDepositItems = Object.entries(groupedDepositItems).map(([key, value]) => ({ date: key, items: value }));

        const requestId=localStorage.getItem('paymentGateWayTrxnId');

        if (requestId) {
           console.log("requestId",requestId);
            const filteredDepositItems:any=[];
            this.groupDepositItems.forEach((item:any)=>{
              item.items.forEach((item1:any)=>{
                if(item1.requestId==requestId){
                  filteredDepositItems.push(item1);
                }
              })
            })
           if (filteredDepositItems.length>0 ){
             this.openDepositDetailsPage(filteredDepositItems[0]);
             this.depositStatus=filteredDepositItems[0].status;
             //if deposit status is pending then recall this function on the interval of 5 seconds
             if(this.depositStatus=="Pending" || this.depositStatus=="pending" || this.depositStatus=="PENDING" || this.depositStatus=='Initiated' || this.depositStatus=='initiated' || this.depositStatus=='INITIATED'){
              //now call this function on the interval of 1 seconds and max count is 10
              let count=0;
              const interval=setInterval(()=>{
                if(count<10){
                  this.transactionReport();
                  count++;
                }else{
                  clearInterval(interval);
                }
              },1000)
             }
           }
        }

        this.withdrawList = await response?.filter((item: any) => item?.type == "Withdrawal");
        //also sort this
        this.withdrawList.sort((a: any, b: any) => {
          if (a.lastUpdateTime > b.lastUpdateTime) {
            return -1;
          }
          else if (a.lastUpdateTime < b.lastUpdateTime) {
            return 1;
          }
          else {
            if (a.createdAt > b.createdAt) {
              return -1;
            }
            else if (a.createdAt < b.createdAt) {
              return 1;
            }
            else {
              return 0;
            }
          }
        });
        //do same for this
        const groupedWithdrawItems: { [key: string]: any[] } = {};

        this.withdrawList.forEach((item: any) => {
          const date = new Date(item.createdAt).toDateString();

          if (!groupedWithdrawItems[date]) {
            groupedWithdrawItems[date] = [];
          }

          groupedWithdrawItems[date].push(item);
        });

        this.groupWithdrawItems = Object.entries(groupedWithdrawItems).map(([key, value]) => ({ date: key, items: value }));
        this.isLoader = false;
      }
    },
      (error) => {
        console.log(error);
        this.isLoader = false;
      })
  }

  dateFormate(date: any) {
    return moment(date).format('MMM DD, YYYY | h:mm a');
  }

  dateFormate1(date: any) {
    //if date is today's date then return today
    const today = new Date().toDateString();
    const date1 = new Date(date).toDateString();
    if (today == date1) {
      return "Today";
    }
    //if date is yesterday's date then return yesterday
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toDateString();
    if (yesterday == date1) {
      return "Yesterday";
    }
    return moment(date).format('MMM DD, YYYY');
  }

  trackByFn(index: any, item: any) {
    return item.requestId;
  }

  backToPrev(back: any) {
    if (this.openDepositDetails == true) {
      if (back == true) {
        this.openDepositDetails = false;
      }
    } else if (this.openWithdrawDetails == true) {
      if (back == true) {
        this.openWithdrawDetails = false;
      }
    } else {
      this.router.navigate(['account']);
    }
  }
  gotoWithdraw() {
    this.router.navigate(['/balancemanagement/Withdraw']);
  }
  gotoDeposit() {
    this.router.navigate(['/balancemanagement/Deposit']);
  }
}
