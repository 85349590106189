<div class="football-card-body" routerLink="/shared/details/{{data?.event?.id}}/{{data?.eventTypeId}}">
    <div class="first-card">
        <div class="section-first">
            <div class="first-text">
                <ng-container *ngIf="!data?.isInPlay">
                    <img style="width: 1rem; height: 1rem; flex-shrink: 0;" src="../../../../assets/images/clock-match.svg" alt="" srcset="">
                </ng-container>
                <ng-container *ngIf="data?.isInPlay">
                    <div class="first">
                        <span>{{time === 45 ? 'HT' : time + "'"}} <div *ngIf="fullTime >= 90">+ {{extraTime}}</div>
                    </span>
                </div>
                    <div class="second" *ngIf="data?.scores">
                    <small>{{data?.scores?.players?.[0]?.score}}</small>
                    <small>{{data?.scores?.players?.[1]?.score}}</small>
                </div>
                </ng-container>
            </div>
            <div class="vrline"></div>
        </div>
        <div class="section-text">
            <div class="section-second">
                <div class="second-text" *ngIf="data?.market?.name !== 'Winner'">
                    <div class="football-logo">
                        <img style="height: 1rem; width: 1rem;" src="../../../../assets/images/football-logo.png"
                            alt="football" *ngIf="data?.scores?.players?.[0]?.serving">
                    <span>{{data?.players?.[0]?.name}}</span>
                    </div>
                    <div class="football-logo">
                        <img style="height: 1rem; width: 1rem;" src="../../../../assets/images/football-logo.png"
                            alt="football" *ngIf="data?.scores?.players?.[0]?.serving">
                    <span>{{data?.players?.[1]?.name}}</span>
                    </div>
                </div>
                <div class="second-text" *ngIf="data?.market?.name == 'Winner'">
                    <div class="football-logo">
                        <span>{{data?.market?.competition?.name}}</span>
                    </div>
                </div>
            </div>
            <div class="section-third">
                <div  class="first-img">
                    <!-- <img *ngIf="data?.scores===null" style="height: 1rem;width: 1rem;"
                        src="../../../../assets/images/live-match-dot.png" alt="dot"> -->
                    <ng-lottie *ngIf="data?.scores" [options]="liveDotLottieOptions" width="20px" height="20px"></ng-lottie>

                    <img *ngIf="data?.hasVideo" src="../../../../assets/images/tv-icon.svg" alt="camera">
                </div>
                <span *ngIf="data?.isInPlay &&
                !isMatchInPast">{{matchDate}}</span>
            </div>
        </div>
    </div>
    <ng-container *ngIf="showOdds">
        <ng-container *ngIf="data?.market?.status != 'OPEN'">
            <div class="suspended-btn">{{'SUSPENDED'|translate}}</div>
        </ng-container>
        <ng-container *ngIf="data?.market?.status == 'OPEN'">
            <div class="bet-card" [ngClass]="{'isBettable': !data?.market?.isBettable}">
                <div class="first-bet-card">
                    <div class="first-bet" (click)="triggerBetSlip(odds?.back[0]?.price,true,0)"
                        [ngClass]="{'blink_me':applyBackAnimation[0]}">
                        <span>{{data?.market?.consolidatedRunner?.back[0]?.price ?
                            data?.market?.consolidatedRunner?.back[0]?.price : '-' }}</span>
                    </div>
                    <div class="center-badge-white"><span>1</span></div>
                    <div class="second-bet" (click)="triggerBetSlip(odds?.lay[0]?.price,false,0)"
                        [ngClass]="{'blink_me':applyLayAnimation[0]}">
                        <span>{{data?.market?.consolidatedRunner?.lay[0]?.price ?
                            data?.market?.consolidatedRunner?.lay[0]?.price : '-' }}</span>
                    </div>
                </div>

        <div class="second-bet-card">
            <div class="first-bet-card">
                    <div class="first-bet" (click)="triggerBetSlip(odds?.back[1]?.price,true,1)"
                        [ngClass]="{'blink_me':applyBackAnimation[1]}">
                    <span>{{data?.market?.consolidatedRunner?.back[1]?.price ?
                        data?.market?.consolidatedRunner?.back[1]?.price : '-' }}</span>
                </div>
                <div class="center-badge-white"><span>X</span></div>
                    <div class="second-bet" (click)="triggerBetSlip(odds?.lay[1]?.price,false,1)"
                        [ngClass]="{'blink_me':applyLayAnimation[1]}">
                    <span>{{data?.market?.consolidatedRunner?.lay[1]?.price ?
                        data?.market?.consolidatedRunner?.lay[1]?.price : '-' }}</span>
                </div>
            </div>
        </div>
        <div class="third-bet-card">
            <div class="first-bet-card">
                    <div class="first-bet" (click)="triggerBetSlip(odds?.back[2]?.price,true,2)"
                        [ngClass]="{'blink_me':applyBackAnimation[2]}">
                    <span>{{data?.market?.consolidatedRunner?.back[2]?.price ?
                        data?.market?.consolidatedRunner?.back[2]?.price : '-' }}</span>
                </div>
                <div class="center-badge-white"><span>2</span></div>
                    <div class="second-bet" (click)="triggerBetSlip(odds?.lay[2]?.price,false,2)"
                        [ngClass]="{'blink_me':applyLayAnimation[2]}">
                    <span>{{data?.market?.consolidatedRunner?.lay[2]?.price ?
                        data?.market?.consolidatedRunner?.lay[2]?.price : '-' }}</span>
                </div>
            </div>
        </div>
    </div>
        </ng-container>
    </ng-container>
</div>
<div class="line"></div>
