export const casinoGamesArray = [
    { name: 'All Games', id: 'all' },
    { name: 'Slots', id: '51' },
    { name: 'Fishing Games', id: '52' },
    { name: 'Multiplayer', id: '54' },
    { name: 'Bingo', id: '53' },
    { name: 'Virtual Sports', id: '55' },
    { name: 'Scratch Cards', id: '56' },
    { name: 'Instant Games', id: '57' }
]

export const LiveCasinoGamesArray = [
    { name: 'All Games', id: 'all' },
    { name: 'Top Games', id: '12' },
    { name: 'Live Games', id: '14' },
    { name: 'Table Games', id: '13' },
    { name: 'Poker', id: '15' },
    { name: 'Blackjak', id: '16' },
    { name: 'Baccarat', id: '17' },
    { name: 'Roulette', id: '18' },
    { name: 'Games Shows', id: '19' },
    { name: 'Virtual Sports', id: '20' }
]