import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RequestTrackerService {
  private pendingRequests: Map<string, Set<Observable<any>>> = new Map();

  constructor() {}

  trackRequest(endpoint: string, request: Observable<any>): Observable<any> {
    if (!this.pendingRequests.has(endpoint)) {
      this.pendingRequests.set(endpoint, new Set());
    }

    const trackedRequest = new Observable((observer) => {
      const subscription = request.subscribe({
        next: (value) => {
          observer.next(value);
        },
        error: (error) => {
          observer.error(error);
        },
        complete: () => {
          observer.complete();
          this.pendingRequests?.get(endpoint)?.delete(trackedRequest);
        },
      });

      this.pendingRequests.get(endpoint).add(trackedRequest);

      return () => {
        subscription.unsubscribe();
        this.pendingRequests.get(endpoint).delete(trackedRequest);
      };
    });

    return trackedRequest;
  }

  isRequestPending(endpoint: string): boolean {
    return this.pendingRequests.has(endpoint) && this.pendingRequests.get(endpoint).size > 0;
  }

  markRequestAsComplete(endpoint: string): void {
    if (this.pendingRequests.has(endpoint)) {
      this.pendingRequests.get(endpoint).clear();
    }
  }
}
