<div class="layout-content-holder-bc">
    <div class="casino-game-tournament-filter">
        <div class="componentFilterWrapper-bc">
            <div class="componentFilterLabel-bc "><i class="componentFilterLabel-filter-i-bc bc-i-filter"></i>
                <div class="componentFilterLabel-filter-bc">
                    <p class="ellipsis">{{'Filter'|translate}}</p>
                </div><i class="componentFilterChevron-bc bc-i-small-arrow-down"></i>
            </div>
            <div class="componentFilterBody-bc">
                <div class="componentFilterElsWrapper-bc">
                    <form class="filter-form-w-bc">
                        <div class="u-i-p-control-item-holder-bc">
                            <div class="multi-select-bc">
                                <div class="form-control-bc filled valid">
                                    <div class="form-control-label-bc inputs">
                                        <div class="form-control-select-bc">{{'Upcoming, Live'|translate}}</div><i
                                            class="form-control-icon-bc bc-i-small-arrow-down"></i><i
                                            class="form-control-input-stroke-bc"></i><span
                                            class="form-control-title-bc ellipsis">{{'Statuses'|translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="u-i-p-control-item-holder-bc">
                            <div class="form-control-bc select has-icon valid filled"><label
                                    class="form-control-label-bc inputs"><select class="form-control-select-bc active"
                                        name="registrationStatus" step="0">
                                        <option value="Infinity">All</option>
                                        <option value="1">{{'Registration Started'|translate}}</option>
                                        <option value="2">{{'Registration Finished'|translate}}</option>
                                    </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i><i
                                        class="form-control-input-stroke-bc"></i><span
                                        class="form-control-title-bc ellipsis">{{'Registration statuses'|translate}}</span></label>
                            </div>
                        </div>
                        <div class="u-i-p-control-item-holder-bc">
                            <div class="form-control-bc select has-icon valid filled"><label
                                    class="form-control-label-bc inputs"><select class="form-control-select-bc active"
                                        name="entryType" step="0">
                                        <option value="Infinity">{{'All'|translate}}</option>
                                        <option value="0">{{'Buy-in'|translate}}</option>
                                        <option value="1">{{'Free Entry'|translate}}</option>
                                    </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i><i
                                        class="form-control-input-stroke-bc"></i><span
                                        class="form-control-title-bc ellipsis">{{'Entry Types'|translate}}</span></label></div>
                        </div>
                        <div class="u-i-p-c-footer-bc"><button class="btn a-color " type="submit"
                                title="Show"><span>{{'Show'|translate}}</span></button></div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <ul class="casino-game-tournament-list-bc casinoTournamentArticleList-bc">
        <li class="casino-game-tournament-list-item casinoTournamentArticle-bc"><button
                class="tournament-game-status btn s-small a-color live " type="button"
                title="Live"><span>{{'Live'|translate}}</span></button><img loading="lazy"
                src="https://ca-ft.trexname.com/RGS/Tournament/7297/1/en/815BB259.jpg" class="tournament-section-img"
                alt="">
            <div class="casino-game-tournament-list-footer">
                <div class="casino-game-tournament-info-bc">
                    <p class="casino-game-tournament-price-bc">₹ 436,790.84</p>
                    <h3 class="casino-game-tournament-title-bc">{{'Fazi Cash Explosion'|translate}}</h3>
                    <div class="casino-game-tournament-join-block-bc">
                        <p class="casino-game-tournament-inner-info-bc">{{'Buy in + Fee: ₹ 0'|translate}}</p>
                        <div class="casino-game-tournament-time-block-bc"><time
                                class="casino-game-tournament-time-bc"><time class="">11.03.2023, 14:01</time></time><i
                                class="casino-game-tournament-arrow-bc bc-i-arrow-to"></i><time
                                class="casino-game-tournament-time-bc"><time class="">18.03.2023, 14:59</time></time>
                        </div>
                    </div>
                </div>
                <div class="casino-game-tournament-join-status-bc"><button class="btn a-color  null "
                        type="button">{{'JOIN'|translate}}</button></div>
            </div>
        </li>
        <li class="casino-game-tournament-list-item casinoTournamentArticle-bc"><button
                class="tournament-game-status btn s-small a-color live " type="button"
                title="Live"><span>Live</span></button><img loading="lazy"
                src="https://ca-ft.trexname.com/RGS/Tournament/7232/1/en/FC5212B1.jpg" class="tournament-section-img"
                alt="">
            <div class="casino-game-tournament-list-footer">
                <div class="casino-game-tournament-info-bc">
                    <p class="casino-game-tournament-price-bc">₹ 873,581.69</p>
                    <h3 class="casino-game-tournament-title-bc">{{'Wazdan! Celebrate good play!'|translate}}</h3>
                    <div class="casino-game-tournament-join-block-bc">
                        <p class="casino-game-tournament-inner-info-bc">{{'Buy in + Fee: ₹ 0'|translate}}</p>
                        <div class="casino-game-tournament-time-block-bc"><time
                                class="casino-game-tournament-time-bc"><time class="">05.03.2023, 14:00</time></time><i
                                class="casino-game-tournament-arrow-bc bc-i-arrow-to"></i><time
                                class="casino-game-tournament-time-bc"><time class="">20.03.2023, 14:59</time></time>
                        </div>
                    </div>
                </div>
                <div class="casino-game-tournament-join-status-bc"><button class="btn a-color  null "
                        type="button">{{'JOIN'|translate}}</button></div>
            </div>
        </li>
        <li class="casino-game-tournament-list-item casinoTournamentArticle-bc"><button
                class="tournament-game-status btn s-small a-color live " type="button"
                title="Live"><span>{{'Live'|translate}}</span></button><img loading="lazy"
                src="https://ca-ft.trexname.com/RGS/Tournament/7214/1/en/F3109D8B.jpg" class="tournament-section-img"
                alt="">
            <div class="casino-game-tournament-list-footer">
                <div class="casino-game-tournament-info-bc">
                    <p class="casino-game-tournament-price-bc">₹ 873,581.69</p>
                    <h3 class="casino-game-tournament-title-bc">{{'Red Rake Tournament'|translate}}</h3>
                    <div class="casino-game-tournament-join-block-bc">
                        <p class="casino-game-tournament-inner-info-bc">{{'Buy in + Fee: ₹ 0'|translate}}</p>
                        <div class="casino-game-tournament-time-block-bc"><time
                                class="casino-game-tournament-time-bc"><time class="">02.03.2023, 14:00</time></time><i
                                class="casino-game-tournament-arrow-bc bc-i-arrow-to"></i><time
                                class="casino-game-tournament-time-bc"><time class="">17.03.2023, 14:59</time></time>
                        </div>
                    </div>
                </div>
                <div class="casino-game-tournament-join-status-bc"><button class="btn a-color  null "
                        type="button">{{'JOIN'|translate}}</button></div>
            </div>
        </li>
        <li class="casino-game-tournament-list-item casinoTournamentArticle-bc"><button
                class="tournament-game-status btn s-small a-color live " type="button"
                title="Live"><span>{{'Live'|translate}}</span></button><img loading="lazy"
                src="https://ca-ft.trexname.com/RGS/Tournament/7196/1/en/B70A1F73.jpg" class="tournament-section-img"
                alt="">
            <div class="casino-game-tournament-list-footer">
                <div class="casino-game-tournament-info-bc">
                    <p class="casino-game-tournament-price-bc">₹ 436,790.84</p>
                    <h3 class="casino-game-tournament-title-bc">{{'Superstars'|translate}}</h3>
                    <div class="casino-game-tournament-join-block-bc">
                        <p class="casino-game-tournament-inner-info-bc">{{'Buy in + Fee: ₹ 0'|translate}}</p>
                        <div class="casino-game-tournament-time-block-bc"><time
                                class="casino-game-tournament-time-bc"><time class="">28.02.2023, 14:01</time></time><i
                                class="casino-game-tournament-arrow-bc bc-i-arrow-to"></i><time
                                class="casino-game-tournament-time-bc"><time class="">14.03.2023, 14:59</time></time>
                        </div>
                    </div>
                </div>
                <div class="casino-game-tournament-join-status-bc"><button class="btn a-color  null "
                        type="button">{{'JOIN'|translate}}</button></div>
            </div>
        </li>
    </ul>
</div>
