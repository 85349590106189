<div class="calendar-card max-width-550">
    <div class="rect-1"></div>
    <div class="rect-2"></div>
    <div class="calendar-header">
        <div class="header-box">
            <button (click)="handleLastDays(7)" class="day" [ngClass]="{'active':activeDays==7}"><span
                    class="sm-text white">{{'Last 7 Days'|translate}}</span></button>
            <button (click)="handleLastDays(14)" class="day" [ngClass]="{'active':activeDays==14}"><span
                    class="sm-text white">{{'Last 14 Days'|translate}}</span></button>
            <button (click)="handleLastDays(28)" class="day" [ngClass]="{'active':activeDays==28}"><span
                    class="sm-text white">{{'Last 28 Days'|translate}}</span></button>
        </div>
    </div>
    <div *ngIf="showCalender" class="calendar-card-body">
        <div class="calendar-card-header">
            <svg (click)="previousMonth()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none">
                <path d="M15 6L9 12L15 18" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
            <span (click)="openYears()" class="white-large-text">{{months[currentMonth]|translate}} {{year}}</span>
            <svg (click)="nextMonth()" xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 8 14"
                fill="none">
                <path d="M1 1L7 7L1 13" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </div>
        <div class="calendar-body">
            <div class="calendar-days">
                <ng-container *ngFor="let item of weekDays">
                    <button class="day-box"><span class="gray-tw-text">{{item}}</span></button>
                </ng-container>
            </div>

            <div class="calendar-number-body">
                <div class="first-row">
                    <ng-container *ngFor="let item of dates; let i=index">
                        <button class="day-box" (click)="selectDateFun(item)" [ngClass]="{
                          'background-blue': i >= indexOfFirstDay && i <= indexofLastDay,
                          'background-transperent-with-blue-border': isBetweenStartAndEnd(item),
                          'background-red': isStartDateOrEndDate(item),
                          'backgroundHover': i > indexOfSelectedStartDate && i < indexOfSelectedEndDate}"
                            [disabled]="dates[i] > currentDate"
                            [ngStyle]="{'cursor': dates[i] > currentDate ? 'not-allowed' : 'pointer'}">
                            <span
                                [ngClass]="{'white-tw-text':dates[i] <= currentDate, 'gray-tw-text':dates[i]>currentDate}">{{item?.getDate()}}</span>
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showYears" class="years-cards">
        <div *ngIf="!showMonthHeader" class="year-rang">
            <svg (click)="previosYears(years[0])" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none">
                <path d="M15 6L9 12L15 18" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
            <span>{{years[0]}}</span>
            <span>To</span>
            <span>{{years[years?.length-1]}}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 8 14" fill="none"
                [style.opacity]="years.includes(currentYear) ? '0.5' : '1'"
                [style.cursor]="years.includes(currentYear) ? 'not-allowed' : 'pointer'"
                (click)="years.includes(currentYear) ? null : nextYears(years[years?.length-1])">
                <path d="M1 1L7 7L1 13" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </div>
        <div class="calendar-number-body">
            <div class="first-row-1">
                <ng-container *ngFor="let item of years; let i=index">
                    <button class="day-box" [disabled]="years[i]>currentYear" (click)="openMonths(years[i])"
                        [ngClass]="{'active':item == year}">
                        <span [ngClass]="{'white-tw-text':item == year}"
                            [ngStyle]="{'cursor': years[i]>currentYear ? 'not-allowed' : 'pointer', 'opacity': years[i]>currentYear ? '0.7' : '1'}"
                            class="gray-tw-text">{{item}}</span>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="show-month" *ngIf="showMonthPopup">
        <div *ngIf="showMonthHeader" class="year-rang month">
            <span style="font-size: 1rem;">{{this.year}}</span>
            <span style="font-size: 1rem;">{{'Select Month'|translate}}</span>
        </div>
        <div class="calendar-number-body" style="padding: 0px;">
            <div class="first-row-1">
                <ng-container *ngFor="let item of months; let i=index">
                    <button class="day-box" (click)="setMonth(months[i])">
                        <span class="white-tw-text">
                          {{item|translate}}
                        </span>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</div>
