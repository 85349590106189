<div class="overlay" *ngIf="isLoader">
    <div class="loader-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>


    </div>
</div>

<div class="bet-slip-section" id="elementToScroll" [ngClass]="{'section-border-bottom':!isBack, 'close-slip':closeBet, 'open-slip':!closeBet}">
    <div class="bet-header-section">
        <div class="bet-input-section back" *ngIf="isBack">
            <span>{{"Profit"|translate}}</span>
            <small>₹ {{profitLoss || '0' | indianCurrency}} </small>
        </div>
        <div class="bet-input-section lay" *ngIf="!isBack">
            <span>{{'Liability'|translate}}</span>
            <small>₹ {{profitLoss || '0' | indianCurrency}}</small>
        </div>
        <div *ngIf="this.betSettings" class="max-bet-box">{{'Max Mkt:'|translate}}{{this.betSettings ? formatToIndianCurrency(this.betSettings?.maxWinPerMarket)
            : 0}}</div>
    </div>
    <div class="bet-add-card" [formGroup]="betForm">
        <div class="bet-odd-card" *ngIf="allowEditOdds">
            <span>{{'ODDS'|translate}}</span>
            <div class="plus-neg-card">
                <button (click)="increaseOdds('minus')" class="negative">-</button>
                <input formControlName="betOdds" class="input-box"
                    type="number" placeholder="0" [value]="matchData?.id?.endsWith('_SB') ? calculatePrice(betData?.bet): betData?.bet">
                <button (click)="increaseOdds('add')" class="positive">+</button>
            </div>
        </div>
        <div class="bet-odds-card" *ngIf="!allowEditOdds">
            <div class="bet-odds">
            <span>{{'ODDS'|translate}}</span>
            <div class="plus-neg-card">
                <input disabled class="input-box" [value]="matchData?.id?.endsWith('_SB') ? calculatePrice(betData?.bet): betData?.bet">
            </div>
        </div>
            <div class="bet-odds" *ngIf="!allowEditOdds && bType == 'LINE'">
            <span>{{'RUNS'|translate}}</span>
            <div class="plus-neg-card">
                <input disabled class="input-box" [value]="matchData?.runners?.[0][isBack ? 'back' : 'lay'][0]?.line">
            </div>
        </div>
        </div>
        <div class="bet-stake-card">
            <span>{{'STAKE'|translate}}</span>
            <div class="bet-card">
                <input formControlName="betStake" class="input-box" (change)="calculateProfitLoss()"
                    (input)="onInput($event)" inputmode="numeric" type="number"
                    placeholder="{{'Max'|translate}} {{this.betSettings ? formatToIndianCurrency(this.betSettings?.maxBet) : 0}}"
                    [maxLength]="15" [max]="this.betSettings?.maxBet">
            </div>
        </div>
    </div>
    <div class="amount-card">
        <button class="card" *ngFor="let stake of stakeArray" (click)="updateStake(stake)">+{{stake}}</button>
    </div>

    <div class="button-box">
        <button (click)="closeBetSlipCard()"  class="cancel-button">{{'Cancel'|translate}}</button>
        <button *ngIf="getToken()" (click)="isConfirmation()" class="login-button">
            {{'Place Bet'|translate}}
        </button>
        <button *ngIf="!getToken()" (click)="goToLogin()" class="login-button">
            {{'Login'|translate}}
        </button>
    </div>

    <div class="confirm-card">
        <span>{{'Confirm bet before placing'|translate}}</span>
        <div class="other-settings-switch">
            <div class="toggle">
                <input type="checkbox" [checked]="isConfirmEnabled" (change)="confirm()"/>
                <label></label>
            </div>
        </div>
    </div>
</div>


<!--cancel bet popup-->
<app-cancel-slip-popup class="Modal-Background max-width-550" (closeClick)="confirmPopup = false" *ngIf="confirmPopup">
    <div class="popup-section">
        <div class="popup-header">
            <span>{{matchData?.event?.name}}</span>
            <small class="back" [ngClass]="{'lay':!isBack}">{{isBack ? 'Backing': 'Laying'}} {{betData?.runnerName}} </small>
        </div>
        <div class="popup-card">
            <div class="popup-card-header back" [ngClass]="{'lay':!isBack}">
                <div class="odd-section"><span>{{'Odds'|translate}}</span></div>
                <div class="stake-sectin"><span>{{'Stake'|translate}}</span></div>
                <div *ngIf="!allowEditOdds && bType == 'LINE'" class="stake-sectin"><span>{{'Runs'|translate}}</span></div>
                <div class="profit-section"><span>{{isBack ? "Profit": "Liability"}}</span></div>
            </div>
            <div class="bet-card-histroy">
                <div class="odd-section"><span>{{this.betForm.value.betOdds}}</span></div>
                <div class="stake-sectin"><span>{{this.betForm.value.betStake}}</span></div>
                <div *ngIf="!allowEditOdds && bType == 'LINE'" class="stake-sectin"><span>{{matchData?.runners?.[0][isBack ? 'back' : 'lay'][0]?.line}}</span></div>
                <div class="profit-section"><span>{{profitLoss}}</span></div>
            </div>
        </div>
        <div class="button-box">
            <button (click)="confirmPopup = false" class="cancel-button">{{'Cancel'|translate}}</button>
            <button class="login-button" (click)="placeBet()">{{'Place Bet'|translate}}</button>
        </div>
    </div>
</app-cancel-slip-popup>
