import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'teamNameSplit'
})
export class TeamNameSplitPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    const splitRegex = / V | v | vs | VS | Vs | vS |v\.| vs\. | Vs\. | vS\. |vS\.| V\/S|v\/s|v\/s\.|V\/S\./;
    const team = {
      first: null,
      second: null
    }
    if (value) {
      const splitValue = value.split(splitRegex);
      if (splitValue.length > 1) {
          team.first = splitValue[0];
          team.second = splitValue[1];
        }
      return args[0] === 'first' && team.second != '' ? team.second : team.first;
    }
    return value;
  }

}
