<div class="box">
    <svg class="leftwall" width="62" height="355" viewBox="0 0 62 355" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M59 0.833496C60.4728 0.833496 61.6667 2.0274 61.6667 3.50016C61.6667 4.97292 60.4728 6.16683 59 6.16683C58.8736 6.16683 58.7492 6.15803 58.6275 6.14102C56.7243 5.87501 54.9892 4.00016 53.0676 4.00016L5 4.00017C2.79086 4.00017 1 5.79103 1 8.00017V346.5C1 348.709 2.79086 350.5 5 350.5H41.5C42.7152 350.5 43.3237 349.031 42.4645 348.172C42.2692 347.976 42.2692 347.66 42.4645 347.464C42.6597 347.269 42.9763 347.269 43.1716 347.464L46.3536 350.646C46.5488 350.842 46.5488 351.158 46.3536 351.354L43.1716 354.536C42.9763 354.731 42.6597 354.731 42.4645 354.536C42.2692 354.34 42.2692 354.024 42.4645 353.828C43.3237 352.969 42.7152 351.5 41.5 351.5H0.5C0.223858 351.5 0 351.276 0 351V350.5L1.51907e-05 3.47724C1.52022e-05 3.21367 0.213679 3 0.477246 3H0.999931C0.999976 3 1.00001 3.00004 1.00001 3.00008C1 3.00013 1.00004 3.00017 1.00008 3.00017L53.0676 3.00016C54.9892 3.00016 56.7243 1.12531 58.6275 0.859306C58.7492 0.842292 58.8736 0.833496 59 0.833496Z"
            fill="url(#paint0_linear_131_8909)" fill-opacity="0.2" />
        <defs>
            <linearGradient id="paint0_linear_131_8909" x1="-7.41731e-07" y1="29.7848" x2="69.3184" y2="31.4256"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#36E2FA" />
                <stop offset="1" stop-color="#EBFFBF" />
            </linearGradient>
        </defs>
    </svg>

    <svg class="rightwall" width="62" height="355" viewBox="0 0 62 355" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.66699 0.833496C1.19423 0.833496 0.000324249 2.0274 0.000324249 3.50016C0.000324249 4.97292 1.19423 6.16683 2.66699 6.16683C2.7934 6.16683 2.91776 6.15803 3.03949 6.14102C4.94266 5.87501 6.67775 4.00016 8.59941 4.00016L56.667 4.00017C58.8761 4.00017 60.667 5.79103 60.667 8.00017V346.5C60.667 348.709 58.8761 350.5 56.667 350.5H20.167C18.9518 350.5 18.3433 349.031 19.2025 348.172C19.3978 347.976 19.3978 347.66 19.2025 347.464C19.0073 347.269 18.6907 347.269 18.4954 347.464L15.3134 350.646C15.1182 350.842 15.1182 351.158 15.3134 351.354L18.4954 354.536C18.6907 354.731 19.0073 354.731 19.2025 354.536C19.3978 354.34 19.3978 354.024 19.2025 353.828C18.3433 352.969 18.9518 351.5 20.167 351.5H61.1671C61.4432 351.5 61.667 351.276 61.667 351V350.5L61.667 3.29249C61.667 3.13105 61.5361 3.00015 61.3747 3.00015H60.667C60.667 3.00015 60.667 3.00016 60.667 3.00016C60.667 3.00016 60.667 3.00017 60.667 3.00017L8.59941 3.00016C6.67775 3.00016 4.94266 1.12531 3.03949 0.859306C2.91776 0.842292 2.7934 0.833496 2.66699 0.833496Z"
            fill="url(#paint0_linear_131_8913)" fill-opacity="0.2" />
        <defs>
            <linearGradient id="paint0_linear_131_8913" x1="61.667" y1="29.7848" x2="-7.65145" y2="31.4256"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#36E2FA" />
                <stop offset="1" stop-color="#EBFFBF" />
            </linearGradient>
        </defs>
    </svg>
    <div class="matter">
        <ng-content></ng-content>
    </div>
</div>