import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-deposit-stepper',
  templateUrl: './deposit-stepper.component.html',
  styleUrls: ['./deposit-stepper.component.scss']
})
export class DepositStepperComponent implements OnInit {

  @Input() step = 1;

  constructor() { }

  ngOnInit(): void {
  }

}
