import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { catchError, filter, from, map, mergeMap, Observable, toArray } from 'rxjs';
import { Races, RacesResult } from 'src/app/models/races.interface';

import { AuthService } from '../../services/auth.service';
import { EventResult, EventTypes } from '../live/eventType.interface';
import { inPlayAndPopularEvents, InPlayEvent, InPlayResult } from '../live/inPlayAndPopularEvents.interface';
import { SamMenu, SmaResult } from '../live/samMenu.interface';

@Component({
  selector: 'app-sport',
  templateUrl: './sport.component.html',
  styleUrls: ['./sport.component.scss']
})
export class SportComponent implements OnInit, OnDestroy {
  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  // public aList: any = [];
  inPlayEvents$: Observable<InPlayEvent[]>;
  public sList: EventResult[] = [];
  istoken: any;
  showbet: any;
  public crickCount: number;
  public Id: number = 4;
  isLoader: boolean = false;
  public footballCount: number;  // for football count
  public tennisCount: number;    // for tennis count
  public horseCount: number;     // for horse count
  public ghorseCount: number;
  public isActiveCricket: boolean;  // for cricket
  public isActiveFootball: boolean;        // for football
  public isActiveTennis: boolean;          // for tennis
  public previousAList: any = [];          // for cricket
  public isActivehorse: boolean;           // for horse
  public isActivegreyhound: boolean;       // for greyhound
  public isActivetable: boolean;           // for table
  isActivePolitics: boolean;                // for politics
  public isActive: boolean;
  eventName: String;
  marketName: String;
  runner: string;
  price: string;
  public interval300: any;
  public interval3001: any;
  noUpcomingEvents: boolean = false;
  isUpcoming: boolean = true;
  upcomingEvents$: Observable<EventResult[][]>;
  upcomingRaces$: Observable<RacesResult[]>;
  matchSummaryData: SmaResult[] = [];  // for cricket, tennis and football only
  raceLeagues: RacesResult[][];        // for horse and greyhound racing only
  matchSummaryData$: Observable<SmaResult[]>;
  inPlayRaces:any;

  allSportOptArray = [
    {
      name: 'Cricket',
      img: '../../../../assets/images/menu-cricket.png',
      navigate: 'c'
    },
    {
      name: 'Tennis',
      img: '../../../../assets/images/menu-tennis.svg',
      navigate: 't'
    },
    {
      name: 'Football',
      img: '../../../../assets/images/menu-sports-option.svg',
      navigate: 'f'
    },
    {
      name: 'Horse Racing',
      img: '../../../../assets/images/menu-horse-racing.svg',
      navigate: 'h'
    },
    {
      name: 'Greyhound Racing',
      img: '../../../../assets/images/menu-greyhord-racing.svg',
      navigate: 'g'
    },
    {
      name: 'Basket Ball',
      img: '../../../../assets/images/menu-basketBall.svg',
      navigate: 'basketball-2'
    },
    {
      name: 'Table Tennis',
      img: '../../../../assets/images/menu-tableTennis.png',
      navigate: 'tennis-5'
    },
    {
      name: 'Kabaddi',
      img: '../../../../assets/images/menu-kabaddi.png',
      navigate: 'kabaddi-138'
    },
    {
      name: 'Rugby',
      img: '../../../../assets/images/menu-rugby.svg',
      navigate: 'rugby-12'
    },
    {
      name: 'Boxing',
      img: '../../../../assets/images/menu-boxing.svg',
      navigate: 'boxing-10'
    },
    {
      name: 'Formula1',
      img: '../../../../assets/images/menu-formula1.svg',
      navigate: 'formula-1-40'
    },
    {
      name: 'Golf',
      img: '../../../../assets/images/menu-golf.svg',
      navigate: 'golf-9'
    },
    {
      name: 'Baseball',
      img: '../../../../assets/images/menu-baseBall.png',
      navigate: 'baseball-3'
    },
    {
      name: 'FIFA',
      img: '../../../../assets/images/menu-sports-option.svg',
      navigate: 'fifa-300'
    },
    {
      name: 'Volleyball',
      img: '../../../../assets/images/menu-vollyBall.svg',
      navigate: 'volleyball-23'
    },
    {
      name: 'FIFA24',
      img: '../../../../assets/images/menu-sports-option.svg',
      navigate: 'penalty-shootout-307'
    },
    {
      name: 'E-Cricket',
      img: '../../../../assets/images/menu-eCricket.svg',
      navigate: 'ecricket-305'
    },
    {
      name: 'Ice Hockey',
      img: '../../../../assets/images/menu-iceHockey.png',
      navigate: 'ice-hockey-4'
    },
    {
      name: 'Handball',
      img: '../../../../assets/images/menu-HandBall.svg',
      navigate: 'handball-6'
    },
    {
      name: 'Cycling',
      img: '../../../../assets/images/menu-cycling.png',
      navigate: 'cycling-17'
    },
    {
      name: 'Darts',
      img: '../../../../assets/images/menu-darts.svg',
      navigate: 'darts-22'
    },
    {
      name: 'Snooker',
      img: '../../../../assets/images/menu-snooker.png',
      navigate: 'snooker-19'
    },
    {
      name: 'American Football',
      img: '../../../../assets/images/menu-americanFootball.svg',
      navigate: 'american-football-16'
    },
    {
      name: 'NBA2K',
      img: '../../../../assets/images/menu-nba2k19.svg',
      navigate: 'nba-2k-302'
    },
    {
      name: 'Waterpolo',
      img: '../../../../assets/images/menu-Waterpolo.png',
      navigate: 'waterpolo-26'
    },
  ];


  liveDotLottieOptions: any = {path: 'assets/static/media/live.json', autoplay: true, loop: true};

  constructor(public authService: AuthService, private router: Router) {
    if (this.authService.currentUserValue == null) {
      this.istoken = true;
    } else {
      this.istoken = false;
    }
  }

  ngOnInit(): void {
    if (this.authService.sportsId) {
      this.getUpdatedSportsId();
      this.getActiveMenu();
      this.getMatchSummery();
      this.getUpcomingData(this.authService.sportsId);
      this.getEventStateData(this.authService.sportsId);
    }
  }

  getUpdatedSportsId(){
    this.authService.sportsIdObservable$.subscribe(() => {
      this.getActiveMenu();
      this.getMatchSummery();
      this.getUpcomingData(this.authService.sportsId);
      this.getEventStateData(this.authService.sportsId);
    }) ;
  }

  getActiveMenu() {
    this.isActiveCricket = this.authService.sportsId == 4;
    this.isActiveFootball = this.authService.sportsId == 1;
    this.isActiveTennis = this.authService.sportsId == 2;
    this.isActivehorse = this.authService.sportsId == 7;
    this.isActivegreyhound = this.authService.sportsId == 4339;
    this.isActivePolitics = this.authService.sportsId == 2378962;
    if (!this.isActiveCricket && !this.isActiveFootball && !this.isActiveTennis && !this.isActivehorse && !this.isActivegreyhound && !this.isActivePolitics) {
      this.authService.sportsId = 4;
    }
  }

  getData(id) {
    clearInterval(this.interval300);
    this.isLoader = true;

    this.isActiveCricket = id === 4;
    this.isActiveFootball = id === 1;
    this.isActiveTennis = id === 2;
    this.isActivehorse = id === 7;
    this.isActivegreyhound = id === 4339;
    this.isActivetable =
      !(this.isActiveCricket || this.isActiveFootball ||
        this.isActiveTennis || this.isActivehorse || this.isActivegreyhound);

    this.isLoader = false;

    this.getUpcomingData(id);
    this.getEventStateData(id);
    this.getMatchSummery();
    this.authService.sportsId = id;
  }

  navigateToSportBook(item: string) {
    const sportIds = {
      f: 1,
      t: 2,
      c: 4,
      h: 7,
      g: 4339,
    };

    const containsNumber = /\d/.test(item);

    if (sportIds[item]) {
      this.isActiveCricket = false;
      this.isActiveFootball = false;
      this.isActiveTennis = false;
      this.isActivehorse = false;
      this.isActivegreyhound = false;

      switch (item) {
        case 'f':
          this.isActiveFootball = true;
          this.authService.sportsId = 1;
          break;
        case 't':
          this.isActiveTennis = true;
          this.authService.sportsId = 2;
          break;
        case 'c':
          this.isActiveCricket = true;
          this.authService.sportsId = 4;
          break;
        case 'h':
          this.isActivehorse = true;
          this.authService.sportsId = 7;
          break;
        case 'g':
          this.isActivegreyhound = true;
          this.authService.sportsId = 4339;
          break;
      }

      this.getData(sportIds[item]);
    } else {
      this.router.navigate([containsNumber ? '/shared/sportsbook' : item]);
      localStorage.setItem('gameId', item);
    }
    //set default tab for upcoming
    this.toggleUpcoming(true);
  }

  openbetpop(item, Id) {
    this.eventName = item.event.name;
    this.marketName = item.name;
    if (Id == 0) {
      this.price = item?.runners?.[0]?.back?.[0]?.price;
    } else if (Id == 1) {
      this.price = item?.runners?.[1]?.back?.[0]?.price;
    } else {
      this.price = item?.runners?.[2]?.back?.[0]?.price;
    }
    if (!this.getToken() && !this.showbet && this.price != undefined) {
      this.showbet = true;
    } else if (!this.getToken() && this.showbet) {
      this.showbet = true;
      const doc =
        <HTMLElement>document.getElementsByClassName('betplacedetail')[0];
      doc.style.display = 'block';
    } else {
      this.router.navigate(
        ['/shared/details/' + item?.event.id + '/' + item?.eventTypeId]);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval300);
  }

  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }

  trackByFn(index: number, item: any): any {
    return item?.market?.id;
  }


  getUpcomingData(eventTypeId: string |
    number) {  // endpoint: exchange/odds/inPlayAndPopularEvents
    // this.isLoader = true;  // pipe(map((res: any) =>
    // res.result.inPlayEvents));
    // if eventTypeId is 4 or 1 or 2 then call getUpcomingdata() else call
    // getHorseandGreyRacing();
    this.isLoader = true;
    if (eventTypeId == 4 || eventTypeId == 1 || eventTypeId == 2) {
      this.upcomingEvents$ =
        this.authService.getUpcomingdata(eventTypeId)
          .pipe(  // get all upcoming matches data with eventTypeId
            map((res: EventTypes) =>
              res.result),  // get all popularEvents
            map((result: EventResult[]) => result.filter(
              event =>
                event.eventTypeId == this.authService.sportsId &&
                event.inPlay == false)),
            map(event => {
              this.noUpcomingEvents = event.length === 0;
              this.isUpcoming = event.length ? true : false;
              return event.length ? event : []
            }),
            map(events => this.groupEventsByDate(events)),
            catchError(error => {
              console.error('Error fetching data: ', error);
              // Return an empty array or an observable with a default
              // value
              return ([
              ]);  // You can use throwError() for custom error handling
            }));
      this.isLoader = false;
    } else {
      this.upcomingRaces$ =
        this.authService.getHorseandGreyRacing(eventTypeId)
          .pipe(  // get all upcoming matches data with eventTypeId
            map((res: Races) => {
              //get isBettable items
              this.inPlayRaces = res.result.filter(event => event.isBettable);
              return res.result
            }),  // get all popularEvents
            catchError(error => {
              console.error('Error fetching data: ', error);
              return ([]);
            }));
      this.upcomingRaces$?.subscribe(res => {
        this.raceLeagues = this.getRaceLeagues(res);
      });
      this.isLoader = false;
    }
    this.isLoader = false;
  }

  getRaceLeagues(leagues: RacesResult[]): RacesResult[][] {
    this.isLoader = true;
    const groupedLeagues = {};

    leagues.forEach(league => {
      const leagueKey = league['countryCode'];

      if (!groupedLeagues[leagueKey]) {
        groupedLeagues[leagueKey] = [];
      }
      groupedLeagues[leagueKey].push(league);
    })
    this.isLoader = false;
    return Object.values(groupedLeagues);
  }



  /**
   * ### Groups the events by same date
   * @param events: EventResult[]
   * @returns EventResult[]
   */
  groupEventsByDate(events: EventResult[]): EventResult[][] {
    this.isLoader = true;
    const groupedEvents = {};

    events.forEach(event => {
      const openDate = new Date(event.event.openDate);
      const dateKey =
        openDate.toISOString().split('T')[0];  // Extract the date portion

      if (!groupedEvents[dateKey]) {
        groupedEvents[dateKey] = [];
      }
      groupedEvents[dateKey].push(event);
    });
    // Convert the grouped events object into an array of arrays
    this.isLoader = false;
    return Object.values(groupedEvents);
  }

  getEventData() {
    this.isLoader = true;
    this.authService.geteventdata().subscribe();
    if (this.interval300) {
      clearInterval(this.interval300);
      this.isLoader = false;
    }
    if (this.getToken()) {
      this.interval300 = setTimeout(() => {
        this.getEventData();
        this.isLoader = false;
      }, 1000)
    } else {
      this.interval300 = setTimeout(() => {
        this.getEventData();
        this.isLoader = false;
      }, 10000)
    }
    this.isLoader = false;
  }

  getEventStateData(id: string) {
    this.isLoader = true;
    this.inPlayEvents$ = this.authService.getEventState().pipe(
      map((res: inPlayAndPopularEvents) => res?.result?.inPlayEvents),
      mergeMap(
        (result: InPlayEvent[]) => from(result).pipe(
          filter(
            event => event?.eventTypeId == id && event?.isInPlay == true),
          toArray())));
    this.isLoader = false;
  }



  getMatchDate(date: any) {
    return moment(date).fromNow();
  }

  shortitembysports(sportsid: any) {
    this.isLoader = true;
    this.isActiveCricket = sportsid === 4;
    this.isActiveFootball = sportsid === 1;
    this.isActiveTennis = sportsid === 2;
    this.isActivehorse = sportsid === 7;
    this.isActivegreyhound = sportsid === 4339;

    clearInterval(this.interval300);
    this.getEventData();

    if (this.getToken()) {
      this.interval300 = setTimeout(() => {
        this.getEventData();
      }, 1000);
    } else {
      this.interval300 = setTimeout(() => {
        this.getEventData();
      }, 10000);
    }

    this.isLoader = false;
  }

  toggleUpcoming(bool: boolean) {
    this.isUpcoming = bool;
  }

  /**
   * ### Get all matches summary with children
   * @returns void
   * @memberof LiveComponent
   * @description
   * 1. Get all matches summary with children
   * 2. Store the data in matchSummeryData
   * 3. Set isLoader to false
   * 4. Subscribe to getMatchSummeryData() to get the latest data
   */
  getMatchSummery() {
    this.isLoader = true;
    this.matchSummaryData$ = this.authService.getMatchSummeryData().pipe(
      map((res: SamMenu) => res?.result),
      map(res => res.filter(
        (data: SmaResult) => data?.id == this.authService?.sportsId)))
   this.isLoader = false;
  }
  
  setDetailspageData(item: any) {
    this.authService.updateDetailsPageData(item);
  }
}