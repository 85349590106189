<!-- <div class="card bonus-card orange-card">
    <div class="card-body">
        <div class="flexbox1">
            <div class="txt">
                <p class="titletxt"> {{this.bonus.promoDetails['short-info']}}
                    <img loading="lazy" class="active-tag" src="../../../../assets/images/activeTag.svg">
                </p>
                <div class="boxssd" style="display: flex;justify-content: space-between;">
                    <p class="pull-right">Wagering</p>
                    <p class="pull-left">₹{{remainWagered | indianCurrency }}</p>
                </div>
                <div class="progressbar">
                    <div class="progressbg" [style.width.%]="percent">
                        <div class="progressdot"></div>
                    </div>
                </div>
                <div class="boxssd" style="display: flex;justify-content: space-between;">
                    <p class="pull-right">₹{{this.bonus.wagered?.claimAmount | indianCurrency}} </p>
                    <p class="pull-left bigx"> <img loading="lazy" style="height: 14px;" src="../../../../assets/images/fi_7420538.svg" alt=""> {{remainDays}}<small>d</small> : {{remainHours}}<small>hr</small></p>
                </div>
            </div>
            <div class="gift">
                <img loading="lazy" class="giftbox" src="../../../assets/images/goldgiftbox.png">
                <p class="claimbtn" (click)="showHowTo()" >How to claim?</p>
            </div>
        </div>
    </div>
</div> -->

<div class="card">
    <div class="card-body">
        <div class="flexbox1">
            <div class="txt">
                <!-- <p class=""> </p> -->
                <!-- <p style="display: flex;"> {{this.bonus.promoDetails['short-info']}} <span class="badge rounded-pill success-bg">Active</span> </p> -->
                <p class="titletxt"> {{this.bonus.promoDetails['short-info']}}
                    <img loading="lazy" class="active-tag" src="../../../../assets/images/activeTag.svg">
                </p>
                <div class="boxssd" style="display: flex;justify-content: space-between;">
                    <p class="pull-right">{{'Wagering'|translate}}</p>
                    <!-- <p class="pull-left">{{wageredCap| currency : 'INR'}}</p> -->
                    <p class="pull-left"> ₹{{remainWagered | indianCurrency }}</p>
                </div>
                <div class="progressbar">
                    <div class="progressbg" [style.width.%]="percent">
                        <div class="progressdot"></div>
                    </div>
                </div>
                <div class="boxssd" style="display: flex;justify-content: space-between;">
                    <p class="pull-right">₹{{this.bonus.wagered?.claimAmount | indianCurrency}} </p>
                    <p class="pull-left bigx"> <img loading="lazy" style="height: 14px;" src="../../../../assets/images/fi_7420538.svg" alt=""> {{remainDays}}d {{remainHours}}hr</p>
                </div>
            </div>
            <div class="gift">
                <img loading="lazy" class="giftbox" src="../../../assets/images/goldgiftbox.png">
            </div>
            <button class="btn claimbtn " (click)="showHowTo()" >{{'How to claim?'|translate}}</button>
        </div>
    </div>
</div>
