<div class="layout-content-holder-bc">
    <div class="live-calendar">
        <div class="">
            <div class="live-calendar-filters-wrapper">
                <div class="multi-select-bc">
                    <div class="form-control-bc filled valid">
                        <div class="form-control-label-bc inputs">
                            <div class="form-control-select-bc">{{'Cricket'|translate}}</div><i
                                class="form-control-icon-bc bc-i-small-arrow-down"></i><i
                                class="form-control-input-stroke-bc"></i><span
                                class="form-control-title-bc ellipsis">{{'Sport'|translate}}</span>
                        </div>
                    </div>
                </div>
                <div class="time-filters-bc">
                    <ul class="time-filters-content">
                        <li class="time-filter-button-bc btn s-small a-outline a-white ">{{'15.03 Wed'|translate}}</li>
                        <li class="time-filter-button-bc btn s-small a-outline  ">{{'16.03 Thu'|translate}}</li>
                        <li class="time-filter-button-bc btn s-small a-outline  ">{{'17.03 Fri'|translate}}</li>
                        <li class="time-filter-button-bc btn s-small a-outline  ">{{'18.03 Sat'|translate}}</li>
                        <li class="time-filter-button-bc btn s-small a-outline  ">{{'19.03 Sun'|translate}}</li>
                        <li class="time-filter-button-bc btn s-small a-outline  ">{{'20.03 Mon'|translate}}</li>
                        <li class="time-filter-button-bc btn s-small a-outline  ">{{'21.03 Tue'|translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="live-calendar-market-scroll">
            <div class="live-calendar-market-bc">
                <div class="market-filter-bc">
                    <div class="market-filter-s-bc">
                        <div class="form-control-bc select s-small filled"><label
                                class="form-control-label-bc inputs"><select class="form-control-select-bc ellipsis">
                                    <option value="WINNER">{{'WINNER'|translate}}</option>
                                    <option value="HANDICAP">{{'HANDICAP'|translate}}</option>
                                    <option value="TOTALS">{{'TOTALS'|translate}}</option>
                                </select><i class="form-control-icon-bc bc-i-small-arrow-down"></i></label></div>
                    </div>
                    <div class="market-filter-cell-bc">
                        <div class="market-filter-info-bc">{{'W1'|translate}}</div>
                        <div class="market-filter-info-bc">{{'W2'|translate}}</div>
                    </div>
                </div>
                <div data-virtuoso-scroller="true" style="position: relative; height: 420px;">
                    <div data-viewport-type="window" style="width: 100%; height: 100%; position: absolute; top: 0px;">
                        <div data-test-id="virtuoso-item-list"
                            style="box-sizing: border-box; padding-top: 0px; padding-bottom: 0px; margin-top: 0px;">
                            <div data-index="0" data-known-size="84" data-item-index="0" style="overflow-anchor: none;">
                                <div class="c-segment-holder-bc single-g-info-bc">
                                    <div class="sw-container-bc " style="transform: translateX(0px);">
                                        <div class="c-segment-holder-bc markets ">
                                            <div class="c-row-bc bordered">
                                                <div class="c-teams-bc">
                                                    <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                            title="UP Warriorz (Wom)">{{'UP Warriorz (Wom)'|translate}}</span></div>
                                                    <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                            title="Royal Challengers Bangalore (Wom)">{{'Royal Challengers Bangalore (Wom)'|translate}}</span></div>
                                                </div>
                                                <div class="c-odd-bc">
                                                    <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                                        <div class="market-coefficient-bc">
                                                            <!-- <i
                                                                class="market-icon-bc bc-i-boost"></i> -->
                                                            <span class="market-odd-bc">1.67</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="c-odd-bc">
                                                    <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                                        <div class="market-coefficient-bc">
                                                            <!-- <i
                                                                class="market-icon-bc bc-i-boost"></i> -->
                                                            <span class="market-odd-bc">2.20</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="c-segment-holder-bc info">
                                            <div class="c-info-bc">
                                                <div class="c-info-content-bc"><i
                                                        class="bc-i-Cricket game-d-c-h-c-r-sport-icon"></i><i
                                                        class="info-live-prematch-icon-bc bc-i-prematch"></i><time
                                                        class="c-info-time-bc">09:00</time>
                                                    <div class="c-info-score-wrapper"></div>
                                                </div>
                                                <div class="c-info-more-bc"><i
                                                        class="info-game-flag-bc flag-bc india"></i><span
                                                        class="c-info-markets-count-bc">+92</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                            class="c-info-icon-bc bc-i-favorite"></i></div>
                                </div>
                            </div>
                            <div data-index="1" data-known-size="84" data-item-index="1" style="overflow-anchor: none;">
                                <div class="c-segment-holder-bc single-g-info-bc">
                                    <div class="sw-container-bc " style="transform: translateX(0px);">
                                        <div class="c-segment-holder-bc markets ">
                                            <div class="c-row-bc bordered">
                                                <div class="c-teams-bc">
                                                    <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                            title="Lahore Qalandars">{{'Lahore Qalandars'|translate}}</span></div>
                                                    <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                            title="Multan Sultans">{{'Multan Sultans'|translate}}</span></div>
                                                </div>
                                                <div class="c-odd-bc">
                                                    <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                                        <div class="market-coefficient-bc"><span
                                                                class="market-odd-bc">1.75</span></div>
                                                    </div>
                                                </div>
                                                <div class="c-odd-bc">
                                                    <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                                        <div class="market-coefficient-bc"><span
                                                                class="market-odd-bc">2.00</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="c-segment-holder-bc info">
                                            <div class="c-info-bc">
                                                <div class="c-info-content-bc"><i
                                                        class="bc-i-Cricket game-d-c-h-c-r-sport-icon"></i><i
                                                        class="info-live-prematch-icon-bc bc-i-prematch"></i><time
                                                        class="c-info-time-bc">09:00</time>
                                                    <div class="c-info-score-wrapper"></div>
                                                </div>
                                                <div class="c-info-more-bc"><i
                                                        class="info-game-flag-bc flag-bc pakistan"></i><span
                                                        class="c-info-markets-count-bc">+96</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                            class="c-info-icon-bc bc-i-favorite"></i></div>
                                </div>
                            </div>
                            <div data-index="2" data-known-size="84" data-item-index="2" style="overflow-anchor: none;">
                                <div class="c-segment-holder-bc single-g-info-bc">
                                    <div class="sw-container-bc " style="transform: translateX(0px);">
                                        <div class="c-segment-holder-bc markets ">
                                            <div class="c-row-bc bordered">
                                                <div class="c-teams-bc">
                                                    <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                            title="Karwan CC">{{'Karwan CC'|translate}}</span></div>
                                                    <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                            title="Interglobe Marine">{{'Interglobe Marine'|translate}}</span></div>
                                                </div>
                                                <div class="c-odd-bc">
                                                    <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                                        <div class="market-coefficient-bc"><span
                                                                class="market-odd-bc">1.85</span></div>
                                                    </div>
                                                </div>
                                                <div class="c-odd-bc">
                                                    <div class="sgm-market-g-i-cell-bc market-bc " title="">
                                                        <div class="market-coefficient-bc"><span
                                                                class="market-odd-bc">1.85</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="c-segment-holder-bc info">
                                            <div class="c-info-bc">
                                                <div class="c-info-content-bc"><i
                                                        class="bc-i-Cricket game-d-c-h-c-r-sport-icon"></i><i
                                                        class="info-live-prematch-icon-bc bc-i-prematch"></i><time
                                                        class="c-info-time-bc">12:45</time>
                                                    <div class="c-info-score-wrapper"></div>
                                                </div>
                                                <div class="c-info-more-bc"><i
                                                        class="info-game-flag-bc flag-bc uae"></i><span
                                                        class="c-info-markets-count-bc">+1</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                            class="c-info-icon-bc bc-i-favorite"></i></div>
                                </div>
                            </div>
                            <div data-index="3" data-known-size="84" data-item-index="3" style="overflow-anchor: none;">
                                <div class="c-segment-holder-bc single-g-info-bc">
                                    <div class="sw-container-bc " style="transform: translateX(0px);">
                                        <div class="c-segment-holder-bc markets ">
                                            <div class="c-row-bc bordered">
                                                <div class="c-teams-bc">
                                                    <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                            title="ANZAC">{{'ANZAC'|translate}}</span></div>
                                                    <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                            title="Papua New Guinea">{{'Papua New Guinea'|translate}}</span></div>
                                                </div>
                                                <div class="c-odd-bc">
                                                    <div class="sgm-market-g-i-cell-bc market-bc empty-market-bc">
                                                        <div class="market-coefficient-bc"><span
                                                                class="market-odd-bc">-</span></div>
                                                    </div>
                                                </div>
                                                <div class="c-odd-bc">
                                                    <div class="sgm-market-g-i-cell-bc market-bc empty-market-bc">
                                                        <div class="market-coefficient-bc"><span
                                                                class="market-odd-bc">-</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="c-segment-holder-bc info">
                                            <div class="c-info-bc">
                                                <div class="c-info-content-bc"><i
                                                        class="bc-i-Cricket game-d-c-h-c-r-sport-icon"></i><i
                                                        class="info-live-prematch-icon-bc bc-i-prematch"></i><time
                                                        class="c-info-time-bc">17:00</time>
                                                    <div class="c-info-score-wrapper"></div>
                                                </div>
                                                <div class="c-info-more-bc"><i
                                                        class="info-game-flag-bc flag-bc world"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                            class="c-info-icon-bc bc-i-favorite"></i></div>
                                </div>
                            </div>
                            <div data-index="4" data-known-size="84" data-item-index="4" style="overflow-anchor: none;">
                                <div class="c-segment-holder-bc single-g-info-bc">
                                    <div class="sw-container-bc " style="transform: translateX(0px);">
                                        <div class="c-segment-holder-bc markets ">
                                            <div class="c-row-bc bordered">
                                                <div class="c-teams-bc">
                                                    <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                            title="Fiji">{{'Fiji'|translate}}</span></div>
                                                    <div class="c-teams-row-bc"><span class="c-team-info-team-bc team"
                                                            title="Vanuatu">{{'Vanuatu'|translate}}</span></div>
                                                </div>
                                                <div class="c-odd-bc">
                                                    <div class="sgm-market-g-i-cell-bc market-bc empty-market-bc">
                                                        <div class="market-coefficient-bc"><span
                                                                class="market-odd-bc">-</span></div>
                                                    </div>
                                                </div>
                                                <div class="c-odd-bc">
                                                    <div class="sgm-market-g-i-cell-bc market-bc empty-market-bc">
                                                        <div class="market-coefficient-bc"><span
                                                                class="market-odd-bc">-</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="c-segment-holder-bc info">
                                            <div class="c-info-bc">
                                                <div class="c-info-content-bc"><i
                                                        class="bc-i-Cricket game-d-c-h-c-r-sport-icon"></i><i
                                                        class="info-live-prematch-icon-bc bc-i-prematch"></i><time
                                                        class="c-info-time-bc">21:00</time>
                                                    <div class="c-info-score-wrapper"></div>
                                                </div>
                                                <div class="c-info-more-bc"><i
                                                        class="info-game-flag-bc flag-bc world"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="swipe-fav-container-bc " style="transform: translateX(60px);"><i
                                            class="c-info-icon-bc bc-i-favorite"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>