<!-- spinning loader -->
<div class="overlay" *ngIf="isLoader">
  <div class="loader-center">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <!-- <img loading="lazy" class="loader1" src="../../../assets/images/loader-gif.gif" alt="Your Image"> -->
    <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>


  </div>
</div>
<!-- spinning loader -->

<div class="versionPopUp Modal-Background max-width-550">
  <div class="versionPopUp__content">
    <div class="rectangle-1"></div>
    <div class="rectangle-2"></div>
    <div class="versionPopUp__header">
      <div (click)="closePopup()">
        <span class="close-icon">&times;</span>
      </div>
    </div>
      <div class="versionPopUp__body">
      <img src="../../../assets/images/version-popup-img.svg" alt="">
      <div class="versionPopup-text">
        <div class="versionPopUp__body-title">
          <span>{{'New Version Available'|translate}}</span>
          <small>{{'Update to experience exciting new feature!'|translate}}</small>
        </div>
        <div class="versionPopUp__body_button" (click)="update()">
          <span>{{'Update'|translate}}</span>
          <!-- <img loading="lazy" src="../../../assets/images/img/arrowRight.svg" alt="update"> -->
        </div>
        </div>
      </div>
  </div>
</div>
