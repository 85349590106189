import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, empty, finalize, Observable, retry, shareReplay, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { RequestTrackerService } from "./request-tracker.service";
import { Balance } from './balance.interface';
@Injectable({
  providedIn: 'root',
})
export class MyprofileService {
  personaldetailsdta = new Subject();
  ChangePassword = new Subject();
  verifyaccount = new Subject();
  authentication = new Subject();
  depositshowes = new Subject();
  withdrawshowes = new Subject();
  balanceamoutinheader = new Subject();
  sportsbook = new BehaviorSubject(false)
  balanceshow = new Subject();
  private bonusDetailedInfo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public transactioReports: any;
  public dataUpdated$ = new Subject<void>();

  API_URL = environment.API_Event_URL;
  membercode: any = localStorage.getItem('Member_code');
  showFooter: boolean = true;
  totalBetCount: any = 0;
  openMenu: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  openMenu$ = this.openMenu.asObservable();
  activeMenuItem: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  activeMenuItem$ = this.activeMenuItem.asObservable();
  isHeaderFixed: boolean = false; //for fixing header on some pages dynamically
  public lastActivityDate = 0; //last activity data of user in milliseconds (used for session timeout)


  balanceapi = 'account/' + this.membercode + '/balance';
  memberurl = 'account/' + this.membercode + '/reports/statement?from=1675189800000&to=1677522600000';
  memberdetails = 'account/' + this.membercode + '/order-list?';
  bethistoryulr = 'account/' + this.membercode + '/order-list?';
  betpandl = 'account/' + this.membercode + '/reports/pnl?from=1678713509393&to=1679404709393';
  changpswrd = 'account/' + this.membercode + '/settings/password';
  // token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJDb2RlIjoiVDEwMTAxMDFNME0iLCJ0b2tlbklkIjoiMWEzNjRlZTFlOTBmYTJhM2M3NmJiOWQzOTExMzc0OTIxMDc3Y2QzMGEzYjdkNzM2Y2E3ODgxYTk5N2NkYmE3YSIsImxvZ2luQ291bnRyeSI6IklOIiwic2Vzc2lvbklkIjoiOTUyODFhNmE0NjBjZmI1MzljYmEyZGY1NWU0MTdlYzYxOWZiNTk2ZDZhZjEzMDFmNjhjZTQ3YTlhN2YwZTc3NCIsImFsbG93U2hha3RpUHJvIjpmYWxzZSwibGFzdExvZ2luVGltZSI6MTY3OTQ4MTA4OTA2OCwibmJmIjoxNjc5NTQ5NzQ2LCJsb2dpbk5hbWUiOiI5MV8xMjM0NTY3ODk5IiwibG9naW5JUCI6IjIwMi4xNDkuMjA0LjcyIiwidGhlbWUiOiJsb3R1cy1lIiwiZXhwIjoxNjc5NTg1NzQ2LCJpYXQiOjE2Nzk1NDk3NDYsIm1lbWJlcklkIjo3Mzg4MTMsInVwbGluZXMiOnsiQ09ZIjp7InVzZXJJZCI6MSwidXNlckNvZGUiOiJhZG1pbi51c2VyIn0sIlNNQSI6eyJ1c2VySWQiOjY1NTU4MiwidXNlckNvZGUiOiJUMTAxIn0sIk1BIjp7InVzZXJJZCI6NjU1NTgzLCJ1c2VyQ29kZSI6IlQxMDEwMSJ9LCJBZ2VudCI6eyJ1c2VySWQiOjY1NTU4NCwidXNlckNvZGUiOiJUMTAxMDEwMSJ9fSwiY3VycmVuY3kiOiJJTlIiLCJpc0RlbW8iOmZhbHNlLCJtYSI6IlkiLCJiIjp7ImlkIjo3Mzg4MTQsIm1jIjoiVDEwMjAxMDFNME0iLCJsbiI6IjkxXzEyMzQ1Njc4OTlfQiJ9LCJzIjp7ImlkIjoxODc0NTQ4LCJtYyI6IlQxMDMwMTAxTTBNIiwibG4iOiI5MV8xMjM0NTY3ODk5X1MifSwiYyI6bnVsbH0.T016t-0pW4lKK2SEVm4TZoVNTkCVR6VkoZmvS880OV4';
  private balanceDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private router: Router, private requestTracker: RequestTrackerService) { }


  checkLanguage() {
    if (localStorage.getItem("lang")) {
      if (
        localStorage.getItem("lang") === "ta" ||
        localStorage.getItem("lang") === "kn" ||
        localStorage.getItem("lang") === "ml"
      ) {
        return true;
      }
      return false;
    }
    return false;
  }
  updateTransactionReports(newData: any[]) {
    this.transactioReports = newData;
    this.dataUpdated$.next();
  }

  updateMenuStatus(value: boolean) {
    this.openMenu.next(value);
  }

  updateActiveMenuItem(value: string) {
    this.activeMenuItem.next(value);
  }

  accountstatement(fromDate = 0, toDate = 0): Observable<any> {

    var dates = new Date()
    var currentdate = Number(dates);
    let previousdatelastweek = currentdate - 7 * 24 * 60 * 60 * 1000

    return this.http.get<any>(this.API_URL + 'account/' + this.membercode + '/reports/statement?from=' + `${fromDate ? fromDate : previousdatelastweek}` + '&to=' + `${toDate ? toDate : currentdate}`)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }



  accountstatementsdetails(markid: any): Observable<any> {


    return this.http.get<any>(this.API_URL + this.memberdetails, {
      params: {
        orderStatus: 'settled',
        marketId: markid
      }
    },)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }

  wageringDetails(): Observable<any> {


    return this.http.get<any>(this.API_URL + 'account/wagering-details?walletType=S')
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }

  accountstatementsdetailsCasino(markid: any): Observable<any> {


    return this.http.get<any>(this.API_URL + 'casino/live/session/bet-history?sessionId=' + markid)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }



  accountstatements(val: any): Observable<any> {


    return this.http.get<any>(this.API_URL + this.memberdetails, {
      params: {
        orderStatus: 'settled',
        marketId: val
      }
    },)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }


  betHistory(eventId: any): Observable<any> {

    //  let Token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJDb2RlIjoiVDEwMTAxMDFNME0iLCJ0b2tlbklkIjoiZWU4YjkwOGEyODYzOGM0Zjg2Y2FkOGE4ZDIxZTBkOTU3ZjY1NmIxNzZkMjRiMjA1Y2JkNWNhZmIwYjcxOTIxYiIsImxvZ2luQ291bnRyeSI6IklOIiwic2Vzc2lvbklkIjoiZDA2ZDZjMTFlMTFiMDYwZGNlYWI1NDkzMjNjZDUzZjdhZjBiN2E1YTVkZDI2NGQ4MzlhZmI4MDc2OTdlNWFmNCIsImFsbG93U2hha3RpUHJvIjpmYWxzZSwibGFzdExvZ2luVGltZSI6MTY3OTIzODEwMjQ1NywibmJmIjoxNjc5MjM4NTU0LCJsb2dpbk5hbWUiOiI5MV8xMjM0NTY3ODk5IiwibG9naW5JUCI6IjIwMi4xNDkuMjA0LjcyIiwidGhlbWUiOiJsb3R1cy1lIiwiZXhwIjoxNjc5Mjc0NTU0LCJpYXQiOjE2NzkyMzg1NTQsIm1lbWJlcklkIjo3Mzg4MTMsInVwbGluZXMiOnsiQ09ZIjp7InVzZXJJZCI6MSwidXNlckNvZGUiOiJhZG1pbi51c2VyIn0sIlNNQSI6eyJ1c2VySWQiOjY1NTU4MiwidXNlckNvZGUiOiJUMTAxIn0sIk1BIjp7InVzZXJJZCI6NjU1NTgzLCJ1c2VyQ29kZSI6IlQxMDEwMSJ9LCJBZ2VudCI6eyJ1c2VySWQiOjY1NTU4NCwidXNlckNvZGUiOiJUMTAxMDEwMSJ9fSwiY3VycmVuY3kiOiJJTlIiLCJpc0RlbW8iOmZhbHNlLCJtYSI6IlkiLCJiIjp7ImlkIjo3Mzg4MTQsIm1jIjoiVDEwMjAxMDFNME0iLCJsbiI6IjkxXzEyMzQ1Njc4OTlfQiJ9LCJzIjp7ImlkIjoxODc0NTQ4LCJtYyI6IlQxMDMwMTAxTTBNIiwibG4iOiI5MV8xMjM0NTY3ODk5X1MifSwiYyI6bnVsbH0.WQS1xS1SVu_PYTBL5ne_bypJKh0AmTDkZqpVuEhncas';
    //   let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*',
    //   'content-type': 'application/json',
    //   'Authorization':this.token,

    //  });

    if (eventId !== null) {
      return this.http.get<any>(this.API_URL + this.bethistoryulr, { params: { orderStatus: 'open', eventId: eventId } })
        .pipe(
          retry(0),
          tap(res => {

            // if ([444, 403].indexOf(res.code) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            // }
            return res;
          }),
          shareReplay()
        );
    } else {
      return this.http.get<any>(this.API_URL + this.bethistoryulr, { params: { orderStatus: 'open' } })
        .pipe(
          retry(0),
          tap(res => {

            // if ([444, 403].indexOf(res.code) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            // }
            return res;
          }),
          shareReplay()
        );
    }
  }

  betHistoryCount(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'account/' + this.membercode + '/order-count',)
      .pipe(
        retry(0),
        tap(res => {
          return res;
        }),
        shareReplay()
      );
  }



  transactionreport(): Observable<any> {
    let transurl: any = 'payments/userid/txns'
    //  let Token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJDb2RlIjoiVDEwMTAxMDFNME0iLCJ0b2tlbklkIjoiZWU4YjkwOGEyODYzOGM0Zjg2Y2FkOGE4ZDIxZTBkOTU3ZjY1NmIxNzZkMjRiMjA1Y2JkNWNhZmIwYjcxOTIxYiIsImxvZ2luQ291bnRyeSI6IklOIiwic2Vzc2lvbklkIjoiZDA2ZDZjMTFlMTFiMDYwZGNlYWI1NDkzMjNjZDUzZjdhZjBiN2E1YTVkZDI2NGQ4MzlhZmI4MDc2OTdlNWFmNCIsImFsbG93U2hha3RpUHJvIjpmYWxzZSwibGFzdExvZ2luVGltZSI6MTY3OTIzODEwMjQ1NywibmJmIjoxNjc5MjM4NTU0LCJsb2dpbk5hbWUiOiI5MV8xMjM0NTY3ODk5IiwibG9naW5JUCI6IjIwMi4xNDkuMjA0LjcyIiwidGhlbWUiOiJsb3R1cy1lIiwiZXhwIjoxNjc5Mjc0NTU0LCJpYXQiOjE2NzkyMzg1NTQsIm1lbWJlcklkIjo3Mzg4MTMsInVwbGluZXMiOnsiQ09ZIjp7InVzZXJJZCI6MSwidXNlckNvZGUiOiJhZG1pbi51c2VyIn0sIlNNQSI6eyJ1c2VySWQiOjY1NTU4MiwidXNlckNvZGUiOiJUMTAxIn0sIk1BIjp7InVzZXJJZCI6NjU1NTgzLCJ1c2VyQ29kZSI6IlQxMDEwMSJ9LCJBZ2VudCI6eyJ1c2VySWQiOjY1NTU4NCwidXNlckNvZGUiOiJUMTAxMDEwMSJ9fSwiY3VycmVuY3kiOiJJTlIiLCJpc0RlbW8iOmZhbHNlLCJtYSI6IlkiLCJiIjp7ImlkIjo3Mzg4MTQsIm1jIjoiVDEwMjAxMDFNME0iLCJsbiI6IjkxXzEyMzQ1Njc4OTlfQiJ9LCJzIjp7ImlkIjoxODc0NTQ4LCJtYyI6IlQxMDMwMTAxTTBNIiwibG4iOiI5MV8xMjM0NTY3ODk5X1MifSwiYyI6bnVsbH0.WQS1xS1SVu_PYTBL5ne_bypJKh0AmTDkZqpVuEhncas';
    //   let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*',
    //   'content-type': 'application/json',
    //   'Authorization':this.token,

    //  });


    return this.http.get<any>(this.API_URL + transurl)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }


  betprofitandlost(fromDate = 0, toDate = 0): Observable<any> {

    var dates = new Date()
    var currentdate = Number(dates);
    let previousdatelastweek = currentdate - 7 * 24 * 60 * 60 * 1000


    return this.http.get<any>(this.API_URL + 'account/' + this.membercode + '/reports/pnl?from=' + `${fromDate ? fromDate : previousdatelastweek}` + '&to=' + `${toDate ? toDate : currentdate}`)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }

  changepasswords(val: any): Observable<any> {

    //  let Token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJDb2RlIjoiVDEwMTAxMDFNME0iLCJ0b2tlbklkIjoiZWU4YjkwOGEyODYzOGM0Zjg2Y2FkOGE4ZDIxZTBkOTU3ZjY1NmIxNzZkMjRiMjA1Y2JkNWNhZmIwYjcxOTIxYiIsImxvZ2luQ291bnRyeSI6IklOIiwic2Vzc2lvbklkIjoiZDA2ZDZjMTFlMTFiMDYwZGNlYWI1NDkzMjNjZDUzZjdhZjBiN2E1YTVkZDI2NGQ4MzlhZmI4MDc2OTdlNWFmNCIsImFsbG93U2hha3RpUHJvIjpmYWxzZSwibGFzdExvZ2luVGltZSI6MTY3OTIzODEwMjQ1NywibmJmIjoxNjc5MjM4NTU0LCJsb2dpbk5hbWUiOiI5MV8xMjM0NTY3ODk5IiwibG9naW5JUCI6IjIwMi4xNDkuMjA0LjcyIiwidGhlbWUiOiJsb3R1cy1lIiwiZXhwIjoxNjc5Mjc0NTU0LCJpYXQiOjE2NzkyMzg1NTQsIm1lbWJlcklkIjo3Mzg4MTMsInVwbGluZXMiOnsiQ09ZIjp7InVzZXJJZCI6MSwidXNlckNvZGUiOiJhZG1pbi51c2VyIn0sIlNNQSI6eyJ1c2VySWQiOjY1NTU4MiwidXNlckNvZGUiOiJUMTAxIn0sIk1BIjp7InVzZXJJZCI6NjU1NTgzLCJ1c2VyQ29kZSI6IlQxMDEwMSJ9LCJBZ2VudCI6eyJ1c2VySWQiOjY1NTU4NCwidXNlckNvZGUiOiJUMTAxMDEwMSJ9fSwiY3VycmVuY3kiOiJJTlIiLCJpc0RlbW8iOmZhbHNlLCJtYSI6IlkiLCJiIjp7ImlkIjo3Mzg4MTQsIm1jIjoiVDEwMjAxMDFNME0iLCJsbiI6IjkxXzEyMzQ1Njc4OTlfQiJ9LCJzIjp7ImlkIjoxODc0NTQ4LCJtYyI6IlQxMDMwMTAxTTBNIiwibG4iOiI5MV8xMjM0NTY3ODk5X1MifSwiYyI6bnVsbH0.WQS1xS1SVu_PYTBL5ne_bypJKh0AmTDkZqpVuEhncas';
    //   let headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*',
    //   'content-type': 'application/json',
    //   'Authorization':this.token


    //  });



    return this.http.put<any>(this.API_URL + this.changpswrd, val)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }



  getBankAccountList(): Observable<any> {
    let accountlist = 'payments/userid/account-list';
    return this.http.get<any>(this.API_URL + accountlist)
      .pipe(
        retry(0),
        tap(res => {
          return res;
        }),
        shareReplay()
      );

  }
  //ADD BANK ACCOUNT OF USER
  addBankAccount(data: { "ifsc": string, "accountNo": string | number, "accountName": string }): Observable<any> {
    let accountlist = 'payments/userid/add-bank-account';
    return this.http.post<any>(this.API_URL + accountlist, data)
      .pipe(
        retry(0),
        tap(res => {
          return res;
        }),
        shareReplay()
      );

  }


  accountusecurentacountdata() {

    return this.http.get<any>(this.API_URL + 'payments/userid/account-list')
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }




  bankdetailsshowafterenterifsccode(ifsccode: any) {


    return this.http.get<any>(this.API_URL + 'payments/userid/ifsc?ifsc=' + ifsccode)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );

  }


  withdrawfunds(withdrawss: any): Observable<any> {

    return this.http.post<any>(this.API_URL + 'payments/userid/withdraw-request', withdrawss)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }


  paymentgatewaylist(): Observable<any> {

    let gatelist = 'payments/userid/payment-gateways';


    return this.http.get<any>(this.API_URL + gatelist)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }

  getPromotion(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'account/promotions?type=DEPOSIT')
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }
  getDetailedBonusInfo(): Observable<any> {
    return this.bonusDetailedInfo.asObservable();
  }
  setDetailedBonusInfo(data) {
    this.bonusDetailedInfo.next(data);
  }

  getWalletDetails(type): Observable<any> {
    return this.http.get<any>(this.API_URL + 'account/wagering-details?walletType=' + type)
      .pipe(
        retry(0),
        tap(res => {
          return res
        }),
        shareReplay()
      );
  }

  paymentgatewayaccountdata(id: any): Observable<any> {

    let gatelist = 'payments/userid/gateway?gatewayId=' + id;

    return this.http.get<any>(this.API_URL + gatelist)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }


  redirectToPaymentGateway(data: any): Observable<any> {
    if (data) {
      return this.http.post<any>(this.API_URL + 'pg/initiate_payment', data)
        .pipe(
          retry(0),
          tap(res => {
            // if ([444, 403].indexOf(res.code) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            // }
            return res;
          }),
          shareReplay()
        );
    } else {
      return (null); // Return an empty observable if data is falsy
    }
  }
  depositmoney(depodata: any, isFormData: boolean): Observable<any> {
    if (isFormData) {
      return this.http.post<any>(this.API_URL + 'payments/userid/deposit-request-form', depodata)
        .pipe(
          retry(0),
          tap(res => {

            // if ([444, 403].indexOf(res.code) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            // }
            return res;
          }),
          shareReplay()
        );
    }
    console.log("from service", depodata)
    return this.http.post<any>(this.API_URL + 'payments/userid/deposit-request', depodata)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }

  // newDepositMoney(depodata : any): Observable<any> {
  //   return

  // }


  balanceamount(): Observable<any> {
    if (this.requestTracker.isRequestPending('balance')) {
      return empty();
    }

    return this.requestTracker.trackRequest('balance', this.http.get<any>(this.API_URL + this.balanceapi)
      .pipe(
        retry(0),
        tap((res: Balance) => {
          if (!res.success) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            // location.reload(true);
            localStorage.removeItem('ROYALTOKEN_KEY');
            localStorage.removeItem('userData');
            this.router.navigate(['/']);
          }
          this.balanceDataSubject.next(res);
          return res;
        }),
        shareReplay(),
        finalize(() => {
          this.requestTracker.markRequestAsComplete('balance');
        })
      ))
  }
  getBalanceData(): Observable<any> {
    return this.balanceDataSubject.asObservable();
  }
  bonusWallet(bWallet, wCode): Observable<any> {
    // console.log("!!!!!!!!!!!!!!!!!", bWallet, 'wCode', wCode)
    return this.http.put<any>(this.API_URL + 'account/' + this.membercode + '/settings/switch-wallet?useBonusWallet=' + bWallet + '&walletCode=' + wCode, {})
      .pipe(
        retry(0),
        tap((res: any) => {
          //console.log('Inside balanceamount value of res:' +  JSON.stringify(res));
          if ([444, 403].indexOf(res.code) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            // location.reload(true);
            localStorage.removeItem('ROYALTOKEN_KEY');
            localStorage.removeItem('userData');
            this.router.navigate(['/']);
          }
          return res;
        }),
        shareReplay()
      );
  }

  deposittransactionreportcancel(requestId, data): Observable<any> {

    return this.http.post<any>(this.API_URL + 'payments/userid/cancel-request?requestId=' + requestId, data)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }

  deposittransactionreportedit(requestId, data): Observable<any> {

    return this.http.post<any>(this.API_URL + 'payments/userid/update-deposit-request?requestId=' + requestId, data)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }
  transactionUpdate(requestId): Observable<any> {
    return this.http.get<any>(this.API_URL + 'payments/userid/txns?requestId=' + requestId)
      .pipe(
        retry(0),
        tap(res => {

          // if ([444, 403].indexOf(res.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          // }
          return res;
        }),
        shareReplay()
      );
  }

  favouritegamelist(singleitem) {
    const list = JSON.parse(localStorage.getItem("gamelist"));
    list.splice(singleitem, 1);
    localStorage.setItem("gamelist", JSON.stringify(list));
  }


}

