import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { MyprofileService } from 'src/app/services/myprofile.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  numOfDays: number;
  year: number = 1960;
  currentYear: number = new Date().getFullYear();
  currentDate = new Date();
  dates: any[] = [];
  // week days
  weekDays: string[] = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  firstDayOfMonth: number;
  currentMonth: number = 0;
  indexOfFirstDay: number;
  indexofLastDay: number;

  startDate = new Date();
  endDate = new Date();

  indexOfSelectedStartDate: number;
  indexOfSelectedEndDate: number;
  showYears: boolean = false;
  years: number[] = [];
  showCalender: boolean = true;
  showMonthPopup: boolean = false;
  showYearPopup: boolean = false;
  showMonthHeader: boolean = false;
  activeDays: any;
  @Output() selectedStartDateChange = new EventEmitter<any>();
  @Output() selectedEndDateChange = new EventEmitter<any>();
  @Input() startDateInput: any;
  @Input() endDateInput: any;

  constructor(public myProfile: MyprofileService) { }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.currentMonth = new Date().getMonth();
    this.getFirstDay();
    this.getRangeOfYears(this.year);
    this.startDate = this.startDateInput && this.standarDateFormatterToLocal(this.startDateInput);
    this.endDate = this.endDateInput && this.standarDateFormatterToLocal(this.endDateInput);
    this.getActiveDays(this.startDate, this.endDate);
  }

  standarDateFormatterToLocal(date: any) {
    const dateArray = date.split('/');
    return new Date(dateArray[2], dateArray[1] - 1, dateArray[0], 0, 0, 0);
  }

  async getActiveDays(startDate?: any, endDate?: any) {
    this.activeDays = await Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));
    return this.activeDays;
  }

  async getFirstDay() {
    this.dates = [];
    let daysInCurrentMonth = await new Date(this.year, this.currentMonth + 1, 0).getDate();
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      this.dates.push(new Date(this.year, this.currentMonth, i, 0, 0, 0));
    }
    this.firstDayOfMonth = await this.dates[0].getDay();
    if (this.firstDayOfMonth > 0) {
      let lastMonth = await this.currentMonth - 1;
      let lastMonthDays = await new Date(this.year, lastMonth + 1, 0).getDate();
      for (let i = this.firstDayOfMonth - 1; i >= 0; i--) {
        this.dates.unshift(new Date(this.year, lastMonth, lastMonthDays, 0, 0, 0));
        lastMonthDays--;
      }
    }
    let lastDayOfMonth = this.dates[this.dates.length - 1].getDay();
    if (lastDayOfMonth < 6) {
      let nextMonth = await this.currentMonth + 1;
      let nextMonthDays = 1;
      for (let i = lastDayOfMonth + 1; i <= 6; i++) {
        this.dates.push(new Date(this.year, nextMonth, nextMonthDays, 0, 0, 0));
        nextMonthDays++;
      }
    }

    this.indexOfFirstDay = await this.dates.findIndex(date => date.getDate() === 1 && date.getMonth() === this.currentMonth);
    this.indexofLastDay = await this.dates.findIndex(date => date.getDate() === daysInCurrentMonth && date.getMonth() === this.currentMonth);
    
  }

  async nextMonth() {
    this.currentMonth++;
    if (this.currentMonth > 11) {
      this.currentMonth = 0;
      this.year++;
    }
    await this.getFirstDay();
  }

  async previousMonth() {
    this.currentMonth--;
    if (this.currentMonth < 0) {
      this.currentMonth = 11;
      this.year--;
    }
    await this.getFirstDay();
  }

  async selectDateFun(date: any) {
    const formatDate = (date: any) => moment(date).format('DD/MM/YYYY');

    if (date.getMonth() !== this.currentMonth) {
      this.currentMonth = await date.getMonth();
      this.year = await date.getFullYear();
      await this.getFirstDay();
    }

    if (this.startDate && this.endDate) {
      this.startDate = date;
      this.endDate = null;
    } else if (this.startDate && !this.endDate) {
      if (this.startDate > date) {
        this.endDate = this.startDate;
        this.startDate = date;
      } else {
        this.endDate = date;
      }
    } else {
      this.startDate = date;
    }

    this.selectedStartDateChange.emit(formatDate(this.startDate));
    if (this.endDate) {
      this.selectedEndDateChange.emit(formatDate(this.endDate));
    }
    await this.getActiveDays(this.startDate, this.endDate);
  }

  isStartDateOrEndDate(date: any): boolean {
    return this.compareDatesWithoutTime(date, this.startDate) || this.compareDatesWithoutTime(date, this.endDate);
  }

  isBetweenStartAndEnd(date: any): boolean {
    const resetTime = (d: Date) => {
      d.setHours(0, 0, 0, 0);
      return d;
    };

    const startDateWithoutTime = resetTime(new Date(this.startDate));
    const endDateWithoutTime = resetTime(new Date(this.endDate));
    const dateWithoutTime = resetTime(new Date(date));

    return startDateWithoutTime < dateWithoutTime && endDateWithoutTime > dateWithoutTime;
  }

  compareDatesWithoutTime(date1: any, date2: any): boolean {
    const resetTime = (d: Date) => {
      d.setHours(0, 0, 0, 0);
      return d;
    };

    const date1WithoutTime = resetTime(new Date(date1));
    const date2WithoutTime = resetTime(new Date(date2));

    return date1WithoutTime.getTime() === date2WithoutTime.getTime();
  }

  async handleLastDays(days: number) {
    let date = new Date();
    let startDate = new Date(date.setDate(date.getDate() - days));
    this.startDate = startDate;
    this.endDate = new Date();
    // console.log(this.startDate, "start date", this.endDate, "end date");
    this.selectedStartDateChange.emit(moment(this.startDate).format('DD/MM/YYYY'));
    this.selectedEndDateChange.emit(moment(this.endDate).format('DD/MM/YYYY'));
    this.activeDays = days;
  }


  openYears() {
    this.showYearPopup = true;
    this.showCalender = true;
    this.showYears = true;
  }

  openMonths(year: number) {
    this.showYearPopup = false;
    this.showCalender = true;
    this.showMonthPopup = true;
    this.showMonthHeader = true;
    this.showYears = false;
    this.year = year;
  }

  async getRangeOfYears(year?: number) {
    let startYear = year - (year % 10);
    let endYear = startYear + 9;
    let rangeOfYears = [];
    for (let i = startYear; i <= endYear; i++) {
      rangeOfYears.push(i);
    }
    this.years = rangeOfYears;
  }

  async previosYears(year: number) {
    year = year - 10;
    await this.getRangeOfYears(year);
  }

  async nextYears(year: number) {
    year = year + 10;
    await this.getRangeOfYears(year);
  }

  async setMonth(month: string) {
    this.currentMonth = this.months.indexOf(month);
    console.log(this.currentMonth);
    this.showMonthPopup = false;
    this.showMonthHeader = false;
    this.showCalender = true;
    await this.getFirstDay();
  }

}
