<div class="grid" style="    width: 100%;
    padding: 0 0.1rem;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    gap: 0.3rem;
    justify-content: space-evenly;
    margin-top: 0.5rem;">

    <div class="list_item">
        <div class="dotcircle" [ngClass]="{
            'green': step >= 1
        }">
            <div class="dot">
                {{step <= 1 ? '1' : '' }} <svg *ngIf="step > 1" class="check" xmlns="http://www.w3.org/2000/svg"
                    width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path
                        d="M4.31258 7.43734L1.97925 5.104L2.42716 4.65609L4.31258 6.5415L8.31258 2.5415L8.7605 2.98942L4.31258 7.43734Z"
                        fill="#E7EAEF" stroke="#E7EAEF" stroke-width="0.8" />
                    </svg>
            </div>
        </div>
    </div>
    <div class="list_item" *ngIf="step <= 1">
        <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="2" viewBox="0 0 58 2" fill="none">
            <path d="M1 1H57" stroke="#D5D9DE" stroke-linecap="round" stroke-dasharray="2 2" />
        </svg>
    </div>
    <div class="list_item" *ngIf="step > 1">
        <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="1" viewBox="0 0 58 1" fill="none">
            <path d="M1 0.5H57" stroke="url(#paint0_linear_427_15008)" stroke-linecap="round" stroke-dasharray="2 2" />
            <defs>
                <linearGradient id="paint0_linear_427_15008" x1="2.07692" y1="0.5" x2="10.3231" y2="20.0514"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#48CD83" />
                    <stop offset="1" stop-color="#0A743A" />
                </linearGradient>
            </defs>
        </svg>
    </div>

    <div class="list_item">
        <div class="dotcircle" [ngClass]="{
            'green': step >= 2
        }">
            <div class="dot">
                {{step <= 2 ? '2' : '' }} <svg *ngIf="step > 2" class="check" xmlns="http://www.w3.org/2000/svg"
                    width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path
                        d="M4.31258 7.43734L1.97925 5.104L2.42716 4.65609L4.31258 6.5415L8.31258 2.5415L8.7605 2.98942L4.31258 7.43734Z"
                        fill="#E7EAEF" stroke="#E7EAEF" stroke-width="0.8" />
                    </svg>
            </div>
        </div>
    </div>
    <div class="list_item" *ngIf="step <= 2">
        <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="2" viewBox="0 0 58 2" fill="none">
            <path d="M1 1H57" stroke="#D5D9DE" stroke-linecap="round" stroke-dasharray="2 2" />
        </svg>
    </div>
    <div class="list_item" *ngIf="step > 2">
        <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="1" viewBox="0 0 58 1" fill="none">
            <path d="M1 0.5H57" stroke="url(#paint0_linear_427_15008)" stroke-linecap="round" stroke-dasharray="2 2" />
            <defs>
                <linearGradient id="paint0_linear_427_15008" x1="2.07692" y1="0.5" x2="10.3231" y2="20.0514"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#48CD83" />
                    <stop offset="1" stop-color="#0A743A" />
                </linearGradient>
            </defs>
        </svg>
    </div>

    <div class="list_item">
        <div class="dotcircle" [ngClass]="{
            'green': step >= 3
        }">
            <div class="dot">
                {{step <= 3 ? '3' : '' }} <svg *ngIf="step > 3" class="check" xmlns="http://www.w3.org/2000/svg"
                    width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path
                        d="M4.31258 7.43734L1.97925 5.104L2.42716 4.65609L4.31258 6.5415L8.31258 2.5415L8.7605 2.98942L4.31258 7.43734Z"
                        fill="#E7EAEF" stroke="#E7EAEF" stroke-width="0.8" />
                    </svg>
            </div>
        </div>
    </div>
    <div class="list_item" *ngIf="step <= 3">
        <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="2" viewBox="0 0 58 2" fill="none">
            <path d="M1 1H57" stroke="#D5D9DE" stroke-linecap="round" stroke-dasharray="2 2" />
        </svg>
    </div>
    <div class="list_item" *ngIf="step > 3">
        <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="1" viewBox="0 0 58 1" fill="none">
            <path d="M1 0.5H57" stroke="url(#paint0_linear_427_15008)" stroke-linecap="round" stroke-dasharray="2 2" />
            <defs>
                <linearGradient id="paint0_linear_427_15008" x1="2.07692" y1="0.5" x2="10.3231" y2="20.0514"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#48CD83" />
                    <stop offset="1" stop-color="#0A743A" />
                </linearGradient>
            </defs>
        </svg>
    </div>

    <div class="list_item">
        <div class="dotcircle" [ngClass]="{
            'green': step >= 4
        }">
            <div class="dot">
                {{step <= 4 ? '4' : '' }} <svg *ngIf="step > 4" class="check" xmlns="http://www.w3.org/2000/svg"
                    width="11" height="10" viewBox="0 0 11 10" fill="none">
                    <path
                        d="M4.31258 7.43734L1.97925 5.104L2.42716 4.65609L4.31258 6.5415L8.31258 2.5415L8.7605 2.98942L4.31258 7.43734Z"
                        fill="#E7EAEF" stroke="#E7EAEF" stroke-width="0.8" />
                    </svg>
            </div>
        </div>
    </div>


</div>
<div class="grid" style="width: 100%;  display: grid; grid-template-columns: auto auto auto auto; gap: 0.75rem;">
    <div class="steptxt2" [ngClass]="{
        'steptxt': step >= 1
    }">
        {{'Enter Amount'|translate}}
    </div>
    <div class="steptxt2" [ngClass]="{
        'steptxt': step >= 2
    }">
        {{'Payment Method'|translate}}
    </div>
    <div class="steptxt2" [ngClass]="{
        'steptxt': step >= 3
    }">
        {{'Make Deposit'|translate}}
    </div>
    <div class="steptxt2" [ngClass]="{
        'steptxt': step >= 4
    }">
        {{'Proof'|translate}}
    </div>
</div>
