<div class="overlay" *ngIf="isLoader===true">
    <div class="loader-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>

    </div>
</div>
<div class="layout-content-holder-bc">
    <div class="cricket-section">
        <div class="cricket-header">
            <img (click)="goBack()" src="../../../assets/images/details-back.svg" alt="back">
            <span>{{eventType}}</span>
        </div>
        <div class="cricket-card">
            <div class="cricket-card-header" *ngIf="!isActivePolitics"
                [ngClass]="{'cricket':isActiveCricket,'tennis':isActiveTennis,'football':isActiveFootball, politics: isActivePolitics}">
                <div class="one"
                    [ngClass]="{'cricket-1':isActiveCricket,'tennis-1':isActiveTennis,'football-1':isActiveFootball}">
                </div>
                <div class="header-text">
                    <img *ngIf="isActiveCricket" src="../../../assets/images/menu-cricket.png" alt="cricket">
                    <img *ngIf="isActiveFootball" src="../../../assets/images/menu-sports-option.svg" alt="football">
                    <img *ngIf="isActiveTennis" src="../../../assets/images/menu-tennis.svg" alt="tennis">
                    <img *ngIf="isActivePolitics" src="../../../assets/images/menu-politics.svg" alt="politics">
                    <img *ngIf="currentRoute == '/shared/horseracing'"
                        src="../../../assets/images/menu-horse-racing.svg" alt="horse">
                    <img *ngIf="currentRoute == '/shared/greyhoundRacing'"
                        src="../../../assets/images/menu-greyhord-racing.svg" alt="racehorse">
                    <span>{{'In Play'|translate}}</span>
                    <div class=" count-bet">
                        <span>{{ (eventList$ | async)?.length || 0}}</span>
                    </div>
                </div>
            </div>

            <div class="sports-section">
                <ng-container *ngFor="let Sitem of eventList$ | async;let i = index;trackBy:trackByFn">
                    <div *ngIf="Sitem.eventTypeId === '4'">
                        <app-cricket-card [showOdds]="true" [data]="Sitem"></app-cricket-card>
                        </div>
                    <div *ngIf="Sitem.eventTypeId === '1'">
                        <app-football-card [showOdds]="true" [data]="Sitem"></app-football-card>
                        </div>
                    <div *ngIf="Sitem.eventTypeId === '2'">
                        <app-tennis-card [showOdds]="true" [data]="Sitem"></app-tennis-card>
                    </div>
                    </ng-container>
            </div>
        </div>

        <div class="cricket-card" *ngIf="authService.sportsId == '2378962'">
            <div class="sports-section">
                <ng-container *ngIf="isActivePolitics">
                    <ng-container *ngFor="let politicMatches of eventList$ |async; let i = index;trackBy:trackByFn">
                        <div *ngIf="politicMatches.eventTypeId === '2378962'">
                            <app-politics-card [showOdds]="false" [data]="politicMatches"></app-politics-card>
                        </div>
                    </ng-container>
                </ng-container>
        </div>
    </div>
        <ng-container *ngIf="authService.sportsId != '2378962'">
        <div class="upcoming-match-section" *ngIf="!noUpcomingEvents">
            <button (click)="toggleUpcoming(true)" [ngClass]="{'active':isUpcoming}">
                <span>{{'Upcoming'|translate}}</span>
            </button>
            <button (click)="toggleUpcoming(false)" [ngClass]="{'active':!isUpcoming}">
                <span>{{"Leagues"|translate}}</span>
            </button>
            </div>
        <ng-container *ngIf="isUpcoming">
            <ng-container *ngFor="let group of upcomingEvents$ | async;trackBy:trackByFn">
                <div class="match-card upcoming-section">
                    <div class="cricket-card-header"
                        [ngClass]="{'cricket': isActiveCricket, 'tennis': isActiveTennis, 'football':isActiveFootball}">
                        <div class="one"
                            [ngClass]="{'cricket-1': isActiveCricket, 'tennis-1': isActiveTennis, 'football-1': isActiveFootball}">
                            </div>
                        <div class="header-text">
                            <img *ngIf="isActiveCricket" src="../../../assets/images/menu-cricket.png" alt="cricket">
                            <img *ngIf="isActivePolitics" style="filter: invert(1);"
                                src="../../../assets/images/menu-politics.svg" alt="politics">
                            <!-- <img src="../../../assets/images/menu-horse-racing.svg" alt="horse">
                                <img src="../../../assets/images/menu-greyhord-racing.svg" alt="racehorse"> -->
                            <img *ngIf="isActiveFootball" src="../../../assets/images/menu-sports-option.svg"
                                alt="football">
                            <img *ngIf="isActiveTennis" src="../../../assets/images/menu-tennis.svg" alt="tennis">
                            <span>{{group[0].event.openDate | customDate}}</span>
                        </div>
                            </div>
                    <div class="team-section" *ngFor="let data of group;trackBy:trackByFn" routerLink="/shared/details/{{data?.event?.id}}/{{data?.eventTypeId}}">
                        <div class="team-card">
                            <div class="team-body">
                                <div class="score-detail">
                                    <div class="over">
                                        <img src="../../../../assets/images/clock-match.svg" alt="clock">
                                    </div>
                                    <div class="divider"
                                        [ngClass]="{'cricket-div': authService.sportsId == '4', 'tennis-div':authService.sportsId=='2','football-div':authService.sportsId=='1'}">
                                    </div>
                                    <div class="team-name">
                                        <div class="match-name">
                                            <div class="first-team">
                                                <span>{{data?.event?.name | teamNameSplit : 'first'}}</span>
                                            </div>
                                            <div class="second-team">
                                                <span>{{data?.event?.name | teamNameSplit : 'second'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="live">
                                    <img class="tv" src="../../../../assets/images/tv-icon.svg" alt="tv">
                                    <div class="time">
                                        <span>{{getMatchDate(data.event.openDate)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                    </ng-container>
                </ng-container>
        <ng-container *ngIf="!isUpcoming" class="leagues-section">
            <div class="subtitle-header leagues-section">
                <img class="trophy-icon" src="../../../assets/images/trophy-icon.svg" alt="trophy">
                <span>{{'Competitions'|translate}}</span>
            </div>
            <div class="cricket-second-card leagues-section">
                <ng-container>
                    <ng-container *ngFor="let item of matchSummaryData;trackBy:trackByFn">
                        <ng-container *ngFor="let comp of item?.children; let i = index;trackBy:trackByFn">
                            <div class="compitation-card"
                                [routerLink]="['/shared','match-summery',authService.sportsId, comp.id]"  [queryParams]="{ competitionName: comp?.altName }">
                            <div class="compitation-body">
                                <span>{{comp?.name}}</span>
                                    <img src="../../../assets/images/live-right-arrow.svg" alt="right">
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
            </ng-container>
        </ng-container>
                </div>

    <div class="live-page-sections-bc ext-2">
        <div class="special-games-container"></div>
    </div>

    <app-betplace *ngIf="showbet" [eventName]="eventName" [marketName]="marketName" [price]="price"> </app-betplace>
