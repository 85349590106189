<div class="card bonus-card orange-card">
    <!-- <div class="card-body">
        <div class="topbox"></div>
        <div class="bottombox"></div>
        <img loading="lazy" class="giftbox" src="../../../assets/images/fi_6453565.svg">
        <img loading="lazy" class="star1" src="../../../assets/images/Group.svg">
        <img loading="lazy" class="star2" src="../../../assets/images/Group.svg">
        <img loading="lazy" class="star3" src="../../../assets/images/Group.svg">
        <img loading="lazy" class="star4" src="../../../assets/images/Group.svg">
        <p class="card-title">GIFT CARD </p>
        <small class="small-card-title">Type or Paste your promocode and get rewards in your wallet.</small>
        <br>
        <span class="badge rounded-pill" style="padding: 8px;">Activate Now</span>
    </div> -->
    <div class="redeem-card">
        <div class="topbox"></div>
        <div class="redeem-title-box">
            <span class="card-title">GIFT CARD</span>
            <span class="small-card-title">Type or Paste your promocode and get rewards in your wallet.</span>
        </div>
        <div class="redeem-input-box">
            <input class="input-box" type="text" [(ngModel)]="redeemValue" placeholder="Enter Gift Code">
            <button (click)="redeemPromo(redeemValue)" type="submit" class="redeem-btn">
                <span>Redeem</span>
            </button>
        </div>
    </div>
</div>