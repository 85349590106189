import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-withdraw-stepper',
  templateUrl: './withdraw-stepper.component.html',
  styleUrls: ['./withdraw-stepper.component.scss']
})
export class WithdrawStepperComponent implements OnInit {

  constructor() { }
  @Input() step = 1;

  ngOnInit(): void {
  }

}
