import { FormControl, FormGroup } from '@angular/forms'; 


//Validation:To  numberic fields  
export class NumericFieldValidator {

    static ValidNumericField(fc: FormControl) {
        if (fc.value != undefined && fc.value != "") {
            const regex = /[0-9]+/;
            if (regex.test(fc.value)) {
                return null;
            } else {

                return { ValidNumericField: true };
            }
        } else {
            return null;
        }
    }
}

//Validation:To  check 2 fields match
export function MustMatchValidator(controlName: string, matchingControlName: string) {

    return (fromGroup: FormGroup) => {
        const control = fromGroup.controls[controlName];
        const matchingControl = fromGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
            return;
        }
        if (control.value != matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }

}