import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indianCurrency'
})
export class IndianCurrencyPipe implements PipeTransform {
  transform(value: number): string {
    value =Number(value);
    const formattedValue = value?.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR'
    });

    const valueWithoutDecimal = formattedValue?.replace('.00', '').replace('₹', '');

    return valueWithoutDecimal;
  }
}
