import { Component, OnInit } from '@angular/core';
import { BonusService } from './bonus.service';
import { MyprofileService } from 'src/app/services/myprofile.service';
import { LandingService } from 'src/app/services/landing.service';

@Component({
  selector: 'app-bonuses-cards',
  templateUrl: './bonuses-cards.component.html',
  styleUrls: ['./bonuses-cards.component.scss']
})
export class BonusesCardsComponent implements OnInit {

  constructor(
    public _bonusService: BonusService,
    private myProfileService: MyprofileService,
  ) { }

  bonusType = 'All';

  ngOnInit(): void {
    this._bonusService.bonuses = [];
    this._bonusService.getAllBonuses('all');
    this.myProfileService.isHeaderFixed = true;
  }

  hide(){
    this._bonusService.showAllBonuses = false;
  }

  activate(bonusType){
    this.bonusType = bonusType;
  }

  isLogedin(){
    return localStorage.getItem('ROYALTOKEN_KEY') ? true : false;
  }

  ngOnDestroy() {
    this.myProfileService.isHeaderFixed = false;
  }

}
