import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
@Component({
  selector: 'app-sporttournament',
  templateUrl: './sporttournament.component.html',
  styleUrls: ['./sporttournament.component.scss']
})
export class SporttournamentComponent implements OnInit {
  istoken:any;
  constructor(private authService: AuthService) { 
    if (this.authService.currentUserValue==null){
      this.istoken=true;
   }
   else{
     this.istoken=false;
   }
  }

  ngOnInit(): void {
  }

}
