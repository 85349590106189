import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotifyServiceService } from 'src/app/notify/notify-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { MyprofileService } from 'src/app/services/myprofile.service';

@Component({
  selector: 'app-bet-history-card',
  templateUrl: './bet-history-card.component.html',
  styleUrls: ['./bet-history-card.component.scss']
})
export class BetHistoryCardComponent implements OnInit {

  lottieOptions: any = {
    path: 'assets/static/media/loader-lottie.json',
    autoplay: true,
    loop: true,
    controls: true,
  }

  @Input() data = null;
  @Input() item = null;
  @Input() name = null; //for horse racing
  cancelPopup = false;
  isLoader: boolean = false;
  @Input() index = null;
  @Output() removeMe = new EventEmitter();


  constructor(private _authService: AuthService, private _myProfileService: MyprofileService, private _notifyService: NotifyServiceService) { }

  splitRegex = / v | vs. /;
  isVs(str: string) {
    const splitValue = str.split(this.splitRegex);
     return splitValue.length > 1;
  }

  cleanhhmm(str: string) {
    return str.split('${HH:mm}').join('');
  }

  ngOnInit(): void {
    // this._myProfileService.betHistory();
  }

  cancelBet() {
    this.cancelPopup = true;
  }

  removeBet() {
    this._authService.cancelBet(this.item?.apolloBetId).subscribe((res: any) => {
      this._notifyService.success(res?.status?.statusDesc)
      // this._myProfileService.betHistory();
      this.cancelPopup = false;
      this.removeMe.emit(this.index);
    }, (err: any) => {
      this._notifyService.error(err?.error?.status?.statusDesc);
      this.cancelPopup = false;
    })

  }

  betNotCancel() {
    this.cancelPopup = false;
  }

}
