import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-how-to-bonus',
  templateUrl: './how-to-bonus.component.html',
  styleUrls: ['./how-to-bonus.component.scss']
})
export class HowToBonusComponent implements OnInit {

  @Input() bonus: any;
  @Output() closeClick: EventEmitter<any> = new EventEmitter<any>();



  fullView = false;

  sanitizeHead = this.sanitizer.bypassSecurityTrustHtml('');
  sanitizeAmount = this.sanitizer.bypassSecurityTrustHtml('');
  sanitizeInfoRules = this.sanitizer.bypassSecurityTrustHtml('');


  constructor(private sanitizer: DomSanitizer) { }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }

  ngOnInit(): void {
    // this.bonus.showHowTo.promoDetails['infoHead']
    this.sanitizeHead = this.sanitizeHtml(this.bonus['infoHead']);
    this.sanitizeAmount = this.sanitizeHtml(this.bonus['infoHeadAmount']);
    this.sanitizeInfoRules = this.sanitizeHtml(this.bonus['infoRules']);
  }

  ViewMore() {
    this.fullView = true;
  }

  closeHowTo() {
    console.log('working');
    this.closeClick.emit(true);
  }

  hide() {
    this.bonus = undefined;
  }

}
