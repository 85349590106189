import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-register-popup',
  templateUrl: './register-popup.component.html',
  styleUrls: ['./register-popup.component.scss']
})
export class RegisterPopupComponent implements OnInit {

  @Output() closeClick: EventEmitter<any> = new EventEmitter();
  @Input() title = "";

  constructor() { }

  ngOnInit(): void {
    this.closeClick
  }

  closeClicked() {
    this.closeClick.emit(true);
  }

}
