<div class="overlay" *ngIf="isLoader">
    <div class="loader-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                <!-- <img loading="lazy" class="loader1" src="assets/images/loader-gif.gif" alt="Your Image"> -->
                <ng-lottie [options]="lottieOptions" width="150px" height="150px"></ng-lottie>

    </div>
</div>


<!--<div class="bonus-page">-->
<!--<div class="bonus-page__banner">-->
<!--  <img loading="lazy" src="../../../assets/images/bonus/offer.png">-->
<!--</div>-->
<!--  <div class="bonus-page__bonuses-wrapper">-->
<!--    <div class="bonus-page__bonus" *ngFor="let bonus of bonusData">-->
<!--      <div class="bonus-page__bonus-image">-->
<!--        <img loading="lazy" [src]="bonus.promoImageUrl" alt="">-->
<!--      </div>-->
<!--      <div class="bonus-page__bonus-code">-->
<!--        {{bonus?.promoCode}}-->
<!--      </div>-->
<!--      <div class="bonus-page__bonus-title">-->
<!--        {{bonus?.tagLine}}-->
<!--      </div>-->
<!--      <div class="bonus-page__bonus-button"></div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
