import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-balance-popup',
  templateUrl: './balance-popup.component.html',
  styleUrls: ['./balance-popup.component.scss']
})
export class BalancePopupComponent implements OnInit {
  @Output() closeClick: EventEmitter<any> = new EventEmitter();
  @Input() title = "";
  constructor() { }
  ngOnInit(): void {
    this.closeClick
  }
  closeClicked() {
    this.closeClick.emit(true);
  }
}