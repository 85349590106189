import {Component, Input, OnInit, ChangeDetectorRef, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { NotifyServiceService } from 'src/app/notify/notify-service.service';
import {BettingHelperService} from 'src/app/services/betting-helper.service';
import {BetSlipData} from "../../../models/BetSlipData";

@Component({
  selector: 'app-matchbetcard',
  templateUrl: './matchbetcard.component.html',
  styleUrls: ['./matchbetcard.component.scss']
})
export class MatchbetcardComponent implements OnInit {

  @Input() matchData: any;
  @Input() exposure: any;
  @Input() isRace: boolean = false;
  @Output() showLadder = new EventEmitter<any>();
  openBetSlipId: any;
  betData: {
    eventId: any,
    bet: any, // bet price
    sportsId: any, // matchData.eventTypeId
    // backLay:any, // matchData.market.consolidatedRunner
    isBack: any, // true or false
    oddIndex: any, // index of odd
    marketId: any, // matchData.market.id
    betDelay: any // matchData.market.betDelay,
    runnerName: any // matchData.market.consolidatedRunner.back[index].runner
    runnerId: any;
  };

  applyBackAnimation: any = [];
  applyLayAnimation: any = [];
  applyLayAnimationFancy: boolean = false;
  applyBackAnimationFancy: boolean = false;

  previousBackPrices: any = [];
  previousLayPrices: any = [];
  runnersPl = [];
  openBetSlipIndex: any = -1;
  protected readonly isNaN = isNaN;

  constructor(private cdr: ChangeDetectorRef, public betHelper: BettingHelperService, private notify: NotifyServiceService) { }

  ngOnInit(): void {
    this.renderExposure();
    this.betHelper.currentBetSlip.subscribe((data) => {
      if (data?.cardId == this.matchData?.id){
        this.renderExposure();
      }
    });
    this.betHelper.currentExposure.subscribe((data) => {
      if (data.eventId == this.matchData?.event.id){
        this.renderExposure();
      }
    });
  }


  // how to use animation for match data response
  ngOnChanges(changes: SimpleChanges) {
    if (this.matchData?.name == "Match Odds" || this.matchData?.id?.endsWith('_SB') || this.matchData?.id?.endsWith('_BR')) {
      for (let i = 0; i < this.matchData?.runners?.length; i++) {
        if (changes['matchData'] && changes['matchData']?.currentValue && changes['matchData']?.previousValue) {

          const backPrices = changes['matchData']?.currentValue?.runners[i]?.back[0]?.size || changes['matchData']?.currentValue?.runners[i]?.back[0]?.price;
          this.previousBackPrices[i] == undefined ? backPrices : this.previousBackPrices[i]?.size || this.previousBackPrices[i]?.price;

          if (this.previousBackPrices[i] !== backPrices) {
            this.applyBackAnimation[i] = true;
            this.previousBackPrices[i] = backPrices;
            setTimeout(() => {
              this.applyBackAnimation[i] = false;
            }, 300);
          }

          const layPrices = changes['matchData']?.currentValue?.runners[i]?.lay[0]?.size || changes['matchData']?.currentValue?.runners[i]?.lay[0]?.price;
          this.previousLayPrices[i] == undefined ? layPrices : this.previousLayPrices[i]?.size || this.previousLayPrices[i]?.price;

          if (this.previousLayPrices[i] !== layPrices) {
            this.applyLayAnimation[i] = true;
            this.previousLayPrices[i] = layPrices;
            setTimeout(() => {
              this.applyLayAnimation[i] = false;
            }, 300);
          }

        }
      }
    }else{
      if (changes['matchData'] && changes['matchData']?.currentValue && changes['matchData']?.previousValue) {

        const backPrices = changes['matchData']?.currentValue?.runners[0]?.back[0]?.size || changes['matchData']?.currentValue?.runners[0]?.back[0]?.price;
        this.previousBackPrices == undefined ? backPrices : this.previousBackPrices?.size || this.previousBackPrices?.price;

        if (this.previousBackPrices !== backPrices) {
          this.applyBackAnimationFancy = true;
          this.previousBackPrices = backPrices;
          setTimeout(() => {
            this.applyBackAnimationFancy = false;
          }, 300);
        }

        const layPrices = changes['matchData']?.currentValue?.runners[0]?.lay[0]?.size || changes['matchData']?.currentValue?.runners[0]?.lay[0]?.price;
        this.previousLayPrices == undefined ? layPrices : this.previousLayPrices?.size || this.previousLayPrices?.price;

        if (this.previousLayPrices !== layPrices) {
          this.applyLayAnimationFancy = true;
          this.previousLayPrices = layPrices;
          setTimeout(() => {
            this.applyLayAnimationFancy = false;
          }, 300);
        }

      }
    }

  }

  closeBetSlip() {
    this.betHelper.cardId = null;
    this.betHelper.itemId = null;
    this.betHelper.selectedButton = null;
    this.betHelper.profitAndLiability = null;
    this.betHelper.stakeOnBet = null;
    this.betData = null;
    this.betHelper.price = null;
    this.betHelper.stakes = null;
    this.betHelper.currentBetslipSubject.next(new BetSlipData());
    this.renderExposure()
  }

  openBetSlip(price, index, isBack) {
    if (!this.matchData.isBettable){
      return;
    }
    if (price == null) {
      return;
    }
    if(this.matchData?.statusLabel==='Ball Running' && this.matchData?.status ==='OPEN'){
      return;
    }
    if(this.matchData?.name == "Match Odds"){
      if(price > 10){
        this.notify.error("Bets are not allowd for odds greater than 10");
        return;
      }
    }
    this.betData = {
      eventId: this.matchData?.event?.id,
      bet: price,
      sportsId: this.matchData.eventTypeId,
      isBack: isBack,
      oddIndex: index,
      marketId: this.matchData?.id, //
      betDelay: this.matchData?.betDelay,
      runnerName: this.matchData?.runners[index]?.name,
      runnerId: this.matchData?.runners[index]?.id,
    };
    // this.selectedButton = index.toString() + '_' + isBack ? 'back' : 'lay';
    this.betHelper.selectedButton = this.matchData?.id + '_' + this.matchData.runners[index].id + `${index}_${isBack ? "back" : "lay"}`;
    this.betHelper.cardId = this.matchData?.id;
    this.betHelper.itemId = this.matchData?.runners[index]?.id;
    this.betHelper.selectedTeam = this.matchData?.id + '_' + this.matchData.runners[index].id;
    this.betHelper.selectedMatchCard = this.matchData?.name;
    this.betHelper.selectedCard = 'match';

    this.renderExposure();
    this.cdr.detectChanges();

  }

  openFancyBetSlip(price, index, isBack, item) {
    if (price == null) {
      return;
    }
    this.betData = {
      eventId: this.matchData?.event?.id,
      bet: price,
      sportsId: this.matchData.eventTypeId,
      isBack: isBack,
      oddIndex: index,
      marketId: this.matchData?.id, //
      betDelay: this.matchData?.betDelay,
      runnerName: this.matchData?.runners[index]?.name,
      runnerId: this.matchData?.runners[index]?.id,
    };
    this.betHelper.cardId = this.matchData?.id;
    this.betHelper.itemId = this.matchData?.runners[index]?.id;
    this.betHelper.selectedCard = "fancy";

    this.betHelper.itemId = this.matchData?.runners[index]?.id;
    this.betHelper.selectedTeam = this.matchData?.id + '_' + this.matchData.runners[index].id;
    this.betHelper.selectedMatchCard = this.matchData?.name;
    this.renderExposure();
    this.cdr.detectChanges();
  }

  trackByMethod(index: number, el: any): number {
    return el.id;
  }

  calculatePrice(item: any) {
    if (item && item > 0) {
      const calculatedPrice = ((item - 1) * 100).toFixed(2);
      const formattedPrice = calculatedPrice.replace(/\.00$/, '');
      return formattedPrice;
    } else {
      return '-';
    }
  }


  renderExposure() {
    this.runnersPl = [];
    this.matchData?.runners?.forEach((runner, index) => {
      let calced = 0;
      let number = 0;
      let isFancy = this.matchData?.id?.endsWith("FY");
      const pnlId = isFancy ? this.matchData.id : `${this.matchData.id}-${runner.id}`;
      if (this.exposure && this.exposure.pnlBySelection && this.exposure.pnlBySelection[pnlId]){
        number = this.exposure.pnlBySelection[pnlId].pnl;
      }

      if (this.betData && this.betData.marketId === this.matchData.id) {
        if (this.betData.runnerId === runner.id) {
          if (this.betData.isBack) {
            // const profit = isFancy ? (betPlacing.stake * betPlacing.priceActual / 100).toFixed(2) : (betPlacing.stake * betPlacing.priceActual - betPlacing.stake).toFixed(2)
            const profit = isFancy
              ? this.betHelper.stakes
              : (
                this.betHelper.stakes * this.betHelper.price -
                this.betHelper.stakes
              ).toFixed(2);
            calced = (isFancy ? 0 : number) + Number(profit) * (isFancy ? -1 : 1);
          } else {
            const profit = isFancy
              ? this.betHelper.stakes * (this.betHelper.price / 100)
              : (
                this.betHelper.stakes * this.betHelper.price -
                this.betHelper.stakes
              ).toFixed(2);
            calced = (isFancy ? 0 : number) + Number(profit) * -1;
          }
        } else {
          calced = this.betData.isBack
            ? number - Number(this.betHelper.stakes)
            : number - Number(this.betHelper.stakes) * -1;
          // calced = number - Number(betPlacing.stake)
        }
      }

      // this.preCalced = this.isFancy && this.number == 0 ? "Max exposure: " : "";
      this.runnersPl.push({
        runnerId: runner.id,
        oldExposure: number.toFixed(2),
        newExposure: calced == 0 ? null : calced.toFixed(2)
      })
    });

    this.cdr.detectChanges();
  };

  getRunnerPl(runnerId) {
    if (this.runnersPl.length == 0) return {};
    return this.runnersPl.filter((runner) => runner.runnerId === runnerId)[0];
  }


  showmarket(item: any) {
    this.showLadder.emit(item);
  }
}
