import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-win-bonus-card',
  templateUrl: './win-bonus-card.component.html',
  styleUrls: ['./win-bonus-card.component.scss']
})
export class WinBonusCardComponent implements OnInit {
  @Input() bonus: any;

  constructor() { }

  ngOnInit(): void {
  }

}
