import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CasinoService } from 'src/app/services/casino.service';
import { LandingService } from 'src/app/services/landing.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dreamwheel',
  templateUrl: './dreamwheel.component.html',
  styleUrls: ['./dreamwheel.component.scss']
})
export class DreamwheelComponent implements OnInit {
  showplaybutton: any = true;
  allgames: any = [];
  mygame: any = [];
  showhideprovider: any = true;
  gamepath = environment.gamespath;
  gameCategory: any = [];
  allgamesactive: any = 'all';
  isActivefavlive: any;
  istoken: any;
  casinoUrl: string;
  favouriteGamelistcasino: any = [];
  filteredArray: any;
  sliderImage: any;
  pointPopUpOpen = true;
  Images: any;
  sliderlConfig = {
    slidesToShow: 3,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,

        }
      }

    ]
  };
  isMobile: boolean = false;
  private urlIframeSubscription: Subscription;
  urlIframe: string;
  public yt: any;
  @ViewChild('iframeLoad', { static: false }) iframeLoad: ElementRef;
  isIphoneChrome = (navigator.userAgent.includes("iPhone") && navigator.userAgent.includes('CriOS'));
  isIphoneSafari = (navigator.userAgent.includes("iPhone") && !navigator.userAgent.includes('CriOS'));
  title: string
  // @ts-ignore
  isSafariPWA = navigator?.standalone

  constructor(private authService: AuthService, private api: LandingService, private casinoServices: CasinoService, private faverate: LandingService) {
    if (this.authService.currentUserValue == null) {
      this.istoken = true;
    }
    else {
      this.istoken = false;
    }
  }

  ngOnInit(): void {
    this.urlIframeSubscription = this.casinoServices.casinoURLObservable$.subscribe((url: string | null) => {
      if (!url && this.urlIframe) {
        this.clearIframe()
      }
      if (url) {
        this.openGame(url)
      }
      this.urlIframe = url;
    })
    this.checkIfMobile();
    this.api.sliderimage().subscribe((res: any) => {
      res;
      this.sliderImage = res;
      this.Images = res.data;
      this.filteredArray = res?.result?.inPlaySlidingBanners;
      this.casinogame();
    })
    this.favouriteGame(null, null)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 1024;
  }

  casinogame() {
    this.casinoServices.getCategoryWiseCasinoGames(18).subscribe((res: any) => {
      this.gameCategory = res?.result;
    });
  }
  //   favouriteGamelive(selectedgames:any,imageurl:any){

  //     this.isActivefavlive =selectedgames;
  //     this.favouriteGamelistcasino.push(imageurl);
  //     this.casinoServices.favouritegame(imageurl);
  //     this.faverate.favouriteimage.next(this.favouriteGamelistcasino);
  //   // alert("favourite games added");
  // }

  favouriteGame(selectedgames: any, imageurl: any) {

    var gamesl = localStorage.getItem("gamelist");

    if ((gamesl == null) && (imageurl != null)) {
      this.casinoServices.equipment = []

      // this.isActivefav = selectedgames;
      this.casinoServices.favouritegame(imageurl);
      this.favouriteGamelistcasino = this.casinoServices.equipment;
      this.faverate.favouriteimage.next(this.favouriteGamelistcasino);
    } else if (gamesl.includes(imageurl)) {


      const list = this.casinoServices.equipment;

      const index = list.indexOf(imageurl);
      this.casinoServices.equipment.splice(index, 1);
      // this.casinoServices.favouritegame(list);
      localStorage.setItem("gamelist", JSON.stringify(this.casinoServices.equipment));
      this.favouriteGamelistcasino = this.casinoServices.equipment;
      this.faverate.favouriteimage.next(this.favouriteGamelistcasino);

      // }




    } else if ((imageurl != null) && (gamesl != null)) {


      this.casinoServices.favouritegame(imageurl);
      this.favouriteGamelistcasino = this.casinoServices.equipment;
      this.faverate.favouriteimage.next(this.favouriteGamelistcasino);
    } else {

    }

  }
  goToGame(id, game = null) {
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = ''
    }
    if (game) {
      this.title = game?.name || game?.Trans.en
    }

    let cId = id.slice(0, 4);

    if (this.getToken()) {
      this.casinoServices.getCasinoUrl(id).subscribe(
        res => {
          // this.casinoUrl=res.result.url;

          // window.open(this.casinoUrl);
          // this.urlIframe = res?.result?.url;
          // this.openGame(this.casinoUrl)
        },
        error => {
        }
      );
    }
    else {
      document.getElementById("demo").click();
    }
  }

  clearIframe() {
    if (this.iframeLoad && this.iframeLoad.nativeElement) {
      this.iframeLoad.nativeElement.innerHTML = ''
    }
    this.title = ""
    this.urlIframe = ""
    this.casinoServices.clearCasinoURL()
  }

  openGame(iurl) { // INIT IFRAME
    this.yt = '<iframe style="width: 100%; height: 100%" class="poker-game-iframe" src="' + iurl + '" frameborder="0" id="casino-fram" allow="autoplay; encrypted-media"  allowfullscreen></iframe>';
    this.iframeLoad.nativeElement.innerHTML = this.yt;
  }
  getToken() {
    return localStorage.getItem('ROYALTOKEN_KEY');
  }
  closePointPopUp() {
    this.pointPopUpOpen = false
  }

}



